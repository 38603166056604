import HttpService from "@/services/HttpService";
import type { PermissionGroupDto } from "./types";

const baseUrl = "permissions";

export class PermissionsService {

	api: HttpService;

	constructor(api: HttpService) {
		this.api = api;
	}

	getMap(): Promise<Record<string, number>> {
		return this.api.get(`/${baseUrl}/map`);
	}

	getTree(): Promise<PermissionGroupDto[]> {
		return this.api.get(`/${baseUrl}/tree`);
	}
}

export * from "./types";

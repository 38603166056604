import L from "leaflet"
import {Colors} from "@blueprintjs/core"

const iconsCache = {}
export const WPIcon = (index) => {
    if (iconsCache[index])
        return iconsCache[index]

    const icon = L.divIcon({
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        html: `<div class="core">${index}</div>`,
        className: "wp-icon bp3-elevation-3",
    })
    iconsCache[index] = icon
    return icon
}

const WPIconSelectedCache = {}

export const WPIconSelected = (index) => {
    if (WPIconSelectedCache[index])
        return WPIconSelectedCache[index]
    const icon = L.divIcon({
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        html: `<div class="core">${index}</div>`,
        className: "wp-icon bp3-elevation-3 selected",
    })
    WPIconSelectedCache[index] = icon
    return icon
}

export const WPStartIcon = L.divIcon({
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    html: "<div class=\"core\">S</div>",
    className: "wp-icon bp3-elevation-3",
})

export const WPStartIconSelected = L.divIcon({
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    html: "<div class=\"core\">S</div>",
    className: "wp-icon bp3-elevation-3 selected",
})

export const POIIcon = L.divIcon({
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    html: "<span class=\"bp3-icon-standard bp3-icon-eye-open\"></span>",
    className: "poi-icon bp3-elevation-3",
})

export const WPStartIconAction = L.divIcon({
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    html: "<div class=\"core\">S</div>",
    className: "wp-icon bp3-elevation-3 action",
})

const WPIconActionCache = {}

export const WPIconAction = (index) => {
    if (WPIconActionCache[index])
        return WPIconActionCache[index]
    const icon = L.divIcon({
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        html: `<div class="core">${index}</div>`,
        className: "wp-icon bp3-elevation-3 action",
    })
    WPIconActionCache[index] = icon
    return icon
}

export const getMarkerIcon = ({color}) => L.divIcon({
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    html: `<span style="color: ${color}" class="bp3-icon-large bp3-icon-map-marker"></span>`,
    className: "wp-icon bp3-elevation-3 marker",
})

export const MovingPointIcon = L.divIcon({
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    tooltipAnchor: [0, 16],
    html: `<span style="color: ${Colors.COBALT1}" class="bp3-icon-large bp3-icon-tractor"></span>`,
    className: "wp-icon bp3-icon-tank",
})

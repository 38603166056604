import {action, makeObservable, observable} from "mobx"
import type {Waypoint} from "@/model/Waypoint"
import {WPIcon, WPIconSelected, WPStartIcon, WPStartIconSelected, WPStartIconAction, WPIconAction} from "@/components/plans/WaypointIcons"
import ActionsStore from "@/store/ActionsStore"

export default class WaypointStore {
    index: number
    @observable waypoint: Waypoint
    @observable selected: boolean
    @observable distBefore: number
    @observable distAfter: number

    constructor(index: number, waypoint: Waypoint) {
        this.index = index
        this.waypoint = waypoint
        this.waypoint.actions = new ActionsStore(this.waypoint.actions.items)
        makeObservable(this)
    }

    getIcon(value) {
        if (value > 0) {
            if (this.index === 0)
                return this.selected ? WPStartIconSelected : WPStartIconAction
            return this.selected ? WPIconSelected(this.index) : WPIconAction(this.index)
        }

        if (this.index === 0)
            return this.selected ? WPStartIconSelected : WPStartIcon
        const icon = this.selected ? WPIconSelected(this.index) : WPIcon(this.index)
        return icon
    }

    @action setWaypointParam(param) {
        Object.assign(this.waypoint, param)
    }

    @action setDist(before: number, after: number) {
        this.distBefore = Math.round(before)
        this.distAfter = Math.round(after)
    }
}

import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {Button, Intent} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import type {RouteProps} from "@/types/RouteProps"
import StationCreateForm from "@/components/left/admin/StationCreateForm"
import EditStationForm from "@/components/left/admin/EditStationForm"

@withRouter
export default class StationsEditor extends Component<RouteProps<{stationId: string}>> {
    render() {
        return (
            <div className="admin-header" style={{padding: 10, minHeight: "100vh"}}>
                <Button icon={IconNames.ARROW_LEFT}
                        onClick={this.goBack}
                        text={i18next.t("admin.stations-editor.button.back")}
                        intent={Intent.PRIMARY}
                        minimal
                        style={{marginTop: 20, marginLeft: 10}}
                />
                {this.props.match.params.stationId ? <EditStationForm stationId={this.props.match.params.stationId} /> : <StationCreateForm />}
            </div>
        )
    }

    goBack = () => this.props.history.replace("/admin/stations")
}

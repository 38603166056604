import type {JoystickPreset} from "@/model/JoystickPreset"
import HttpService from "@/services/HttpService"

const baseUrl = "/joystick"

export default class JoystickService {
    constructor(api: HttpService) {
        this.api = api
    }

    get() {
        return this.api.get(baseUrl)
    }

    save(preset: JoystickPreset) {
        return this.api.post(baseUrl, preset)
    }

    remove(id: string) {
        return this.api.delete(`${baseUrl}/${id}`)
    }
}

import React, {Component} from "react"
import {Button,
    Classes,
    Dialog,
    FormGroup,
    HTMLTable, InputGroup,
    Intent,
    NumericInput,
    ProgressBar,
    Tab,
    Tabs} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import {observer, Provider} from "mobx-react"
import {JoystickMenuItems} from "@/components/control/JoystickControls"
import type {JoystickMenu} from "@/components/control/JoystickControls"
import {warningToast} from "@/components/AppToaster"
import type {JoystickPreset} from "@/model/JoystickPreset"
import PresetStore from "@/store/PresetStore"

function getGamepads() {
    if (navigator.getGamepads)
        return navigator.getGamepads()
    return navigator.webkitGetGamepads ? navigator.webkitGetGamepads() : []
}

@observer
class ControlRow extends Component<{store: PresetStore, control: any}> {
    interval = null
    state = {
        axes: null,
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval)
    }

    render() {
        const {store, control} = this.props
        const num = store.bindingsByControl[control.id] // Забинденная кнопка/ось
        const pressed = control.axis ? false : store.buttonsPressed[num]
        const value = control.axis ? store.axisValue[num] : undefined
        const sm = store.multipliers[control.id]
        const multiplier = sm === undefined ? control.multiplier : sm

        return (
            <tr>
                <td><p>{i18next.t(control.caption)}</p></td>
                <td>
                    <Button text={this.buttonText} onClick={this.setup} active={pressed} />
                </td>
                <td style={{width: 100, verticalAlign: "middle"}}>
                    {control.axis && num !== undefined && <ProgressBar value={(1.0 + value) / 2.0} animate={false} stripes={false} />}
                </td>
                <td>
                    {control.axis && (
                        <NumericInput
                            style={{width: 60}}
                            buttonPosition="none"
                            defaultValue={multiplier}
                            onValueChange={(n: number) => {
                                if (!Number.isNaN(n))
                                    store.setMultiplier(control.id, n)
                            }}
                        />
                    )}
                </td>
            </tr>
        )
    }

    get buttonText() {
        const {store, control} = this.props
        const setupMode = store.currentControlId === control.id
        const num = store.bindingsByControl[control.id] // Забинденная кнопка/ось

        if (control.axis) {
            if (setupMode)
                return i18next.t("control.joystick-settings.button.move-axis")
            if (num === undefined)
                return i18next.t("control.joystick-settings.button.setup")
            return i18next.t("control.joystick-settings.button.axis") + num
        }

        if (setupMode)
            return i18next.t("control.joystick-settings.button.press-button")
        if (num === undefined)
            return i18next.t("control.joystick-settings.button.setup")
        return i18next.t("control.joystick-settings.button.button") + num
    }

    setup = () => {
        const gamepads = getGamepads()
        if (!gamepads.length) {
            warningToast(i18next.t("control.joystick-settings.toast.joystick-not-connected"))
            return
        }

        this.props.store.beginSet(this.props.control.id)
        if (this.props.control.axis) {
            this.fixAxes()
            this.interval = setInterval(this.scanAxes, 100)
        } else
            this.interval = setInterval(this.scanButtons, 100)
    }

    fixAxes() {
        const gamepads = getGamepads()

        if (!gamepads.length) return
        const gp = gamepads[0]

        this.setState({axes: gp.axes.slice()})
    }

    scanAxes = () => {
        const gamepads = getGamepads()

        if (!gamepads.length) return
        const gp = gamepads[0]

        for (let i = 0; i < gp.axes.length; i++) {
            const diff = Math.abs(this.state.axes[i] - gp.axes[i])
            if (diff > 0.5) {
                this.setAxis(i)
                return
            }
        }
    }

    setAxis(i: number) {
        clearInterval(this.interval)

        this.setState({axes: null})
        this.props.store.setAxis(i, this.props.control.id)
    }

    scanButtons = () => {
        const gamepads = getGamepads()

        if (!gamepads.length) return
        const gp = gamepads[0]

        for (let i = 0; i < gp.buttons.length; i++) {
            if (gp.buttons[i].pressed) {
                this.setButton(i)
                return
            }
        }
    }

    setButton(i: number) {
        clearInterval(this.interval)

        this.props.store.setButton(i, this.props.control.id)
    }
}

function ControlsPanel({store, menu}: {store: PresetStore, menu: JoystickMenu}) {
    return (
        <HTMLTable>
            <tbody>
                {Object.keys(store.joystick.commands)
                    .map((key) => store.joystick.commands[key])
                    .filter((c) => c.menu === menu.id)
                    .map((c) => <ControlRow key={`ctrl-${c.id}`} store={store} control={c} />)}
            </tbody>
        </HTMLTable>
    )
}

type Props = {
    editPreset: ?JoystickPreset,
    onClose: (settings?: JoystickPreset) => void
}

@observer
export default class JoystickSettings extends Component<Props> {
    store = new PresetStore()

    state = {
        gpConnected: false,
        gpName: null,
    }

    componentDidMount() {
        if (this.props.editPreset)
            this.store.setPreset(this.props.editPreset)
        this.interval = setInterval(this.scanGamepads, 100)
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval)
    }

    render() {
        return (
            <Provider controls={this.controls}>
                <Dialog
                    icon={IconNames.SETTINGS}
                    title={i18next.t("control.joystick-settings.text.joystick-preset") + (this.state.gpConnected ? this.state.gpName : i18next.t("control.joystick-settings.text.not-connected"))}
                    canOutsideClickClose={false}
                    isOpen
                    onClose={this.cancelHandle}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup label={i18next.t("control.joystick-settings.form.name.label")} labelInfo={i18next.t("control.joystick-settings.form.name.label-info")} labelFor="joystick-preset-name">
                            <InputGroup
                                id="joystick-preset-name"
                                placeholder={i18next.t("control.joystick-settings.form.name.placeholder")}
                                onChange={(e) => this.store.setName(e.target.value)}
                                value={this.store.name}
                            />
                        </FormGroup>
                        <Tabs>
                            {JoystickMenuItems.map((m) => (
                                <Tab
                                    key={`panel-${m.id}`}
                                    id={`panel-${m.id}`}
                                    title={i18next.t(m.caption)}
                                    panel={<ControlsPanel store={this.store} menu={m} />}
                                />
                            ))}
                        </Tabs>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.PRIMARY} onClick={this.okHandle}>{i18next.t("control.joystick-settings.button.save")}</Button>
                            <Button onClick={this.cancelHandle}>{i18next.t("control.joystick-settings.button.cancel")}</Button>
                        </div>
                    </div>
                </Dialog>
            </Provider>
        )
    }

    cancelHandle = () => {
        this.props.onClose()
    }

    okHandle = () => {
        const preset = this.store.getPreset()
        if (preset) this.props.onClose(preset)
    }

    scanGamepads = () => {
        const gamepads = getGamepads()
        if (!gamepads.length) {
            if (this.state.gpConnected)
                this.setState({gpConnected: false, gpName: undefined})
            return
        }

        const gp = gamepads[0]

        if (!this.state.gpConnected || this.state.gpName !== gp.id)
            this.setState({gpConnected: true, gpName: gp.id})

        if (this.store.gamepadName !== gp.id)
            this.store.setGamepadName(gp.id)

        gp.buttons.forEach((b, i) => this.store.buttonPressing(i, b.pressed))
        gp.axes.forEach((v, i) => this.store.setAxisValue(i, v))
    }
}

import React, {useContext} from "react"
import {MobXProviderContext, observer} from "mobx-react"
import {LayerGroup, Pane, Polyline} from "react-leaflet"
import {Colors} from "@blueprintjs/core"
import gpsTrackLog, {toLeafletPos} from "@/store/GpsTrackStore"
import {SelectionStore} from "@/store/SelectionStore"
import DroneMarker from "@/components/map/DroneMarker"

const lineOptions = {color: Colors.BLACK}

const TrackMarkers = observer(() => {
    const {selection} = useContext<{selection: SelectionStore}>(MobXProviderContext)
    const coords = gpsTrackLog.getPolylinePos
    const start = coords[0]
    const end = coords[coords?.length - 1]

    if (!gpsTrackLog.currentPosition || !start || !end)
        return <></>

    return (
        <Pane name="track" style={{zIndex: 650}}>
            <DroneMarker
                position={toLeafletPos(gpsTrackLog.currentPosition)}
                yaw={gpsTrackLog.currentPosition?.y}
                droneName={selection.drone.displayName}
            />
        </Pane>
    )
})

const GpsTrackLayer = () => (
    <LayerGroup>
        <TrackMarkers />
        <Pane name="track-route" style={{zIndex: 645}}>
            <Polyline pathOptions={lineOptions} positions={gpsTrackLog.getPolylinePos} />
        </Pane>
    </LayerGroup>
)

export default observer(GpsTrackLayer)

import {Cartesian3, JulianDate} from "cesium"
import type DegreesPosition from "@/models/DegreesPosition"
import type {Waypoint} from "@/model/Waypoint"

export const keyDegrees = ({lat, lon}: DegreesPosition, prefix = null) => `${prefix ? `${prefix}-` : ""}${lat}${lon}`
export const keyCartesian = ({x, y, z}: Cartesian3, prefix = null) => `${prefix ? `${prefix}-` : ""}-${x}-${y}-${z}`

export const pointToCartesian3 = ({lon, lat, height}: DegreesPosition, heightAboveEllipsoid = 0, heightOffset = 0) => Cartesian3.fromDegrees(lon, lat, (height ?? 0) + heightAboveEllipsoid - heightOffset)
export const secondsToJulian = (startTime: JulianDate, timeInSeconds: number) => JulianDate.addSeconds(startTime, timeInSeconds, new JulianDate())
export const getWaypointTupleArray = (waypoints: Waypoint[]): [Waypoint, Waypoint][] => {
    const res = []

    waypoints.forEach((curr, i, arr) => {
        const next = i + 1 <= arr.length - 1 ? arr[i + 1] : null
        if (!next)
            return

        res.push([curr, next])
    })

    return res
}

import {action, makeAutoObservable} from "mobx"

export default class PlaySettings {
    constructor(signalingURL: string, stream: string) {
        this.signalingURL = signalingURL
        this.streamName = stream

        makeAutoObservable(this)
    }

    signalingURL: string;
    applicationName: string;
    streamName: string;

    aspectRatio: number | null = null

    play: boolean = false;
    playStarting = false;
    playStopping = false;

    showErrorAfterAttempt = 0;

    retrying = false;

    error: Error | null = null;

    @action onError = () => {
        this.error = true
    }

    @action setAspect = (aspect: number) => {
        this.aspectRatio = aspect
    }

    @action setPlayStarting = (starting: boolean) => {
        this.playStarting = starting
    }

    @action setPlayStopping = (stopping: boolean) => {
        this.playStopping = stopping
    }

    @action setPlay = (playing: boolean) => {
        this.play = playing
    }

    @action setRetry = (ret: boolean) => {
        this.retrying = ret
        this.waiting = false
    }

    @action reset = () => {
        this.aspectRatio = null

        this.play = false
        this.playStarting = false
        this.playStopping = false
        this.retrying = false

        this.error = null
    }

    @action incrementRetry = () => {
        this.showErrorAfterAttempt += 1
    }

    @action resetRetry = () => {
        this.showErrorAfterAttempt = 0
    }
}

import jwtDecode from "jwt-decode"
import userInfo from "@/store/UserInfoStore"
import globalStore from "@/store/GlobalStore"
import HttpService from "@/services/HttpService"
import permissionsStore from "@/store/PermissionsStore"
import permissionConstants from "@/authorization/authConstants"

const baseUrl = "/auth"

function storeToken(accessToken: string, refreshToken: string) {
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)
}

type TokenResponse = {
    accessToken: string,
    refreshToken: string
}

export default class AuthService {
    constructor(api: HttpService) {
        this.api = api
        this.accessToken = null
    }

    login(login: string, password: string) {
        return this.api.post(baseUrl, {login, password})
            .then((result: TokenResponse) => {
                this.setToken(result.accessToken, result.refreshToken)
            })
    }

    logout() {
        this.setToken(null, null)
        window.location.reload()
    }

    getToken() {
        if (!this.accessToken) {
            const accessToken = localStorage.getItem("accessToken")
            const refreshToken = localStorage.getItem("refreshToken")
            this.setToken(accessToken, refreshToken)
            if (refreshToken) {
                this.api.post(`${baseUrl}/refresh`, {refreshToken})
                    .then((result: TokenResponse) => storeToken(result.accessToken, result.refreshToken))
            }
        }
        return this.accessToken
    }

    setToken(accessToken: string, refreshToken: string) {
        this.accessToken = accessToken
        this.api.httpConfig = null

        if (!accessToken) {
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken")

            globalStore.setLoggedIn(false)
        } else {
            storeToken(accessToken, refreshToken)
            const decoded = jwtDecode(accessToken)
            permissionsStore.setPermissions(decoded[permissionConstants.permissionsClaim])
            userInfo.setRolesInfoFromClaim(decoded[permissionConstants.roleClaim])
            userInfo.username = decoded.name || decoded.preferred_username
        }
    }
}

import {action, IObservableArray, makeObservable, observable} from 'mobx'
import i18next from 'i18next'
import L from 'leaflet'

import type {Waypoint} from '@/model/Waypoint'
import type Route from '@/model/Route'
import type Plan from '@/model/Plan'
import type {StationPosition} from '@/model/Station'
import WaypointStore from '@/store/WaypointStore'
import ActionsStore from '@/store/ActionsStore'
import type Mission from '@/model/Mission'

const routeDefaults = {
	height: 50,
	speed: 5.0,
}

export const maxLastPointDist = 20.0
export const MaxDistBetweenPoints = 4000.0

export default class PlanStore {
    @observable id: string
    @observable name: string
    @observable created: string
    @observable editMode = false
    @observable points: IObservableArray<WaypointStore> = []
    @observable selectedWP: ?WaypointStore
    @observable route: Route = {}
    @observable distance = 0
    @observable sum = 0
    @observable startPoint: StationPosition
    @observable setupPOIMode = false
    @observable anonymous = false

    constructor(plan: ?Plan, startPoint: ?StationPosition) {
    	if (plan) {
    		this.id = plan.id
    		this.name = plan.name
    		this.created = plan.created
    		this.points = plan.points.map((p, i) => new WaypointStore(i, p))
    		this.startPoint = startPoint
    		this.setRouteParam(plan.route)
    		this.calcDist()
    	} else
    		this.setRouteParam(routeDefaults)

    	makeObservable(this)
    }

    get displayName() {
    	if (this.name)
    		return this.name
    	if (this.id)
    		return this.id
    	if (this.anonymous)
    		return i18next.t('store.plan-store.get-display-name-anonymous')

    	return i18next.t('store.plan-store.get-display-name')
    }

    @action setAnonymous(val: boolean) {
    	this.anonymous = val
    }

    @action setName(name) {
    	this.name = name
    }

    @action setMission(m: Mission) {
    	this.mission = m
    }

    @action setEditMode(enabled: boolean) {
    	this.editMode = enabled
    	if (enabled && !this.selectedWP)
    		this.selectFirstWP()
    }

    selectFirstWP() {
    	if (this.points.length)
    		this.selectWP(this.points[0])
    }

    @action selectWP(wp: ?WaypointStore) {
    	if (this.selectedWP) this.selectedWP.selected = false
    	if (wp) wp.selected = true

    	this.selectedWP = wp
    	this.setupPOIMode = false
    }

    @action addWaypoint(ll, index) {
    	const w: Waypoint = {
    		lat: ll.lat,
    		lon: ll.lng,
    		speed: 5,
    		useSpeed: false,
    		height: 50,
    		useHeight: false,
    		actions: new ActionsStore(),
    		isCurve: false,
    	}
    	const m: WaypointStore = new WaypointStore(index, w)
    	if (index === undefined) {
    		m.index = this.points.length
    		this.points.push(m)
    	} else {
    		this.points.splice(index, 0, m)
    		for (let i = index + 1; i < this.points.length; i++)
    			this.points[i].index = i
    	}

    	this.selectWP(m)
    	this.calcDist()
    }

    @action deleteCurrent() {
    	if (!this.selectedWP)
    		return

    	const ind = this.selectedWP.index
    	this.points.splice(ind, 1)
    	for (let i = ind; i < this.points.length; i++)
    		this.points[i].index = i

    	// Select next
    	let newWP = null
    	if (this.points.length > 0) {
    		if (ind < this.points.length)
    			newWP = this.points[ind]
    		else
    			newWP = this.points[this.points.length - 1]
    	}

    	this.selectWP(newWP)
    	this.calcDist()
    }

    @action movePoint(idx, ll) {
    	const p = this.points[idx].waypoint
    	p.lat = ll.lat
    	p.lon = ll.lng
    	this.calcDist()
    }

    @action setRouteParam(param) {
    	Object.assign(this.route, param)
    }

    @action copyFrom(plan: PlanStore) {
    	this.id = plan.id
    	this.name = plan.name
    	this.points.replace(plan.points.map((s, i) => new WaypointStore(i, s.waypoint)))
    	this.setRouteParam(plan.route)
    	this.selectFirstWP()
    	this.calcDist()
    }

    @action reset() {
    	this.id = undefined
    	this.name = undefined
    	this.points.clear()
    	this.setRouteParam(routeDefaults)
    	this.selectedWP = null
    	this.distance = 0
    }

    @action update(name: string, route: Route, points: Waypoint[]) {
    	this.name = name
    	this.route = route
    	this.points.replace(points.map((w, i) => new WaypointStore(i, w)))
    	this.calcDist()
    }

    @action calcDist() {
    	if (!this.points.length) {
    		this.distance = 0
    		return
    	}

    	let distBefore
    	let d = 0
    	let p = L.latLng(this.points[0].waypoint.lat, this.points[0].waypoint.lon)

    	if (this.startPoint) {
    		distBefore = p.distanceTo(L.latLng(this.startPoint.lat, this.startPoint.lon))
    		d += distBefore
    	}

    	for (let i = 1; i < this.points.length; i++) {
    		const n = L.latLng(this.points[i].waypoint.lat, this.points[i].waypoint.lon)
    		const dist = n.distanceTo(p)
    		d += dist
    		p = n

    		this.points[i - 1].setDist(distBefore, dist)
    		distBefore = dist
    	}

    	if (this.startPoint) {
    		const distAfter = p.distanceTo(L.latLng(this.startPoint.lat, this.startPoint.lon))
    		d += distAfter
    		this.points[this.points.length - 1].setDist(distBefore, distAfter)
    	}

    	this.distance = Math.round(d)
    }

    @action setStartPoint(startPoint: StationPosition) {
    	this.startPoint = startPoint
    	this.calcDist()
    }

    @action setupPOI(val: boolean) {
    	this.setupPOIMode = val
    }

    @action removePOI() {
    	this.selectedWP.waypoint.pointOfInterest = null
    }

    @action setPOIPosition(ll: {lat: number, lng: number}, index) {
    	const p = index === undefined ? this.selectedWP : this.points[index]
    	p.waypoint.pointOfInterest = {
    		lat: ll.lat,
    		lon: ll.lng,
    		height: 0,
    	}
    	this.setupPOIMode = false
    }

    validateDrone(totalBattery): ?string {
    	if (totalBattery >= 0.8)
    		return 'Battery usage > 80%'

    	if (this.points.length < 2)
    		return 'Route must include at least 2 points'

    	if (this.points[0].waypoint.isCurve || this.points[this.points.length - 1].waypoint.isCurve)
    		return 'Curve must be not at the first or last point'

    	for (let i = 1; i < this.points.length; i++) {
    		const m = this.points[i]
    		const k = this.points[i - 1]
    		const mLL = L.latLng(m.waypoint.lat, m.waypoint.lon)
    		const kLL = L.latLng(k.waypoint.lat, k.waypoint.lon)
    		if (mLL.distanceTo(kLL) > MaxDistBetweenPoints)
    			return `Distance between two points must be not more than ${MaxDistBetweenPoints}m`
    	}
    	return null
    }

    validateStation(totalBattery): ?string {
    	if (!this.startPoint)
    		return 'Drone with station must have a start point'

    	if (this.points.length < 2)
    		return 'Route must include at least 2 points'

    	const last = this.points[this.points.length - 1]
    	const first = this.points[0]
    	const firstLL = L.latLng(first?.waypoint.lat, first?.waypoint.lon)
    	const lastLL = L.latLng(last?.waypoint.lat, last?.waypoint.lon)
    	const startLL = L.latLng(this.startPoint.lat, this.startPoint.lon)

    	if (lastLL?.distanceTo(startLL) < maxLastPointDist)
    		return `Last point must be at a distance of ${maxLastPointDist}m or more`

    	if (lastLL?.distanceTo(startLL) > MaxDistBetweenPoints || firstLL?.distanceTo(startLL) > MaxDistBetweenPoints)
    		return `Distance between two points must be not more than ${MaxDistBetweenPoints}m`

    	return this.validateDrone(totalBattery)
    }
}

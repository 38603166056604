import React, {Component} from "react"
import L from "leaflet"
import {Marker, Tooltip} from "react-leaflet"
import arrow from "@/components/map/markers/arrow.png"
import type {GpsPosition} from "@/model/GpsTrackLog"

type Props = {
    position: Omit<GpsPosition, 'time'>;
    droneName?: string;
    yaw?: number;
    gpsHealth?: number;
}

class DroneMarker extends Component<Props> {
    constructor(props) {
        super(props);

        const icon = L.divIcon({
            iconSize: [132, 32],
            iconAnchor: [16, 16],
            tooltipAnchor: [0, 16],
            html: `<img style="transform: rotate(0rad);" height="32" width="32" src='${arrow}' alt="drone">`,
            className: "drone-marker",
        })

        this.state = { icon }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState, snapshot) {
        if (prevProps.yaw !== this.props.yaw && this.markerRef) {
            const {yaw} = this.props

            let angle = yaw === undefined ? 0 : yaw
            angle = -angle + Math.PI / 2
            this.markerRef._icon.childNodes[0].style = `transform: rotate(${angle}rad);`
        }
    }

    render() {
        const {droneName, gpsHealth} = this.props
        const {lat, lon} = this.props.position
        const {icon} = this.state

        if ((!lat && !lon) || (gpsHealth && gpsHealth < 3))
            return <></>

        return (
            <Marker ref={(r) => this.markerRef = r} position={[lat, lon]} icon={icon}>
                {droneName && <Tooltip direction="bottom">{droneName}</Tooltip>}
            </Marker>
        )
    }
}
export default DroneMarker

import General from "./General.json"
import DroneList from "./DroneList.json"
import UnitPanel from "./UnitPanel.json"
import DroneParams from "./DroneParams.json"
import StationParams from "./StationParams.json"
import PlanList from "./PlanList.json"
import MissionList from "./MissionList.json"
import OperatorRequestList from "./OperatorRequestList.json"
import PlanEditor from "./PlanEditor.json"
import MissionPanel from "./MissionPanel.json"
import OperatorRequestEditor from "./OperatorRequestEditor.json"
import WaypointParamEditor from "./WaypointParamEditor.json"
import RouteParamEditor from "./RouteParamEditor.json"
import ActionsEditor from "./ActionsEditor.json"
import StationControl from "./StationControl.json"
import SnowMeltingControl from "./SnowMeltingControl.json"

const Operator = {
    ...General,
    ...DroneList,
    ...UnitPanel,
    ...DroneParams,
    ...StationParams,
    ...PlanList,
    ...MissionList,
    ...OperatorRequestList,
    ...PlanEditor,
    ...MissionPanel,
    ...OperatorRequestEditor,
    ...WaypointParamEditor,
    ...RouteParamEditor,
    ...ActionsEditor,
    ...StationControl,
    ...SnowMeltingControl,
}
export default Operator

import {makeObservable, observable, action, IObservableArray} from "mobx"
import StationStore from "@/store/StationStore"
import DroneStore, {PARAM_TIMEOUT_MS} from "@/store/DroneStore"
import type StationState from "@/model/StationState"
import type DroneState from "@/model/DroneState"
import type {Unit} from "@/model/Unit"

export class UnitStores {
    id: string
    drone: DroneStore
    station: StationStore

    constructor(drone, station) {
        this.id = drone ? drone.droneId : station.stationId
        this.drone = drone ? new DroneStore(drone) : null
        this.station = station ? new StationStore(station) : null
    }

    get displayName() {
        if (this.station)
            return this.station.displayName
        return this.drone.displayName
    }
}

interface StationsById {
    [id: string]: StationStore
}

interface DronesById {
    [id: string]: DroneStore
}

export class DroneListStore {
    @observable loading = true
    @observable stores: IObservableArray<UnitStores> = []
    @observable stationsById: StationsById = {}
    @observable dronesById: DronesById = {}
    @observable connectionStatus = false
    unitById = {}

    constructor() {
        makeObservable(this)
        setInterval(this.checkTimeout, PARAM_TIMEOUT_MS)
    }

    @action setIsConnected() {
        this.connectionStatus = true
    }

    @action setIsDisconnected() {
        this.connectionStatus = false
    }

    @action setLoggedIn(isLoggedIn: boolean) {
        this.loggedIn = isLoggedIn
    }

    @action setList(units: Unit[]) {
        this.loading = false
        const stores: UnitStores[] = units.map((u) => new UnitStores(u.drone, u.station))

        this.stationsById = {}
        this.dronesById = {}
        this.unitById = {}
        stores.forEach((u) => {
            if (u.drone) {
                this.unitById[u.drone.droneId] = u
                this.dronesById[u.drone.droneId] = u.drone
            }

            if (u.station) {
                this.unitById[u.station.stationId] = u
                this.stationsById[u.station.stationId] = u.station

                if (u.drone)
                    u.drone.station = u.station
            }
        })
        this.stores.replace(stores)
    }

    @action showOnlySelected(id) {
        if (!id) return
        const newStores = this.stores.map((item) => (item.id === id ? item : {...item, hidden: true}))
        this.stores.replace(newStores)
    }

    @action clearShowSelection() {
        this.stores.replace(this.stores.map((item) => ({...item, hidden: false})))
    }

    getUnitById(id: string): UnitStores {
        return this.unitById[id]
    }

    @action setStationProps(stationId: string, params: StationState) {
        const station = this.stationsById[stationId]
        if (station)
            station.setParams(params)
    }

    @action setDroneProps(serial: string, params: DroneState) {
        const drone = this.dronesById[serial]
        if (drone)
            drone.setParams(params)
    }

    getDrone(droneId: string): DroneStore {
        return this.dronesById[droneId]
    }

    checkTimeout = () => {
        this.stores.forEach((u) => {
            if (u.drone)
                u.drone.checkTimeout()

            if (u.station)
                u.station.checkTimeout()
        })
    }
}

const drones = new DroneListStore()

export default drones

/* eslint-disable react/no-unused-prop-types */
import React from "react"
import {FormGroup, NumericInput, Slider, Switch} from "@blueprintjs/core"

type Props = {
    checked?: boolean,
    onChecked?: (value: boolean) => void,
    label: string,
    disabled?: boolean,
    min: number,
    max: number,
    step: number,
    value: number,
    onChange: (num: number) => void,
}

function FieldInput(props: Props) {
    return (
        <NumericInput min={props.min}
                      max={props.max}
                      step={props.step}
                      disabled={props.disabled}
                      buttonPosition="none"
                      style={{width: 56}}
                      clampValueOnBlur
                      value={props.value}
                      onValueChange={(num: number) => {
                          if (!Number.isNaN(num))
                              props.onChange(num)
                      }}
        />
    )
}

export default function ParamEditor(props: Props) {
    const disabled = (props.checked !== undefined && !props.checked) || props.disabled

    return (
        <FormGroup>
            <div style={{display: "flex"}}>
                {props.checked !== undefined ? <Switch disabled={props.disabled} onChange={() => props.onChecked(!props.checked)} checked={props.checked} /> : <></>}
                <div style={{flex: 1}}>{props.label}</div>
                <FieldInput {...props} disabled={disabled} />
            </div>
            <Slider disabled={disabled}
                    min={props.min}
                    max={props.max}
                    stepSize={props.step}
                    labelStepSize={props.max - props.min}
                    value={props.value}
                    onChange={(val) => props.onChange(val)}
            />
        </FormGroup>
    )
}

import {makeObservable, observable, IObservableArray, action} from "mobx"

import type {Station} from "@/model/Station"

export class AdminStationsStore {
    @observable loading = true
    @observable adminStationsList: IObservableArray<Station> = []

    constructor() {
        makeObservable(this)
    }

    @action setAdminStationsList(adminStationsList: Station[]) {
        this.loading = false
        this.adminStationsList = adminStationsList
    }

    getAdminStationById(id: string): Station {
        return this.adminStationsList.filter((s) => s.id === id)[0]
    }

    @action setAdminStation(adminStationsList: Station) {
        this.adminStationsList.push(adminStationsList)
    }

    isHaveStaionIdInAdminStations(stationId: string): Station {
        return this.adminStationsList.some((s) => s.stationId === stationId)
    }

    @action updateAdminStation(station: Station) {
        this.adminStationsList = this.adminStationsList.map((s) => {
            if (s.id === station.id)
                return station
            return s
        })
    }

    @action removeAdminStation(id: string) {
        this.adminStationsList = this.adminStationsList.filter((s) => s.id !== id)
    }
}

const adminStations = new AdminStationsStore()

export default adminStations

import {RouteProps, Redirect, Route} from "react-router-dom"
import React, {FC} from "react"
import type {PrivateComponentProps} from "@/authorization/components/types"
import PrivateComponent from "@/authorization/components/PrivateComponent"

type Props = PrivateComponentProps & RouteProps & {
    fallbackRedirect?: string
}
const PrivateRoute: FC<Props> = ({permission,
    permissions,
    role,
    roles,
    rolesCompare = "or",
    permissionsCompare = "and",
    fallbackRedirect = "/login",
    component: Component,
    children,
    ...rest}) => (
        <PrivateComponent
            permission={permission}
            permissions={permissions}
            role={role}
            roles={roles}
            rolesCompare={rolesCompare}
            permissionsCompare={permissionsCompare}
            fallback={<Redirect to={fallbackRedirect} />}
        >
            <Route {...rest} component={Component}>
                {children}
            </Route>
        </PrivateComponent>
)

export default PrivateRoute

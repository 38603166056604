import React, {useCallback, useEffect, useLayoutEffect, useRef, useState, FC} from "react"
import {observer} from "mobx-react"
import {reaction} from "mobx"
import {Button, Colors, Icon, Intent, Spinner} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import WebrtcSettings from "@/store/WebrtcSettings"
import PlaySettings from "@/store/PlaySettings"
import {warningToast} from "@/components/AppToaster"
import stopPlay from "../webrtc/stopPlay"
import "./Player.scss"
import Receiver from "@/webrtc/receiverPlayer";

const server = process.env.NODE_ENV === "development" ? "wss://video.drone-test.aniklab.com/app/" : `wss://video.${window.location.host}/app/`

const DEFAULT_ASPECT = 16 / 9

const getPaddingByAspect = (aspect: number): string => `${(1 / aspect.toFixed(4)) * 100}%`

const getIconColor = (retrying: boolean, connected: boolean) => {
    if (retrying)
        return Colors.ORANGE4

    if (connected)
        return Colors.GREEN4

    return Colors.RED4
}

type Props = {
    streamName: string,
    autoplay: boolean,
    showErrorOnFailCount: number,
}

const Player: FC<Props> = ({streamName, autoplay, children, showErrorOnFailCount = 0}) => {
    const videoElement = useRef(null)
    const hoverHideTimeoutRef = useRef<number>(null)
    const retryRef = useRef<number>(null)
    const mouseRef = useRef<boolean>(false)

    const [playSettings] = useState(new PlaySettings(server + streamName + '/ws', streamName))
    const [webrtcSettings] = useState(new WebrtcSettings())
    const [controlVisible, setVisibility] = useState(true)

    useEffect(() => {
        if (hoverHideTimeoutRef.current)
            clearTimeout(hoverHideTimeoutRef.current)

        hoverHideTimeoutRef.current = setTimeout(() => {
            setVisibility(false)
        }, 2000)
    }, [])

    const playStream = useCallback(() => {
        playSettings.setPlay(true)
        playSettings.setPlayStarting(true)

        const retryIfNoConnection = (conn: any) => {
            if (conn)
                playSettings.setRetry(false)
            else
                playSettings.setRetry(true)

            return conn
        }

		new Receiver(playSettings, webrtcSettings.websocket, {
            onError: ({message}: {message: string}) => {
                if (!playSettings.play)
                    return

                playSettings.setPlayStarting(false)

                const showMessages = playSettings.showErrorAfterAttempt >= showErrorOnFailCount

                if (showMessages) {
                    warningToast(`Unable to connect to the stream: ${message}`)
                    playSettings.resetRetry()
                } else
                    playSettings.incrementRetry()

                playSettings.setRetry(true)
                playSettings.onError()
            },
            onSetWebsocket: ({websocket}) => webrtcSettings.setWebsocket(retryIfNoConnection(websocket)),
            onConnectionStateChange: ({connected}) => webrtcSettings.onConnectionStateChange(retryIfNoConnection(connected)),
            onSetPeerConnection: ({peerConnection}) => webrtcSettings.setPeerConnection(retryIfNoConnection(peerConnection)),
            onPeerConnectionOnTrack: (event) => {
                if (event.track != null && event.track.kind != null) {
                    if (event.track.kind === "audio")
                        webrtcSettings.setAudio(event.track)

                    else if (event.track.kind === "video")
                        webrtcSettings.setVideo(event.track)

                    playSettings.setRetry(false)
                    playSettings.setPlayStarting(false)
                }
            },
        })
    }, [playSettings, showErrorOnFailCount, webrtcSettings])

    useEffect(() => () => {
        const hover = hoverHideTimeoutRef.current
        const retry = retryRef.current

        if (hover)
            clearInterval(hover)

        if (retry)
            clearInterval(retry)

        playSettings.reset()
        webrtcSettings?.peerConnection?.close()
        webrtcSettings?.websocket?.close()
    }, [autoplay, playSettings, playStream, webrtcSettings])

    const pauseStream = useCallback(() => {
        playSettings.setPlay(false)
        playSettings.setPlayStopping(true)

        stopPlay(webrtcSettings.peerConnection, webrtcSettings.websocket, {
            onSetPeerConnection: ({peerConnection}) => webrtcSettings.setPeerConnection(peerConnection),
            onSetWebsocket: ({websocket}) => webrtcSettings.setWebsocket(websocket),
            onPlayStopped: () => playSettings.setPlayStopping(false),
        })
    }, [playSettings, webrtcSettings])

    useEffect(() => {
        const cancel = reaction(() => playSettings.retrying, (next, prev) => {
            if (next === prev)
                return

            if (retryRef.current)
                clearInterval(retryRef.current)

            if (!next)
                return

            retryRef.current = setInterval(() => {
                if (next && !playSettings.playStarting) {
                    pauseStream()
                    playStream()
                }
            }, 2000)
        })

        return () => cancel()
    }, [pauseStream, playSettings, playStream, webrtcSettings])

    useEffect(() => {
        if (autoplay && !playSettings.play)
            playStream()

        const cancel = reaction(() => playSettings.play, (next, prev) => {
            if (next === prev || playSettings.retrying)
                return

            if (next && !playSettings.playStarting) {
                playStream()
                return
            }
            if (!next && !playSettings.playStopping)
                pauseStream()
        })

        return () => cancel()
    }, [autoplay, pauseStream, playSettings, playStream, webrtcSettings])

    useLayoutEffect(() => {
        const cancel = reaction(() => webrtcSettings.connected, () => {
            const el = videoElement.current
            if (!el)
                return

            const {connected, audioTrack, videoTrack} = webrtcSettings
            if (connected) {
                const newStream = new MediaStream()
                if (audioTrack != null)
                    newStream.addTrack(audioTrack)

                if (videoTrack != null) {
                    newStream.addTrack(videoTrack)
                    el.srcObject = newStream
                } else
                    el.srcObject = null
            }
        })

        return () => cancel()
    }, [webrtcSettings])

    const togglePlay = useCallback(() => {
        playSettings.setPlay(!playSettings.play)
    }, [playSettings])

    const handleMouseEnter = useCallback(() => {
        if (hoverHideTimeoutRef.current)
            clearTimeout(hoverHideTimeoutRef.current)

        if (mouseRef.current)
            return

        if (!controlVisible)
            setVisibility(true)
    }, [controlVisible])

    const handleMouseLeave = useCallback(() => {
        if (hoverHideTimeoutRef.current)
            clearTimeout(hoverHideTimeoutRef.current)

        if (mouseRef.current)
            return

        hoverHideTimeoutRef.current = setTimeout(() => {
            setVisibility(false)
        }, 1000)
    }, [])

    useEffect(() => {
        let origPosition

        const hide = () => {
            if (hoverHideTimeoutRef.current)
                clearTimeout(hoverHideTimeoutRef.current)

            setVisibility(false)
        }

        const onMouseMove = (e: MouseEvent) => {
            const [x2, y2] = origPosition
            const {x: x1, y: y1} = e
            const distance = Math.hypot(x2 - x1, y2 - y1)

            if (distance > 30) {
                mouseRef.current = true
                hide()
            }
        }

        const onMouseDown = (e: MouseEvent) => {
            origPosition = [e.x, e.y]
            document.addEventListener("mousemove", onMouseMove)
        }
        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove)
            mouseRef.current = false
        }

        document.addEventListener("mousedown", onMouseDown)
        document.addEventListener("mouseup", onMouseUp)

        return () => {
            document.removeEventListener("mousedown", onMouseDown)
            document.removeEventListener("mouseup", onMouseUp)
            document.removeEventListener("mousemove", onMouseMove)
        }
    }, [])

    const loading = playSettings.playStarting || playSettings.playStopping || playSettings.retrying

    return (
        <div
            className="video"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                paddingBottom: getPaddingByAspect(playSettings.aspectRatio ?? DEFAULT_ASPECT),
            }}
        >
            {children}
            <video
                className={!webrtcSettings.connected ? "hidden" : ""}
                id={streamName}
                ref={videoElement}
                autoPlay
                playsInline
                muted
            />

            <div className={`bottom ${controlVisible ? "active" : ""}`}>
                <div className="control">
                    <Button minimal
                            onClick={togglePlay}
                            icon={<Icon icon={playSettings.play ? IconNames.PAUSE : IconNames.PLAY} color="#fff" />}
                            disabled={loading}
                    />
                </div>
                <div className="status">
                    <Icon icon={IconNames.DOT}
                          color={getIconColor(playSettings.retrying, webrtcSettings.connected)}
                    />
                    {playSettings.retrying && (
                        <p>
                            {playSettings.retrying && "Retry..."}
                        </p>
                    )}
                    {!playSettings.retrying && (
                        <p>
                            {webrtcSettings.connected ? "Live" : "Offline"}
                        </p>
                    )}
                </div>
            </div>

            {loading && <Spinner intent={Intent.PRIMARY} className="spinner-center" />}
        </div>
    )
}

export default observer(Player)

import React, {Component} from "react"
import {Slider} from "@blueprintjs/core"

type Props = {
    className?: string,
    vertical?: boolean,
    min: number,
    max: number,
    onChange: (value: number) => void,
    onRelease: (value: number) => void,
    value: number,
}

export default class LogSlider extends Component<Props> {
    state = {
        rawValue: 0,
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.value !== prevProps.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                rawValue: this.getPosition(this.props.value),
            })
        }
    }

    sliderChange = (value: number) => {
        this.setState({
            rawValue: value,
        })
        if (this.props.onChange)
            this.props.onChange(this.getLog(value))
    }

    sliderRelease = (value: number) => {
        this.setState({
            rawValue: value,
        })
        if (this.props.onRelease)
            this.props.onRelease(this.getLog(value))
    }

    getLog(value: number) {
        const minv = Math.log(this.props.min)
        const maxv = Math.log(this.props.max)
        const scale = (maxv - minv) / 100
        return Math.exp(minv + scale * value)
    }

    getPosition(value: number) {
        const minv = Math.log(this.props.min)
        const maxv = Math.log(this.props.max)
        const scale = (maxv - minv) / 100
        return (Math.log(value) - minv) / scale
    }

    convertValue = (value: number, opts: { isHandleTooltip: boolean }) => {
        if (!opts || !opts.isHandleTooltip) return React.Fragment
        return Math.round(this.getLog(value))
    }

    render() {
        return (
            <Slider className={this.props.className}
                    value={this.state.rawValue}
                    min={0}
                    max={100}
                    labelStepSize={100}
                    stepSize={1}
                    onChange={this.sliderChange}
                    onRelease={this.sliderRelease}
                    labelRenderer={this.convertValue}
                    vertical={this.props.vertical}
            />
        )
    }
}

import React from "react";
import { Viewer } from "resium";
import type { ReactNode, VFC } from "react";
import "./CesiumMap.scss";
import { createWorldTerrain, DataSourceDisplay, EntityCluster, Scene } from "cesium";
import { ConicSensorVisualizer, CustomPatternSensorVisualizer, RectangularSensorVisualizer } from "cesium-sensors-es6";
import type { DataSource } from "@/components/charts/types";
import PlanLayer from "./PlanLayer";

type Props = {
	controls: ReactNode;
};

const originalDefaultVisualizersCallback = DataSourceDisplay.defaultVisualizersCallback;
DataSourceDisplay.defaultVisualizersCallback = (
	scene: Scene,
	entityCluster: EntityCluster,
	dataSource: DataSource,
) => {
	const { entities } = dataSource;
	const array = originalDefaultVisualizersCallback(
		scene,
		entityCluster,
		dataSource,
	);
	return array.concat([
		new ConicSensorVisualizer(scene, entities),
		new CustomPatternSensorVisualizer(scene, entities),
		new RectangularSensorVisualizer(scene, entities),
	]);
};

const CesiumMap: VFC<Props> = ({ controls }) => (
	<div className="cesium-wrap">
		<Viewer terrainProvider={createWorldTerrain()}>
			<PlanLayer />
		</Viewer>
		<div className="controls">
			{controls}
		</div>
	</div>
);

export default CesiumMap;

import type {Callbacks} from "@/webrtc/types"

const stopPlay = (peerConnection: RTCPeerConnection, websocket: WebSocket, callbacks?: Callbacks) => {
    if (peerConnection != null) {
        peerConnection.close()
        if (callbacks?.onSetPeerConnection)
            callbacks.onSetPeerConnection({peerConnection: undefined})
    }
    if (websocket != null) {
        websocket.close()
        if (callbacks?.onSetWebsocket)
            callbacks.onSetWebsocket({websocket: undefined})
    }
    if (callbacks?.onPlayStopped)
        callbacks.onPlayStopped()
}
export default stopPlay

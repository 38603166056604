import {observer} from "mobx-react"
import React, {ComponentType, useEffect, useState, useCallback, useRef} from "react"
import {reaction} from "mobx"
import {Button, Colors, Icon} from "@blueprintjs/core"
import {Tooltip2} from "@blueprintjs/popover2"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import api from "@/services/ApiService"
import cameraAccess from "@/store/CameraAccessStore"
import type {GrantedPermission} from "@/store/CameraAccessStore"
import useTimeLeft from "@/hooks/useTimeLeft"
import userInfo from "@/store/UserInfoStore"

type Props = {
    setShow: (val: boolean) => void,
    show: boolean,
    droneId: string
}

const formatTime = (time: Date) => {
    const hours = time.getUTCHours()
    return `${hours && `${hours}:`}${time.getUTCMinutes()}:${time.getUTCSeconds()}`
}

const AccessInfo: ComponentType<Props> = ({setShow, show, droneId}) => {
    const [loading, setLoading] = useState(true)
    const [permission, setPermission] = useState<GrantedPermission | null>(null)
    const onExpire = useCallback(() => setPermission(null), [])
    const timeLeft = useTimeLeft(permission?.expiresIn, onExpire)
    const prevPermission = useRef()

    prevPermission.current = permission

    useEffect(() => {
        api.cameraAccess.getPermissions().then((res) => {
            cameraAccess.updatePermissions(res)
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        reaction(() => cameraAccess.permissions.slice(), (next) => {
            const found = next.find((x) => x.droneId === droneId)
            setPermission(found)
        })
    }, [droneId])

    useEffect(() => () => {
        if (prevPermission.current && userInfo.isConsumer)
            api.cameraAccess.revokeMyAccess(droneId).then()
    }, [droneId])

    useEffect(() => {
        const {isConsumer} = userInfo
        if (isConsumer)
            setShow(permission != null)
    }, [droneId, permission, setShow])

    const request = useCallback(() => {
        setLoading(true)
        api.cameraAccess.requestAccess(droneId).finally(() => setLoading(false))
    }, [droneId])

    const revoke = useCallback(() => {
        if (permission == null)
            return

        setLoading(true)

        if (userInfo.isConsumer)
            api.cameraAccess.revokeMyAccess(droneId).finally(() => setLoading(false))
        else
            api.cameraAccess.revokeAccess(droneId, permission.user.id).finally(() => setLoading(false))
    }, [droneId, permission])

    const {isConsumer} = userInfo

    const showRequestControl = isConsumer && !show

    return (
        <div className="access-control">
            {timeLeft && (
                <Tooltip2 content={
                    i18next.t("control.drone-control.camera.access.text.time-left", {
                        time: formatTime(timeLeft),
                    })
                }
                >
                    <div className="wrap">
                        <div className="text">
                            <p>
                                {isConsumer
                                    ? i18next.t("control.drone-control.camera.access.text.you-have-control")
                                    : i18next.t("control.drone-control.camera.access.text.consumer-has-control", {
                                        user: `${permission?.user?.firstName} ${permission?.user?.lastName}`,
                                    })}
                            </p>
                            <Icon icon={IconNames.DOT} color={Colors.GREEN4} />
                        </div>
                        <Button
                                minimal
                                disabled={loading}
                                icon={<Icon icon={IconNames.CROSS} color={Colors.WHITE} />}
                                loading={loading}
                                onClick={revoke}
                                className="revoke"
                        />
                    </div>
                </Tooltip2>
            )}
            {showRequestControl && (
                <Button
                        disabled={loading}
                        minimal
                        outlined
                        loading={loading}
                        text={i18next.t("control.drone-control.camera.access.text.request-control")}
                        onClick={request}
                />
            )}
        </div>
    )
}

export default observer(AccessInfo)

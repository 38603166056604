import React from "react"
import {Intent, ProgressBar} from "@blueprintjs/core"

type UploadInfo = {
    upload_percent: number,
    upload_speed: string,
    upload_time: string,
}

export default function UploadProgress(props: { params: UploadInfo }) {
    const {upload_percent, upload_speed, upload_time} = props.params
    if (upload_percent === undefined || upload_percent >= 100)
        return React.Fragment

    return (
        <>
            <p className="upload-progress">
                <span className="percent">{upload_percent}%</span>
                <span className="speed">{upload_speed}</span>
                <span className="time">{upload_time}</span>
            </p>
            <ProgressBar value={upload_percent / 100.0} intent={Intent.PRIMARY} />
        </>
    )
}

import AdminPanel from "./AdminPanel.json"
import UsersList from "./UsersList.json"
import DronesList from "./DronesList.json"
import StationsList from "./StationsList.json"
import UsersEditor from "./UsersEditor.json"
import DronesEditor from "./DronesEditor.json"
import StationsEditor from "./StationsEditor.json"
import ProfilesList from "./ProfilesList.json"

const Admin = {
    ...AdminPanel,
    ...UsersList,
    ...DronesList,
    ...StationsList,
    ...UsersEditor,
    ...DronesEditor,
    ...StationsEditor,
    ...ProfilesList,
}
export default Admin

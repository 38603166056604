import moment from "moment"
import byteSize from "byte-size"

export function formatDate(dt: string) {
    if (!dt) return ""
    return moment(dt).locale("ru").format("DD.MM.YY")
}

export function formatTime(dt: string) {
    if (!dt) return ""
    return moment(dt).locale("ru").format("HH:mm:ss")
}

export function formatDateTime(dt: string) {
    if (!dt) return ""
    return moment(dt).locale("ru").format("DD.MM.YY HH:mm:ss")
}

export function secondsToTime(seconds: number) {
    return moment(new Date()).startOf("day")
        .seconds(seconds)
        .format("H:mm:ss")
}

export function bytesHuman(bytes: number) {
    const {value, unit} = byteSize(bytes)
    return value + unit
}

import {makeObservable, observable, IObservableArray, action} from "mobx"

import type {DroneInfo} from "@/model/DroneInfo"

export class AdminDronesStore {
    @observable loading = true
    @observable adminDronesList: IObservableArray<DroneInfo> = []

    constructor() {
        makeObservable(this)
    }

    @action setAdminDronesList(adminDronesList: DroneInfo[]) {
        this.loading = false
        this.adminDronesList = adminDronesList
    }

    getAdminDroneById(id: string): DroneInfo {
        return this.adminDronesList.filter((i) => i.droneId === id)[0]
    }

    @action setAdminDrone(adminDronesList: DroneInfo) {
        this.adminDronesList.push(adminDronesList)
    }

    @action updateAdminDrone(drone: DroneInfo) {
        this.adminDronesList = this.adminDronesList.map((d) => {
            if (d.droneId === drone.droneId)
                return drone
            return d
        })
    }

    @action removeAdminDrone(id: string) {
        this.adminDronesList = this.adminDronesList.filter((d) => d.droneId !== id)
    }
}

const adminDrones = new AdminDronesStore()

export default adminDrones

import React, {Component} from "react"
import {inject} from "mobx-react"
import {Button, Card, Classes, FormGroup, InputGroup, Intent, Tooltip, Popover, Menu, MenuItem, Position} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import {autorun} from "mobx"
import api from "@/services/ApiService"
import {dangerToast} from "@/components/AppToaster"
import globalStore, {GlobalStore} from "@/store/GlobalStore"
import type {RouteProps} from "@/types/RouteProps"

@inject("globalStore")
export default class Login extends Component<{globalStore?: GlobalStore} & RouteProps> {
    state = {
        showPassword: false,
        login: "",
        password: "",
        disabled: false,
        lang: "en",
    }

    componentDidMount() {
        const lang = localStorage.getItem("i18nextLng")
        if (lang)
            this.setState({lang})

        autorun(() => {
            if (globalStore.loggedIn)
                this.props.history.push("/")
        })
    }

    switchLanguage = (lang) => {
        this.setState({lang})
        i18next.changeLanguage(lang).then()
    }

    render() {
        const {showPassword} = this.state

        const lockButton = (
            <Tooltip content={showPassword ? i18next.t("login.text.hide") : i18next.t("login.text.show")}>
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                    disabled={this.state.disabled}
                />
            </Tooltip>
        )

        const menu = (
            <Menu style={{minWidth: "auto"}}>
                <MenuItem text="EN" onClick={() => this.switchLanguage("en")} />
                <MenuItem text="RU" onClick={() => this.switchLanguage("ru")} />
            </Menu>
        )

        return (
            <Card style={{width: 400}} className="centered" elevation={2}>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup label={i18next.t("login.form.login.label")} labelFor="login-input">
                        <InputGroup
                            id="login-input"
                            leftIcon={IconNames.USER}
                            disabled={this.state.disabled}
                            value={this.state.login}
                            onChange={(e) => this.setState({login: e.target.value})}
                        />
                    </FormGroup>

                    <FormGroup label={i18next.t("login.form.password.label")} labelFor="password-input">
                        <InputGroup
                            id="password-input"
                            leftIcon={IconNames.KEY}
                            type={showPassword ? "text" : "password"}
                            rightElement={lockButton}
                            disabled={this.state.disabled}
                            value={this.state.password}
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                    </FormGroup>

                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Popover content={menu} position={Position.BOTTOM_RIGHT}>
                            <Button text={this.state.lang.toUpperCase()} />
                        </Popover>
                        <Button text={i18next.t("login.button.login")} intent={Intent.PRIMARY} type="submit" loading={this.state.disabled} />
                    </div>
                </form>
            </Card>
        )
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})

    handleSubmit = (event) => {
        const {login, password} = this.state

        this.setState({disabled: true})

        api.auth.login(login, password)
            .then(() => {
                this.props.globalStore.setLoggedIn(true)
            })
            .catch((error) => {
                if (error.response.status === 401)
                    dangerToast(i18next.t("login.toast.pass-incorrect"))
                else
                    dangerToast(i18next.t("login.toast.server-err"))

                this.setState({disabled: false})
            })

        event.preventDefault()
        return false
    }
}

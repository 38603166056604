import React, {Fragment, memo, useCallback, useEffect, useMemo, useState} from "react"
import "./Chart.scss"
import {scaleLinear, scaleUtc} from "@visx/scale"
import {AxisBottom, AxisLeft} from "@visx/axis"
import {Group} from "@visx/group"
import {AreaClosed, Bar, Line, LinePath} from "@visx/shape"
import {curveMonotoneX} from "@visx/curve"
import {GridColumns, GridRows} from "@visx/grid"
import {MarkerCircle} from "@visx/marker"
import {useTooltip, useTooltipInPortal, defaultStyles, TooltipWithBounds} from "@visx/tooltip"
import {localPoint} from "@visx/event"
import {Text} from "@visx/text"
import {bisector} from "d3-array"
import {LinearGradient} from "@visx/gradient"
import moment from "moment"
import {
	calcMsRange, calcLinearDomain,
	fixupNumberOfTicks,
	getMinMax,
	getStringWidth,
	getTicks,
	graphTimeFormat
} from "@/components/charts/utils"
import type {ChartSize, Margin, TimeSeriesValue, TooltipData} from "@/components/charts/types"
import {DataSource} from "@/components/charts/types"
import {allGradients, singleColors, themeColors} from "@/components/charts/colors"
import type {ChartType, TimeSeriesValueDto} from "@/model/ChartDto"

export type ChartProps = {
	id?: string;
	margin?: Margin;
	title: string;
	definitions?: Record<string, string>;
	setBox: (ChartSize: ChartSize) => void;
	chartType?: ChartType;
	chartName: string;
	unit?: string;
	series?: TimeSeriesValueDto[];
	timeRangeSeconds?: number;
	startTime?: Date;
	endTime?: Date;
	width: number;
	height: number;
	loading: boolean;
}

const defaultMargin: Margin = {top: 40, right: 50, bottom: 50, left: 40}

const tooltipStyles = {
	...defaultStyles,
	minWidth: 60,
	backgroundColor: "rgba(0,0,0,0.9)",
	color: "white",
}

const getDate = (x: TimeSeriesValue) => new Date(x.time)
const getValue = (x: TimeSeriesValue) => x.value
const bisectDate = bisector<TimeSeriesValue, Date>((x) => getDate(x)).right

const TimeSeries: React.VFC<{
	chartId: string;
	chartType: ChartType;
	series: TimeSeriesValueDto[];
	xScale: any;
	yScale: any;
}> = memo(({chartId, chartType, series, xScale, yScale}) => {
	if (!series || series.length === 0) return null

	const dataSources: DataSource[] = series.map((s) => {
		const begin = moment(s.begin).valueOf()
		const source: DataSource = {
			alias: s.alias,
			series: s.times.map((time, i) => ({time: new Date(begin + time * 1000), value: s.values[i]})),
		}
		return source
	})
	if (!dataSources) return null

	return (
		<>
			{dataSources.map(({series, alias}, i) => {
				const gradientNum = i % allGradients.length
				const gradientLine = `url('#gradient-${chartId}-line-${gradientNum}')`
				const key = `${chartId}-${alias}-${i.toString()}`

				if (series.length > 1) {
					return (
						<Fragment key={key}>
							{chartType === "area" && (
								<AreaClosed
									data={series}
									x={(p) => xScale(getDate(p))}
									y={(p) => yScale(getValue(p))}
									yScale={yScale}
									fill={`url('#gradient-${chartId}-background-${gradientNum}')`}
									curve={curveMonotoneX}
								/>
							)}
							<LinePath
								data={series}
								x={(p) => xScale(getDate(p))}
								y={(p) => yScale(getValue(p))}
								stroke={gradientLine}
								strokeWidth={2}
								curve={curveMonotoneX}
							/>
						</Fragment>
					)
				}

				const [point] = series

				return <circle key={`radar-point-${chartId}-${key}`} cx={xScale(getDate(point))}
							   cy={yScale(getValue(point))} r={1} fill={gradientLine}/>
			})}
			{allGradients.slice(0, ((dataSources.length ?? 0) % allGradients.length))
				.map(([from, to], i) => {
					const getKey = (area: string) => `gradient-${chartId}-${area}-${i}`

					return (
						<Fragment key={i}>
							<LinearGradient
								id={getKey("line")}
								from={from}
								to={to}
								gradientUnits="userSpaceOnUse"
							/>

							<LinearGradient
								id={getKey("background")}
								from={to}
								to={themeColors.black}
								toOpacity={0.2}
							/>
						</Fragment>
					)
				})}
		</>
	)
})

const XYChart: React.VFC<ChartProps> = ({
											id,
											series,
											setBox,
											width,
											height,
											chartType,
											timeRangeSeconds,
											startTime,
											endTime,
											chartName,
											margin = defaultMargin,
											loading,
											unit
										}) => {
	const {
		tooltipLeft,
		tooltipTop,
		tooltipData,
		hideTooltip,
		showTooltip,
	} = useTooltip<TooltipData>()

	const chartId = chartName ?? id
	if (!chartId)
		// eslint-disable-next-line no-console
		console.warn("You forgot to pass chartName prop or id!")

	const [mouseData, setMouseData] = useState<{ show: boolean, x: number, y: number }>({show: false, x: 0, y: 0})

	const {containerRef} = useTooltipInPortal({
		scroll: false,
	})

	const handleTooltipHide = useCallback(() => {
		hideTooltip()
		setMouseData((prev) => ({...prev, show: false}))
	}, [hideTooltip])

	const isEmpty = (series == null || series?.length === 0)
	const hasInterval = endTime && startTime
	const linearSeries = series?.flatMap((x => x.values)) ?? []

	const yMax = height - margin.top - margin.bottom
	const yScale = useMemo(() => scaleLinear({
			domain: calcLinearDomain(linearSeries),
			range: [yMax, 0],
			zero: false,
			nice: true,
			clamp: true,
		}),
		[series, yMax])

	const unitLeftOffset = useMemo(() => {
		const max = Math.max.apply(null, yScale.domain())
		return getStringWidth(unit ? `${max.toString()} ${unit}` : max.toString())
	}, [unit, yScale])

	const totalLeftOffset = margin.left + unitLeftOffset
	const xMax = width - totalLeftOffset - margin.right
	const xScale = useMemo(() => {
		let domain
		if (hasInterval)
			domain = [startTime, endTime]
		else if (series)
			domain = getMinMax(series?.flatMap((x) => x.times.map((n) => new Date(x.begin).valueOf() + n)))
		else {
			const now = new Date()
			domain = [now.setHours(now.getHours() - 1), new Date()]
		}

		return scaleUtc({
			domain,
			range: [0, xMax],
			round: false,
			nice: false,
			clamp: true,
		})
	}, [endTime, hasInterval, series, startTime, xMax])

	const widthTicks = getTicks(width - totalLeftOffset - margin.right)
	const heightTicks = Math.floor(fixupNumberOfTicks(yMax))

	const xTickFormat = useMemo(() => {
		const diff = hasInterval ? calcMsRange(startTime, endTime) : (timeRangeSeconds ?? 1) * 1000
		const strFormat = graphTimeFormat(widthTicks, diff)
		return (x: any) => moment(x).format(strFormat)
	}, [endTime, hasInterval, startTime, timeRangeSeconds, widthTicks])

	useEffect(() => {
		setBox({
			width: width - totalLeftOffset - margin.right,
			height: height - margin.top - margin.bottom,
		})
	}, [height, margin, setBox, totalLeftOffset, width])

	const dataSources: DataSource[] | undefined = series?.map((s) => {
		const begin = moment(s.begin).valueOf()
		const source: DataSource = {
			alias: s.alias,
			series: s.times.map((time, i) => ({time: new Date(begin + time * 1000), value: s.values[i]})),
		}
		return source
	})
	const handleTooltip = useCallback(
		(event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
			const {x, y} = localPoint(event) || {x: 0, y: 0}
			const nx = x - totalLeftOffset
			if (nx <= 0 || nx > xMax || !dataSources)
				return

			setMouseData({show: true, x, y})

			const x0 = xScale.invert(nx)
			let time = x0
			// заполняю значения
			const values: { [x: string]: number } = {}
			dataSources.forEach((el) => {
				const index = bisectDate(el.series, x0)
				const d0 = el.series[index - 1]
				const d1 = el.series[index]
				let d = d0
				if (d1 && getDate(d1))
					d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0
				time = d.time
				values[el.alias] = d.value
			})

			if (Object.keys(values).length > 0) {
				showTooltip({
					tooltipData: {
						time: time,
						values: values
					},
					tooltipLeft: xScale(time),
					tooltipTop: dataSources.map((d) => yScale(values[d.alias]))[dataSources.length - 1],
				})
			}
		},
		[series, showTooltip, totalLeftOffset, xMax, xScale, yScale],
	)

	if (width <= 0 || height <= 0)
		return null

	return (
		<div style={{position: "relative"}}>
			<svg height={height} width={width} ref={containerRef}>
				<rect
					x={0}
					y={0}
					width={width}
					height={height}
					style={{
						fill: themeColors.black,
					}}
					rx={14}
				/>

				<MarkerCircle id="marker-circle" fill={themeColors.gray} size={1.5} refX={2}/>

				<Group top={margin.top} left={totalLeftOffset}>

					<GridRows
						scale={yScale}
						width={xMax}
						height={yMax}
						opacity={0.1}
						stroke="#fff"
						strokeWidth={2}
						numTicks={widthTicks}
					/>
					<GridColumns
						scale={xScale}
						width={xMax}
						height={yMax}
						opacity={0.1}
						stroke="#fff"
						strokeWidth={2}
						numTicks={heightTicks}
					/>

					<AxisBottom
						hideTicks
						scale={xScale}
						top={yMax}
						tickFormat={xTickFormat}
						numTicks={widthTicks}
						stroke={themeColors.darkGray}
						strokeWidth={2}
						tickStroke={themeColors.darkGray}
						tickLabelProps={() => ({
							fill: themeColors.gray,
							textAnchor: "middle",
							verticalAnchor: "middle",
						})}
					/>

					<AxisLeft
						hideTicks
						hideAxisLine
						scale={yScale}
						numTicks={heightTicks}
						orientation="left"
						stroke={themeColors.darkGray}
						strokeWidth={2}
						tickStroke={themeColors.darkGray}
						tickFormat={unit ? (value) => `${value} ${unit}` : undefined}
						tickLabelProps={() => ({
							fill: themeColors.gray,
							textAnchor: "end",
							verticalAnchor: "middle",
						})}
					/>

					{series && chartId && <TimeSeries
						chartId={chartId}
						chartType={chartType ?? 'linear'}
						series={series}
						yScale={yScale}
						xScale={xScale}
					/>
					}

					{isEmpty && !loading && (
						<Text
							verticalAnchor="middle"
							textAnchor="middle"
							x={xMax / 2}
							y={yMax / 2}
							fill={themeColors.white}
							fontSize={16}
						>
							No data
						</Text>
					)}
				</Group>
				<Group top={margin.top} left={totalLeftOffset}>
					{mouseData.show && (
						<Line
							from={{x: mouseData.x - totalLeftOffset, y: 0}}
							to={{x: mouseData.x - totalLeftOffset, y: yMax}}
							stroke="#93291E"
							strokeWidth={2}
							pointerEvents="none"
						/>
					)}

					{tooltipData && (
						<g>
							{dataSources?.map((d, i) => (
								<circle
									key={`${chartId}-circle-${d.alias}`}
									cx={tooltipLeft}
									cy={tooltipTop}
									r={4}
									fill={singleColors[i]}
									pointerEvents="none"
								/>
							))}
						</g>
					)}

					{series && (
						<Bar
							width={xMax}
							height={yMax}
							fill="transparent"
							rx={14}
							onTouchStart={handleTooltip}
							onTouchMove={handleTooltip}
							onMouseMove={handleTooltip}
							onMouseLeave={handleTooltipHide}
						/>
					)}
				</Group>
			</svg>
			<div>
				{tooltipData && (
					<TooltipWithBounds
						key={Math.random()}
						top={mouseData.y + 5}
						left={mouseData.x + 5}
						style={tooltipStyles}
						className="text-center"
					>
						{Object.entries(tooltipData.values).map(([k, v], i) => (
							<p key={i} style={{ color: singleColors[i] }}>
								{k}: <b>{v}</b>
							</p>
						))}
						<p>
							Time: <b>{xTickFormat(getDate(tooltipData))}</b>
						</p>
					</TooltipWithBounds>
				)}
			</div>
		</div>
	)
}

export default XYChart

import React, {Component} from "react"
import {observer} from "mobx-react"
import {Button,
    FormGroup,
    Icon,
    Popover,
    Slider,
    Position,
    Menu,
    MenuItem,
    Intent,
    NumericInput} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc"
import i18next from "i18next"

import {actionPrefix, AllActions, Action, ActionData} from "@/model/Actions"
import ActionsStore from "@/store/ActionsStore"
import "./ActionEditor.scss"

class FieldInput extends Component<{action: ActionData, onChange: (num: number) => void, disabled: boolean}> {
    state = {
        value: undefined,
    }

    render() {
        const {action} = this.props

        return (
            <NumericInput min={action.type.minValue}
                          max={action.type.maxValue}
                          step={action.type.step}
                          disabled={this.props.disabled}
                          buttonPosition="none"
                          style={{width: 56}}
                          clampValueOnBlur
                          value={this.state.value === undefined ? action.param : this.state.value}
                          onValueChange={(num: number, value: string) => {
                              this.setState({value})
                              if (!Number.isNaN(num))
                                  this.props.onChange(num)
                          }}
            />
        )
    }
}

const DragHandle = SortableHandle(() => <Icon style={{userSelect: "none"}} icon={IconNames.DRAG_HANDLE_VERTICAL} iconSize={Icon.SIZE_LARGE} />)

const SortableItem = SortableElement(({action, onDelete, onChange, inputDisabled}) => (
    <div className="action">
        <div className="action-header">
            <DragHandle />
            <Icon icon={action.type.icon} intent={Intent.PRIMARY} />
            <span className="title">{i18next.t(actionPrefix + action.type.name)}</span>
            {action.type.minValue !== undefined ? <FieldInput disabled={inputDisabled} action={action} onChange={onChange} /> : <></>}
            {!inputDisabled && (<Button onClick={onDelete} icon={IconNames.TRASH} minimal />)}
        </div>
        {action.type.minValue !== undefined
            ? (
                <Slider min={action.type.minValue}
                        max={action.type.maxValue}
                        stepSize={action.type.step}
                        labelStepSize={action.type.maxValue - action.type.minValue}
                        value={action.param}
                        onChange={onChange}
                        disabled={inputDisabled}
                />
            )
            : null}
    </div>
))

const SortableList = SortableContainer(({children}) => <div>{children}</div>)

@observer
export default class ActionsEditor extends Component<{actionsStore: ActionsStore, disabled?: boolean}> {
    onSortEnd = ({oldIndex, newIndex}) => {
        this.props.actionsStore.moveAction(oldIndex, newIndex)
    };

    addAction(type: Action) {
        this.props.actionsStore.addAction({
            type,
            param: type.defaultValue,
        })
    }

    render() {
        const actions = this.props.actionsStore.items

        const menu = (
            <Menu>
                {AllActions.map((t) => (
                    <MenuItem key={`action-menu-${t.id}`}
                              icon={t.icon}
                              text={i18next.t(actionPrefix + t.name)}
                              onClick={() => this.addAction(t)}
                    />
                ))}
            </Menu>
        )

        return (
            <FormGroup label={i18next.t("operator.actions-editor.button.actions")}>
                {!this.props.disabled && (
                    <Popover content={menu} position={Position.RIGHT_TOP}>
                        <Button icon={IconNames.ADD} minimal />
                    </Popover>
                )}
                <SortableList onSortEnd={this.onSortEnd} useDragHandle lockAxis="y" lockToContainerEdges>
                    {actions.map((a: ActionData, index) => (
                        <SortableItem key={`item-${index.toString()}`}
                                      index={index}
                                      disabled={this.props.disabled}
                                      inputDisabled={this.props.disabled}
                                      onDelete={() => this.props.actionsStore.removeAction(a)}
                                      action={a}
                                      onChange={(v) => {
                                          this.props.actionsStore.setActionParam(index, v)
                                      }}
                        />
                    ))}
                </SortableList>
            </FormGroup>
        )
    }
}

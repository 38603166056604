import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {observer} from "mobx-react"
import {Button,
    Card,
    Elevation,
    H5,
    Icon,
    Intent,
    Spinner} from "@blueprintjs/core"
import {DatePicker} from "@blueprintjs/datetime"
import {IconNames} from "@blueprintjs/icons"
import {Popover2, Tooltip2} from "@blueprintjs/popover2"
import moment from "moment"
import i18next from "i18next"
import MomentLocaleUtils from "react-day-picker/moment"

import {formatDate} from "@/Tools"
import api from "@/services/ApiService"
import type {IRequest} from "@/model/IRequest"
import {requestStateDescription, requestStateIcon, requestTypeName} from "@/model/Enums"
import StationStore from "@/store/StationStore"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"

@withRouter
class RequestCard extends Component<{request: IRequest} & RouteProps> {
    render() {
        const {request} = this.props

        return (
            <Card elevation={Elevation.TWO}
                  className="bp3-text-small"
                  interactive
                  onClick={this.onClick}
            >

                <H5>
                    <Tooltip2 content={requestStateDescription(request.state)}>
                        <Icon icon={requestStateIcon(request.state)} intent={Intent.PRIMARY} />
                    </Tooltip2> {requestTypeName(request.type)}
                </H5>
                <p>{i18next.t("operator.operator-request-list.card.text.author")} {request.author}</p>
                <p>{i18next.t("operator.operator-request-list.card.text.created")} {formatDate(request.createTime)}</p>
                <p>{i18next.t("operator.operator-request-list.card.text.planned-date")} {formatDate(request.date)}</p>
            </Card>
        )
    }

    onClick = () => {
        this.props.history.push(`${this.props.match.url}/${this.props.request.id}`)
    }
}

const LAST_REQUEST_RANGE_KEY = "last_request_range"
const LAST_REQUEST_LABEL_KEY = "last_request_label"

@withRouter
@observer
export default class OperatorRequestList extends LoaderComponent<
    { station: StationStore } & RouteProps,
    { loading: boolean, requests: IRequest[], dateRange: Date, rangeLabel: string }
> {
    state = {
        loading: true,
        requests: [],
        dateRange: new Date(),
        rangeLabel: i18next.t("operator.operator-request-list.date-picker.today"),
    }

    prepare(): Promise {
        const dateRangeJson = localStorage.getItem(LAST_REQUEST_RANGE_KEY)
        const dateRange = dateRangeJson ? JSON.parse(dateRangeJson) : new Date()
        return this.updateList(dateRange)
    }

    updateList(dateRange): Promise {
        const {station} = this.props
        if (!station || !dateRange) return Promise.resolve()
        this.setState({loading: true})
        let dr = dateRange

        if (moment(dateRange).isBefore(new Date())) {
            dr = new Date()
            localStorage.setItem(LAST_REQUEST_LABEL_KEY, i18next.t("operator.operator-request-list.date-picker.today"))
        }

        const from = null
        const to = moment(dr).startOf("day").add(1, "day").toDate()
        const params = {from, to, completed: false, canceled: false, stationId: station.stationId}
        localStorage.setItem(LAST_REQUEST_RANGE_KEY, JSON.stringify(dr))

        return api.requests.query(params).then((list) => {
            station.setRequestList(list)
            this.setState({loading: false})
        }).catch(() => {
            localStorage.removeItem(LAST_REQUEST_RANGE_KEY)
            localStorage.removeItem(LAST_REQUEST_LABEL_KEY)
        })
    }

    successRender() {
        if (this.state.loading)
            return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

        const rangeLabelRequest = localStorage.getItem(LAST_REQUEST_LABEL_KEY) ?? this.state.rangeLabel

        const shortcuts = [{
            label: i18next.t("operator.operator-request-list.date-picker.today"),
            date: new Date(),
            includeTime: false,
        }, {
            label: i18next.t("operator.operator-request-list.date-picker.next-7-days"),
            date: moment().add(7, "days").toDate(),
            includeTime: false,
        }, {
            label: i18next.t("operator.operator-request-list.date-picker.month"),
            date: moment().add(1, "month").toDate(),
            includeTime: false,
        }]

        const picker = (
            <DatePicker
                locale={localStorage.getItem("i18nextLng")}
                localeUtils={MomentLocaleUtils}
                shortcuts={shortcuts}
                value={this.state.dateRange}
                onChange={(dateRange) => {
                    this.setState({dateRange})

                    if (dateRange) {
                        const dateStr = formatDate(dateRange)
                        localStorage.setItem(LAST_REQUEST_LABEL_KEY, dateStr)
                        this.setState({rangeLabel: dateStr})
                        setTimeout(() => this.updateList(dateRange), 0)
                    }
                }}
                onShortcutChange={(shortcut) => {
                    localStorage.setItem(LAST_REQUEST_LABEL_KEY, shortcut.label)
                    this.setState({rangeLabel: shortcut.label})
                }}
                minDate={new Date()}
            />
        )

        return (
            <div>
                <div>
                    <Popover2
                        interactionKind="click"
                        placement="right-start"
                        content={picker}
                        renderTarget={({isOpen, ref, ...targetProps}) => (
                            <Button {...targetProps} elementRef={ref} icon={IconNames.TIME} minimal text={rangeLabelRequest} />)}
                    />
                </div>
                {this.props.station?.requests?.map((r) => <RequestCard key={r.id} request={r} {...this.props} />)}
            </div>
        )
    }
}

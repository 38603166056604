import {CancelToken} from "axios"
import HttpService from "@/services/HttpService"
import type {GpsPositionDto} from "@/model/GpsTrackLog"

const baseUrl = "/gpsTrackLog"

export default class GpsTrackLogService {
    constructor(api: HttpService) {
        this.api = api
    }

    get: GpsPositionDto[] = (droneId: string, start: string, end: string, cancelToken?: CancelToken) => this.api.post(`${baseUrl}/${droneId}`, {start, end}, {cancelToken});
}

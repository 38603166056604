import type {IUser} from "@/model/IUser"
import HttpService from "@/services/HttpService"

const baseUrl = "/admin/users"

export default class AdminUsersService {
    constructor(api: HttpService) {
        this.api = api
    }

    list(): Promise<IUser[]> {
        return this.api.get(baseUrl)
    }

    submit(params: IUser): Promise {
        return this.api.post(baseUrl, params)
    }

    update(userId: string, params: IUser): Promise {
        return this.api.post(`${baseUrl}/${userId}`, params)
    }

    resetPassword(userId: string, password: string): Promise {
        return this.api.post(`${baseUrl}/${userId}/reset-password`, {password})
    }
}

import i18next from "i18next"
import DroneConnection from "@/connection/DroneConnection"

export type DroneCommand = {
    name: string,
    execute: (dronesConn: DroneConnection, droneId: string) => Promise,
    droneId: string
}

function simpleCommand(cmd: string, args?: any): DroneCommand {
    let argument = ""

    if (args?.start) argument = "-true"
    if (args?.step === "get_mission") argument = "-get"
    if (args?.step === "prepare_landing") argument = "-prepare"
    if (args?.step === "return_home") argument = "-home"

    return {
        name: i18next.t(`model.drone-commands.commands.${cmd}${argument}`, cmd),
        execute: (dronesConn: DroneConnection, droneId: string) => dronesConn.command(droneId, cmd, args),
    }
}

export const DroneCommands: DroneCommand[] = (dronId) => [
    simpleCommand("gimbal_reset"),
    simpleCommand("shoot_photo", {cam_idx: 0}),
    simpleCommand("record_video_action", {cam_idx: 0, start: true}),
    simpleCommand("record_video_action", {cam_idx: 0, start: false}),
    simpleCommand("ros_pause_mission"),
    simpleCommand("ros_resume_mission"),
    simpleCommand("ros_return_home"),
    simpleCommand("ros_exit_rth"),
    /* simpleCommand('Analytic Start', 'analytic_start'),
        simpleCommand('Analytic Stop', 'analytic_stop'), */
    dronId !== "1ZNBJ6P00C00BX" ? simpleCommand("restart_tunnel") : null,
    simpleCommand("reboot"),
    simpleCommand("dispatcher_control", {step: "get_mission"}),
    dronId !== "1ZNBJ6P00C00BX" ? simpleCommand("dispatcher_control", {step: "prepare_landing"}) : null,
    dronId !== "1ZNBJ6P00C00BX" ? simpleCommand("dispatcher_control", {step: "return_home"}) : null,
    simpleCommand("restart_roslaunch"),
    dronId !== "1ZNBJ6P00C00BX" ? simpleCommand("calibrate_camera") : null,
    dronId !== "1ZNBJ6P00C00BX" ? simpleCommand("get_battery_info") : null,
    simpleCommand("force_landing"),
].filter((command) => command !== null)

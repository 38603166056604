import React, {useEffect, useState} from "react"
import {reaction} from "mobx"
import {observer} from "mobx-react"
import {Spinner} from "@blueprintjs/core"
import api from "@/services/ApiService"
import charts from "@/store/ChartsStore"
import {groupBy} from "@/utils"
import "./MissionStatsPanel.scss"
import Chart from "@/components/charts/Chart"
import selection from "@/store/SelectionStore"
import type {ChartSettingsDto, DeviceType} from "@/model/ChartDto"
import RightPanel from "./RightPanel"

const capitalizeFirst = (str: string) => str[0].toUpperCase() + str.slice(1, str.length)

const MissionStatsPanel: React.VFC = () => {
    const [allSettings, setAllSettings] = useState<Record<DeviceType, ChartSettingsDto[]>>()

    useEffect(() => {
        const groupByDevices = (arr: ChartSettingsDto[]) => setAllSettings(groupBy(arr, "deviceType"))

        reaction(() => charts.getChartSettings, (res) => groupByDevices(res))

        if (charts.getChartSettings.length > 0) {
            groupByDevices(charts.getChartSettings)
            return
        }

        if (charts.loading)
            return

        charts.setLoading(true)
        api.charts.getAllSettings().then((x) => charts.setSettings(x))
    }, [])

    return (
        <RightPanel>
            {(charts.loading || !selection.mission) && (
                <div className="flex center loader">
                    <Spinner />
                </div>
            )}
            {allSettings && selection.mission && Object.entries(allSettings).map(([device, settingsArr]) => (
                <div className="device" key={device}>
                    <h2>{capitalizeFirst(device)} statistics</h2>
                    <div className="grid">
                        {settingsArr.map((settings) => (
                            <div key={settings.id} className="grid-item">
                                <Chart
                                    settings={settings}
                                    startTime={new Date(selection.mission.startTime)}
                                    endTime={new Date(selection.mission.completed)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </RightPanel>
    )
}

export default observer(MissionStatsPanel)

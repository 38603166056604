import React from "react"
import {withRouter} from "react-router-dom"
import CameraPlayer from "@/components/control/CameraPlayer"
import type {RouteProps} from "@/types/RouteProps"

type RProps = {
    droneId: string,
    camera: string,
}

function CameraPage(props: RouteProps<RProps>) {
    const {droneId, camera} = props.match.params
    return <div className="cameraFullscreen"><CameraPlayer droneId={droneId} camera={camera} /></div>
}

export default withRouter(CameraPage)

import {action, makeObservable, observable} from "mobx"
import DroneStore from "@/store/DroneStore"

export class JoystickStore {
    @observable activated = false
    @observable drone: DroneStore = null

    constructor() {
        makeObservable(this)
    }

    @action activate(drone: DroneStore) {
        this.drone = drone
        this.activated = true
    }

    @action close() {
        this.activated = false
        this.drone = null
    }
}

const joystick = new JoystickStore()

export default joystick

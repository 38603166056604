import {IOptionProps} from "@blueprintjs/core"

const AdminUsersTypes: Array<IOptionProps> = [
    {label: "Admin", value: "admin"},
    {label: "Operator", value: "operator"},
    {label: "Station Operator", value: "station_operator"},
    {label: "Consumer", value: "consumer"},
]

export default AdminUsersTypes

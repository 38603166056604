import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {inject, observer} from 'mobx-react'
import {Button,
	FormGroup,
	Tab,
	Tabs,
	Tooltip,
	Position,
	Intent,
	EditableText,
	H3, Spinner, Dialog, Classes, Tag, Icon} from '@blueprintjs/core'
import {IconNames} from '@blueprintjs/icons'
import {DatePicker} from '@blueprintjs/datetime'
import i18next from 'i18next'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import L from 'leaflet'
import {formatDateTime, secondsToTime} from '@/Tools'
import api from '@/services/ApiService'
import type {RouteProps} from '@/types/RouteProps'
import type {UnitStores} from '@/store/DroneListStore'
import PlanStore from '@/store/PlanStore'
import {SelectionStore} from '@/store/SelectionStore'
import AppToaster, {dangerToast, infoToast} from '@/components/AppToaster'
import Navigator from '@/components/left/Navigator'
import LoaderComponent from '@/components/LoaderComponent'
import './PlanEditor.scss'
import ActionsStore from '@/store/ActionsStore'
import {PrivateComponent} from '@/authorization/components'
import permissions from '@/authorization/permissions'
import permissionsStore from '@/store/PermissionsStore'
import RouteParamEditor from './RouteParamEditor'
import WaypointParamEditor from './WaypointParamEditor'

type Props = {
    selection?: SelectionStore,
    unit: UnitStores,
    plan: PlanStore,
    anonymousMode?: boolean,
    createPlan: (plan: PlanStore) => void,
}

const PREPARE_TAKE_OFF_TIME = 60 // примерное время подготовки дрона к взлету
const DRONE_VERTICAL_SPEED = 3 // вертикальная скорость дрона(предположительно)
const WAYPOINT_TURN_TIME = 4 // округленное время на поворот в каждой точке
const BATTERY_PERCENT_DECREASE_PER_SECOND = 0.0005

function calculateTimeBetweenWayPoints(first, second, defaultSpeed) {
	const currentWaypoint = L.latLng(first.lat, first.lon)
	const nextWayPoint = L.latLng(second.lat, second.lon)
	const distance = nextWayPoint.distanceTo(currentWaypoint)
	const speed = first.useSpeed ? first.speed : defaultSpeed
	return speed ? Math.round(distance / speed) : 0
}

@withRouter
@inject('selection')
@observer
class PlanEditorControl extends Component<Props & RouteProps<{ unitId: string, actionsStore: ActionsStore }>> {
    state = {
    	scheduleOpened: false,
    	scheduledTime: moment().seconds(0).milliseconds(0).add(5, 'minutes').toDate(),
    }

    componentDidMount() {
    	const {plan} = this.props
    	plan.setStartPoint(this.stationPosition)
    	plan.setEditMode(true)
    	this.props.selection.setPlan(plan)
    }

    get stationPosition() {
    	const {drone, station} = this.props.unit
    	if (station)
    		return station.position
    	return drone.stationPosition
    }

    componentWillUnmount() {
    	this.props.selection.setPlan(null)
    }

    calculateTotalTime(plan) {
    	if (!plan)
    		return 0
    	const totalTakeOffTime = plan.distance !== 0 ? Math.round(plan.route.height / DRONE_VERTICAL_SPEED + PREPARE_TAKE_OFF_TIME) : 0
    	const totalLandingTime = totalTakeOffTime * 1.8 // посадка
    	const totalTurnTime = plan.points.length * WAYPOINT_TURN_TIME
    	let totalRouteTime = 0
    	if (plan.points.length > 0) {
    		if (plan.startPoint)
    			totalRouteTime += calculateTimeBetweenWayPoints(plan.startPoint, plan.points[0].waypoint, plan.route.speed)

    		for (let i = 0; i < plan.points.length - 1; i++)
    			totalRouteTime += calculateTimeBetweenWayPoints(plan.points[i].waypoint, plan.points[i + 1].waypoint, plan.route.speed)

    		if (plan.startPoint) {
    			const lastIndex = plan.points.length - 1
    			totalRouteTime += calculateTimeBetweenWayPoints(plan.points[lastIndex].waypoint, plan.startPoint, plan.route.speed)
    		}
    	}

    	const totalActionsTime = plan.points.map(
    		(m) => (
    			m.waypoint.actions.items.reduce(
    				(sum, action) => sum + (action.param && action.type.name === 'hover' ? +action.param : +action.type.time),
    				0,
    			)
    		),
    	).reduce((total, current) => total + current, 0)
    	return totalActionsTime + totalRouteTime + totalTakeOffTime + totalLandingTime + totalTurnTime
    }

    calculateTotalBatteryUsage(totalTime: any) {
    	return totalTime * BATTERY_PERCENT_DECREASE_PER_SECOND
    }

    startAnonymousMission = () => {
    	const {drone, station} = this.props.unit
    	const {plan} = this.props
    	const {name, route, points} = plan
    	const stationId = station ? station.stationId : null
    	const waypoints = points.map((p) => p.waypoint)
    	api.mission.startAnonymous(drone.droneId, stationId, name, route, waypoints).then(() => {
    		infoToast(i18next.t('operator.plan-editor.toast.mission-started'))
    	})
    }

    render() {
    	const {plan} = this.props
    	const {drone} = this.props.selection

    	if (!plan)
    		return <></>

    	const {anonymousMode} = this.props
    	const totalTime = this.calculateTotalTime(plan)
    	const totalBattery = this.calculateTotalBatteryUsage(totalTime)
    	const error = drone?.withStation ? plan.validateStation(totalBattery) : plan.validateDrone(totalBattery)
    	const disabledInput = !permissionsStore.hasPermission(permissions.planWrite)

    	const playButton = (
	<PrivateComponent
       permission={permissions.missionCanStart}
       permissionsCompare="or"
    		>
	<Tooltip content={i18next.t('operator.plan-editor.form.tooltip.start')}
                position={Position.BOTTOM}
    			>
	<Button icon={IconNames.PLAY}
                minimal
                onClick={!anonymousMode ? this.startMission : this.startAnonymousMission}
                intent={Intent.SUCCESS}
    				/>
    			</Tooltip>
    		</PrivateComponent>
    	)

    	return (
	<div className="plan-editor">
	{!anonymousMode && (
    				<PrivateComponent
         permissions={[
    						permissions.planWrite,
    						permissions.missionCanRequest,
    						permissions.missionCanStart,
    					]}
         permissionsCompare="or"
    				>
		<FormGroup>
	<PrivateComponent permission={permissions.planWrite}>
	<Tooltip content={i18next.t('operator.plan-editor.form.tooltip.save')}
                    position={Position.BOTTOM}
    							>
	<Button icon={IconNames.FLOPPY_DISK} minimal onClick={this.savePlan} />
    							</Tooltip>
    						</PrivateComponent>
	{plan.id && (
    							<>
		<PrivateComponent permission={permissions.planWrite}>
	<Tooltip content={i18next.t('operator.plan-editor.form.tooltip.delete')}
                      position={Position.BOTTOM}
    									>
	<Button icon={IconNames.TRASH}
                      minimal
                      onClick={this.deletePlan}
                      intent={Intent.DANGER}
    										/>
    									</Tooltip>
    								</PrivateComponent>
		{this.props.unit.drone && (
    									this.props.unit.drone.missionApprovement ? (
	<PrivateComponent permission={permissions.missionCanStart}>
	<Tooltip content="Schedule" position={Position.BOTTOM}>
	<Button icon={IconNames.HISTORY}
                        minimal
                        onClick={this.openScheduleMission}
                        intent={Intent.SUCCESS}
    												/>
    											</Tooltip>
    										</PrivateComponent>
    									) : playButton
    								)}
	</>
    						)}
    					</FormGroup>
	</PrivateComponent>
    			)}

	{anonymousMode && (
    				<FormGroup>
		{playButton}
	</FormGroup>
    			)}

	{!anonymousMode && (
    				<FormGroup>
		<H3>
	<PrivateComponent
           permission={permissions.planWrite}
           fallback={plan.name}
    						>
	<EditableText
            placeholder={i18next.t('operator.plan-editor.form.plan-name.placeholder')}
            value={plan.name}
            onChange={(val) => plan.setName(val)}
    							/>
    						</PrivateComponent>
    					</H3>
	</FormGroup>
    			)}

	<FormGroup>
	<p>{i18next.t('operator.plan-editor.form.text.distance')} {plan.distance}{i18next.t('operator.plan-editor.form.text.m')}</p>
	<p>{i18next.t('operator.plan-editor.form.text.total-mission-time')} {secondsToTime(totalTime)}</p>
	<p className={totalBattery > 1 ? 'plan-error' : undefined}>{i18next.t('operator.plan-editor.form.text.battery-usage')} {+(totalBattery * 100).toFixed(2)}{i18next.t('operator.plan-editor.form.text.percent')}</p>
	{error && (
    					<p>{i18next.t('operator.plan-editor.form.text.error')}
		<span className="plan-error">
	<span><Icon icon={IconNames.WARNING_SIGN} intent={Intent.DANGER} iconSize={15} /> {error}</span>
    						</span>
	</p>
    				)}
	{!anonymousMode && plan.created
                    && <p>{i18next.t('operator.plan-editor.form.text.created')} {formatDateTime(plan.created)}</p>}
    			</FormGroup>
	<Tabs id="TabsExample">
	<Tab id="rt"
             title={i18next.t('operator.plan-editor.tab.title.route')}
             panel={<RouteParamEditor disabled={disabledInput} planStore={plan} />}
    				/>
	<Tab id="wp"
             title={i18next.t('operator.plan-editor.tab.title.waypoint')}
             panel={<WaypointParamEditor disabled={disabledInput} planStore={plan} />}
    				/>
    			</Tabs>

	{!anonymousMode && this.props.unit.drone && this.props.unit.drone.missionApprovement && (
    				<Dialog
         title={i18next.t('operator.plan-editor.scheduling.title')}
         icon={IconNames.HISTORY}
         isOpen={this.state.scheduleOpened}
    				>
		<div className={Classes.DIALOG_BODY} style={{textAlign: 'center'}}>
	<div style={{display: 'inline-block'}}>
	<DatePicker
            locale={localStorage.getItem('i18nextLng')}
            localeUtils={MomentLocaleUtils}
            className={Classes.ELEVATION_1}
            timePickerProps={{showArrowButtons: false}}
            onChange={(date: Date) => this.setState({scheduledTime: date})}
            defaultValue={this.state.scheduledTime}
            highlightCurrentDay
    							/>
    						</div>
	<p style={{marginTop: 8}}>
	<Tag
            intent={Intent.PRIMARY}
    							>{moment(this.state.scheduledTime).format('DD.MM.YY HH:mm')}
    							</Tag>
    						</p>
    					</div>
		<div className={Classes.DIALOG_FOOTER}>
	<div className={Classes.DIALOG_FOOTER_ACTIONS}>
	<Button
            onClick={this.cancelSchedule}
    							>{i18next.t('operator.plan-editor.scheduling.cancel')}
    							</Button>
	<Button onClick={this.startMission}
                   intent={Intent.SUCCESS}
    							>{i18next.t('operator.plan-editor.scheduling.schedule')}
    							</Button>
    						</div>
    					</div>
	</Dialog>
    			)}
    		</div>
    	)
    }

    savePlan = () => {
    	const {drone, station} = this.props.unit
    	const {plan} = this.props
    	const {id, name, route, points} = plan

    	const waypoints = points.map((p) => p.waypoint)

    	if (id) {
    		api.plans.update(id, name, route, waypoints)
    			.then(() => {
    				this.props.plan.update(name, route, waypoints)
    				this.planSaved()
    			})
    	} else {
    		const droneId = station ? null : drone.droneId
    		const stationId = station ? station.stationId : null
    		api.plans.create(droneId, stationId, name, route, waypoints)
    			.then((created) => {
    				const selected = new PlanStore(created, this.stationPosition)
    				selected.setEditMode(true)
    				this.props.selection.setPlan(selected)
    				if (station)
    					station.addPlan(this.props.selection.plan)
    				else
    					drone.addPlan(this.props.selection.plan)
    				this.planSaved()
    				this.props.createPlan(selected)
    				this.props.history.push(`/unit/${this.props.match.params.unitId}/plans/edit/${this.props.plan.id}`)
    			})
    	}
    }

    deletePlan = () => {
    	const {drone, station} = this.props.unit
    	const action = {
    		text: i18next.t('yes'),
    		onClick: () => {
    			api.plans.remove(this.props.selection.plan.id)
    				.then(() => {
    					infoToast(i18next.t('operator.plan-editor.toast.plan-deleted'))
    					if (station)
    						station.deletePlan(this.props.plan)
    					else
    						drone.deletePlan(this.props.plan)
    					this.props.history.replace(`/unit/${this.props.match.params.unitId}/plans`)
    				})
    		},
    	}
    	AppToaster.show({message: i18next.t('operator.plan-editor.toast.show.plan-confirm'), action, timeout: 10000})
    }

    planSaved = () => {
    	infoToast(i18next.t('operator.plan-editor.toast.plan-saved'))
    }

    startMission = (event) => {
    	const {plan, drone} = this.props.selection

    	const totalTime = this.calculateTotalTime(plan)
    	const totalBattery = this.calculateTotalBatteryUsage(totalTime)
    	const error = drone.withStation ? plan.validateStation(totalBattery) : plan.validateDrone(totalBattery)

    	if (error === 'Battery usage > 80%') {
    		const playButton = event.target
    		const action = {
    			text: i18next.t('yes'),
    			onClick: (() => {
    				this.sendStartMissionRequest(plan)
    				playButton.disabled = false
    			}),
    		}
    		playButton.disabled = true
    		AppToaster.show({
    			message: i18next.t('operator.plan-editor.toast.show.error-confirm'),
    			action,
    			timeout: 0,
    			onDismiss: () => {
    				playButton.disabled = false
    				playButton.blur()
    			},
    		})
    		return
    	}
    	if (error != null && error !== 'Battery usage > 80%') {
    		dangerToast(error)
    		return
    	}

    	this.sendStartMissionRequest(plan)
    }

    sendStartMissionRequest(plan) {
    	api.plans.start(plan.id, this.props.unit.drone.droneId, this.state.scheduledTime)
    		.then((response) => {
    			if (response.error)
    				dangerToast(response.error)
    			if (this.props.unit.drone.missionApprovement)
    				infoToast('Mission scheduled')
    			else {
    				infoToast(i18next.t('operator.plan-editor.toast.mission-started'))
    				this.props.unit.drone.setMissionId(response.missionId)
    			}
    			this.props.history.push(`/unit/${this.props.unit.id}/info`)
    		})
    }

    openScheduleMission = () => {
    	this.setState({scheduleOpened: true})
    }

    cancelSchedule = () => {
    	this.setState({scheduleOpened: false})
    }
}

@withRouter
@observer
export default class PlanEditor extends LoaderComponent<{ unit: UnitStores, anonymousMode?: boolean } & RouteProps<{ planId: string, unitId: string }>> {
    state = {
    	plan: null,
    }

    prepare(): Promise {
    	if (this.props.match.params.planId) {
    		return api.plans.get(this.props.match.params.planId).then((result) => {
    			const plan = new PlanStore(result)
    			this.setState({plan})
    		})
    	}
    	const plan = new PlanStore()
    	this.setState({plan})
    	if (this.props.anonymousMode)
    		plan.setAnonymous(true)

    	return Promise.resolve()
    }

    createPlan = (plan) => {
    	this.setState({plan})
    }

    successRender = () => {
    	const {plan} = this.state
    	if (!plan)
    		return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

    	return (
	<Navigator backTitle={this.props.unit.displayName}
                 title={plan.displayName}
                 backUrl={`/unit/${this.props.match.params.unitId}/${this.props.anonymousMode ? 'info' : 'plans'}`}
    		>
	<PlanEditorControl anonymousMode={this.props.anonymousMode}
                          unit={this.props.unit}
                          plan={plan}
                          createPlan={this.createPlan}
    			/>
    		</Navigator>
    	)
    }
}

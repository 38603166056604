import {action, makeObservable, observable} from "mobx"
import {JoystickControls} from "@/components/control/JoystickControls"
import type {JoystickPreset} from "@/model/JoystickPreset"
import {dangerToast} from "@/components/AppToaster"

function unsetValue(obj, val) {
    Object.entries(obj).forEach(([k, v]) => {
        if (v === val) obj[k] = undefined
    })
}

export default class PresetStore {
    joystick = new JoystickControls()
    axes = {}
    buttons = {}

    id: string
    @observable gamepadName: string
    @observable name: string
    @observable bindingsByControl = {}
    @observable multipliers = {}

    @observable currentControlId: string
    @observable buttonsPressed = {}
    @observable axisValue = {}

    constructor() {
        makeObservable(this)
    }

    @action setName(name: string) {
        this.name = name
    }

    @action setGamepadName(name: string) {
        this.gamepadName = name
    }

    @action beginSet(id: string) {
        this.currentControlId = id
    }

    @action cancelSet() {
        this.currentControlId = null
    }

    setObj(obj, num: number, controlId: string) {
        this.cancelSet()
        unsetValue(obj, controlId)

        const curr = obj[num]
        if (curr !== undefined)
            this.bindingsByControl[curr] = undefined

        obj[num] = controlId
        this.bindingsByControl[controlId] = num
    }

    @action setAxis(num: number, controlId: string) {
        this.setObj(this.axes, num, controlId)
    }

    @action setButton(num: number, controlId: string) {
        this.setObj(this.buttons, num, controlId)
    }

    @action buttonPressing(num: number, pressed: boolean) {
        this.buttonsPressed[num] = pressed
    }

    @action setAxisValue(num: number, value: number) {
        this.axisValue[num] = value
    }

    @action setMultiplier(control: string, value: number) {
        this.multipliers[control] = value
    }

    getPreset(): ?JoystickPreset {
        if (!this.name) {
            dangerToast("Enter name")
            return null
        }
        if (!this.gamepadName) {
            dangerToast("Gamepad not connected")
            return null
        }

        const multipliers = {}
        Object.entries(this.multipliers).forEach(([k, v]) => {
            multipliers[k] = v
        })

        return {
            id: this.id,
            name: this.name,
            gamepadName: this.gamepadName,
            buttons: this.buttons,
            axes: this.axes,
            multipliers,
        }
    }

    @action setPreset(preset: JoystickPreset) {
        this.id = preset.id
        this.name = preset.name
        this.gamepadName = preset.gamepadName

        Object.entries(preset.buttons).forEach(([k, ctrl]) => {
            this.buttons[k] = ctrl
            this.bindingsByControl[ctrl] = k
        })

        Object.entries(preset.axes).forEach(([k, ctrl]) => {
            this.axes[k] = ctrl
            this.bindingsByControl[ctrl] = k
        })

        Object.entries(preset.multipliers).forEach(([k, val]) => {
            this.multipliers[k] = val
        })
    }
}

import General from "./General.json"
import UnitParams from "./UnitParams.json"
import TemplateList from "./TemplateList.json"
import RequestList from "./RequestList.json"
import TemplateEditor from "./TemplateEditor.json"
import RequestEditor from "./RequestEditor.json"
import UnitPanel from "./UnitPanel.json"

const Consumer = {
    ...General,
    ...UnitParams,
    ...TemplateList,
    ...RequestList,
    ...TemplateEditor,
    ...RequestEditor,
    ...UnitPanel,
}
export default Consumer

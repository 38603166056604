import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {Button, Intent} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import type {RouteProps} from "@/types/RouteProps"
import CreateDroneForm from "@/components/left/admin/CreateDroneForm"
import EditDroneForm from "@/components/left/admin/EditDroneForm"

@withRouter
export default class DronesEditor extends Component<RouteProps<{droneId: string}>> {
    render() {
        return (
            <div className="admin-header" style={{padding: 10}}>
                <Button icon={IconNames.ARROW_LEFT}
                        onClick={this.goBack}
                        text={i18next.t("admin.drones-editor.button.back")}
                        intent={Intent.PRIMARY}
                        minimal
                        style={{marginTop: 10, marginLeft: 10}}
                />
                {this.props.match.params.droneId ? <EditDroneForm droneId={this.props.match.params.droneId} /> : <CreateDroneForm />}
            </div>
        )
    }

    goBack = () => this.props.history.replace("/admin/drones")
}

import {action, IObservableArray, makeObservable, observable, set} from "mobx"
import type StationState from "@/model/StationState"
import type {Station, StationPosition} from "@/model/Station"
import type {IRequest} from "@/model/IRequest"
import type Plan from "@/model/Plan"
import PlanStore from "@/store/PlanStore"

export const PARAM_TIMEOUT_MS = 5 * 1000

export default class StationStore {
    stationId: string
    sshPort: number
    @observable name: string
    @observable position: StationPosition
    @observable params: StationState = {}
    @observable requests: IObservableArray<IRequest> = []
    @observable plans: IObservableArray<PlanStore> = []
    @observable paramsTimeout = {}
    paramsUpdTime = {}

    constructor(station: Station) {
        this.stationId = station.stationId
        this.sshPort = station.sshPort
        this.name = station.name
        this.position = station.position
        makeObservable(this)
    }

    get displayName() {
        return this.name ? this.name : this.stationId
    }

    @action setName(name: string) {
        this.name = name
    }

    @action setPosition(pos: StationPosition) {
        this.position = pos
    }

    @action setParams(params) {
        set(this.params, params)
        Object.keys(params).forEach((k) => {
            this.paramsUpdTime[k] = Date.now()
            this.paramsTimeout[k] = false
        })
    }

    @action checkTimeout() {
        const now = Date.now()
        Object.entries(this.paramsUpdTime).forEach(([k, v]) => {
            if (now - v > PARAM_TIMEOUT_MS)
                this.paramsTimeout[k] = true
        })
    }

    @action setRequestList(list: IRequest[]) {
        this.requests.replace(list)
    }

    @action appendRequest(r: IRequest) {
        this.requests.push(r)
    }

    @action updateRequest(request: IRequest) {
        const index = this.requests.findIndex((r) => r.id === request.id)
        if (index < 0) {
            this.appendRequest(request)
            return
        }
        this.requests.splice(index, 1, request)
    }

    @action setPlans(plans: Plan[]) {
        this.plans.replace(plans.map((p) => new PlanStore(p, this.position)))
    }

    @action addPlan(planStore: PlanStore): PlanStore {
        const ps = new PlanStore()
        ps.copyFrom(planStore)
        this.plans.push(ps)
        return ps
    }

    @action deletePlan(planStore: PlanStore) {
        this.plans.remove(planStore)
    }
}

import React, {Component} from "react"
import {inject} from "mobx-react"
import {Button, Classes, FormGroup, InputGroup, Intent, Spinner, Switch, Tooltip} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import {AdminDronesStore} from "@/store/AdminDronesStore"
import api from "@/services/ApiService"
import {infoToast} from "@/components/AppToaster"

type State = {
    name: string,
    droneId: string,
    withStation: boolean,
    password: string,
    passwordConfirm: string,
    showPassword: boolean,
    disabled: boolean,
    loading: boolean,
}

@inject("adminDrones")
export default class EditDroneForm extends Component<{ adminDrones?: AdminDronesStore, droneId: string }, State> {
    state: State = {
        name: "",
        droneId: "",
        withStation: true,
        password: "",
        passwordConfirm: "",
        showPassword: false,
        disabled: false,
        loading: true,
    }

    componentDidMount() {
        const drone = this.props.adminDrones.getAdminDroneById(this.props.droneId)
        if (drone) {
            this.setState({
                name: drone.name,
                droneId: drone.droneId,
                withStation: drone.withStation,
                loading: false,
            })
        } else {
            api.drone.get(this.props.droneId).then((d) => {
                this.setState({
                    name: d.name,
                    droneId: d.droneId,
                    withStation: d.withStation,
                    loading: false,
                })
            })
        }
    }

    render() {
        const {name, droneId, withStation, password, passwordConfirm, showPassword, disabled, loading} = this.state

        if (loading)
            return <Spinner />

        const lockButton = (
            <Tooltip
                content={showPassword ? i18next.t("admin.drones-editor.text.hide-pass") : i18next.t("admin.drones-editor.text.show-pass")}
            >
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        )

        return (
            <>
                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <p>{i18next.t("admin.drones-editor.text.edit-drone")}</p>
                    <p>{i18next.t("admin.drones-editor.text.drone-id")} {droneId}</p>
                    <FormGroup label={i18next.t("admin.drones-editor.form.name.label")} labelFor="name">
                        <InputGroup
                            id="name"
                            leftIcon={IconNames.PERSON}
                            value={name}
                            onChange={(e) => this.setState({name: e.target.value})}
                        />
                    </FormGroup>
                    <FormGroup label={i18next.t("admin.drones-editor.form.with-station.label")} labelFor="withStation">
                        <Switch
                            id="withStation"
                            checked={withStation}
                            onChange={() => this.setState({withStation: !withStation})}
                            inline
                        />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={i18next.t("admin.drones-editor.button.save")}
                                intent={Intent.PRIMARY}
                                loading={disabled}
                                onClick={this.edit}
                        />
                    </div>
                </div>

                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <p>{i18next.t("admin.drones-editor.text.change-password")}</p>
                    <FormGroup label={i18next.t("admin.drones-editor.form.password.label")} labelFor="password">
                        <InputGroup
                            id="password"
                            leftIcon={IconNames.KEY}
                            type={showPassword ? "text" : "password"}
                            rightElement={lockButton}
                            value={password}
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                    </FormGroup>
                    <FormGroup label={i18next.t("admin.drones-editor.form.password-rep.label")}
                               labelFor="password-confirm"
                    >
                        <InputGroup
                            id="password-confirm"
                            leftIcon={IconNames.KEY}
                            type={showPassword ? "text" : "password"}
                            rightElement={lockButton}
                            value={passwordConfirm}
                            onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                        />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={i18next.t("admin.drones-editor.button.change")}
                                intent={Intent.PRIMARY}
                                loading={disabled}
                                onClick={this.changePassword}
                        />
                    </div>
                </div>
            </>
        )
    }

    edit = () => {
        const {droneId, name, withStation} = this.state

        if (!droneId || !name) return
        this.setState({disabled: true})

        api.adminDrones
            .update(droneId, {name, withStation})
            .then((request) => {
                infoToast(i18next.t("admin.drones-editor.toast.drone-edit"))
                this.props.adminDrones.updateAdminDrone(request)
            })
            .finally(() => this.setState({disabled: false}))
    }

    changePassword = () => {
        const {droneId, password, passwordConfirm} = this.state
        if (!password || !passwordConfirm) return
        if (password !== passwordConfirm) {
            infoToast(i18next.t("admin.drones-editor.toast.password-match"))
            return
        }
        this.setState({disabled: true})

        api.adminDrones
            .resetPassword(droneId, password)
            .then(() => {
                infoToast(i18next.t("admin.drones-editor.toast.pass-change"))
            })
            .finally(() => this.setState({disabled: false}))
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})
}

import React, {Component} from "react"
import {Redirect, Route, Switch, withRouter} from "react-router-dom"
import {Provider} from "mobx-react"
import LeftPanel from "@/components/left/LeftPanel"
import CameraView from "@/components/control/CameraView"
import DroneControl from "@/components/control/DroneControl"
import OperatorRequestsLayer from "@/components/map/OperatorRequestsLayer"
import DroneConnection from "@/connection/DroneConnection"
import drones from "@/store/DroneListStore"
import cameras from "@/store/CameraStore"
import joystick from "@/store/JoystickStore"
import selection from "@/store/SelectionStore"
import RequestStore from "@/store/RequestStore"
import adminUsers from "@/store/AdminUsersStore"
import adminDrones from "@/store/AdminDronesStore"
import adminStations from "@/store/AdminStationsStore"
import api from "@/services/ApiService"
import AdminPanel from "@/components/left/admin/AdminPanel"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"
import FloatingCharts from "@/components/charts/FloatingCharts"
import MissionStatsPanel from "@/components/right/MissionStatsPanel"
import Map from "@/components/map/Map"
import permissions from "@/authorization/permissions"
import {PrivateComponent, PrivateRoute} from "@/authorization/components"

// TODO: MAKE SINGLE COMPONENT WITH AUTHORIZATION LOGIC INSTEAD (PRIVATE ROUTES)
class DashboardMainOperator extends Component<{}> {
    constructor() {
        super()
        this.requestStore = new RequestStore()
        this.droneConn = new DroneConnection(drones)
    }

    render() {
        return (
            <Provider
                drones={drones}
                dronesConn={this.droneConn}
                cameras={cameras}
                joystick={joystick}
                selection={selection}
                request={this.requestStore}
            >
                <Switch>
                    {/* TODO: CHANGE TO THE missionStatisticsView */}
                    <PrivateRoute permission={permissions.chartsView} path="/unit/:unitId/missions/:missionId/stats" exact component={MissionStatsPanel} />
                    <Route>
                        <Map>
                            <OperatorRequestsLayer />
                        </Map>
                    </Route>
                </Switch>
                <LeftPanel />
                <PrivateComponent permission={permissions.cameraView}>
                    <CameraView />
                </PrivateComponent>
                <PrivateComponent permission={permissions.chartsView}>
                    <FloatingCharts />
                </PrivateComponent>
                <PrivateComponent permission={permissions.droneFlyControl}>
                    <DroneControl />
                </PrivateComponent>
            </Provider>
        )
    }
}

function DashboardMainAdmin() {
    return (
        <Provider adminUsers={adminUsers} adminDrones={adminDrones} adminStations={adminStations}>
            <Switch>
                <Redirect from="/admin" to="/admin/users" exact />
                <Route path="/admin" component={AdminPanel} />
            </Switch>
        </Provider>
    )
}

@withRouter
export default class DashboardMain extends LoaderComponent<RouteProps> {
    prepare(): Promise {
        return api.units.list().then((list) => {
            if (list)
                drones.setList(list)
        })
    }

    successRender() {
        return (
            <Switch>
                <PrivateRoute
                    permissions={[
                        permissions.droneWrite,
                        permissions.stationsWrite,
                        permissions.profilesReadWrite,
                        permissions.userConsumerWrite,
                        permissions.userFullWrite,
                    ]}
                    permissionsCompare="or"
                    path="/admin"
                    component={DashboardMainAdmin}
                />
                <Route>
                    <DashboardMainOperator />
                </Route>
            </Switch>
        )
    }
}

import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {H5, Card, Elevation, H4} from "@blueprintjs/core"
import i18next from "i18next"
import type {RouteProps} from "@/types/RouteProps"
import {flightStatusDescription} from "@/model/Enums"
import {DroneListStore} from "@/store/DroneListStore"
import {SelectionStore} from "@/store/SelectionStore"
import type {UnitStores} from "@/store/DroneListStore"
import Navigator from "@/components/left/Navigator"
import "./DroneList.scss"

type CardProps = {
    unit: UnitStores,
    selection?: SelectionStore,
}

const StationContext = React.createContext()

@observer
class StationParameter extends Component<{param: string, children?: React.ReactNode}> {
    static contextType = StationContext
    render() {
        const station = this.context
        if (station.params[this.props.param] === undefined)
            return <></>

        const cl = station.paramsTimeout[this.props.param] ? "param-timeout" : ""
        return (
            <div className={`param ${cl}`}>
                {this.props.children}
            </div>
        )
    }
}

const DroneContext = React.createContext()

@observer
class DroneParameter extends Component<{param: string, children?: React.ReactNode}> {
    static contextType = DroneContext
    render() {
        const drone = this.context
        if (drone.params[this.props.param] === undefined)
            return <></>

        const cl = drone.paramsTimeout[this.props.param] ? "param-timeout" : ""
        return (
            <div className={`param ${cl}`}>
                {this.props.children}
            </div>
        )
    }
}

@withRouter
@inject("selection")
@observer
class DroneCard extends Component<CardProps & RouteProps, {}> {
    render() {
        const {station, drone} = this.props.unit
        return (
            <Card
                elevation={Elevation.TWO}
                className="bp3-text-small"
                interactive
                onClick={this.onClick}
            >
                {station && <H4>{station.displayName}</H4>}
                {drone && <H5>{drone.displayName}</H5>}
                {drone && (
                    <DroneContext.Provider value={drone}>
                        <p>{drone.droneId}</p>
                        {drone.currentMissionId && <p>{i18next.t("operator.drone-list.mission")} {drone.currentMissionId}</p>}
                        <DroneParameter param="flight_status">{i18next.t("operator.drone-list.flight-status")} {i18next.t(flightStatusDescription[drone.params.flight_status])}</DroneParameter>
                    </DroneContext.Provider>
                )}
                {station && (
                    <StationContext.Provider value={station}>
                        <StationParameter param="state">{i18next.t("operator.drone-list.state")} {station.params.state}</StationParameter>
                        <StationParameter param="task">{i18next.t("operator.drone-list.task")} {station.params.task}</StationParameter>
                    </StationContext.Provider>
                )}
            </Card>
        )
    }

    get title() {
        const {station, drone} = this.props.unit
        if (drone) return drone.displayName
        return station.displayName
    }

    onClick = () => {
        const {unit, selection, history} = this.props
        selection.selectDrone(unit)
        const unitId = selection.selectUnitId
        history.push(`/unit/${unitId}`)
    }
}

@inject("drones")
@observer
class DroneListContent extends Component<{ drones?: DroneListStore }> {
    render() {
        return (
            <div className="drone-list">
                {this.props.drones.stores.map((s) => (
                    <DroneCard key={s.id} unit={s} />
                ))}
            </div>
        )
    }
}

export default function DroneList() {
    return (
        <Navigator title={i18next.t("general.drone.title")}>
            <DroneListContent />
        </Navigator>
    )
}

import type {Unit} from "@/model/Unit"
import HttpService from "@/services/HttpService"

const baseUrl = "/units"

export default class UnitsService {
    constructor(api: HttpService) {
        this.api = api
    }

    list(): Promise<Unit[]> {
        return this.api.get(baseUrl)
    }
}

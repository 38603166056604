import React, {Component} from "react"
import {reaction} from "mobx"
import {inject, observer} from "mobx-react"
import {Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import DroneConnection from "@/connection/DroneConnection"
import LogSlider from "@/components/control/LogSlider"
import DroneStore from "@/store/DroneStore"
import "./ZoomSlider.scss"

@inject("dronesConn")
@observer
export default class ZoomSlider extends Component<{dronesConn?: DroneConnection, drone: DroneStore}> {
    state = {
        zoomSlider: 1,
    }

    componentDidMount() {
        if (this.props.drone.params.main_camera_zoom) {
            this.setState({
                zoomSlider: Math.round(this.props.drone.params.main_camera_zoom),
            })
        }
        reaction(() => this.props.drone.params.main_camera_zoom,
            (zoom) => {
                this.setState({zoomSlider: Math.round(zoom)})
            })
    }

    zoomSliderChange = (value: number) => {
        this.setState({zoomSlider: value})
    }

    zoomSliderRelease = (value: number) => {
        this.props.dronesConn.setZoomFactor(this.props.drone.droneId, 0, value).then()
    }

    render() {
        return (
            <div className="zoom-slider-wrapper">
                <Button icon={IconNames.PLUS}
                        minimal
                        onMouseDown={() => this.zoom(true, true)}
                        onMouseUp={() => this.zoom(false, true)}
                />
                <LogSlider className="zoom-slider"
                           vertical
                           min={1}
                           max={200}
                           onChange={this.zoomSliderChange}
                           onRelease={this.zoomSliderRelease}
                           value={this.state.zoomSlider}
                />
                <Button icon={IconNames.MINUS}
                        minimal
                        onMouseDown={() => this.zoom(true, false)}
                        onMouseUp={() => this.zoom(false, false)}
                />
            </div>
        )
    }

    zoom(start: boolean, zoomIn: boolean) {
        return this.props.dronesConn.zoomCtrl(this.props.drone.droneId, 0, start, zoomIn ? 1 : 0, 75)
    }
}

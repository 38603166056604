import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import langs from "./locales"

const resources = {
    en: {
        translation: langs.en,
    },
    ru: {
        translation: langs.ru,
    },
}

const options = {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "i18nextLng",
    caches: ["localStorage"],
    checkWhitelist: true,
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        whitelist: ["en", "ru"],
        debug: false,
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        },
        detection: options,
    })
    .then()

export default i18n

import React, {Component} from "react"
import {Route, Switch, withRouter} from "react-router-dom"
import {Tab, Tabs, Intent, Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import type {RouteProps} from "@/types/RouteProps"
import UsersList from "@/components/left/admin/UsersList"
import DronesList from "@/components/left/admin/DronesList"
import StationsList from "@/components/left/admin/StationsList"
import UsersEditor from "@/components/left/admin/UsersEditor"
import DronesEditor from "@/components/left/admin/DronesEditor"
import StationsEditor from "@/components/left/admin/StationsEditor"
import {PrivateRoute} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import permissionsStore from "@/store/PermissionsStore"
import ProfileEditor from "@/components/left/admin/profile/ProfileEditor"
import ProfilesList from "@/components/left/admin/profile/ProfilesList"

@withRouter
class AdminTabs extends Component<RouteProps<{tabId: string}>> {
    render() {
        return (
            <div className="admin-header" style={{padding: 10}}>
                <Button icon={IconNames.ARROW_LEFT} onClick={this.goBack} text="Back" intent={Intent.PRIMARY} minimal />
                <Tabs className="margin-8" selectedTabId={this.props.match.params.tabId} onChange={this.tabChange}>
                    <Tab
                        id="users"
                        title={i18next.t("admin.admin-panel.tabs.title.users")}
                        panel={<UsersList />}
                        hidden={!permissionsStore.hasPermission(permissions.userConsumerWrite) || !permissionsStore.hasPermission(permissions.userFullWrite)}
                    />

                    <Tab
                        id="drones"
                        title={i18next.t("admin.admin-panel.tabs.title.drones")}
                        panel={<DronesList />}
                        hidden={!permissionsStore.hasPermission(permissions.droneWrite)}
                    />

                    <Tab
                        id="stations"
                        title={i18next.t("admin.admin-panel.tabs.title.stations")}
                        panel={<StationsList />}
                        hidden={!permissionsStore.hasPermission(permissions.stationsWrite)}
                    />
                    <Tab
                        id="profiles"
                        title={i18next.t("admin.admin-panel.tabs.title.profiles")}
                        panel={<ProfilesList />}
                        hidden={!permissionsStore.hasPermission(permissions.profilesReadWrite)}
                    />
                </Tabs>
            </div>
        )
    }

    tabChange = (newTabId) => {
        this.props.history.replace(`/admin/${newTabId}`)
    }

    goBack = () => this.props.history.replace("/")
}

@withRouter
export default class AdminPanel extends Component<RouteProps> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/:tabId`} exact>
                    <AdminTabs />
                </Route>
                <PrivateRoute
                    permissions={[permissions.userConsumerWrite, permissions.userFullWrite]}
                    permissionsCompare="or"
                    path="/admin/users/create"
                >
                    <UsersEditor />
                </PrivateRoute>
                <PrivateRoute
                    permissions={[permissions.userConsumerWrite, permissions.userFullWrite]}
                    permissionsCompare="or"
                    path="/admin/users/:userId"
                >
                    <UsersEditor />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.droneWrite}
                    path="/admin/drones/create"
                >
                    <DronesEditor />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.droneWrite}
                    path="/admin/drones/:droneId"
                >
                    <DronesEditor />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.stationsWrite}
                    path="/admin/stations/create"
                >
                    <StationsEditor />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.stationsWrite}
                    path="/admin/stations/:stationId"
                >
                    <StationsEditor />
                </PrivateRoute>

                <PrivateRoute
                    permission={permissions.profilesReadWrite}
                    path="/admin/profiles/create"
                    component={ProfileEditor}
                />
                <PrivateRoute
                    permission={permissions.profilesReadWrite}
                    path="/admin/profiles/:id"
                    component={ProfileEditor}
                />
            </Switch>
        )
    }
}

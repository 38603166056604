import React from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Intent, Button, Switch} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import api from "@/services/ApiService"
import {AdminUsersStore} from "@/store/AdminUsersStore"
import AppToaster, {infoToast} from "@/components/AppToaster"
import type {RouteProps} from "@/types/RouteProps"
import type {IUser} from "@/model/IUser"
import LoaderComponent from "@/components/LoaderComponent"

import "./Admin.scss"

@withRouter
@inject("adminUsers")
@observer
export default class UsersList extends LoaderComponent<{ adminUsers?: AdminUsersStore } & RouteProps, { enabled: boolean, disabled: boolean }> {
    state = {
        enabled: true,
        disabled: false,
    }

    prepare(): Promise {
        return api.adminUsers.list()
            .then((list) => { this.props.adminUsers.setAdminUsersList(list) })
    }

    filtered(): IUser[] {
        return this.props.adminUsers.adminUsersList.filter((u) => u.enabled === this.state.enabled)
    }

    successRender() {
        const {enabled, disabled} = this.state
        return (
            <>
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <p><Button icon={IconNames.ADD} text={i18next.t("admin.users-list.button.add-user")} minimal onClick={this.createUser} intent={Intent.PRIMARY} /></p>
                    <Switch className="bp3-control" checked={enabled} label={i18next.t("admin.users-list.switch.label.enabled")} onChange={() => this.setState({enabled: !enabled})} />
                </div>
                <div className="admin-panel">
                    <div className="admin-table-wrapper">
                        <table
                            className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped"
                            style={{border: "1px solid #ccc"}}
                        >
                            <thead>
                                <tr>
                                    <th>{i18next.t("admin.users-list.text.login")}</th>
                                    <th>{i18next.t("admin.users-list.text.first-name")}</th>
                                    <th>{i18next.t("admin.users-list.text.last-name")}</th>
                                    <th>{i18next.t("admin.users-list.text.role")}</th>
                                    {enabled ? (
                                        <>
                                            <th>{i18next.t("admin.users-list.text.edit")}</th>
                                            <th>{i18next.t("admin.users-list.text.remove")}</th>
                                        </>
                                    ) : (
                                        <th>{i18next.t("admin.users-list.text.restore")}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.filtered().map((i) => (
                                    <tr key={i.id}>
                                        <td>{i.login}</td>
                                        <td>{i?.firstName ?? "-"}</td>
                                        <td>{i?.lastName ?? "-"}</td>
                                        <td>{i.role}</td>
                                        {enabled ? (
                                            <>
                                                <td><Button icon={IconNames.EDIT} minimal onClick={() => this.editUser(i.id)} loading={disabled} intent={Intent.PRIMARY} /></td>
                                                <td><Button icon={IconNames.TRASH} minimal onClick={() => this.removeUser(i.id)} loading={disabled} intent={Intent.DANGER} /></td>
                                            </>
                                        ) : (
                                            <td><Button icon={IconNames.EDIT} minimal onClick={() => this.restoreUser(i.id)} loading={disabled} intent={Intent.PRIMARY} /></td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    removeUser = (userId: string) => {
        const user = this.props.adminUsers.getAdminUserById(userId)
        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                this.setState({disabled: true})
                api.adminUsers
                    .update(user.id, {...user, enabled: false})
                    .then(() => {
                        infoToast(i18next.t("admin.users-list.toast.user-deleted"))
                        this.props.adminUsers.updateAdminUser({...user, enabled: false})
                    })
                    .finally(() => this.setState({disabled: false}))
            },
        }
        AppToaster.show({
            message: i18next.t("admin.users-list.toast.show.user-deleted", {
                login: user.login,
            }),
            action,
            timeout: 10000,
            intent: Intent.DANGER,
        })
    }

    restoreUser = (userId: string) => {
        const user = this.props.adminUsers.getAdminUserById(userId)
        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                this.setState({disabled: true})
                api.adminUsers.update(user.id, {...user, enabled: true})
                    .then(() => {
                        infoToast(i18next.t("admin.users-list.toast.user-restore"))
                        this.props.adminUsers.updateAdminUser({...user, enabled: true})
                    })
                    .finally(() => this.setState({disabled: false}))
            },
        }
        AppToaster.show({
            message: i18next.t("admin.users-list.toast.show.user-restore", {
                login: user.login,
            }),
            action,
            timeout: 10000,
            intent: Intent.DANGER,
        })
    }

    editUser = (userId: string) => this.props.history.push(`${this.props.match.url}/${userId}`)
    createUser = () => this.props.history.push(`${this.props.match.url}/create`)
}

import L from "leaflet"
import red from "@/components/map/markers/red.png"
import gray from "@/components/map/markers/gray.png"
import green from "@/components/map/markers/green.png"

export const RedIcon = L.icon({
    iconUrl: red,
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    tooltipAnchor: [32, -32],
})

export const GrayIcon = L.icon({
    iconUrl: gray,
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    tooltipAnchor: [32, -32],
})

export const GreenIcon = L.icon({
    iconUrl: green,
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    tooltipAnchor: [32, -32],
})

import type {Property} from "cesium"
import {Cartesian3,
    Ellipsoid, HeadingPitchRoll,
    JulianDate,
    Matrix3,
    Quaternion,
    SampledPositionProperty,
    Transforms,
    Math as CMath,
    VelocityVectorProperty} from "cesium"

import type {CesiumSimulatorActionTask,
    CesiumSimulatorMovetoTask,
    CesiumSimulatorTasks} from "@/extensions/cesium/types"
import {bisectTasks, cartesianFromPoint} from "@/extensions/cesium/utils"

const velocityScratch = new Cartesian3()
const positionScratch = new Cartesian3()
const matrix3Scratch = new Matrix3()
const quaternionScratch = new Quaternion()

export default class DroneSimulatorOrientationProperty implements Property {
    // TODO: REMOVE SAMPLED AND VECTOR PROPERTIES LATER
    positionProperty: SampledPositionProperty;
    velocityProperty: VelocityVectorProperty;
    tasks: CesiumSimulatorTasks[];
    takeoffLandingCache: Quaternion;

    constructor(tasks: CesiumSimulatorTasks[], position: SampledPositionProperty) {
        this.tasks = tasks
        this.positionProperty = position
        this.velocityProperty = new VelocityVectorProperty(position, false)
    }

    getValue = (time: JulianDate, result: Quaternion): Quaternion | undefined => {
        const currentTaskIndex = bisectTasks(this.tasks, time)
        const task = this.tasks[currentTaskIndex]

        if (!task)
            return undefined

        switch (task.type) {
            case "Landing":
            case "Takeoff":
                return this.#handleTakeoffAndLanding()
            case "Action":
                return this.#handleAction(task, currentTaskIndex, time)

            case "MoveTo": {
                const position = this.positionProperty.getValue(time)
                if (!position)
                    return undefined

                const poi = task.from.pointOfInterest
                if (poi)
                    return this.#lootAtPoi(position, poi)

                const velocity = this.velocityProperty.getValue(time)

                const rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(
                    position,
                    Cartesian3.normalize(velocity, velocity),
                )

                return Quaternion.fromRotationMatrix(rotationMatrix, result)
            }

                return undefined
        }
    }

    #lootAtPoi = (currentPos: Cartesian3, poi: Cartesian3): Quaternion => {
        const velocity = Cartesian3.subtract(poi, currentPos, velocityScratch)
        const rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(
            currentPos,
            Cartesian3.normalize(velocity, velocity),
        )

        return Quaternion.multiply(
            Quaternion.fromRotationMatrix(rotationMatrix, new Quaternion()),
            Quaternion.fromHeadingPitchRoll(this.#cameraHpr, new Quaternion()),
            quaternionScratch,
        )
    }

    #handleTakeoffAndLanding = (): Quaternion => {
        if (this.takeoffLandingCache)
            return this.takeoffLandingCache

        // task with waypoints from the first point above the ground to the first plan waypoint
        const next: CesiumSimulatorMovetoTask = this.tasks[1]
        if (!next)
            throw new Error("Unable to get next simulator task while computing takeoff quaternion.")

        if (next.type !== "MoveTo")
            throw new Error("The next task after take-off must be of type \"MoveTo\"")

        const fromFirstAboveToFirstWaypoint = Cartesian3.normalize(
            Cartesian3.subtract(
                cartesianFromPoint(next.to),
                cartesianFromPoint(next.from),
                positionScratch,
            ),
            positionScratch,
        )

        const rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(
            cartesianFromPoint(next.from),
            fromFirstAboveToFirstWaypoint,
            Ellipsoid.WGS84,
            matrix3Scratch,
        )

        const result = Quaternion.fromRotationMatrix(rotationMatrix)

        this.takeoffLandingCache = result

        return result
    }

    // TODO: NOT IMPLEMENTED
    #handleAction = (currentTask: CesiumSimulatorActionTask, currentIndex: number, time: JulianDate): Quaternion => new Quaternion(0, 0, 0)

    definitionChanged: Event;
    isConstant = false;

    equals = (other?: Property): boolean => other instanceof DroneSimulatorOrientationProperty

    #cameraHpr: HeadingPitchRoll = new HeadingPitchRoll(0, CMath.toRadians(30), 0)
    set cameraHpr(hpr: HeadingPitchRoll) {
        this.#cameraHpr = hpr
    }
}

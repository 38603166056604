import {action, makeObservable, observable} from "mobx"
import type User from "@/model/User"

export class UserInfoStore implements User {
    @observable firstName: string;
    @observable lastName: string;
    @observable id: string;
    @observable login: string;
    @observable enabled: boolean;
    @observable role: string;
    @observable roles: string[];
    @observable stations: string[];

    @observable username = ""
    @observable isAdmin = false
    @observable isOperator = false
    @observable isConsumer = false
    @observable isStationOperator = false

    constructor() {
        makeObservable(this)
    }

    isInRole = (role: string) => this.roles.includes(role);

    @action setRolesInfoFromClaim = (roles: string[]) => {
        this.roles = roles
        roles.forEach((x) => {
            switch (x) {
                case "admin":
                    this.isAdmin = true
                    break
                case "operator":
                    this.isOperator = true
                    break
                case "station_operator":
                    this.isStationOperator = true
                    break
                case "consumer":
                    this.isConsumer = true
                    break
            }
        })
    }

    @action
    setFromProfile(user: User) {
        if (user == null)
            return

        this.lastName = user.lastName
        this.firstName = user.firstName
        this.id = user.id
        this.login = user.login
        this.enabled = user.enabled
        this.role = user.role
        this.stations = user.stations

        this.username = `${user.firstName} ${user.lastName}`
    }
}

const userInfo = new UserInfoStore()

export default userInfo

import type {Station} from "@/model/Station"
import HttpService from "@/services/HttpService"

const baseUrl = "/admin/stations"

export default class AdminStationsService {
    constructor(api: HttpService) {
        this.api = api
    }

    list(): Promise<Station[]> {
        return this.api.get(baseUrl)
    }

    submit(station: { stationId: string, name: string, password: string }) {
        return this.api.post(baseUrl, station)
    }

    update(stationId: string, station: Station) {
        return this.api.post(`${baseUrl}/${stationId}`, station)
    }

    delete(stationId: string) {
        return this.api.delete(`${baseUrl}/${stationId}`)
    }

    resetPassword(stationId: string, password: string) {
        return this.api.post(`${baseUrl}/${stationId}/reset-password`, {password})
    }
}

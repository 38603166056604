import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {inject} from "mobx-react"
import {Button, Classes, FormGroup, InputGroup, Intent, Tooltip} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import type {RouteProps} from "@/types/RouteProps"
import {AdminStationsStore} from "@/store/AdminStationsStore"
import api from "@/services/ApiService"
import {infoToast} from "@/components/AppToaster"
import generatePassword from "@/components/left/admin/utils"

type State = {
    name: string,
    stationId: string,
    disabled: boolean,
    password: string,
    passwordConfirm: string,
    showPassword: boolean,
}

@withRouter
@inject("adminStations")
export default class StationCreateForm extends Component<{ adminStations?: AdminStationsStore } & RouteProps<>, State> {
    state: State = {
        name: "",
        stationId: "",
        disabled: false,
        password: "default",
        passwordConfirm: "default",
        showPassword: false,
    }

    copyToClip = () => {
        navigator.clipboard.writeText(this.state.password)
    }

    generateBtnClick = async () => {
        const passwd = generatePassword(6)
        await this.setState({
            password: passwd,
            passwordConfirm: passwd,
        })
        this.copyToClip()
    }

    render() {
        const {name, stationId, disabled, showPassword} = this.state

        const lockButton = (
            <Tooltip
                content={showPassword ? i18next.t("admin.drones-editor.text.hide-pass") : i18next.t("admin.drones-editor.text.show-pass")}
            >
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        )

        return (
            <div style={{maxWidth: 300, marginTop: 10, padding: 10, border: "1px solid #aaa"}}>
                <p>{i18next.t("admin.stations-editor.text.create-station")}</p>
                <FormGroup label={i18next.t("admin.stations-editor.form.name.label")} labelFor="name">
                    <InputGroup
                        id="name"
                        leftIcon={IconNames.PERSON}
                        value={name}
                        onChange={(e) => this.setState({name: e.target.value})}
                    />
                </FormGroup>
                <FormGroup label={i18next.t("admin.stations-editor.form.id.label")} labelFor="stationId">
                    <InputGroup
                        id="stationId"
                        leftIcon={IconNames.PERSON}
                        value={stationId}
                        onChange={(e) => this.setState({stationId: e.target.value})}
                    />
                </FormGroup>
                <FormGroup label={i18next.t("admin.drones-editor.form.password.label")} labelFor="password">
                    <InputGroup
                        id="password"
                        leftIcon={IconNames.KEY}
                        type={showPassword ? "text" : "password"}
                        rightElement={lockButton}
                        value={this.state.password}
                        onChange={(e) => this.setState({password: e.target.value})}
                    />
                </FormGroup>
                <FormGroup label={i18next.t("admin.drones-editor.form.password-rep.label")}
                           labelFor="password-confirm"
                >
                    <InputGroup
                        id="password-confirm"
                        leftIcon={IconNames.KEY}
                        type={showPassword ? "text" : "password"}
                        rightElement={lockButton}
                        value={this.state.passwordConfirm}
                        onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                    />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{justifyContent: "space-between"}}>
                    <Button
                        style={{marginLeft: 0}}
                        text={i18next.t("admin.stations-editor.button.generate-password")}
                        intent={Intent.PRIMARY}
                        type="button"
                        loading={disabled}
                        onClick={this.generateBtnClick}
                    />
                    <Button
                        text={i18next.t("admin.stations-editor.button.create")}
                        intent={Intent.PRIMARY}
                        loading={disabled}
                        onClick={this.create}
                    />
                </div>
            </div>
        )
    }

    create = () => {
        const {name, stationId, password, passwordConfirm} = this.state

        if (!name) {
            infoToast(i18next.t("admin.stations-editor.toast.enter-name"))
            return
        }

        const isHaveStationId = this.props.adminStations.isHaveStaionIdInAdminStations(stationId)
        if (isHaveStationId) {
            infoToast(i18next.t("admin.stations-editor.toast.station-id-exists"))
            return
        }

        if (!stationId) {
            infoToast(i18next.t("admin.stations-editor.toast.enter-station-id"))
            return
        }

        if (!password || !passwordConfirm) {
            infoToast(i18next.t("admin.drones-editor.toast.enter-pass"))
            return
        }
        if (password !== passwordConfirm) {
            infoToast(i18next.t("admin.drones-editor.toast.password-match"))
            return
        }

        this.setState({disabled: true})

        api.adminStations
            .submit({name, stationId, password})
            .then((request) => {
                infoToast(i18next.t("admin.stations-editor.toast.station-create"))
                this.props.adminStations.setAdminStation(request)
                this.props.history.replace("/admin/stations")
            })
            .catch((error) => {
                infoToast(error.response.data.message)
                this.setState({disabled: false})
            })
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})
}

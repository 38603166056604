import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import L from "leaflet"
import {Marker, Pane, Tooltip} from "react-leaflet"
import station from "@/components/map/markers/station.png"
import {DroneListStore} from "@/store/DroneListStore"
import StationStore from "@/store/StationStore"

@observer
export class StationMarker extends Component<{station: StationStore}> {
    render() {
        const {position} = this.props.station
        if (!position)
            return <></>

        const angle = -position.angle + Math.PI / 2

        const icon = L.divIcon({
            iconSize: [64, 64],
            iconAnchor: [32, 32],
            tooltipAnchor: [0, 16],
            html: `<img style="transform: rotate(${angle}rad);" height="64" width="64" src='${station}' alt="station">`,
            className: "drone-marker",
        })

        return (
            <Marker position={[position.lat, position.lon]}
                    icon={icon}
            >
                <Tooltip direction="bottom">{this.props.station.displayName}</Tooltip>
            </Marker>
        )
    }
}

@inject("drones")
@observer
export default class StationLayer extends Component<{drones?: DroneListStore}> {
    render() {
        const {stores} = this.props.drones
        return (
            <Pane name="station-markers" style={{zIndex: 610}}>
                {stores.map((u) => u.station && !u.hidden && <StationMarker key={u.id} station={u.station} />)}
            </Pane>
        )
    }
}

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const API_URL = "/api";

export default class HttpService {

	httpConfig: AxiosRequestConfig | null = null;

	constructor() {
	}

	// eslint-disable-next-line class-methods-use-this
	authHeader() { }

	config() {
		if (!this.httpConfig) {
			this.httpConfig = { headers: this.authHeader() };
		}
		return this.httpConfig;
	}

	put(path: string, data: any): Promise<any> {
		return axios.put(API_URL + path, data, this.config())
			.then((response) => response.data);
	}

	post(path: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {
		return axios.post(API_URL + path, data, { ...this.config(), ...config })
			.then((response) => response.data);
	}

	get<T>(path: string, config: AxiosRequestConfig = {}): Promise<T> {
		return axios.get<T>(API_URL + path, { ...this.config(), ...config })
			.then((response) => response.data);
	}

	delete(path: string): Promise<any> {
		return axios.delete(API_URL + path, this.config())
			.then((response) => response.data);
	}

	upload(path: string, file: File, onUploadProgress: (progress: any) => void) {
		const formData = new FormData();
		formData.append("file", file);
		const config = {
			headers: this.authHeader(),
			onUploadProgress,
		};
		return axios.post(API_URL + path, formData, config)
			.then((response) => response.data);
	}

	downloadFile(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<ArrayBuffer>> {
		return axios.get(url, { ...this.config(), responseType: "arraybuffer", ...config });
	}
}

import React, {useCallback, useEffect, useState} from "react"
import {Button, H5, Intent, Spinner} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {Link} from "react-router-dom"
import i18next from "i18next"
import {Classes,
    Popover2} from "@blueprintjs/popover2"
import api from "@/services/ApiService"
import type {ProfileDto} from "@/services/ProfilesService"
import {successToast} from "@/components/AppToaster"

const ProfilesList = () => {
    const [loading, setLoading] = useState(true)
    const [profiles, setProfiles] = useState<ProfileDto[]>([])

    const refetch = useCallback(() => {
        api.profiles.getAll()
            .then((res) => {
                setProfiles(res)
                setLoading(false)
            }).catch(() => setLoading(false))
    }, [])

    useEffect(() => {
        refetch()
    }, [refetch])

    const handleDelete = useCallback((e: MouseEvent) => {
        if (!e || !e.currentTarget)
            return

        const id = e.currentTarget.getAttribute("data-id")
        if (!id)
            return

        api.profiles.delete(id)
            .then(() => {
                successToast("Successfully deleted")
                refetch()
            })
    }, [refetch])

    if (loading)
        return <Spinner intent={Intent.PRIMARY} size={100} className="centered" />

    return (
        <>
            <Link to="/admin/profiles/create">
                <p><Button icon={IconNames.ADD} text="Create" minimal intent={Intent.PRIMARY} /></p>
            </Link>
            <div className="admin-panel">
                <div className="admin-table-wrapper">
                    <table
                        className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped"
                        style={{border: "1px solid #ccc"}}
                    >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>{i18next.t("admin.profiles-list.text.edit")}</th>
                                <th>{i18next.t("admin.profiles-list.text.delete")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profiles.map(({id, name}) => (
                                <tr key={id}>
                                    <td>{name}</td>
                                    <td>
                                        <Link to={`/admin/profiles/${id}`}>
                                            <Button
                                            icon={IconNames.EDIT}
                                            minimal
                                            loading={loading}
                                            intent={Intent.PRIMARY}
                                            />
                                        </Link>
                                    </td>
                                    <td>
                                        <Popover2
                                        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                                        portalClassName="confirmation"
                                        enforceFocus={false}
                                        content={(
                                            <div key="text">
                                                <H5>Confirm deletion</H5>
                                                <p>Are you sure you want to delete this profile? You would not be able to recover it.</p>
                                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: 15}}>
                                                    <Button className={Classes.POPOVER2_DISMISS} style={{marginRight: 10}}>
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        data-id={id}
                                                        intent={Intent.DANGER}
                                                        onClick={handleDelete}
                                                        className={Classes.POPOVER2_DISMISS}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                        >
                                            <Button
                                            icon={IconNames.TRASH}
                                            minimal
                                            loading={loading}
                                            intent={Intent.DANGER}
                                            />
                                        </Popover2>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ProfilesList

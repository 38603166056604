import React, {VFC, ComponentProps} from "react"
import {observer} from "mobx-react"
import {Entity} from "resium"
import type {Waypoint} from "@/model/Waypoint"
import {pointToCartesian3} from "@/components/map/cesium/utils"

type Props = {
    waypoint: Waypoint;
    heightAboveEllipsoid: number;
    pointOfInterest?: boolean;
    entityProps?: ComponentProps<Entity>;
}

const WaypointEntity: VFC<Props> = ({waypoint, entityProps, pointOfInterest, heightAboveEllipsoid}) => (
    <Entity
        {...entityProps}
        position={pointToCartesian3(
            pointOfInterest ? waypoint.pointOfInterest : waypoint,
            heightAboveEllipsoid,
        )}
    />
)

export default observer(WaypointEntity)

import { FocusStyleManager } from "@blueprintjs/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "../../../public/favicon.ico";
import "../../i18n";
import * as ServiceWorker from "@/infrastructure/web/service-worker";
import PageContainer from "../layout/page-container";
import "./startup.scss";

FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
	<BrowserRouter>
		<PageContainer />
	</BrowserRouter>,
	document.getElementById("ReactApplicationContainer"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
ServiceWorker.unregister();

import HttpService from "@/services/HttpService"
import type User from "@/model/User"

const baseUrl = "/user"

export default class UserService {
    constructor(api: HttpService) {
        this.api = api
    }

    profile(): Promise<User> {
        return this.api.get(`${baseUrl}/profile`)
    }

    changePassword = (password: string) => this.api.put(`${baseUrl}/password`, {password})
    updateProfile = (firstName: string, lastName: string) => this.api.put(`${baseUrl}/profile`, {firstName, lastName})
}

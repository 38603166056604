import AdminDronesService from "@/services/AdminDronesService";
import AdminStationsService from "@/services/AdminStationsService";
import AdminUsersService from "@/services/AdminUsersService";
import AuthService from "@/services/AuthService";
import CameraAccessService from "@/services/CameraAccessService";
import ChartsService from "@/services/ChartsService";
import DroneService from "@/services/DroneService";
import GpsTrackLogService from "@/services/GpsTrackLogService";
import HttpService from "@/services/HttpService";
import JoystickService from "@/services/JoystickService";
import MissionService from "@/services/MissionService";
import { PermissionsService } from "@/services/PermissionsService";
import { ProfilesService } from "@/services/ProfilesService";
import RequestsService from "@/services/RequestsService";
import StationService from "@/services/StationService";
import UnitsService from "@/services/UnitsService";
import UserService from "@/services/UserService";
import PlanService from "./PlanService";

export class ApiService extends HttpService {

	auth = new AuthService(this);
	user = new UserService(this);
	units = new UnitsService(this);
	plans = new PlanService(this);
	drone = new DroneService(this);
	station = new StationService(this);
	mission = new MissionService(this);
	joystick = new JoystickService(this);
	requests = new RequestsService(this);
	adminUsers = new AdminUsersService(this);
	adminDrones = new AdminDronesService(this);
	adminStations = new AdminStationsService(this);
	cameraAccess = new CameraAccessService(this);
	gpsTrackLog = new GpsTrackLogService(this);
	charts = new ChartsService(this);
	permissions = new PermissionsService(this);
	profiles = new ProfilesService(this);

	authHeader() {
		const token = this.auth.getToken();
		if (!token)
			return {};

		return { Authorization: `Bearer ${token}` };
	}
}

const api = new ApiService();

export default api;

import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {Pane} from "react-leaflet"
import {DroneListStore} from "@/store/DroneListStore"
import DroneMarker from "@/components/map/DroneMarker"
import "./DroneLayer.scss"

@inject("drones")
@observer
export default class DroneLayer extends Component<{drones?: DroneListStore}> {
    render() {
        const {stores} = this.props.drones
        return (
            <Pane name="drone" style={{zIndex: 640}}>
                {stores
                    .filter(({drone}) => drone != null && drone.params.gps != null && !drone.hidden)
                    .map(({id, drone: {params, displayName}}) => (
                        <DroneMarker
                            key={id}
                            position={params.gps}
                            gpsHealth={params.gps_health}
                            droneName={displayName}
                            yaw={params.yaw}
                        />
                    ))}
            </Pane>
        )
    }
}

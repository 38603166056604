export const STOPPED = 0
export const MOVE_TO_FIRST = 2
export const IN_PROGRESS = 3
export const PAUSED = 4
export const RETURNING = 5

const descriptions = {}
descriptions[STOPPED] = "Stopped"
descriptions[MOVE_TO_FIRST] = "Move to first point"
descriptions[IN_PROGRESS] = "In progress"
descriptions[PAUSED] = "Paused"
descriptions[RETURNING] = "Returning"

export function description(val: number) {
    if (val in descriptions)
        return descriptions[val]
    return `Unknown ${val}`
}

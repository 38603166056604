import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {Marker, Pane, Tooltip} from "react-leaflet"

import {SelectionStore} from "@/store/SelectionStore"
import RequestStore from "@/store/RequestStore"
import {formatDate} from "@/Tools"
import {requestMapIcon, requestTypeName} from "@/model/Enums"

@inject("selection", "request")
@observer
export default class OperatorRequestsLayer extends Component<{selection?: SelectionStore, request?: RequestStore}> {
    render() {
        const {selection, request} = this.props

        if (request.latitude === undefined || request.longitude === undefined) {
            return (
                <Pane name="request-markers" style={{zIndex: 611}}>
                    {selection.station && selection.station.requests.map((r) => (
                        <Marker key={r.id} position={{lat: r.lat, lng: r.lon}} icon={requestMapIcon(r)}>
                            <Tooltip>
                                <p>{requestTypeName(r.type)}</p>
                                <p>{formatDate(r.date)}</p>
                            </Tooltip>
                        </Marker>
                    ))}
                </Pane>
            )
        }

        return (
            <Pane name="request-markers" style={{zIndex: 611}}>
                <Marker position={{lat: request.latitude, lng: request.longitude}} icon={requestMapIcon(request)}>
                    <Tooltip>
                        <p>{requestTypeName(request.type)}</p>
                        <p>{formatDate(request.date)}</p>
                    </Tooltip>
                </Marker>
            </Pane>
        )
    }
}

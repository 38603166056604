import { action, makeObservable, observable } from 'mobx';
import LayoutTheme from '@/application/layout/theme';
import MapViewMode from '@/application/map/view-mode';

const layoutThemeLocalStorageKey: string = 'global.layoutTheme';
const mapViewModeLocalStorageKey: string = 'global.mapViewMode';

export class GlobalStore {
	@observable authLoading: boolean = true;
	@observable loggedIn: boolean = false;
	@observable collapsed: boolean = false;
	@observable layoutTheme: string = localStorage.getItem(layoutThemeLocalStorageKey) || LayoutTheme.Light;
	@observable scrollPosition = {};
	@observable mapViewMode: string = localStorage.getItem(mapViewModeLocalStorageKey) || MapViewMode.TwoD;

	constructor() {
		makeObservable(this);
	}

	@action setLoggedIn(value: boolean): void {
		this.loggedIn = value;
		this.authLoading = false;
	}

	@action
	setScrollPosition(data: { url: string, scrollPosition: number; }): void {
		this.scrollPosition[data.url] = data.scrollPosition;
	}

	getScrollPosition(url: string): void {
		return this.scrollPosition[url] ?? 0;
	}

	@action
	setCollapsed(value: boolean): void {
		this.collapsed = value;
	}

	@action
	setLayoutTheme(value: string): void {
		this.layoutTheme = value;
		localStorage.setItem(layoutThemeLocalStorageKey, value);
	}

	@action
	setMapViewMode(value: string): void {
		this.mapViewMode = value;
		localStorage.setItem(mapViewModeLocalStorageKey, value);
	}
}

const globalStore: GlobalStore = new GlobalStore();

export default globalStore;

import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {Tooltip2} from "@blueprintjs/popover2"
import {Select, IItemRendererProps} from "@blueprintjs/select"
import {Button, Classes, Intent, MenuItem} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import api from "@/services/ApiService"
import {JoystickStore} from "@/store/JoystickStore"
import DroneStore from "@/store/DroneStore"
import type {JoystickPreset} from "@/model/JoystickPreset"
import DroneConnection from "@/connection/DroneConnection"
import DraggableWindow from "@/components/control/DraggableWindow"
import {JoystickControls, joystickTickInterval} from "@/components/control/JoystickControls"
import JoystickSettings from "@/components/control/JoystickSettings"
import AppToaster, {infoToast} from "@/components/AppToaster"

import joystickNF from "./joystick-notfound.png"
import joystickPS from "./joystick-ps.png"
import joystickXBox from "./joystick-xbox.png"

function getGamepads() {
    if (navigator.getGamepads)
        return navigator.getGamepads()
    return navigator.webkitGetGamepads ? navigator.webkitGetGamepads() : []
}

function axisThr(val) {
    return Math.abs(val) < 0.1 ? 0 : val
}

function presetsItemRenderer(it: JoystickPreset, itemProps: IItemRendererProps) {
    return (
        <MenuItem key={it.id}
                  text={it.name}
                  onClick={itemProps.handleClick}
                  active={itemProps.modifiers.active}
                  disabled={itemProps.modifiers.disabled}
        />
    )
}

type Props = {
    drone: DroneStore,
    dronesConn?: DroneConnection,
    onClose: () => void
}

type State = {
    preset: JoystickPreset,
    presets: JoystickPreset[],
    gpName: string,
    setupMode: boolean
}

@inject("dronesConn")
class DroneJoystick extends Component<Props, State> {
    interval = null
    intervalButtons = null
    buttons: boolean[] = []

    state = {
        setupMode: false,
        presets: [],
        preset: undefined,
        gpName: undefined,
    }

    controls: JoystickControls

    settings = {
        buttons: {},
        axes: {},
    }

    componentDidMount(): void {
        this.startScan()
        this.loadPresets()
    }

    componentWillUnmount(): void {
        this.stopScan()
    }

    loadPresets() {
        api.joystick.get().then((presets) => {
            this.setState({presets})
            this.refreshPreset()
        })
    }

    refreshPreset() {
        if (this.state.preset) return

        const {presets, gpName} = this.state
        const last = localStorage.getItem("defaultJoystickPreset")

        let preset: JoystickPreset
        if (presets.length) {
            if (last)
                preset = presets.find((p) => p.id === last)

            if (!preset) // Take first available
                [preset] = presets

            if (preset.gamepadName !== gpName)
                preset = undefined

            if (preset)
                this.handlePresetChange(preset)
        }
    }

    startScan() {
        if (!this.interval) {
            this.interval = setInterval(() => {
                if (this.controls) this.controls.tick()
            }, joystickTickInterval)
        }

        if (!this.intervalButtons)
            // eslint-disable-next-line no-console
            this.intervalButtons = setInterval(() => this.scanButtons().then().catch((error) => console.log(error)), 20)
    }

    stopScan() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
        if (this.intervalButtons) {
            clearInterval(this.intervalButtons)
            this.intervalButtons = null
        }
    }

    async scanButtons() {
        const gamepads = getGamepads()
        if (!gamepads.length) {
            if (this.state.gpName)
                this.setState({gpName: undefined})
            return
        }
        const gp = gamepads[0]
        if (!this.state.gpName || this.state.gpName !== gp.id) {
            this.setState({gpName: gp.id})
            this.refreshPreset()
        }

        gp.buttons.forEach((b, i) => {
            if (this.buttons[i] && !b.pressed) {
                this.buttons[i] = false

                if (i in this.settings.buttons) {
                    const s = this.settings.buttons[i]
                    if (s.up) s.up()
                }
            } else if (b.pressed) {
                if (!this.buttons[i]) {
                    this.buttons[i] = true

                    if (i in this.settings.buttons) {
                        const s = this.settings.buttons[i]
                        if (s.down) s.down()
                    }
                } else if (i in this.settings.buttons) {
                    const s = this.settings.buttons[i]
                    if (s.pressed) s.pressed()
                }
            }
        })

        gp.axes.forEach((v, i) => {
            const a = this.settings.axes[i]
            a.axis(axisThr(v) * a.multiplier)
        })
    }

    render() {
        const {preset, presets} = this.state

        let bgr = joystickPS
        if (this.state.gpName === undefined)
            bgr = joystickNF
        else if (this.state.gpName.toLowerCase().indexOf("xinput") !== -1)
            bgr = joystickXBox

        const filtered = presets.filter((p) => p.gamepadName === this.state.gpName)

        return (
            <>
                <DraggableWindow
                    caption={`${this.props.drone.displayName} - ${this.state.gpName || i18next.t("control.drone-control.text.not-connected")}`}
                    enableFullscreen={false}
                    onClose={this.props.onClose}
                    style={{maxWidth: 255}}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div>
                            <Select
                                items={filtered}
                                itemRenderer={presetsItemRenderer}
                                filterable={false}
                                popoverProps={{minimal: true}}
                                onItemSelect={this.handlePresetChange}
                                activeItem={preset}
                            >
                                <Button text={preset ? preset.name : i18next.t("control.drone-control.text.not-connected")} rightIcon={IconNames.CARET_DOWN} />
                            </Select>
                            <Button icon={IconNames.ADD} onClick={this.createPreset} />
                            {preset && <Button icon={IconNames.EDIT} className={Classes.FIXED} onClick={this.editPreset} />}
                            {preset && <Button icon={IconNames.TRASH} className={Classes.FIXED} intent={Intent.DANGER} onClick={this.deletePresetHandle} />}
                        </div>
                        <div>
                            <Button text="Obtain control" onClick={this.obtainControlClick} />
                            <Tooltip2 content="Take Off"
                                      placement="bottom"
                            >
                                <Button icon={IconNames.ARROW_UP} onClick={this.takeOffClick} />
                            </Tooltip2>
                            <Tooltip2 content="Landing"
                                      placement="bottom"
                            >
                                <Button icon={IconNames.ARROW_DOWN} onClick={this.landingClick} />
                            </Tooltip2>
                        </div>
                        <div style={{backgroundImage: `url(${bgr})`, backgroundSize: "cover", minWidth: 215, minHeight: 165, opacity: 0.2, marginTop: 10}} />
                    </div>
                </DraggableWindow>
                {this.state.setupMode && <JoystickSettings onClose={this.closeSettings} editPreset={this.state.editPreset} />}
            </>
        )
    }

    handlePresetChange = (p: JoystickPreset) => {
        this.stopScan()
        this.setState({preset: p})

        if (p) {
            this.controls = new JoystickControls(this.props.drone, this.props.dronesConn)
            this.settings = this.controls.setPreset(p)
        } else {
            this.controls = new JoystickControls(this.props.drone, this.props.dronesConn)
            this.settings = {
                buttons: {},
                axes: {},
            }
        }
        this.startScan()

        if (p)
            localStorage.setItem("defaultJoystickPreset", p.id)
        else
            localStorage.setItem("defaultJoystickPreset", undefined)
    }

    createPreset = () => {
        this.stopScan()
        this.setState({setupMode: true, editPreset: undefined})
    }

    editPreset = () => {
        this.stopScan()
        this.setState({setupMode: true, editPreset: this.state.preset})
    }

    addPreset(p: JoystickPreset) {
        this.setState((state) => {
            const presets = state.presets.concat(p)
            return {presets}
        })
    }

    replacePreset(preset: JoystickPreset) {
        this.setState((state) => {
            const old = state.presets.find((p) => p.id === preset.id)
            const index = state.presets.indexOf(old)
            const presets = [...state.presets]
            presets[index] = preset
            return {presets}
        })
    }

    removePreset(preset: JoystickPreset) {
        this.setState((state) => {
            const index = state.presets.indexOf(preset)
            const presets = [...state.presets]
            presets.splice(index, 1)
            return {presets}
        })
    }

    closeSettings = (preset: JoystickPreset) => {
        if (preset) {
            const isNew = preset.id === undefined
            api.joystick.save(preset)
                .then((answer) => {
                    preset.id = answer.id

                    if (isNew)
                        this.addPreset(preset)
                    else
                        this.replacePreset(preset)
                    this.handlePresetChange(preset)

                    this.setState({setupMode: false})
                    this.startScan()
                })
        } else {
            this.setState({setupMode: false})
            this.startScan()
        }
    }

    deletePresetHandle = () => {
        const {preset} = this.state
        if (!preset) return

        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                api.joystick.remove(preset.id)
                    .then(() => {
                        this.removePreset(preset)
                        infoToast("Preset deleted")

                        if (this.state.presets.length)
                            this.handlePresetChange(this.state.presets[0])
                        else
                            this.handlePresetChange(undefined)
                    })
            },
        }
        AppToaster.show({message: "Confirm preset delete?", action, timeout: 10000})
    }

    obtainControlClick = () => {
        this.props.dronesConn.command(this.props.drone.droneId, "obtain_control").then()
    }

    takeOffClick = () => {
        this.props.dronesConn.command(this.props.drone.droneId, "take_off").then()
    }

    landingClick = () => {
        this.props.dronesConn.command(this.props.drone.droneId, "landing").then()
    }
}

@inject("joystick")
@observer
export default class DroneControl extends Component<{joystick?: JoystickStore}> {
    render() {
        if (this.props.joystick?.activated)
            return <DroneJoystick drone={this.props.joystick.drone} onClose={() => this.props.joystick.close()} />
        return <></>
    }
}

import React, {VFC} from "react"
import {observer} from "mobx-react"
import {DroneParams} from "@/components/left/DroneParams"
import StationParams from "@/components/left/StationParams"
import type {UnitStores} from "@/store/DroneListStore"

const DroneInfoPanel: VFC<{unit: UnitStores}> = ({unit}) => (
    <>
        {unit.drone && <DroneParams drone={unit.drone} />}
        {unit.station && <StationParams unit={unit} />}
    </>
)

export default observer(DroneInfoPanel)

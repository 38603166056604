import React from "react"
import {observer} from "mobx-react"
import {Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {CameraStore} from "@/store/CameraStore"
import DroneStore from "@/store/DroneStore"

function CameraButton(props: { drone: DroneStore, name: string, cameras: CameraStore }) {
    const {drone, name, cameras} = props
    const [, cameraName] = name.split(".")
    // const [, index] = prefix.split("_")

    return (
        <Button text={cameraName}
                icon={drone.params.main_camera_activity ? IconNames.RECORD : undefined}
                onClick={() => cameras.addCamera(drone.droneId, "main", cameraName)}
        />
    )
}

export default observer(CameraButton)

import type {IRequest} from "@/model/IRequest"
import type Plan from "@/model/Plan"
import type RequestQueryParams from "@/model/RequestQueryParams"
import HttpService from "@/services/HttpService"

const baseUrl = "/request"

export default class RequestsService {
    constructor(api: HttpService) {
        this.api = api
    }

    submit(request: IRequest) {
        return this.api.post(baseUrl, request)
    }

    list(stationId: string): Promise<Array<IRequest>> {
        return this.api.get(`${baseUrl}/station/${stationId}`)
    }

    query(params: RequestQueryParams): Promise<Array<IRequest>> {
        return this.api.post(`${baseUrl}/query`, params)
    }

    get(requestsId: string): Promise<Plan> {
        return this.api.get(`${baseUrl}/${requestsId}`)
    }

    cancel(requestsId: string): Promise {
        return this.api.post(`${baseUrl}/${requestsId}/cancel`)
    }

    upload(requestsId: string, file: File, onUploadProgress) {
        return this.api.upload(`${baseUrl}/${requestsId}/upload`, file, onUploadProgress)
    }
}

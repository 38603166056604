import {makeAutoObservable, action, IObservableArray} from "mobx"
import permissions from "@/authorization/permissions"
import type {PermissionGroupDto} from "@/services/PermissionsService"

type MapType = Record<string, number>;

class PermissionsStore {
    initialized: boolean = false

    permissionsMap: MapType = new Map();
    permissions: IObservableArray<number> = [];
    tree: IObservableArray<PermissionGroupDto[]> = [];
    treeInitialized: boolean;
    shortAllAccess: number;

    constructor() {
        makeAutoObservable(this)
    }

    hasPermission = (permission: string) => {
        const shortValue = this.permissionsMap.get(permission)
        if (shortValue == null)
            // eslint-disable-next-line no-console
            console.warn(`Undefined short value for permission ${permission}`)

        return this.permissions.includes(shortValue) || this.permissions.includes(this.shortAllAccess)
    }

    @action setPermissionsMap = (val: MapType) => {
        if (this.initialized)
            return

        this.permissionsMap = new Map(Object.entries(val))
        this.shortAllAccess = this.permissionsMap.get(permissions.accessAll)
    }

    @action setPermissions = (val: number[]) => {
        if (this.initialized)
            return

        this.permissions?.replace(val)
    }

    @action setTree = (tree: PermissionGroupDto[]) => {
        this.tree.replace(tree)
        if (!this.treeInitialized)
            this.treeInitialized = true
    }
}

const permissionsStore = new PermissionsStore()
export default permissionsStore

import ViewMode from '@/application/map/view-mode';
import CircleButton from '@/components/CircleButton';
import CesiumMap from '@/components/map/cesium/CesiumMap';
import LeafletMap from '@/components/map/LeafletMap';
import globalStore from '@/store/GlobalStore';
import type { SelectionStore } from '@/store/SelectionStore';
import { faChevronRight, faGlobe, faMap } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { inject, observer } from 'mobx-react';
import type { FC } from 'react';
import React from 'react';
import './Map.scss';

const Controls: React.FC<{}> = () => (
	<>
		{globalStore.collapsed && (
			<CircleButton
				tooltip={i18next.t('drone-map.tooltip.show-info')}
				icon={faChevronRight}
				onClick={() => globalStore.setCollapsed(false)}
			/>
		)}

		<CircleButton
			tooltip={i18next.t('drone-map.tooltip.switch-view-mode')}
			icon={globalStore.mapViewMode === ViewMode.TwoD ? faGlobe : faMap}
			onClick={() => globalStore.setMapViewMode(globalStore.mapViewMode === ViewMode.TwoD ? ViewMode.ThreeD : ViewMode.TwoD)}
		/>
	</>
);

const Map: FC<{ selection: SelectionStore; }> = ({ children: children }) => (
	<div className={`map-wrapper ${globalStore.collapsed ? 'full' : ''}`}>
		{globalStore.mapViewMode === ViewMode.ThreeD ? (
			<CesiumMap controls={<Controls />} />
		) : (
			<LeafletMap controls={<Controls />}>
				{children}
			</LeafletMap>
		)}
	</div>
);

export default inject('selection')(observer(Map));

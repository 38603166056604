import React, {Component} from "react"
import {Marker, Pane} from "react-leaflet"
import {inject, observer} from "mobx-react"
import {RedIcon} from "@/components/map/markers/MapIcons"
import {SelectionStore} from "@/store/SelectionStore"

@inject("selection")
@observer
export default class FileLocationLayer extends Component<{selection?: SelectionStore}> {
    render() {
        const {file} = this.props.selection

        return (
            <Pane name="file-location" style={{zIndex: 631}}>
                {file && file.lat && file.lon && <Marker position={{lat: file.lat, lng: file.lon}} icon={RedIcon} />}
            </Pane>
        )
    }
}

import i18next from "i18next"
import {IOptionProps} from "@blueprintjs/core"

export const RequestTypes = (): Array<IOptionProps> => ([
    {label: i18next.t("model.request-types.request-types.not-selected"), value: ""},
    {label: i18next.t("model.request-types.request-types.panorama"), value: "pano"},
    {label: i18next.t("model.request-types.request-types.single-photo"), value: "photo"},
    {label: i18next.t("model.request-types.request-types.video"), value: "video"},
    {label: i18next.t("model.request-types.request-types.translation"), value: "translation"},
    {label: i18next.t("model.request-types.request-types.other"), value: "other"},
])

export const RequestTypesLabel = (val) => {
    switch (val) {
        case "pano": return i18next.t("model.request-types.request-types.panorama")
        case "photo": return i18next.t("model.request-types.request-types.single-photo")
        case "video": return i18next.t("model.request-types.request-types.video")
        case "translation": return i18next.t("model.request-types.request-types.translation")
        case "other": return i18next.t("model.request-types.request-types.other")

        default: return i18next.t("model.request-types.request-types.not-selected")
    }
}

import type {DroneInfo} from "@/model/DroneInfo"
import type MissionQueryParams from "@/model/MissionQueryParams"
import type Mission from "@/model/Mission"
import HttpService from "@/services/HttpService"

const baseUrl = "/drone"

export default class DroneService {
    constructor(api: HttpService) {
        this.api = api
    }

    get(droneId: string): Promise<DroneInfo> {
        return this.api.get(`${baseUrl}/${droneId}`)
    }

    missionsQuery(droneId: string, params: MissionQueryParams): Promise<Mission[]> {
        return this.api.post(`${baseUrl}/${droneId}/missions`, params)
    }

    plans(droneId: string) {
        return this.api.get(`${baseUrl}/${droneId}/plans`)
    }

    cameraView(droneId: string, camera: string) {
        return this.api.get(`${baseUrl}/${droneId}/cameraView/${camera}`)
    }

    cancelMission(droneId: string) {
        return this.api.post(`${baseUrl}/${droneId}/cancelMission`)
    }

    withStation(droneId: string, withStation: boolean) {
        return this.api.post(`${baseUrl}/${droneId}/withStation`, {withStation})
    }

    missionApprovement(droneId: string, missionApprovement: boolean) {
        return this.api.post(`${baseUrl}/${droneId}/missionApprovement`, {missionApprovement})
    }

    enableRTK(droneId: string, rtk: boolean) {
        return this.api.post(`${baseUrl}/${droneId}/enableRTK`, {RTK: rtk})
    }

    rename(droneId: string, name: string) {
        return this.api.post(`${baseUrl}/${droneId}/rename`, {name})
    }
}

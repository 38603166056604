import {action, makeAutoObservable} from "mobx"

export default class WebrtcSettings {
    constructor() {
        makeAutoObservable(this)
    }

    websocket = undefined;
    peerConnection: RTCPeerConnection = undefined;
    audioTrack = undefined;
    videoTrack = undefined;
    connected = false

    @action onConnectionStateChange = (connected: boolean) => {
        this.connected = connected
    }

    @action setAudio = (audioTrack: MediaStreamTrack) => {
        this.audioTrack = audioTrack
    }

    @action setVideo = (videoTrack: MediaStreamTrack) => {
        this.videoTrack = videoTrack
    }

    @action setWebsocket = (ws: WebSocket) => {
        if (!ws)
            this.connected = false

        this.websocket = ws
    }

    @action setPeerConnection = (conn: RTCPeerConnection) => {
        if (!conn)
            this.connected = false

        this.peerConnection = conn
    }

    @action reset = () => {
        this.websocket = undefined
        this.peerConnection = undefined
        this.audioTrack = undefined
        this.videoTrack = undefined
        this.connected = false
    }
}

import React, {VFC} from "react"
import {Link, useParams} from "react-router-dom"
import {Button, Intent} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import ProfileForm from "@/components/left/admin/profile/ProfileForm"

type RouteProps = {
    id: string;
}

const ProfileEditor: VFC = () => {
    const params = useParams<RouteProps>()
    return (
        <div className="admin-header" style={{padding: 10}}>
            <Link to="/admin/profiles">
                <Button icon={IconNames.ARROW_LEFT}
                        text="Back"
                        intent={Intent.PRIMARY}
                        minimal
                        style={{marginTop: 20, marginLeft: 10}}
                />
            </Link>
            <ProfileForm id={params.id} />
        </div>
    )
}

export default ProfileEditor

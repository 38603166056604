import React, {PropsWithChildren, useEffect, FC} from "react"
import Player from "@/components/Player"
import "./CameraPlayer.scss"
import api from "@/services/ApiService"

type Props = PropsWithChildren & {
    droneId: string,
    camera: string,
    stream?: number,
}

const CameraPlayer: FC<Props> = ({stream: streamName, camera, droneId, children}) => {
    useEffect(() => {
        const sendSignal = () => {
            api.drone.cameraView(droneId, camera).then()
        }

        sendSignal()
        const interval = setInterval(() => {
            sendSignal()
        }, 1000)

        return () => clearInterval(interval)
    }, [camera, droneId])

    return (
        <div className="video-player-wrapper">
            <Player
                autoplay
                showErrorOnFailCount={5}
                streamName={streamName ?? `${droneId}_${camera}`}
            >
                {children}
            </Player>
        </div>

    )
}

export default CameraPlayer

import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {CameraStore} from "@/store/CameraStore"
import {DroneListStore} from "@/store/DroneListStore"
import DroneStore from "@/store/DroneStore"
import CameraPlayer from "@/components/control/CameraPlayer"
import DraggableWindow from "@/components/control/DraggableWindow"
import CameraControl from "@/components/control/CameraControl"

function AnalyticDrawer(props: {analytics: any[]}) {
    return (
        <>
            {props.analytics.map((a, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}
                     style={{position: "absolute",
                         border: `3px solid ${a.class === 1 ? "lime" : "red"}`,
                         width: `${Math.round(a.w * 100)}%`,
                         height: `${Math.round(a.h * 100)}%`,
                         left: `${Math.round(a.x * 100)}%`,
                         top: `${Math.round(a.y * 100)}%`}}
                >
                    {a.confidence}
                </div>
            ))}
        </>
    )
}

@observer
class CameraViewer extends Component<{drone: DroneStore, camera: string, cameraName: string, onClose: () => void}> {
    render() {
        const showControl = this.props.camera === "main"
        const {analytics} = this.props.drone.params

        return (
            <DraggableWindow
                caption={`${this.props.drone.displayName} - ${this.props.cameraName}`}
                link={`/camera/${this.props.droneId}/${this.props.camera}`}
                onClose={this.props.onClose}
                enableFullscreen
            >
                <CameraPlayer droneId={this.props.droneId} camera={this.props.camera}>
                    {showControl && <CameraControl drone={this.props.drone} cameraName={this.props.cameraName} />}
                </CameraPlayer>
                {analytics && <AnalyticDrawer analytics={analytics} />}
            </DraggableWindow>
        )
    }
}

@inject("cameras", "drones")
@observer
export default class CameraView extends Component<{cameras?: CameraStore, drones?: DroneListStore}> {
    render() {
        return this.props.cameras.cameras.map((c) => (
            <CameraViewer key={c.droneId + c.camera}
                          droneId={c.droneId}
                          drone={this.props.drones.getDrone(c.droneId)}
                          camera={c.camera}
                          cameraName={c.cameraName}
                          onClose={() => {
                              this.props.cameras.removeCamera(c.droneId, c.camera)
                          }}
            />
        ))
    }
}

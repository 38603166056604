import React, {useCallback, useEffect, useLayoutEffect, useRef, useState, VFC} from "react"
import {Color,
    PositionProperty,
    Entity as CesiumEntity,
    CallbackProperty,
    Quaternion,
    GridMaterialProperty,
    ClassificationType,
    Property,
    ArcType,
    ColorMaterialProperty,
    Math as CMath,
    Cartesian3,
    HeadingPitchRoll,
    Clock,
    Matrix3,
    Camera} from "cesium"
import {CesiumComponentRef,
    Entity,
    ModelGraphics, PolylineGraphics as PolylineGraphicsComponent, useCesium} from "resium"

import {RectangularSensorGraphics} from "cesium-sensors-es6"

type Props = {
    orientation: Property;
    position: PositionProperty;
    cameraAngle: number;
    showFrustum?: boolean;
    poiLinePositions?: [Cartesian3, Cartesian3] | Property;
    tracked?: boolean;
}

const droneCameraFov = 50
const rectangularGraphics = new RectangularSensorGraphics()
rectangularGraphics.xHalfAngle = (droneCameraFov / 2) * (Math.PI / 180)
rectangularGraphics.yHalfAngle = (droneCameraFov / 2) * (Math.PI / 180)
rectangularGraphics.show = true
rectangularGraphics.showIntersection = false
rectangularGraphics.intersectionColor = Color.fromAlpha(Color.RED, 0.4)
rectangularGraphics.intersectionWidth = 2
rectangularGraphics.solidColor = Color.fromAlpha(Color.CYAN, 0.4)
rectangularGraphics.radius = 20
rectangularGraphics.classificationType = ClassificationType.BOTH
rectangularGraphics.lateralSurfaceMaterial = new GridMaterialProperty({
    color: Color.CYAN,
})

const quaternionScratch = new Quaternion()
const matrix3Scratch = new Matrix3()
const rotateSensorQuaternion = Quaternion.fromHeadingPitchRoll(new HeadingPitchRoll(0, -CMath.toRadians(90), 0), new Quaternion())
const makeQuaternion = (angle: number) => Quaternion.fromHeadingPitchRoll(new HeadingPitchRoll(0, -CMath.toRadians(90 + angle), 0), new Quaternion())

const CesiumDroneModel: VFC<Props> = ({
    orientation: orientationProp,
    position: positionProp,
    cameraAngle,
    showFrustum,
    tracked,
    poiLinePositions,
}) => {
    const cesium = useCesium()
    const [firstPerson, setFirstPerson] = useState(false)
    const entityRef = useRef<CesiumComponentRef<CesiumEntity>>(null)
    const cameraRef = useRef<Camera>({})
    const quaternionRef = useRef<Quaternion>(makeQuaternion(cameraAngle))

    useEffect(() => {
        quaternionRef.current = makeQuaternion(cameraAngle)
    }, [cameraAngle])

    useLayoutEffect(() => {
        const entity = entityRef.current?.cesiumElement
        if (!entity)
            return

        entity.addProperty("rectangularSensor")
        entity.rectangularSensor = rectangularGraphics
    }, [])

    const sensorOrientation = useCallback((time) => {
        if (!orientationProp || !positionProp)
            return undefined

        const value = orientationProp.getValue(time)
        const pos = positionProp.getValue(time)

        if (!value || !pos || !quaternionRef.current)
            return undefined

        return Quaternion.multiply(value, quaternionRef.current, quaternionScratch)
    }, [orientationProp, positionProp])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key.toLowerCase() === "c")
                setFirstPerson((x) => !x)
        }

        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [cesium.camera])

    useEffect(() => {
        const {clock, camera} = cesium?.viewer ?? {}

        const listener = (currentClock: Clock): void => {
            if (!(showFrustum && firstPerson && positionProp && currentClock && camera))
                return

            const time = currentClock.currentTime

            const destination = positionProp.getValue(time)
            if (!destination)
                return

            const orientation = sensorOrientation(time)
            if (!orientation)
                return

            Quaternion.multiply(
                orientation,
                rotateSensorQuaternion,
                orientation,
            )

            const rotMat = Matrix3.fromQuaternion(orientation, matrix3Scratch)

            Matrix3.getColumn(rotMat, 0, camera.direction)
            Cartesian3.negate(camera.direction, camera.direction)
            Matrix3.getColumn(rotMat, 2, camera.up)
            Cartesian3.negate(camera.up, camera.up)
            Cartesian3.cross(camera.direction, camera.up, camera.right)
            camera.position = destination
            camera.frustum.near = 0.1
        }

        clock.onTick.addEventListener(listener)

        return () => {
            clock.onTick.removeEventListener(listener)
        }
    }, [cesium?.viewer, firstPerson, orientationProp, positionProp, sensorOrientation, showFrustum])

    return (
        <>
            {showFrustum && (
                <>
                    <Entity
                        ref={entityRef}
                        name="sensor"
                        position={positionProp}
                        orientation={new CallbackProperty(sensorOrientation, false)}
                    />
                    {poiLinePositions && (
                        <Entity>
                            <PolylineGraphicsComponent
                                width={1}
                                arcType={ArcType.GEODESIC}
                                material={new ColorMaterialProperty(Color.LIGHTGREEN)}
                                positions={poiLinePositions}
                            />
                        </Entity>
                    )}
                </>
            )}
            {!firstPerson && (
                <Entity
                    name="drone"
                    tracked={tracked}
                    position={positionProp}
                    orientation={orientationProp}

                >
                    <ModelGraphics
                        uri="/models/M300.glb"
                        runAnimations
                        scale={0.001}
                        minimumPixelSize={20}
                    />
                </Entity>
            )}
        </>
    )
}

export default CesiumDroneModel

import {makeObservable, observable, action, IObservableArray} from "mobx"

export class CameraStore {
    @observable cameras: IObservableArray<{droneId: string, camera: string, cameraName: string}> = []

    constructor() {
        makeObservable(this)
    }

    @action addCamera(droneId: string, camera: string, cameraName: string) {
        if (this.cameras.find((c) => c.droneId === droneId && c.camera === camera))
            return

        this.cameras.push({droneId, camera, cameraName})
    }

    @action removeCamera(droneId: string, camera: string) {
        const cam = this.cameras.find((c) => c.droneId === droneId && c.camera === camera)
        if (!cam)
            return

        this.cameras.remove(cam)
    }
}

const cameras = new CameraStore()

export default cameras

import HttpService from "@/services/HttpService"
import type {ProfileDto} from "./types"

const baseUrl = "/admin/profiles"

export class ProfilesService {
    constructor(api: HttpService) {
        this.api = api
    }

    getById: Promise<ProfileDto> = (id: string) => this.api.get(`${baseUrl}/${id}`);
    getAll: Promise<ProfileDto[]> = () => this.api.get(`${baseUrl}/all`);
    create: Promise<ProfileDto> = (dto: ProfileDto) => this.api.post(`${baseUrl}`, dto);
    update: Promise<ProfileDto> = (dto: ProfileDto) => this.api.put(`${baseUrl}`, dto);
    delete: Promise = (id: string) => this.api.delete(`${baseUrl}/${id}`);
}

export * from "./types"

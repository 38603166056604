import React, {Component} from "react"
import {Route, Switch, withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Spinner, Tab, Tabs, Intent} from "@blueprintjs/core"
import i18next from "i18next"
import {reaction} from "mobx"
import {DroneListStore} from "@/store/DroneListStore"
import type {UnitStores} from "@/store/DroneListStore"
import DroneInfoPanel from "@/components/left/DroneInfoPanel"
import PlanList from "@/components/left/PlanList"
import Navigator from "@/components/left/Navigator"
import MissionList from "@/components/left/MissionList"
import OperatorRequestList from "@/components/left/OperatorRequestList"
import OperatorRequestEditor from "@/components/left/OperatorRequestEditor"
import PlanEditor from "@/components/plans/PlanEditor"
import MissionPanel from "@/components/left/MissionPanel"
import type {RouteProps} from "@/types/RouteProps"
import {SelectionStore} from "@/store/SelectionStore"
import api from "@/services/ApiService"
import {PrivateRoute} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import permissionsStore from "@/store/PermissionsStore"

@inject("selection")
@withRouter
class UnitTabs extends Component<{ unit: UnitStores, selection?: SelectionStore } & RouteProps<{ unitId: string, tabId: string }>> {
    reactionDisposer;

    componentDidMount() {
        this.reactionDisposer = reaction(() => this.props.unit?.drone?.params.mission_stage,
            (stage) => {
                if (stage === "New") {
                    api.drone.get(this.props.unit.drone.droneId).then((drone) => {
                        if (this.props.unit.drone.currentMissionId !== drone.currentMissionId)
                            this.props.unit.drone.setMissionId(drone.currentMissionId)
                    })
                }
                if (stage === "Completed" || stage === "Failed")
                    this.props.unit.drone.setMissionId(null)
                this.props.selection.setPlan(null)
            })
    }

    componentWillUnmount() {
        this.reactionDisposer()
    }

    render() {
        const {unit} = this.props

        return (
            <div>
                <Navigator backTitle={i18next.t("operator.unit-panel.navigator.back-title")} title={unit.displayName}>
                    <Tabs className="margin-8" selectedTabId={this.props.match.params.tabId} onChange={this.tabChange}>
                        <Tab id="info"
                             title={i18next.t("operator.unit-panel.tab.title.info")}
                             panel={<DroneInfoPanel unit={unit} />}
                        />
                        <Tab id="plans"
                             title={i18next.t("operator.unit-panel.tab.title.plans")}
                             hidden={!permissionsStore.hasPermission(permissions.planRead)}
                             panel={<PlanList unit={unit} />}
                        />
                        <Tab id="missions"
                             hidden={!permissionsStore.hasPermission(permissions.missionRead)}
                             title={i18next.t("operator.unit-panel.tab.title.missions")}
                             panel={<MissionList unit={unit} />}
                        />
                        <Tab id="requests"
                             title={i18next.t("operator.unit-panel.tab.title.requests")}
                             panel={<OperatorRequestList station={unit.station} />}
                             hidden={!(unit.station && permissionsStore.hasPermission(permissions.missionCanRequest))}
                        />
                    </Tabs>
                </Navigator>
            </div>
        )
    }

    tabChange = (newTabId) => {
        const {unitId} = this.props.match.params
        this.props.history.replace(`/unit/${unitId}/${newTabId}`)
    }
}

@inject("drones", "selection")
@observer
export default class UnitPanel extends Component<{ drones?: DroneListStore, selection?: SelectionStore } & RouteProps<{ unitId: string }>> {
    state = {
        unit: null,
    }

    componentDidMount() {
        const unit = this.props.drones.getUnitById(this.props.match.params.unitId)
        this.setState({unit})
        this.props.selection.selectDrone(unit)
        // если мы уже знаем, что запущена миссия сразу ее показываем
        this.props.selection.setPlan(unit.drone?.currentPlan)

        // на всякий случай запрашиваем api, вдруг у этого дрона запущена миссия
        api.drone.get(unit.drone?.droneId).then((drone) => {
            if (unit.drone?.currentMissionId !== drone?.currentMissionId) {
                // если запущена миссия - показываем её
                unit.drone.setMissionId(drone.currentMissionId)
                    .then((unitDrone) => this.props.selection.setPlan(unitDrone.currentPlan))
                    // eslint-disable-next-line no-console
                    .catch(console.error)
            }
        })
    }

    componentWillUnmount() {
        this.props.selection.setPlan(null)
        this.props.selection.selectDrone(null)
    }

    render() {
        const {unit} = this.state

        if (!unit)
            return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

        const {path} = this.props.match

        return (
            <Switch>
                <Route path={`${path}/:tabId`} exact>
                    <UnitTabs unit={unit} />
                </Route>

                <PrivateRoute
                    permission={permissions.planWrite}
                    key={`${path}/plans/create`}
                    path={`${path}/plans/create`}
                    exact
                >
                    <PlanEditor unit={unit} />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.planRead}
                    key={`${path}/plans/edit/:planId`}
                    path={`${path}/plans/edit/:planId`}
                    exact
                >
                    <PlanEditor unit={unit} />
                </PrivateRoute>
                <PrivateRoute
                    permission={permissions.missionRead}
                    key={`${path}/missions/:missionId`}
                    path={`${path}/missions/:missionId`}
                >
                    <MissionPanel unit={unit} />
                </PrivateRoute>

                <PrivateRoute
                    permission={permissions.missionCanRequest}
                    key={`${path}/requests/:requestsId`}
                    path={`${path}/requests/:requestsId`}
                    exact
                >
                    <OperatorRequestEditor unit={unit} />
                </PrivateRoute>
            </Switch>
        )
    }
}

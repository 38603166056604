import React, { useEffect, useState, VFC } from 'react';
import {
	BoundingSphere, CallbackProperty,
	Cartesian3,
	Cartographic, ClockRange,
	Color, createOsmBuildings,
	JulianDate, PolylineArrowMaterialProperty,
	SampledPositionProperty,
	sampleTerrainMostDetailed,
	TerrainProvider
} from 'cesium';
import { BoxGraphics, Clock, Entity, useCesium } from 'resium';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import selection from '@/store/SelectionStore';
import { buildSimulatorTasks } from '@/services/PlanSimulatorService';
import type DegreesPosition from '@/models/DegreesPosition';
import CesiumDroneModel from '@/components/cesium/CesiumDroneModel';
import {
	getWaypointTupleArray,
	keyDegrees,
	pointToCartesian3
} from '@/components/map/cesium/utils';
import WaypointEntity from '@/components/cesium/WaypointEntity';
import WaypointsArrow from '@/components/cesium/WaypointsArrow';
import DroneSimulatorOrientationProperty from '@/extensions/cesium/DroneSimulatorOrientationProperty';
import { bisectTasks, cartesianFromPoint, makeCesiumSimulatorTasks } from '@/extensions/cesium/utils';

const getHeight = async (provider: TerrainProvider, position: DegreesPosition) => {
	try {
		const [cartographic] = await sampleTerrainMostDetailed(
			provider,
			[Cartographic.fromDegrees(position.lon, position.lat)],
		);

		if (cartographic && cartographic.height)
			return cartographic.height;
	} catch (e) {
		// TODO: LOGGER
		// eslint-disable-next-line no-console
		console.error('Unable to sample terrain', e);
	}
	return 100;
};

const startPointsArrowMaterial = new PolylineArrowMaterialProperty(Color.ORANGE);

const stationHeight = 2;

const PlanLayer: VFC = () => {
	const [initialized, setInitialized] = useState(false);
	const [heightAboveEllipsoid, setHeightAboveEllipsoid] = useState<number | null>(null);
	const [{ positionProp, orientationProp, poiArrowProp }, setCesiumProps] = useState({});
	const [{ startPoint, aboveStartPoint }, setStartPoints] = useState({});
	// const [droneCameraFov, setFov] = useState(100)
	const [startTime] = useState<JulianDate>(JulianDate.now(new JulianDate()));
	const [endTime, setEndTime] = useState<JulianDate | null>(null);

	const cesium = useCesium();
	const hasPoints = selection.plan?.points && selection.plan?.points?.length > 0;

	useEffect(() => {
		if (initialized || !hasPoints)
			return;

		const sphere = BoundingSphere.fromPoints(
			selection.plan.points.map((store) => pointToCartesian3(store.waypoint, heightAboveEllipsoid)),
		);
		sphere.radius *= 1.5;
		cesium.camera.flyToBoundingSphere(sphere, {
			duration: 0,
		});
		cesium.viewer.scene.primitives.add(createOsmBuildings());
		setInitialized(true);
	}, [cesium, hasPoints, heightAboveEllipsoid, initialized]);

	useEffect(() => {
		const timeline = cesium?.viewer?.timeline;
		if (!endTime || !startTime || !timeline)
			return;

		timeline.zoomTo(startTime, endTime);
	}, [cesium.viewer.timeline, endTime, startTime]);

	useEffect(() => {
		const cancel = autorun(() => {
			if (!hasPoints || !startPoint || !aboveStartPoint)
				return;

			const hasStation = selection.station != null;

			let pointBeforeTakeoff: DegreesPosition;
			let pointAboveTheGround: DegreesPosition;

			const [first] = selection?.plan?.points || [];
			const firstWaypoint = first?.waypoint;
			const { station } = selection;

			if (hasStation) {
				const { lat, lon } = station.position;
				pointBeforeTakeoff = {
					lat,
					lon,
					height: stationHeight,
				};
				pointAboveTheGround = {
					lat,
					lon,
					height: firstWaypoint?.height,
				};

				getHeight(cesium.scene.terrainProvider, station.position).then((x) => setHeightAboveEllipsoid(x));
			} else {
				const { lat, lon } = firstWaypoint;
				pointBeforeTakeoff = {
					lat,
					lon,
					height: 0,
				};
				pointAboveTheGround = firstWaypoint;
				getHeight(cesium.scene.terrainProvider,
					{
						lat: firstWaypoint.lat,
						lon: firstWaypoint.lon,
					}).then((x) => {
						setHeightAboveEllipsoid(x);
					});
			}

			setStartPoints({
				startPoint: pointBeforeTakeoff,
				aboveStartPoint: pointAboveTheGround,
			});
		});

		return () => cancel();
	}, [cesium.scene?.terrainProvider, cesium.viewer?.scene.globe]);

	useEffect(() => {
		const cancel = autorun(() => {
			if (!hasPoints || !startPoint || !aboveStartPoint)
				return;

			const simulatorTasks = makeCesiumSimulatorTasks(startTime, heightAboveEllipsoid, buildSimulatorTasks(
				startPoint,
				aboveStartPoint,
				selection.plan.points.map((x) => x.waypoint),
			));

			if (simulatorTasks.length <= 0)
				return;

			// TODO: REPLACE WITH A NEW POSITION PROPERTY CLASS
			const sampled = new SampledPositionProperty();

			simulatorTasks.forEach((x) => {
				if (x.type === 'Takeoff') {
					sampled.addSample(
						startTime,
						cartesianFromPoint(x.from),
					);

					sampled.addSample(
						x.timeFromStart,
						cartesianFromPoint(x.to),
					);
				}
				// TODO: NOT IMPLEMENTED
				// if (x.type === "Action") {
				//     sampled.addSample(
				//         x.timeFromStart,
				//         cartesianFromPoint(x.waypoint),
				//     )
				// }
				if (x.type === 'MoveTo') {
					sampled.addSample(
						x.timeFromStart,
						cartesianFromPoint(x.to),
					);
				}
				if (x.type === 'Landing') {
					sampled.addSample(
						JulianDate.addSeconds(x.timeFromStart.clone(), -x.taskTime, new JulianDate()),
						cartesianFromPoint(x.from),
					);
					sampled.addSample(
						x.timeFromStart,
						cartesianFromPoint(x.to),
					);
					setEndTime(x.timeFromStart);
				}
			});

			setCesiumProps({
				positionProp: sampled,
				poiArrowProp: new CallbackProperty((time: JulianDate): [Cartesian3, Cartesian3] => {
					const position = sampled.getValue(time);
					if (!position)
						return undefined;

					const index = bisectTasks(simulatorTasks, time);
					const task = simulatorTasks[index];
					if (!task)
						return undefined;

					if (task.type !== 'MoveTo')
						return undefined;

					if (!task?.from?.pointOfInterest)
						return undefined;

					return [task.from.pointOfInterest, position];
				}, false),
				orientationProp: new DroneSimulatorOrientationProperty(simulatorTasks, sampled),
			});
		});
		return () => cancel();
	}, [aboveStartPoint, hasPoints, heightAboveEllipsoid, startPoint, startTime]);

	if (!positionProp || !orientationProp)
		return null;

	const waypoints = selection.plan.points.map((x) => x.waypoint);
	const [firstWaypoint] = waypoints;
	const lastWaypoint = waypoints[waypoints.length - 1];
	const arrows = getWaypointTupleArray(waypoints);

	return (
		<>
			{startTime && endTime && (
				<Clock
					startTime={startTime}
					currentTime={startTime}
					stopTime={endTime}
					clockRange={ClockRange.CLAMPED}
				/>
			)}
			{waypoints.filter((x) => x.pointOfInterest != null).map((x) => (
				<WaypointEntity
					key={keyDegrees(x.pointOfInterest)}
					waypoint={x}
					heightAboveEllipsoid={heightAboveEllipsoid}
					pointOfInterest
					entityProps={{
						point: { pixelSize: 10, color: Color.YELLOW },
					}}
				/>
			))}

			{selection.station && (
				<Entity
					description="station"
					position={pointToCartesian3(selection.station.position, heightAboveEllipsoid)}
				>
					<BoxGraphics
						dimensions={new Cartesian3(2, 3, stationHeight)}
						material={Color.RED.withAlpha(0.5)}
					/>
				</Entity>
			)}
			{selection.plan.points.map(({ waypoint }, i) => (
				<WaypointEntity
					key={keyDegrees(waypoint, 'points')}
					waypoint={waypoint}
					heightAboveEllipsoid={heightAboveEllipsoid}
					entityProps={{
						description: `Point ${i}`,
						point: { pixelSize: 10, color: Color.WHITE },
					}}
				/>
			))}

			{arrows.map(([from, to], i) => (
				<WaypointsArrow
					key={`arrow-${keyDegrees(from)}-${keyDegrees(to)}`}
					from={from}
					to={to}
					arrowNumber={i}
					heightAboveEllipsoid={heightAboveEllipsoid}
				/>
			))}

			{startPoint && aboveStartPoint && (
				<>
					<WaypointsArrow
						from={startPoint}
						to={aboveStartPoint}
						heightAboveEllipsoid={heightAboveEllipsoid}
						arrowProps={{
							material: startPointsArrowMaterial,
						}}
					/>
					<WaypointsArrow
						from={aboveStartPoint}
						to={firstWaypoint}
						heightAboveEllipsoid={heightAboveEllipsoid}
						arrowProps={{
							material: startPointsArrowMaterial,
						}}
					/>
					<WaypointsArrow
						from={lastWaypoint}
						to={aboveStartPoint}
						heightAboveEllipsoid={heightAboveEllipsoid}
						arrowProps={{
							material: startPointsArrowMaterial,
						}}
					/>
				</>
			)}

			<CesiumDroneModel
				orientation={orientationProp}
				position={positionProp}
				poiLinePositions={poiArrowProp}
				cameraAngle={30}
				tracked
				showFrustum
			/>
		</>
	);
};

export default observer(PlanLayer);

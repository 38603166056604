import React, {Component} from "react"
import {Link, withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Icon} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import type {RouteProps} from "@/types/RouteProps"
import {GlobalStore} from "@/store/GlobalStore"
import './Navigator.scss'

@withRouter
@inject("globalStore")
@observer
export default class Navigator extends Component<{globalStore?: GlobalStore, title: string, backTitle?: string, backUrl?: string} & RouteProps> {
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()
    }

    componentDidMount() {
        const {url} = this.props.match
        this.scrollRef.scrollTop = this.props.globalStore.getScrollPosition(url)
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.url !== prevProps.match.url) {
            const prevUrl = prevProps.match.url
            const prevScrollValue = this.scrollRef.scrollTop
            this.props.globalStore.setScrollPosition({url: prevUrl, scrollPosition: prevScrollValue})

            const {url} = this.props.match
            this.scrollRef.scrollTop = this.props.globalStore.getScrollPosition(url)
        }
    }

    componentWillUnmount() {
        const {url} = this.props.match
        const scrollValue = this.scrollRef.scrollTop
        this.props.globalStore.setScrollPosition({url, scrollPosition: scrollValue})
    }

    render() {
        return (
            <div className="bp3-panel-stack">
                <div className="bp3-panel-stack-view">
                    <div className="bp3-panel-stack-header">
                        {this.props.backTitle ? (
                            <span>
                                <Link className="bp3-button bp3-minimal bp3-small bp3-panel-stack-header-back"
                                      to={this.props.backUrl ?? "/"}
                                >
                                    <Icon style={{margin: "0 2px"}}
                                          icon={IconNames.CHEVRON_LEFT}
                                    />
                                    {this.props.backTitle}
                                </Link>
                            </span>
                        ) : <span />}
                        <div className="bp3-heading">{this.props.title}</div>
                        <span />
                    </div>
                    <div style={{overflowY: "auto", overflowX: "hidden"}} ref={(ref) => { this.scrollRef = ref }}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

import {scaleLinear} from "@visx/scale"

export const themeColors = {
    white: "#FFFFFF",
    black: "#1B1B1B",
    gray: "#98A7C0",
    darkGray: "#2A2A2A",
    accent: "#40FEAE",
    darkAccent: "#256769",
}

export const allGradients = [
    ["#134E5E", "#71B280"],
    ["#FFE000", "#799F0C"],
    ["#6be585", "#dd3e54"],
    ["#b92b27", "#1565C0"],
    ["#4286f4", "#373B44"],
    ["#f953c6", "#b91d73"],
    ["#FF416C", "#FF4B2B"],
    ["#f12711", "#f5af19"],
    ["#8E2DE2", "#4A00E0"],
    ["#009FFF", "#ec2F4B"],
]

export const singleColors = allGradients.map(([first, second]) => {
    const firstScale = scaleLinear({
        domain: [0, 20],
        range: [first, second],
    })

    const next = firstScale(10)

    const secondScale = scaleLinear({
        domain: [0, 20],
        range: [next, "#fff"],
    })

    return secondScale(0)
})

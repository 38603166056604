import React, {Component} from "react"
import {observer} from "mobx-react"
import i18next from "i18next"
import {Button, Card, H3, Menu, MenuItem, Overlay, Popover, PopoverPosition} from "@blueprintjs/core"
import {autoCommands, manualCommands} from "@/model/StationCommands"
import api from "@/services/ApiService"
import type {UnitStores} from "@/store/DroneListStore"
import "./StationControl.scss"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"

@observer
export default class StationControl extends Component<{unit: UnitStores}> {
    state = {
        isOpen: false,
        cmdId: null,
        cmdCode: null,
        cmdStatus: "",
    }

    render() {
        const {params} = this.props.unit.station
        const commands = params?.mode?.toLowerCase() === "auto" ? autoCommands : manualCommands

        const menu = (
            <Menu>
                {commands.map((c) => (
                    <MenuItem key={c}
                              text={c}
                              onClick={() => this.command(c)}
                    />
                ))}
            </Menu>
        )

        return (
            <>
                <PrivateComponent permission={permissions.stationCanExecute}>
                    <Popover content={menu} position={PopoverPosition.LEFT_BOTTOM} modifiers={{arrow: {enabled: false}}}>
                        <Button text={i18next.t("operator.station-control.button.command")} />
                    </Popover>
                </PrivateComponent>
                <Overlay isOpen={this.state.isOpen} onClose={this.closeWindow} usePortal hasBackdrop enforceFocus>
                    <Card className="centered">
                        <H3>{this.state.cmdCode}</H3>
                        <p>{this.state.cmdStatus}</p>
                        <br />
                        <div>
                            <Button onClick={this.closeWindow}>Close</Button>
                        </div>
                    </Card>
                </Overlay>
            </>
        )
    }

    componentDidMount() {
        if (this.interval)
            clearInterval(this.interval)
    }

    command(cmd: string) {
        const {station} = this.props.unit

        this.setState({
            isOpen: true,
            cmdCode: cmd,
        })
        api.station.command(station.stationId, cmd)
            .then((result) => {
                this.setState({
                    cmdId: result.id,
                    cmdStatus: result.status,
                })
                this.interval = setInterval(this.updateCommand, 1000)
            })
    }

    updateCommand = () => {
        api.station.status(this.props.unit.station.stationId, this.state.cmdId).then((result) => this.setState({cmdStatus: result.status}))
    }

    closeWindow = () => {
        this.setState({
            isOpen: false,
            cmdId: null,
            cmdStatus: "",
        })
        clearInterval(this.interval)
        this.interval = null
    }
}

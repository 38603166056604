import type {JoystickPreset} from "@/model/JoystickPreset"
import DroneStore from "@/store/DroneStore"
import {DisplayModes} from "@/model"
import DroneConnection from "@/connection/DroneConnection"

export const joystickTickInterval = 200 // Частота отправки команд для маневрирования и вращения камеры, мс

const yawSpeed = 0.05 // Скорость рысканья в градусах / мс
const pitchSpeed = -0.05 // Скорость наклона в градусах / мс

const yawSpeedR = yawSpeed * joystickTickInterval // Скорость рысканья в градусах / interval
const pitchSpeedR = pitchSpeed * joystickTickInterval // Скорость наклона в градусах / interval

const J_MOVE_MENU = 0
const J_GIMBAL_MENU = 1

export type JoystickMenu = {
    id: number,
    caption: string
}

export const JoystickMenuItems: JoystickMenu[] = [
    {
        id: J_MOVE_MENU,
        caption: "control.joystick-controls.menu-items.moving",
    }, {
        id: J_GIMBAL_MENU,
        caption: "control.joystick-controls.menu-items.gimbal",
    },
]

export class JoystickControls {
    gimbalPitch = 0
    gimbalYaw = 0

    upDown = 0
    leftRight = 0
    yaw = 0
    forwardBackward = 0

    constructor(drone: DroneStore, droneConn: DroneConnection) {
        this.droneConn = droneConn
        this.drone = drone
        Object.entries(this.commands).forEach(([k, v]) => { v.id = k })
    }

    commands = {
        axesAircraftJoyLX: {
            caption: "control.joystick-controls.commands.up-down",
            menu: J_MOVE_MENU,
            multiplier: -1,
            axis: (val) => { this.upDown = val },
        },
        axesAircraftJoyLY: {
            caption: "control.joystick-controls.commands.turn-left-right",
            menu: J_MOVE_MENU,
            multiplier: 0.2,
            axis: (val) => { this.leftRight = val },
        },
        axesAircraftJoyRY: {
            caption: "control.joystick-controls.commands.forward-backward",
            menu: J_MOVE_MENU,
            multiplier: -0.2,
            axis: (val) => { this.forwardBackward = val },
        },
        axesAircraftJoyRX: {
            caption: "control.joystick-controls.commands.left-right",
            menu: J_MOVE_MENU,
            multiplier: -1,
            axis: (val) => { this.yaw = val },
        },
        takeOff: {
            caption: "control.joystick-controls.commands.takeoff",
            menu: J_MOVE_MENU,
            down: () => {
                if (this.drone.params.display_mode === DisplayModes.AUTOLANDING)
                    this.onCommand("cancel_landing")
                else
                    this.onCommand("take_off")
            },
        },
        landing: {
            caption: "control.joystick-controls.commands.landing",
            menu: J_MOVE_MENU,
            down: () => this.onCommand("landing"),
        },
        obtainControl: {
            caption: "control.joystick-controls.commands.obtain-control",
            menu: J_MOVE_MENU,
            down: () => this.onCommand("obtain_control"),
        },
        gimbalUp: {
            caption: "control.joystick-controls.commands.gimbal-up",
            menu: J_GIMBAL_MENU,
            down: () => { this.gimbalPitch = -pitchSpeedR },
            up: () => { this.gimbalPitch = 0 },
        },
        gimbalDown: {
            caption: "control.joystick-controls.commands.gimbal-down",
            menu: J_GIMBAL_MENU,
            down: () => { this.gimbalPitch = pitchSpeedR },
            up: () => { this.gimbalPitch = 0 },
        },
        gimbalLeft: {
            caption: "control.joystick-controls.commands.gimbal-left",
            menu: J_GIMBAL_MENU,
            down: () => { this.gimbalYaw = -yawSpeedR },
            up: () => { this.gimbalYaw = 0 },
        },
        gimbalRight: {
            caption: "control.joystick-controls.commands.gimbal-right",
            menu: J_GIMBAL_MENU,
            down: () => { this.gimbalYaw = yawSpeedR },
            up: () => { this.gimbalYaw = 0 },
        },
        gimbalReset: {
            caption: "control.joystick-controls.commands.gimbal-reset",
            menu: J_GIMBAL_MENU,
            down: () => this.onCommand("gimbal_reset"),
        },
    }

    onCommand(cmd: string, args?: any) {
        this.droneConn.command(this.drone.droneId, cmd, args).then()
    }

    tick() {
        if (this.gimbalYaw !== 0 || this.gimbalPitch !== 0) {
            const {gimbalYaw, gimbalPitch} = this
            const zoom = this.drone.params?.main_camera_zoom ?? 1
            this.droneConn.gimbalShift(this.drone.droneId, gimbalYaw / zoom, gimbalPitch / zoom, joystickTickInterval / 1000).then()
        }

        if (this.upDown !== 0 || this.leftRight !== 0 || this.yaw !== 0 || this.forwardBackward !== 0) {
            this.onCommand("aircraft_control", {
                ud: this.upDown,
                lr: this.leftRight,
                yaw: this.yaw,
                fb: this.forwardBackward,
            })
        }
    }

    setPreset(preset: JoystickPreset) {
        Object.entries(preset.multipliers).forEach(([k, v]) => { this.commands[k].multiplier = v })

        const buttons = {}
        Object.entries(preset.buttons).forEach(([k, v]) => { buttons[k] = this.commands[v] })

        const axes = {}
        Object.entries(preset.axes).forEach(([k, v]) => { axes[k] = this.commands[v] })

        return {buttons, axes}
    }
}

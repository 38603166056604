import React, {Component} from "react"
import Draggable from "react-draggable"
import {Button, Classes, H4} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"

import "./DraggableWindow.scss"

type Props = {
    caption: string,
    link?: string,
    onClose: () => void,
    enableFullscreen?: boolean,
    style?: React.CSSProperties,
    children: React.ReactNode,
}

export default class DraggableWindow extends Component<Props> {
    state = {
        fullscreen: false,
    }

    render() {
        const {fullscreen} = this.state
        const {link, caption, style, onClose, children} = this.props
        const enableFullscreen = this.props.enableFullscreen ?? false
        return (
            <Draggable defaultPosition={{x: 560, y: 0}}
                       disabled={fullscreen}
                       handle={`.${Classes.DIALOG_HEADER}`}
                       defaultClassName={fullscreen ? "cameraFullscreen" : "cameraWindowed"}
            >
                <div className={Classes.DIALOG_CONTAINER}>
                    <div className={Classes.DIALOG} style={style}>
                        <div className={Classes.DIALOG_HEADER}>
                            <H4>{caption}</H4>
                            {link && <Button icon={IconNames.LINK} onClick={this.openLink} onTouchEnd={this.openLink} minimal /> }
                            {enableFullscreen && <Button icon={IconNames.FULLSCREEN} onClick={this.toggleFullscreen} onTouchEnd={this.toggleFullscreen} minimal /> }
                            <Button icon={IconNames.CROSS} onClick={onClose} onTouchEnd={onClose} minimal />
                        </div>
                        {children}
                    </div>
                </div>
            </Draggable>
        )
    }

    toggleFullscreen = () => {
        this.setState({fullscreen: !this.state.fullscreen})
    }

    openLink = () => {
        window.open(this.props.link, "_blank").focus()
    }
}

export const MANUAL_CONTROL = 0
export const ATTITUDE = 1
export const P_GPS = 6
export const HOTPOINT = 9
export const ASSISTED_TAKEOFF = 9
export const TAKEOFF = 9
export const AUTOLANDING = 12
export const ON_MISSION = 14
export const GO_HOME = 15
export const SDK_CTRL = 17
export const SPORT = 21
export const SPORT2 = 31
export const FORCE_AUTO_LANDING = 33
export const TRIPOD = 38
export const SEARCH = 40
export const ENGINE_START = 41
export const SMART_TRACK = 51

const descriptions = {}
descriptions[MANUAL_CONTROL] = "Manual control"
descriptions[ATTITUDE] = "Attitude"
descriptions[P_GPS] = "P-GPS"
descriptions[HOTPOINT] = "Hotpoint"
descriptions[ASSISTED_TAKEOFF] = "Assisted takeoff"
descriptions[TAKEOFF] = "Auto takeoff"
descriptions[AUTOLANDING] = "Auto landing"
descriptions[ON_MISSION] = "On mission"
descriptions[GO_HOME] = "Go Home"
descriptions[SDK_CTRL] = "SDK Ctrl"
descriptions[SPORT] = "Sport"
descriptions[SPORT2] = "Sport"
descriptions[FORCE_AUTO_LANDING] = "Force Auto Landing"
descriptions[TRIPOD] = "Tripod"
descriptions[SEARCH] = "Search"
descriptions[ENGINE_START] = "Engine Start"
descriptions[SMART_TRACK] = "Smart Track"

export function description(val: number) {
    if (val in descriptions)
        return descriptions[val]
    return `Unknown ${val}`
}

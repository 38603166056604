import React, {Component} from "react"
import {inject} from "mobx-react"
import i18next from "i18next"
import {Button, Classes, FormGroup, InputGroup, Intent, Tooltip} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"

import {AdminStationsStore} from "@/store/AdminStationsStore"
import api from "@/services/ApiService"
import {infoToast} from "@/components/AppToaster"

type State = {
    name: string,
    stationId: string,
    disabled: boolean,
    password: string,
    passwordConfirm: string,
    showPassword: boolean,
}

@inject("adminStations")
export default class EditStationForm extends Component<{ stationId: string, adminStations?: AdminStationsStore }, State> {
    state: State = {
        name: "",
        stationId: "",
        disabled: false,
        password: "",
        passwordConfirm: "",
        showPassword: false,
    }

    componentDidMount() {
        const station = this.props.adminStations.getAdminStationById(this.props.stationId)
        if (station?.id) {
            this.setState({
                name: station.name,
                stationId: station.stationId,
            })
        }
    }

    render() {
        const {name, stationId, disabled, showPassword, password, passwordConfirm} = this.state

        const lockButton = (
            <Tooltip
                content={showPassword ? i18next.t("admin.drones-editor.text.hide-pass") : i18next.t("admin.drones-editor.text.show-pass")}
            >
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        )

        return (
            <>
                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <p>{i18next.t("admin.stations-editor.text.edit-station")}</p>
                    <p>{i18next.t("admin.stations-editor.text.station-id")} {stationId}</p>
                    <FormGroup label={i18next.t("admin.stations-editor.form.name.label")} labelFor="name">
                        <InputGroup
                            id="name"
                            leftIcon={IconNames.PERSON}
                            value={name}
                            onChange={(e) => this.setState({name: e.target.value})}
                        />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={i18next.t("admin.stations-editor.button.save")}
                                intent={Intent.PRIMARY}
                                loading={disabled}
                                onClick={this.edit}
                        />
                    </div>
                </div>

                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <p>{i18next.t("admin.drones-editor.text.change-password")}</p>
                    <FormGroup label={i18next.t("admin.drones-editor.form.password.label")} labelFor="password">
                        <InputGroup
                            id="password"
                            leftIcon={IconNames.KEY}
                            type={showPassword ? "text" : "password"}
                            rightElement={lockButton}
                            value={password}
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                    </FormGroup>
                    <FormGroup label={i18next.t("admin.drones-editor.form.password-rep.label")}
                               labelFor="password-confirm"
                    >
                        <InputGroup
                            id="password-confirm"
                            leftIcon={IconNames.KEY}
                            type={showPassword ? "text" : "password"}
                            rightElement={lockButton}
                            value={passwordConfirm}
                            onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                        />
                    </FormGroup>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={i18next.t("admin.drones-editor.button.change")}
                                intent={Intent.PRIMARY}
                                loading={disabled}
                                onClick={this.changePassword}
                        />
                    </div>
                </div>
            </>
        )
    }

    edit = () => {
        const {name, stationId} = this.state

        if (!stationId || !name) return
        this.setState({disabled: true})

        api.adminStations
            .update(stationId, {name})
            .then((request) => {
                infoToast(i18next.t("admin.stations-editor.toast.station-edit"))
                this.props.adminStations.updateAdminStation(request)
            })
            .finally(() => this.setState({disabled: false}))
    }

    changePassword = () => {
        const {stationId, password, passwordConfirm} = this.state

        if (!password || !passwordConfirm) return

        if (password !== passwordConfirm) {
            infoToast(i18next.t("admin.drones-editor.toast.password-match"))
            return
        }
        this.setState({disabled: true})

        api.adminStations
            .resetPassword(stationId, password)
            .then(() => {
                infoToast(i18next.t("admin.drones-editor.toast.pass-change"))
            })
            .finally(() => this.setState({disabled: false}))
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})
}

import {Intent, Position, Toaster, IToastProps} from "@blueprintjs/core"

const AppToaster = Toaster.create({
    position: Position.TOP,
})

export function warningToast(message: string, props: Omit<IToastProps, "message">) {
    AppToaster.show({message, intent: Intent.WARNING, ...props})
}

export function dangerToast(message: string, props: Omit<IToastProps, "message">) {
    AppToaster.show({message, intent: Intent.DANGER, ...props})
}

export function successToast(message: string, props: Omit<IToastProps, "message">) {
    AppToaster.show({message, intent: Intent.SUCCESS, ...props})
}

export function infoToast(message: string, props: Omit<IToastProps, "message">) {
    AppToaster.show({message, ...props})
}

export default AppToaster

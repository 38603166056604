import {createPathComponent} from "@react-leaflet/core"
import {Polyline as LeafletPolyline} from "leaflet"

const PolylineExt = createPathComponent(
    ({positions, ...options}, ctx) => {
        const instance = new LeafletPolyline(positions, options)
        return {instance, context: {...ctx, overlayContainer: instance}}
    },
    (layer, props, prevProps) => {
        if (props.positions !== prevProps.positions)
            layer.setLatLngs(props.positions)

        if (props.className !== prevProps.className) {
            if (prevProps.className)
                prevProps.className.split(" ").forEach((c) => c && layer.getElement().classList.remove(c))

            if (props.className)
                props.className.split(" ").forEach((c) => c && layer.getElement().classList.add(c))
        }
    },
)
export default PolylineExt

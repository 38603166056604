import {action, makeObservable, observable} from "mobx"
import type {IRequest, IRequestFile} from "@/model/IRequest"

export default class RequestStore {
    @observable id: string
    stationId: string
    @observable state: number
    @observable editMode = false
    @observable latitude: number
    @observable longitude: number
    @observable type: string
    @observable comments: string
    @observable operatorComment: string
    @observable date: Date
    @observable author: string
    @observable createTime: string
    @observable changeDate: string
    @observable files: IRequestFile[]

    constructor() {
        makeObservable(this)
    }

    @action setRequest(request: IRequest) {
        this.id = request.id
        this.stationId = request.stationId
        this.state = request.state
        this.latitude = request.lat
        this.longitude = request.lon
        this.type = request.type
        this.comments = request.comments
        this.operatorComment = request.operatorComment
        this.date = new Date(request.date)
        this.changeDate = request.changeDate
        this.author = request.author
        this.createTime = request.createTime
        this.files = request.files
    }

    @action reset() {
        this.id = undefined
        this.state = undefined
        this.latitude = undefined
        this.longitude = undefined
        this.type = ""
        this.comments = ""
        this.operatorComment = ""
        this.date = new Date()
        this.changeDate = undefined
        this.author = ""
        this.createTime = undefined
        this.files = undefined
    }

    @action setEditMode(value: boolean) {
        this.editMode = value
    }

    @action setLatitude(value: number) {
        this.latitude = value
    }

    @action setLongitude(value: number) {
        this.longitude = value
    }

    @action setLocation(ll: {lat: number, lng: number}) {
        this.latitude = ll.lat
        this.longitude = ll.lng
    }

    @action setType(type: string) {
        this.type = type
    }

    @action setComments(comments: string) {
        this.comments = comments
    }

    @action setOperatorComment(operatorComment: string) {
        this.operatorComment = operatorComment
    }

    @action setDate(date: Date) {
        this.date = date
    }

    @action setFiles(files: IRequestFile[]) {
        if (!this.files) this.files = []
        this.files.push(files)
    }

    @action removeFiles(id: string) {
        this.files = this.files.filter((i) => i.id !== id)
    }

    getRequest(): IRequest {
        return {
            id: this.id,
            stationId: this.stationId,
            lat: this.latitude,
            lon: this.longitude,
            type: this.type,
            comments: this.comments,
            operatorComment: this.operatorComment,
            date: this.date.toISOString(),
            files: this.files,
        }
    }
}

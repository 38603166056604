import {Cartesian3, JulianDate} from "cesium"
import {bisector} from "d3-array"
import type {Waypoint} from "@/model/Waypoint"
import {pointToCartesian3, secondsToJulian} from "@/components/map/cesium/utils"
import {SimulatorTasks} from "@/services/PlanSimulatorService"
import type {CesiumSimulatorTasks} from "@/extensions/cesium/types"

export const toCesiumWaypoint = (x: Waypoint, heightAboveEllipsoid: number) => {
    const {lat, lon, height, pointOfInterest, ...other} = x
    return {
        lat,
        lon,
        height,
        ...other,
        ...(pointOfInterest && {pointOfInterest: pointToCartesian3(pointOfInterest, heightAboveEllipsoid)}),
        cartesian: pointToCartesian3({lat, lon, height}, heightAboveEllipsoid),
    }
}

export const makeCesiumSimulatorTasks = (startTime: JulianDate, heightAboveEllipsoid: number, tasks: SimulatorTasks[]): CesiumSimulatorTasks[] => tasks
    .filter((x) => x.type !== "Action")
    .map((x) => {
        const {timeFromStart} = x
        const time = {timeFromStart: secondsToJulian(startTime, timeFromStart)}
        if (x.type === "MoveTo") {
            const {to, from, ...rest} = x
            return {
                ...rest,
                ...time,
                from: toCesiumWaypoint(from, heightAboveEllipsoid),
                to: toCesiumWaypoint(to, heightAboveEllipsoid),
            }
        }

        // TODO: NOT IMPLEMENTED
        // if (x.type === "Action") {
        //     const {waypoint, ...rest} = x
        //     return {
        //         ...rest,
        //         ...time,
        //         waypoint: toCesiumWaypoint(waypoint, heightAboveEllipsoid),
        //     }
        // }

        const {from, to, ...rest} = x
        return {
            ...rest,
            ...time,
            from: pointToCartesian3(from, heightAboveEllipsoid),
            to: pointToCartesian3(to, heightAboveEllipsoid),
        }
    })

export const bisectTasks = bisector<CesiumSimulatorTasks, JulianDate>((x) => x.timeFromStart).right

export const cartesianFromPoint = (x: Cartesian3 | Waypoint) => (x instanceof Cartesian3 ? x : x.cartesian)

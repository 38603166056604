// eslint-disable-next-line import/extensions
import Theme from "@/application/layout/theme";
import { PrivateComponent } from "@/authorization/components";
import permissions from "@/authorization/permissions";
import api from "@/services/ApiService";
import { DroneListStore } from "@/store/DroneListStore";
import { GlobalStore } from "@/store/GlobalStore";
import userInfo from "@/store/UserInfoStore";
import type { RouteProps } from "@/types/RouteProps";
import { Button, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

@withRouter
@inject("globalStore", "drones")
@observer
export default class UserInfo extends Component<{ globalStore?: GlobalStore, drones?: DroneListStore; } & RouteProps> {
	render() {
		const { globalStore, history } = this.props;

		const menu = (
			<Menu>
				{globalStore.layoutTheme === Theme.Dark
					? <MenuItem icon={IconNames.FLASH} text={i18next.t("user-info.text.light-theme")} onClick={this.setLightLayoutTheme} />
					: <MenuItem icon={IconNames.MOON} text={i18next.t("user-info.text.dark-theme")} onClick={this.setDarkLayoutTheme} />}

				<PrivateComponent
					permissions={[
						permissions.droneWrite,
						permissions.stationsWrite,
						permissions.profilesReadWrite,
						permissions.userConsumerWrite,
						permissions.userFullWrite,
					]}
					permissionsCompare="or"
				>
					<MenuItem text={i18next.t("user-info.text.admin-area")} onClick={() => history.push("/admin")} />
				</PrivateComponent>

				<MenuItem text={i18next.t("user-info.text.edit-profile")} onClick={() => history.push("/profile")} />
				<MenuItem text={i18next.t("user-info.text.language")}>
					<MenuItem text="EN" onClick={() => this.switchLanguage("en")} />
					<MenuItem text="RU" onClick={() => this.switchLanguage("ru")} />
				</MenuItem>

				<MenuItem text={i18next.t("user-info.text.logout")} onClick={this.logout} />
			</Menu>
		);

		return (
			<div className="user-info">
				<Popover2 content={menu} placement="bottom-start">
					<Icon icon={IconNames.USER} iconSize={32} className="avatar" style={{ cursor: "pointer" }} />
				</Popover2>
				<div className="ws-connection">{this.props.drones.connectionStatus
					? <span className="flex center"><Icon icon={IconNames.RECORD} iconSize={10} color="#008000" /></span>
					: <span className="flex center"><Icon icon={IconNames.RECORD} iconSize={10} color="#474A51" /></span>}
				</div>
				<span className="user-name">{userInfo.username}</span>
				<Button minimal icon={IconNames.CHEVRON_LEFT} onClick={() => globalStore.setCollapsed(true)} />
			</div>
		);
	}

	logout = () => {
		api.auth.logout();
	};

	setLightLayoutTheme = () => {
		this.props.globalStore.setLayoutTheme(Theme.Light);
	};

	setDarkLayoutTheme = () => {
		this.props.globalStore.setLayoutTheme(Theme.Dark);
	};

	switchLanguage = (lang) => {
		i18next.changeLanguage(lang).then(() => window.location.reload());
	};
}

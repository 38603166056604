import Enums from "./Enums.json"
import RequestTypes from "./RequestTypes.json"
import DroneCommands from "./DroneCommands.json"
import Actions from "./Actions.json"

const Model = {
    ...Enums,
    ...RequestTypes,
    ...DroneCommands,
    ...Actions,
}
export default Model

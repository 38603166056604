import type {Waypoint} from "@/model/Waypoint"
import type Route from "@/model/Route"
import type Plan from "@/model/Plan"
import HttpService from "@/services/HttpService"

const baseUrl = "/plans"

export default class PlanService {
    constructor(api: HttpService) {
        this.api = api
    }

    update(planId: string, name: string, route: Route, points: Waypoint[]) {
        return this.api.post(baseUrl, {
            id: planId, name, route, points,
        })
    }

    create(droneId: string, stationId: string, name: string, route: Route, points: Waypoint[]) {
        return this.api.put(baseUrl, {
            droneId, stationId, name, route, points,
        })
    }

    remove(planId: string) {
        return this.api.delete(`${baseUrl}/${planId}`)
    }

    start(planId: string, droneId: string, scheduledTime?: string): Promise<{missionId: string}> {
        return this.api.post(`${baseUrl}/${planId}/start`, {droneId, scheduledTime})
    }

    get(planId: string): Promise<Plan> {
        return this.api.get(`${baseUrl}/${planId}`)
    }
}

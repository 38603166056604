import React, {MouseEventHandler} from "react"
import {inject} from "mobx-react"
import DroneConnection from "@/connection/DroneConnection"
import DroneStore from "@/store/DroneStore"
import "./CameraTouchArea.scss"

const normalize = (x) => (x > 0 ? Math.min(10, x) : Math.max(-10, x))

const getAbsPosition = (e) => {
    if (e.changedTouches) {
        return {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
        }
    }
    return {
        x: e.clientX,
        y: e.clientY,
    }
}

const getRelativePosition = (e, relativeEl) => {
    const absPos = getAbsPosition(e)

    const boxPos = relativeEl.getBoundingClientRect()

    return {
        x: absPos.x - boxPos.left,
        y: absPos.y - boxPos.top,
    }
}

const FORCE_FACTOR = 10
const SEND_INTERVAL = 200
const CAMERA_RESOLUTION = [1920, 1080]

type Props = {
    drone: DroneStore;
    dronesConn?: DroneConnection;
}

@inject("dronesConn")
export default class CameraTouchArea extends React.Component<Props> {
    areaEl = React.createRef(null)
    arrowEl = React.createRef(null)

    dragStart = null
    normalizedPos = null
    interval = null
    preventClick = false
    state = {
        distance: 0,
        active: false,
        stickPos: {x: 0, y: 0},
        startPos: {x: 0, y: 0},
        focusPos: {x: 0, y: 0},
        angle: 0,
        focusAnimating: false,
    }

    handleMove = () => {
        const zoom = this.props.drone.params.main_camera_zoom ?? 1

        this.props.dronesConn.gimbalShift(this.props.drone.droneId, this.normalizedPos.x / zoom, this.normalizedPos.y / zoom, SEND_INTERVAL / 1000).then()
    }

    handleMouseMove: MouseEventHandler = (e) => {
        e.preventDefault()
        if (this.dragStart == null) return

        const pos = getRelativePosition(e, this.areaEl.current)

        this.setState({
            stickPos: pos,
        })

        const xDiff = this.state.stickPos.x - this.state.startPos.x
        const yDiff = this.state.stickPos.y - this.state.startPos.y

        const distance = Math.hypot(xDiff, yDiff)
        if (distance > this.arrowEl.current.offsetHeight * 2.5) {
            const angle = Math.atan2(yDiff, xDiff) * (180 / Math.PI) + 90

            this.setState({arrowVisible: true, angle, distance})
            this.preventClick = true
        } else {
            this.setState({arrowVisible: false, distance})
            this.removeInterval()
            return
        }

        const boxSize = {
            w: this.areaEl.current.offsetWidth,
            h: this.areaEl.current.offsetHeight,
        }

        this.normalizedPos = {
            x: normalize((xDiff * FORCE_FACTOR) / boxSize.w),
            y: normalize(-(yDiff * FORCE_FACTOR) / boxSize.h),
        }

        if (this.interval == null)
            this.interval = setInterval(this.handleMove, SEND_INTERVAL)
    }

    removeInterval = () => {
        clearInterval(this.interval)
        this.interval = null
    }

    handleMouseUp: MouseEventHandler = (e) => {
        e.preventDefault()

        this.dragStart = null
        this.setState({
            distance: 0,
            active: false,
            focusAnimating: false,
        })
        this.removeInterval()
        return false
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove)
        document.addEventListener("mouseup", this.handleMouseUp)

        document.addEventListener("touchmove", this.handleMouseMove)
        document.addEventListener("touchend", this.handleMouseUp)
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleMouseMove)
        document.removeEventListener("mouseup", this.handleMouseUp)

        document.removeEventListener("touchmove", this.handleMouseMove)
        document.removeEventListener("touchend", this.handleMouseUp)
        this.removeInterval()
    }

    showJoystick: MouseEventHandler = (e) => {
        e.preventDefault()

        const pos = getRelativePosition(e, this.areaEl.current)

        this.setState({
            startPos: pos,
            stickPos: pos,
            arrowVisible: false,
            active: true,
        })

        this.dragStart = pos
        return false
    }

    handleAreaClick: MouseEventHandler = (e) => {
        e.preventDefault()

        if (this.preventClick) {
            this.preventClick = false
            return
        }

        this.setState({
            focusAnimating: false,
        })

        const boxSize = {
            w: this.areaEl.current.offsetWidth,
            h: this.areaEl.current.offsetHeight,
        }

        const pos = getRelativePosition(e, this.areaEl.current)

        this.setState({
            focusPos: pos,
            distance: 0,
            focusAnimating: true,
        })

        const [cameraW, cameraH] = CAMERA_RESOLUTION

        const [scaleX, scaleY] = [cameraW / boxSize.w, cameraH / boxSize.h]

        const {dronesConn, drone} = this.props

        dronesConn.setFocusPoint(drone.droneId, (pos.x * scaleX) / cameraW, (pos.y * scaleY) / cameraH, 0).then()
    }

    render() {
        const {startPos, stickPos, focusPos, arrowVisible, angle, distance, focusAnimating} = this.state
        const opacity = Math.min(distance / 100, 1)
        return (
            <div ref={this.areaEl}
                 aria-hidden="true"
                 className={`camera-touch-area ${this.state.active ? "active" : ""}`}
                 onMouseDown={this.showJoystick}
                 onTouchStart={this.showJoystick}
                 onMouseUp={this.handleMouseUp}
                 onClick={this.handleAreaClick}
            >
                <div
                    className="stickEl"
                    style={{left: stickPos.x, top: stickPos.y, opacity}}
                />
                <div
                    className="dragStart"
                    style={{left: startPos.x, top: startPos.y, opacity}}
                >
                    <div
                        ref={this.arrowEl}
                        className="arrow"
                        style={{opacity: arrowVisible ? 1 : 0, transform: `rotate(${angle}deg)`}}
                    >
                        <img alt="arrow" src="/doubleArrow.svg" />
                    </div>
                </div>
                <div
                    className={`focusEl ${focusAnimating ? "active" : ""}`}
                    style={{left: focusPos.x, top: focusPos.y}}
                />
            </div>
        )
    }
}

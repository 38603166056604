import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {observer} from "mobx-react"
import {Intent, Button, FormGroup, InputGroup, Classes, Divider, Tooltip, H3} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import {reaction} from "mobx"
import {successToast, warningToast} from "@/components/AppToaster"
import type {RouteProps} from "@/types/RouteProps"
import api from "@/services/ApiService"
import userInfo from "@/store/UserInfoStore"

type States = {
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirm: string,
    showPassword: boolean,
    disabled: boolean,
    changed: boolean,
}

@withRouter
@observer
export default class Profile extends Component<RouteProps, States> {
    state = {
        firstName: userInfo.firstName ?? "",
        lastName: userInfo.lastName ?? "",
        password: "",
        passwordConfirm: "",
        showPassword: false,
        disabled: false,
        changed: false,
    }

    componentDidMount() {
        reaction(() => userInfo.firstName, () => {
            if (!this.state.changed) {
                const {firstName, lastName} = userInfo
                this.setState({
                    firstName, lastName,
                })
            }
        })
    }

    render() {
        const {firstName, lastName, password, passwordConfirm, showPassword, disabled} = this.state

        const lockButton = (
            <Tooltip content={showPassword ? i18next.t("profile.profile.text.hide-pass") : i18next.t("profile.profile.text.show-pass")}>
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        )

        return (
            <div style={{margin: 10}}>
                <Button icon={IconNames.ARROW_LEFT} onClick={this.goBack} text={i18next.t("profile.profile.button.back")} intent={Intent.PRIMARY} minimal />
                <Divider />
                <H3 style={{marginLeft: "10px"}}>{i18next.t("profile.profile.text.main-setting")}</H3>
                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <form onSubmit={this.edit}>
                        <FormGroup label={i18next.t("profile.profile.form.first-name.label")} labelFor="firstName">
                            <InputGroup
                                id="firstName"
                                leftIcon={IconNames.USER}
                                value={firstName}
                                onChange={(e) => this.setState({firstName: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup label={i18next.t("profile.profile.form.last-name.label")} labelFor="lastName">
                            <InputGroup
                                id="lastName"
                                leftIcon={IconNames.USER}
                                value={lastName}
                                onChange={(e) => this.setState({lastName: e.target.value})}
                            />
                        </FormGroup>

                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button text={i18next.t("profile.profile.button.save")} intent={Intent.PRIMARY} type="submit" loading={disabled} />
                        </div>
                    </form>
                </div>

                <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                    <p>{i18next.t("profile.profile.text.change-pass")}</p>
                    <form onSubmit={this.changePassword}>
                        <FormGroup label={i18next.t("profile.profile.form.password.label")} labelFor="password">
                            <InputGroup
                                id="password"
                                leftIcon={IconNames.KEY}
                                type={showPassword ? "text" : "password"}
                                rightElement={lockButton}
                                value={password}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup label={i18next.t("profile.profile.form.password-rep.label")} labelFor="password-confirm">
                            <InputGroup
                                id="password-confirm"
                                leftIcon={IconNames.KEY}
                                type={showPassword ? "text" : "password"}
                                rightElement={lockButton}
                                value={passwordConfirm}
                                onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                            />
                        </FormGroup>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button text={i18next.t("profile.profile.button.change")} intent={Intent.PRIMARY} type="submit" loading={disabled} />
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    edit = (event) => {
        event.preventDefault()
        this.setState({disabled: true})

        const {firstName, lastName} = this.state

        api.user.updateProfile(firstName, lastName)
            .then(() => {
                api.user.profile().then((res) => {
                    userInfo.setFromProfile(res)
                })
                successToast("Saved changes")
            })
            .finally(() => {
                this.setState({disabled: false})
            })
    }

    changePassword = (event) => {
        event.preventDefault()
        const {password, passwordConfirm} = this.state

        if (!password || !passwordConfirm) return

        if (password !== passwordConfirm) {
            warningToast("The passwords you entered don't match")
            return
        }

        this.setState({disabled: true})
        api.user.changePassword(this.state.passwordConfirm)
            .then(() => successToast("The password changed"))
            .finally(() => this.setState({disabled: false}))
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})

    goBack = () => this.props.history.replace("/")
}

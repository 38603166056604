import React, {useCallback, useEffect} from "react"
import {observer} from "mobx-react"
import charts from "@/store/ChartsStore"
import DraggableWindow from "@/components/control/DraggableWindow"
import "./FloatingCharts.scss"
import Chart from "@/components/charts/Chart"
import api from "@/services/ApiService"
import type {ChartSettingsDto} from "@/model/ChartDto"

export type ChartNames = "battery" | "markers"

const DraggableChart: React.FC<{settings: ChartSettingsDto}> = observer(({settings}) => {
    const {name, id, title, ...rest} = settings
    const handleClose = useCallback(() => charts.hideChart(name), [name])
    if (!charts.isChartVisible(name))
        return <></>

    return (
        <DraggableWindow caption={title} onClose={handleClose} enableFullscreen={false}>
            <div className="flex center">
                <div className="chart-wrap">
                    <Chart
                        settings={{
                            ...rest,
                            id,
                            name,
                        }}
                    />
                </div>
            </div>
        </DraggableWindow>
    )
})

const FloatingCharts = () => {
    useEffect(() => {
        if (charts.getChartSettings > 0)
            return

        if (charts.loading)
            return

        charts.setLoading(true)
        api.charts.getAllSettings().then((x) => charts.setSettings(x))
    }, [])

    return (
        <div className="floating-charts">
            {charts.getChartSettings.map((settings) => (
                <DraggableChart key={settings.id} settings={settings} />
            ))}
        </div>
    )
}

export default observer(FloatingCharts)

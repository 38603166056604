import CircleButton from "@/components/CircleButton";
import globalStore from "@/store/GlobalStore";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import "./RightPanel.scss";

const RightPanel: React.FC = ({ children }) => {
	const handleClick = useCallback(() => globalStore.setCollapsed(false), []);
	return (
		<div className={`right-panel ${globalStore.collapsed ? "full" : ""}`}>
			{globalStore.collapsed && <CircleButton tooltip={i18next.t("drone-map.tooltip.show-info")} icon={faChevronRight} onClick={handleClick} />}
			{children}
		</div>
	);
};

export default observer(RightPanel);

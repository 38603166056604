import React, {Component} from "react"
import {observer} from "mobx-react"
import {Button, FormGroup, NumericInput, Switch} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import ParamEditor from "@/components/plans/ParamEditor"
import PlanStore from "@/store/PlanStore"
import ActionsEditor from "./ActionsEditor"

@observer
export default class WaypointParamEditor extends Component<{ planStore: PlanStore, disabled?: boolean }> {
    render() {
        const {selectedWP, route} = this.props.planStore
        if (selectedWP == null)
            return <></>

        const wp = selectedWP.waypoint
        return (
            <>
                <FormGroup>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Button icon={IconNames.CHEVRON_LEFT} minimal onClick={this.selectPrev} />
                        <div style={{flex: 1, textAlign: "center"}}>
                            {i18next.t("operator.waypoint-param-editor.text.waypoint")} {selectedWP.index}
                        </div>
                        <Button icon={IconNames.CHEVRON_RIGHT} minimal onClick={this.selectNext} />
                    </div>
                </FormGroup>

                <FormGroup>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span>{selectedWP.distBefore}{i18next.t("operator.waypoint-param-editor.text.m")}</span>
                        <div style={{flex: 1, textAlign: "center"}} />
                        <span>{selectedWP.distAfter}{i18next.t("operator.waypoint-param-editor.text.m")}</span>
                    </div>
                </FormGroup>

                <ParamEditor label={i18next.t("operator.waypoint-param-editor.param.height.label")}
                             checked={wp.useHeight}
                             onChecked={(val) => selectedWP.setWaypointParam({useHeight: val})}
                             min={50}
                             max={500}
                             step={10}
                             value={wp.useHeight ? wp.height : route.height}
                             disabled={this.props.disabled}
                             onChange={(val) => selectedWP.setWaypointParam({height: val})}
                />

                <ParamEditor label={i18next.t("operator.waypoint-param-editor.param.speed.label")}
                             checked={wp.useSpeed}
                             onChecked={(val) => selectedWP.setWaypointParam({useSpeed: val})}
                             min={1}
                             max={15}
                             step={1}
                             value={wp.speed}
                             disabled={this.props.disabled}
                             onChange={(val) => selectedWP.setWaypointParam({speed: val})}
                />

                <FormGroup label={i18next.t("operator.waypoint-param-editor.param.latitude.label")}
                           labelFor="wp-lat"
                >
                    <NumericInput id="wp-lat"
                                  min={-90}
                                  max={90}
                                  stepSize={0.0001}
                                  minorStepSize={0.00001}
                                  className="bp3-fill"
                                  value={wp.lat}
                                  onValueChange={(v, valueAsString) => {
                                      const lat = Number(valueAsString)
                                      if (Number.isNaN(lat) || lat === undefined || lat === null) return
                                      selectedWP.setWaypointParam({lat})
                                  }}
                    />
                </FormGroup>

                <FormGroup label={i18next.t("operator.waypoint-param-editor.param.longitude.label")}
                           labelFor="wp-lng"
                >
                    <NumericInput id="wp-lng"
                                  min={-180}
                                  max={180}
                                  stepSize={0.0001}
                                  minorStepSize={0.00001}
                                  className="bp3-fill"
                                  value={wp.lon}
                                  disabled={this.props.disabled}
                                  onValueChange={(v) => selectedWP.setWaypointParam({lon: v})}
                    />
                </FormGroup>

                {!this.props.disabled && (
                    <FormGroup>
                        <p>{i18next.t("operator.waypoint-param-editor.text.point-interest")} {selectedWP.waypoint.pointOfInterest
                            ? (
                                <Button text={i18next.t("operator.waypoint-param-editor.button.remove")}
                                        onClick={this.removePOI}
                                />
                            )
                            : (
                                <Button text={i18next.t("operator.waypoint-param-editor.button.setup")}
                                        onClick={this.setupPOI}
                                        active={this.props.planStore.setupPOIMode}
                                />
                            )}
                        </p>
                    </FormGroup>
                )}

                <FormGroup>
                    <p>{i18next.t("operator.waypoint-param-editor.text.curve")} <Switch disabled={this.props.disabled}
                                                                                        onChange={() => selectedWP.setWaypointParam({isCurve: !selectedWP.waypoint.isCurve})}
                                                                                        checked={selectedWP.waypoint.isCurve}
                                                                                        inline
                    />
                    </p>
                </FormGroup>

                <ActionsEditor actionsStore={wp.actions} disabled={this.props.disabled} />
            </>
        )
    }

    selectPrev = () => {
        const {planStore} = this.props
        if (planStore.selectedWP.index === 0) return

        planStore.selectWP(planStore.points[planStore.selectedWP.index - 1])
    }

    selectNext = () => {
        const {planStore} = this.props
        if (planStore.selectedWP.index === planStore.points.length - 1) return

        planStore.selectWP(planStore.points[planStore.selectedWP.index + 1])
    }

    setupPOI = () => {
        const {planStore} = this.props
        planStore.setupPOI(true)
    }

    removePOI = () => {
        const {planStore} = this.props
        planStore.removePOI()
    }
}

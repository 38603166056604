import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {observer} from "mobx-react"
import {Button, Card, Elevation, H5} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import api from "@/services/ApiService"
import PlanStore from "@/store/PlanStore"
import type {UnitStores} from "@/store/DroneListStore"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"

@withRouter
@observer
class PlanCard extends Component<{plan: PlanStore} & RouteProps> {
    render() {
        const {plan} = this.props

        return (
            <Card elevation={Elevation.TWO}
                  className="bp3-text-small"
                  interactive
                  onClick={this.onClick}
            >
                <H5>{plan.name ? plan.name : plan.id}</H5>
                <div>{i18next.t("operator.plan-list.card.text.points")} {plan.points.length},
                    <span style={{marginLeft: "5px"}}>{i18next.t("operator.plan-list.card.text.distance")} {plan.distance}{i18next.t("operator.plan-list.card.text.m")}</span>
                </div>
            </Card>
        )
    }

    onClick = () => {
        this.props.history.push(`${this.props.match.url}/edit/${this.props.plan.id}`)
    }
}

@withRouter
@observer
export default class PlanList extends LoaderComponent<{unit: UnitStores} & RouteProps, {plans: PlanStore[], value: string}> {
    state = {
        plans: [],
        value: "",
    }

    prepare(): Promise {
        const {station, drone} = this.props.unit

        if (station) {
            return api.station.plans(station.stationId)
                .then((plans) => {
                    station.setPlans(plans)
                    this.plansLoaded(station.plans)
                })
        }
        return api.drone.plans(drone.droneId)
            .then((plans) => {
                drone.setPlans(plans)
                this.plansLoaded(drone.plans)
            })
    }

    plansLoaded = (plans) => this.setState({plans})

    filtered(): PlanStore[] {
        const {plans, value} = this.state

        let plansFiltered = plans

        if (value) {
            const valLower = value.toLowerCase()
            plansFiltered = plansFiltered.filter((p) => p.name && p.name.toLowerCase().includes(valLower))
        }

        return plansFiltered
    }

    successRender() {
        const {value} = this.state

        return (
            <div>
                <PrivateComponent permission={permissions.planWrite}>
                    <div style={{textAlign: "center"}}>
                        <Button icon={IconNames.ADD} text={i18next.t("operator.plan-list.button.create-plan")} minimal onClick={this.createPlan} />
                    </div>
                </PrivateComponent>
                <div style={{margin: 8}} className="bp3-input-group">
                    <span className="bp3-icon bp3-icon-search" />
                    <input
                        type="search"
                        className="bp3-input"
                        value={value}
                        onChange={(e) => this.setState({value: e.target.value})}
                        placeholder={i18next.t("operator.plan-list.search.placeholder")}
                        dir="auto"
                    />
                </div>
                {this.filtered().map((p) => (
                    <PlanCard
                        key={p.id}
                        plan={p}
                    />
                ))}
            </div>
        )
    }

    createPlan = () => {
        this.props.history.push(`${this.props.match.url}/create`)
    }
}

/* eslint-disable no-bitwise */
import L from "leaflet"

type XYZ = {
    x: number,
    y: number,
    z: number
}

export type LayerInfo = {
    name: string,
    url: string,
    default?: boolean,
    crs?: any,
    subdomains?: string,
    h?: (t: XYZ) => string
}

function tileToQuadKey(t: XYZ): string {
    let quadKey = ""
    for (let i = t.z; i > 0; i--) {
        let digit = "0"
        const mask = 1 << (i - 1)
        if ((t.x & mask) !== 0)
            digit += 1
        if ((t.y & mask) !== 0)
            digit += 2
        quadKey += digit
    }
    return quadKey
}

export const tileLayers: LayerInfo[] = [
    {name: "OpenStreetMap", url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", default: true},
    {name: "Google-Hybrid", url: "https://mts{s}.google.com/vt/lyrs=y&x={x}&s=&y={y}&z={z}", subdomains: "0123"},
    {name: "Yandex-Maps", url: `https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.08.28-0-b210701140430&x={x}&y={y}&z={z}&scale=${window.devicePixelRatio}&lang=ru_RU`, crs: L.CRS.EPSG3395},
    {name: "Yandex-Satellite", url: "https://sat0{s}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}&lang=ru-RU", subdomains: "1234", crs: L.CRS.EPSG3395},
    {name: "Bing", url: "https://ecn.t{s}.tiles.virtualearth.net/tiles/a{h}.jpeg?g=918", subdomains: "123", h: tileToQuadKey},
    {name: "OpenStreetMap.BlackAndWhite", url: "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"},
    {name: "2GIS", url: "https://tile{s}.maps.2gis.com/tiles?v=1&x={x}&y={y}&z={z}", subdomains: "0123"},
]

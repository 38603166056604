import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import CameraTouchArea from "@/components/control/CameraTouchArea"
import userInfo from "@/store/UserInfoStore"
import ZoomSlider from "@/components/control/ZoomSlider"
import DroneConnection from "@/connection/DroneConnection"
import DroneStore from "@/store/DroneStore"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import AccessInfo from "./AccessInfo"

type Props = {
    dronesConn?: DroneConnection,
    drone: DroneStore,
    cameraName: string,
}

@inject("dronesConn")
@observer
export default class CameraControl extends Component<Props> {
    state = {
        show: !userInfo.isConsumer,
    }

    setWideSource = () => {
        const {dronesConn, drone} = this.props
        dronesConn.cameraSource(drone.droneId, 0, 1).then()
    }

    setZoomSource = () => {
        const {dronesConn, drone} = this.props
        dronesConn.cameraSource(drone.droneId, 0, 2).then()
    }

    setThermalSource = () => {
        const {dronesConn, drone} = this.props
        dronesConn.cameraSource(drone.droneId, 0, 3).then()
    }

    shootPhoto = () => {
        const {dronesConn, drone} = this.props
        dronesConn.shootPhoto(drone.droneId, 0).then()
    }

    resetGimbal = () => {
        const {dronesConn, drone} = this.props
        dronesConn.resetGimbal(drone.droneId, 0).then()
    }

    setShow = (show) => this.setState({show})

    render() {
        const {drone, cameraName} = this.props
        const {show} = this.state

        return (
            <div className="camera-control">
                <div className="top">
                    {show && (
                        <div className="top-buttons">
                            <Button text="WIDE" onClick={this.setWideSource} />
                            <Button text="ZOOM" onClick={this.setZoomSource} />
                            {cameraName === "H20T" && <Button text="THERMAL" onClick={this.setThermalSource} />}
                            <Button icon={IconNames.CAMERA} onClick={this.shootPhoto} />
                            <Button icon={IconNames.REFRESH} onClick={this.resetGimbal} />
                        </div>
                    )}
                    <PrivateComponent permission={permissions.cameraControl}>
                        <AccessInfo show={show} setShow={this.setShow} droneId={drone.droneId} />
                    </PrivateComponent>
                </div>
                {show && (
                    <>
                        <CameraTouchArea drone={drone} />
                        <ZoomSlider drone={drone} />
                    </>
                )}
            </div>
        )
    }
}

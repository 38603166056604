import React, {Component} from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import {Classes, Spinner, Intent} from "@blueprintjs/core"
import {inject, observer} from "mobx-react"
import {DroneListStore} from "@/store/DroneListStore"
import UserInfo from "@/components/left/UserInfo"
import DroneList from "@/components/left/DroneList"
import UnitPanel from "@/components/left/UnitPanel"
import {GlobalStore} from "@/store/GlobalStore"
import "./LeftPanel.scss"

@inject("drones", "globalStore")
@observer
export default class LeftPanel extends Component<{drones?: DroneListStore, globalStore?: GlobalStore}> {
    render() {
        const {globalStore} = this.props

        return (
            <div className={`left-panel ${Classes.ELEVATION_3} ${globalStore.collapsed ? "collapsed" : ""}`}>
                <UserInfo />
                {this.props.drones.loading ? <Spinner intent={Intent.PRIMARY} className="spinner-center" /> : (
                    <Switch>
                        <Route path="/" component={DroneList} exact />
                        <Redirect from="/unit/:unitId" to="/unit/:unitId/info" exact />
                        <Route path="/unit/:unitId" component={UnitPanel} />
                    </Switch>
                )}
            </div>
        )
    }
}

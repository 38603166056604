import {observer} from "mobx-react"
import React, {Component, useCallback, useEffect, useState} from "react"
import {Button, Classes, Collapse, EditableText, H2, Icon, Intent, Position, Tooltip} from "@blueprintjs/core"
import i18next from "i18next"
import {IconNames} from "@blueprintjs/icons"
import api from "@/services/ApiService"
import type {UnitStores} from "@/store/DroneListStore"
import StationStore from "@/store/StationStore"
import {stationErrorDescription, stationErrorName} from "@/model/Enums"
import StationControl from "@/components/control/StationControl"
import AppToaster, {dangerToast, infoToast} from "@/components/AppToaster"
import SnowMeltingControl from "@/components/control/SnowMeltingControl"
import UploadProgress from "@/components/left/UploadProgress"
import "./StationParams.scss"
import charts from "@/store/ChartsStore"
import {PrivateComponent} from "@/authorization/components"
import roles from "@/authorization/roles"
import permissions from "@/authorization/permissions"

function OnOff(props: { value: boolean }) {
    if (props.value === undefined || props.value === null)
        return <></>

    return <span>{props.value ? i18next.t("operator.station-params.on-off.on") : i18next.t("operator.station-params.on-off.off")}</span>
}

@observer
class Slot extends Component<{ block: string, number: number, station: StationStore }> {
    render() {
        const {block, number} = this.props
        const {params} = this.props.station
        const status = params[`slot_${block}_${number}_status`]
        const percent = params[`slot_${block}_${number}_percent`]
        const sensor = params[`slot_${block}_${number}_sensor`]
        const ext = params[`slot_${block}_${number}_ext`]
        if (!status || percent === undefined) {
            return (
                <Tooltip content={i18next.t("operator.station-params.slot.no-data")} position={Position.RIGHT}>
                    <span className="batt-slot">{i18next.t("operator.station-params.slot.nd")}</span>
                </Tooltip>
            )
        }

        const tt = (
            <>
                {status}
                <br />
                {sensor ? i18next.t("operator.station-params.slot.connected") : i18next.t("operator.station-params.slot.disconnected")}
                <br />
                {ext ? i18next.t("operator.station-params.slot.sensor-on") : i18next.t("operator.station-params.slot.sensor-off")}
            </>
        )

        return (
            <Tooltip content={tt} position={Position.RIGHT}>
                <span
                    className={`batt-slot batt-slot-${status?.toString().toLowerCase()}${sensor ? " batt-sensor" : ""}${ext ? " batt-ext" : ""}`}
                >{percent}
                </span>
            </Tooltip>
        )
    }
}

const StationContext = React.createContext()

@observer
class StationParameter extends Component<{ param: string, children: React.Node, renderRight?: React.ReactNode }> {
    static contextType = StationContext

    render() {
        const station = this.context
        if (station.params[this.props.param] === undefined)
            return <></>

        const cl = station.paramsTimeout[this.props.param] ? "param-timeout" : ""
        return (
            <div className={`flex vertical param ${cl}`}>
                {this.props.children}
                {this.props.renderRight && this.props.renderRight}
            </div>
        )
    }
}

function checkParameterValue(chartName, values) {
    if (chartName === "windSpeed") {
        const parameterValue = values["meteodata/wind_speed"]
        if (parameterValue > 15)
            return true
    }
    return false
}

const WeatherIndicator = () => {
    const [rainfall, setRainfall] = useState()
    const [picture, setPicture] = useState()

    useEffect(() => {
        const lastPosition = localStorage.getItem("positionMap")
        const stationCoordinates = JSON.parse(lastPosition)
        if (!stationCoordinates) return
        const APPID = "942774ed589ff6b2f62b4cf0602f4d76"
        const URL = `https://api.openweathermap.org/data/2.5/weather?lat=${stationCoordinates[0]}&lon=${stationCoordinates[1]}&appid=${APPID}`

        fetch(URL).then((response) => {
            if (response.ok)
                return response.json()
            throw response.status
        })
            .then((data) => {
                const {weather} = data
                const [{description, icon}] = weather
                const weatherIcon = `https://openweathermap.org/img/wn/${icon}.png`
                setRainfall(description)
                setPicture(weatherIcon)
            })
            .catch((err) => {
                console.warn(`Данные не были получены, ошибка: ${err}`)
            })
    }, [])
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            {rainfall}
            <img src={picture} alt={rainfall} />
        </div>
    )
}

const ShowChartButton: React.VFC<{ chartName: string, title: string }> = ({chartName, title}) => {
    const handleClick = useCallback(() => {
        charts.showChart(chartName)
    }, [chartName])
    return (
        <Button
            minimal
            onClick={handleClick}
            rightIcon={<Icon icon={IconNames.TIMELINE_LINE_CHART} />}
            small
        >
            {title}
        </Button>
    )
}

/* eslint-disable no-bitwise */
@observer
export default class StationParams extends Component<{ unit: UnitStores }> {
    state = {
        infoOpen: false,
        smsOpen: false,
    }

    render() {
        const {station, drone} = this.props.unit
        if (!station.stationId)
            return <></>

        const {params} = station
        return (
            <StationContext.Provider value={station}>
                <div className={Classes.TEXT_SMALL}>
                    <H2>
                        <PrivateComponent
                            permission={permissions.stationsWrite}
                            fallback={station.name}
                        >
                            <EditableText placeholder={i18next.t("operator.station-params.station.placeholder")}
                                          defaultValue={station.name}
                                          onConfirm={this.rename}
                            />
                        </PrivateComponent>
                    </H2>
                    <PrivateComponent role={roles.admin}>
                        <p>
                            {i18next.t("operator.station-params.text.id")}
                            {" "}
                            {station.stationId}
                        </p>
                        <p>
                            SSH: {station.sshPort}
                        </p>
                    </PrivateComponent>
                    <PrivateComponent permission={permissions.stationsWrite}>
                        {drone?.params?.gps != null && (
                            <p>
                                <Button onClick={this.setupLocation}
                                        text={i18next.t("operator.station-params.button.setup-location")}
                                />
                            </p>
                        )}
                    </PrivateComponent>
                    <p><StationControl unit={this.props.unit} /></p>
                    <StationParameter param="state">
                        {i18next.t("operator.station-params.text.state")}
                        {" "}
                        {params.state}
                    </StationParameter>
                    <StationParameter param="task">
                        {i18next.t("operator.station-params.text.task")}
                        {" "}
                        {params.task}
                    </StationParameter>
                    {!!params.upload_percent && <UploadProgress params={params} />}
                    <p>
                        {i18next.t("operator.station-params.text.details")}
                        {" "}
                        <Button
                            text={this.state.infoOpen ? i18next.t("operator.station-params.button.hide") : i18next.t("operator.station-params.button.show")}
                            onClick={() => this.setState({infoOpen: !this.state.infoOpen})}
                        />
                    </p>
                    <Collapse isOpen={this.state.infoOpen}>
                        <p className="buttons-margin">
                            <PrivateComponent permission={permissions.chartsView}>
                                <Button icon={IconNames.SHARE} text="Grafana" onClick={(e) => this.clickOnLinkToGrafana(e)} minimal />
                            </PrivateComponent>
                        </p>
                        <StationParameter param="power_input">
                            {i18next.t("operator.station-params.text.power-input")}
                            {" "}
                            <OnOff value={params.power_input} />
                        </StationParameter>
                        <StationParameter param="mode">
                            {i18next.t("operator.station-params.text.mode")}
                            {" "}
                            {params.mode}
                        </StationParameter>
                        {params.error_flag && <p>{i18next.t("operator.station-params.text.error")}</p>}
                        <StationParameter param="last_error_code">
                            {i18next.t("operator.station-params.text.last-error-code")}
                            {" "}
                            <Tooltip content={stationErrorDescription(params.last_error_code)}
                                     position={Position.TOP_RIGHT}
                            >
                                {stationErrorName(params.last_error_code)}
                            </Tooltip>
                        </StationParameter>
                        <br />
                        <p>{i18next.t("operator.station-params.text.limit-switches")}</p>
                        <StationParameter param="roof_opened">
                            {i18next.t("operator.station-params.text.roof-opened")}
                            {" "}
                            <OnOff value={params.roof_opened} />
                        </StationParameter>
                        <StationParameter param="roof_closed">
                            {i18next.t("operator.station-params.text.roof-closed")}
                            {" "}
                            <OnOff value={params.roof_closed} />
                        </StationParameter>
                        <StationParameter param="lift_up">
                            {i18next.t("operator.station-params.text.lift-up")}
                            {" "}
                            <OnOff value={params.lift_up} />
                        </StationParameter>
                        <StationParameter param="lift_down">
                            {i18next.t("operator.station-params.text.lift-down")}
                            {" "}
                            <OnOff value={params.lift_down} />
                        </StationParameter>
                        <StationParameter param="centralizer_x_clamped">
                            {i18next.t("operator.station-params.text.centralizer-x-clamped")}
                            {" "}
                            <OnOff value={params.centralizer_x_clamped} />
                        </StationParameter>
                        <StationParameter param="centralizer_x_released">
                            {i18next.t("operator.station-params.text.centralizer-x-released")}
                            {" "}
                            <OnOff value={params.centralizer_x_released} />
                        </StationParameter>
                        <StationParameter param="centralizer_y_clamped">
                            {i18next.t("operator.station-params.text.centralizer-y-clamped")}
                            {" "}
                            <OnOff value={params.centralizer_y_clamped} />
                        </StationParameter>
                        <StationParameter param="centralizer_y_released">
                            {i18next.t("operator.station-params.text.centralizer-y-released")}
                            {" "}
                            <OnOff value={params.centralizer_y_released} />
                        </StationParameter>
                        <StationParameter param="drone_holder_up">
                            {i18next.t("operator.station-params.text.drone-holder-up")}
                            {" "}
                            <OnOff value={params.drone_holder_up} />
                        </StationParameter>
                        <StationParameter param="drone_holder_down">
                            {i18next.t("operator.station-params.text.drone-holder-down")}
                            {" "}
                            <OnOff value={params.drone_holder_down} />
                        </StationParameter>
                        <br />
                        <StationParameter param="plc_connected">
                            {i18next.t("operator.station-params.text.plc-connected")}
                            {" "}
                            <OnOff value={params.plc_connected} />
                        </StationParameter>
                        <StationParameter param="meteo_connected">
                            {i18next.t("operator.station-params.text.meteo-connected")}
                            {" "}
                            <OnOff value={params.meteo_connected} />
                        </StationParameter>
                        <StationParameter param="charging_left_connected">
                            {i18next.t("operator.station-params.text.charging-left-connected")}
                            {" "}
                            <OnOff value={params.charging_left_connected} />
                        </StationParameter>
                        <StationParameter param="charging_right_connected">
                            {i18next.t("operator.station-params.text.charging-right-connected")}
                            {" "}
                            <OnOff value={params.charging_right_connected} />
                        </StationParameter>
                        <StationParameter param="rc">
                            {i18next.t("operator.station-params.text.rc")}
                            {" "}
                            {params.rc}
                        </StationParameter>
                        <table>
                            <tbody>
                                <tr>
                                    <td><Slot block="left" number={3} station={station} /></td>
                                    <td><Slot block="right" number={3} station={station} /></td>
                                </tr>
                                <tr>
                                    <td><Slot block="left" number={2} station={station} /></td>
                                    <td><Slot block="right" number={2} station={station} /></td>
                                </tr>
                                <tr>
                                    <td><Slot block="left" number={1} station={station} /></td>
                                    <td><Slot block="right" number={1} station={station} /></td>
                                </tr>
                                <tr>
                                    <td><Slot block="left" number={0} station={station} /></td>
                                    <td><Slot block="right" number={0} station={station} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Collapse>
                    <PrivateComponent permission={permissions.chartsView}>
                        <div style={{marginBottom: "10px"}} className="buttons flex">
                            <WeatherIndicator />
                            <ShowChartButton chartName="temperature" title="Temperature" />
                            <ShowChartButton chartName="humidity" title="Humidity" />
                            <ShowChartButton chartName="pressure" title="Pressure" />
                            <ShowChartButton chartName="windSpeed" title="Wind speed" />
                            <ShowChartButton chartName="batteries" title="Batteries" />
                        </div>
                    </PrivateComponent>
                    {params.snow_melting_state !== undefined && (
                        <>
                            <p>
                                {i18next.t("operator.station-params.text.melting-system")}
                                {" "}
                                <Button
                                    text={this.state.smsOpen ? i18next.t("operator.station-params.button.hide") : i18next.t("operator.station-params.button.show")}
                                    onClick={() => this.setState({smsOpen: !this.state.smsOpen})}
                                />
                            </p>
                            <Collapse isOpen={this.state.smsOpen}>
                                <StationParameter param="snow_melting_mode">
                                    {i18next.t("operator.station-params.text.snow-melting-mode")}
                                    {" "}
                                    {params.snow_melting_mode === "Disabled" ? i18next.t("operator.station-params.text.disabled") : i18next.t("operator.station-params.text.enabled")}
                                </StationParameter>
                                <StationParameter param="snow_melting_state">
                                    {i18next.t("operator.station-params.text.snow-melting-state-1")}
                                    <OnOff value={params.snow_melting_state & 1} />
                                </StationParameter>
                                <StationParameter param="snow_melting_state">
                                    {i18next.t("operator.station-params.text.snow-melting-state-2")}
                                    {" "}
                                    {params.snow_melting_state & 2 ? i18next.t("yes") : i18next.t("operator.station-params.text.no")}
                                </StationParameter>
                                <StationParameter param="snow_melting_state">
                                    {i18next.t("operator.station-params.text.snow-melting-state-4")}
                                    {" "}
                                    {params.snow_melting_state & 4 ? i18next.t("yes") : i18next.t("operator.station-params.text.no")}
                                </StationParameter>
                                <p><SnowMeltingControl stationId={station.stationId} /></p>
                            </Collapse>
                        </>
                    )}
                    <hr />
                </div>
            </StationContext.Provider>
        )
    }

    /* eslint-enable no-bitwise */
    clickOnLinkToGrafana = () => {
        window.open(process.env.NODE_ENV === "development" ? `https://grafana.drone-test.aniklab.com/d/Eay90J1Gk/stations?orgId=1&refresh=5s&var-station=${this.props.unit.drone.station.stationId}` : `https://grafana.${window.location.host}/d/Eay90J1Gk/stations?orgId=1&refresh=5s&var-station=${this.props.unit.drone.station.stationId}`, "_blank").focus()
    }

    setupLocation = () => {
        const {drone, station} = this.props.unit
        if (!drone.params.gps || drone.params.gps_health < 3) {
            dangerToast(i18next.t("operator.station-params.toast.coordinates"))
            return
        }

        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                const {gps, yaw} = drone.params
                const pos = {lat: gps.lat, lon: gps.lon, angle: yaw}

                api.station.setLocation(station.stationId, pos)
                    .then(() => {
                        station.setPosition(pos)
                        infoToast(i18next.t("operator.station-params.toast.location-set"))
                    })
            },
        }

        AppToaster.show({
            message: i18next.t("operator.station-params.toast.location-set-confirm"),
            action,
            timeout: 10000,
            intent: Intent.DANGER,
        })
    }

    rename = (val) => {
        const {station} = this.props.unit
        if (val !== station.name) {
            api.station.rename(station.stationId, val)
                .then(() => {
                    station.setName(val)
                })
        }
    }
}

import {makeObservable, observable, IObservableArray, action} from "mobx"

import type {IUser} from "@/model/IUser"

export class AdminUsersStore {
    @observable loading = true
    @observable adminUsersList: IObservableArray<IUser> = []

    constructor() {
        makeObservable(this)
    }

    @action setAdminUsersList(adminUsersList: IUser[]) {
        this.loading = false
        this.adminUsersList = adminUsersList
    }

    @action setAdminUser(adminUsersList: IUser) {
        this.adminUsersList.push(adminUsersList)
    }

    getAdminUserById(id: string): IUser {
        return this.adminUsersList.filter((i) => i.id === id)[0]
    }

    @action updateAdminUser(user: IUser) {
        this.adminUsersList = this.adminUsersList.map((u) => {
            if (u.id === user.id)
                return user
            return u
        })
    }
}

const adminUsers = new AdminUsersStore()

export default adminUsers

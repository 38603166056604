import {makeObservable, observable, action, IObservableArray} from "mobx"
import type Username from "@/model/Username"

export type GrantedPermission = {
    droneId: string;
    expiresIn: string;
    user: Username
}

export class CameraAccessStore {
    @observable permissions: IObservableArray<GrantedPermission> = []

    constructor() {
        makeObservable(this)
    }

    @action updatePermissions = (permissions: GrantedPermission[]) => this.permissions.replace(permissions);
}

const cameraAccess = new CameraAccessStore()
export default cameraAccess

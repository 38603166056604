import React, {FC} from "react"
import {observer} from "mobx-react"
import permissionsStore from "@/store/PermissionsStore"
import userInfo from "@/store/UserInfoStore"
import globalStore from "@/store/GlobalStore"
import {PrivateComponentProps} from "./types"
import type {CompareType} from "./types"

const and = (p, n) => p && n
const or = (p, n) => p || n
const skipNull = (x) => (x == null ? true : Boolean(x))
const getByCompareType = (x: CompareType) => (x === "and" ? and : or)

type Props = PrivateComponentProps & {
    fallback?: React.ReactNode | null;
}

const PrivateComponent: FC<Props> = ({
    permissions,
    permission,
    roles,
    role,
    rolesCompare,
    permissionsCompare,
    fallback = null,
    children,
}) => {
    if (permission && permissions)
        throw new Error("Provide either a single permission or some")

    if (role && roles)
        throw new Error("Provide either a single role or some")

    let hasPermissionAccess = null
    let hasRoleAccess = null

    if (globalStore.authLoading)
        return null

    if (!globalStore.loggedIn)
        return fallback

    if (permission)
        hasPermissionAccess = permissionsStore.hasPermission(permission)

    if (permissions)
        hasPermissionAccess = permissions.map((x) => permissionsStore.hasPermission(x)).reduce(getByCompareType(permissionsCompare))

    if (role)
        hasRoleAccess = userInfo.isInRole(role)

    if (roles)
        hasRoleAccess = roles.map((x) => userInfo.isInRole(x)).reduce(getByCompareType(rolesCompare))

    const hasAccess = skipNull(hasPermissionAccess) && skipNull(hasRoleAccess)

    return hasAccess ? children : fallback
}

export default observer(PrivateComponent)

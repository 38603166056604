import { Tooltip2 } from "@blueprintjs/popover2";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import React from "react";
import "./CircleButton.scss";

type ButtonProps = {
	icon: IconProp,
	onClick: () => void,
	tooltip: JSX.Element | string;
};

export default function CircleButton({ icon, onClick, tooltip }: ButtonProps) {
	return (
		<Tooltip2 content={tooltip} placement="right">
			<button className="circle-button bp3-elevation-3 flex center"
				type="button"
				onClick={(e) => {
					onClick();
					L.DomEvent.disableClickPropagation(e.target);
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<FontAwesomeIcon icon={icon} size="lg" />
			</button>
		</Tooltip2>
	);
}

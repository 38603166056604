import React, {DetailedHTMLProps, HTMLAttributes, ReactNode} from "react"

const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    bottom: "leaflet-bottom x-centered",
}

export type ControlProps = {
    position?: string;
    containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    children: ReactNode;
};

const Control = (props: ControlProps): JSX.Element => {
    const {position, containerProps, children} = props
    return (
        <div className={POSITION_CLASSES[position]}>
            <div className="leaflet-control" {...containerProps}>
                {children}
            </div>
        </div>
    )
}

Control.defaultProps = {
    position: "topleft",
    containerProps: {},
}

export default Control

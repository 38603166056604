import React, {Component, useCallback, useEffect, useRef, useState, VFC} from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Intent,
    Button,
    FormGroup,
    InputGroup,
    Classes,
    HTMLSelect,
    Tooltip,
    MenuItem,
    Dialog,
    DialogProps,
    Spinner, Collapse} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {MultiSelect} from "@blueprintjs/select"
import i18next from "i18next"
import api from "@/services/ApiService"
import {dangerToast, infoToast} from "@/components/AppToaster"
import {AdminUsersStore} from "@/store/AdminUsersStore"
import {AdminStationsStore} from "@/store/AdminStationsStore"
import type {RouteProps} from "@/types/RouteProps"
import AdminUsersTypes from "@/model/AdminUsersTypes"
import generatePassword from "@/components/left/admin/utils"
import PermissionsTree from "@/components/left/admin/PermissionsTree"
import type {ProfileDto} from "@/services/ProfilesService"

type Props = {
    adminUsers?: AdminUsersStore,
    adminStations?: AdminStationsStore
}

type States = {
    id: string,
    login: string,
    firstName: string,
    lastName: string,
    role: string,
    password: string,
    passwordConfirm: string,
    stations: string[],
    permissions: number[],
    disabled: boolean,
    isCreate: boolean,
    showPassword: boolean,
    permissionsOpen: boolean,
    stationsData: Array<{ name: string, stationId: string }>,
}

type PermissionDialogProps = Omit<DialogProps, 'canOutsideClickClose' | 'canEscapeKeyClose' | 'title' | 'onChange'> & {
    onChange?: (permissions: number[]) => void;
    permissions: number[];
}

const PermissionsDialog: VFC<PermissionDialogProps> = ({onChange, permissions, ...rest}) => {
    const [loading, setLoading] = useState(false)
    const [profiles, setProfiles] = useState<ProfileDto[] | null>(null)
    const [selectedPreset, setPreset] = useState<number[] | null>(permissions)
    const prevPermissions = useRef()
    const [profileOpen, setOpen] = useState(false)

    useEffect(() => {
        // TODO: lodash _.isEqual would be useful here
        if (JSON.stringify(permissions) !== JSON.stringify(prevPermissions.current))
            setPreset(permissions)

        prevPermissions.current = permissions
    }, [permissions])

    useEffect(() => {
        if (profiles)
            return

        setLoading(true)

        api.profiles.getAll()
            .then((res) => setProfiles(res))
            .catch(() => dangerToast("Unable to get profiles"))
            .finally(() => {
                setLoading(false)
            })
    }, [profiles])

    const handleSelectProfile = useCallback((e) => {
        if (!e || !e.currentTarget)
            return

        const id = e.currentTarget.getAttribute("data-id")
        if (!id)
            return

        setPreset(profiles.find((x) => x.id === id).permissions)
    }, [profiles])

    const handleCollapseClick = useCallback(() => setOpen((prev) => !prev), [])

    return (
        <Dialog
            canEscapeKeyClose
            canOutsideClickClose
            title="Select permissions"
            {...rest}
        >
            <div style={{
                padding: "30px 20px",
            }}
            >
                <FormGroup>
                    <Button intent={Intent.PRIMARY} onClick={handleCollapseClick}>
                        {profileOpen ? "Hide" : "Show"} profiles
                    </Button>
                    <Collapse isOpen={profileOpen}>
                        <ul>
                            {loading
                                ? <Spinner intent={Intent.PRIMARY} className="spinner-center" />
                                : profiles?.map((x) => (
                                    <li key={x.id} className="flex vertical">
                                        <p style={{margin: 0}}>
                                            {x.name}
                                        </p>
                                        <Button style={{marginLeft: 10}} onClick={handleSelectProfile} data-id={x.id}>
                                            Select
                                        </Button>
                                    </li>
                                ))}
                        </ul>
                    </Collapse>
                </FormGroup>
                <FormGroup>
                    <PermissionsTree
                        onChange={onChange}
                        preset={selectedPreset}
                    />
                </FormGroup>
            </div>
        </Dialog>
    )
}

@withRouter
@inject("adminUsers", "adminStations")
@observer
export default class UsersEditor extends Component<Props & RouteProps<{ userId: string }>, States> {
    constructor() {
        super()
        this.state = {
            id: "",
            login: "",
            firstName: "",
            lastName: "",
            role: "consumer",
            password: "",
            passwordConfirm: "",
            stations: [],
            permissions: [],
            disabled: false,
            isCreate: true,
            showPassword: false,
            stationsData: [],
        }
    }

    copyToClip = () => {
        navigator.clipboard.writeText(this.state.password)
    }

    generateBtnClick = async () => {
        const passwd = generatePassword(6)
        await this.setState({
            password: passwd,
            passwordConfirm: passwd,
        })
        this.copyToClip()
    }

    componentDidMount() {
        // TODO: BUG. This func does not return anything after reloading the page.
        const user = this.props.adminUsers.getAdminUserById(this.props.match.params.userId)

        if (user?.login) {
            this.setState({
                id: user.id,
                login: user.login,
                firstName: user?.firstName ?? "",
                lastName: user?.lastName ?? "",
                role: user.role,
                isCreate: false,
                stations: user?.stations ?? [],
                permissions: user.permissions,
                stationsData: [],
            })
        }

        const stationsList = this.props.adminStations.adminStationsList
        const stationsId = user?.stations ?? []
        const stationsData = []

        stationsId.forEach((id) => {
            stationsList.forEach((s) => {
                if (s.stationId === id)
                    stationsData.push(s)
            })
        })

        this.setState({stationsData})
    }

    handleDialogClose = () => {
        this.setState({permissionsOpen: false})
    }

    handleTreeChange = (permissions: number[]) => this.setState({permissions})

    render() {
        const {
            login,
            firstName,
            lastName,
            role,
            password,
            passwordConfirm,
            disabled,
            isCreate,
            showPassword,
            stationsData,
            permissionsOpen,
            permissions,
        } = this.state

        const dialogButton = (
            <FormGroup>
                <Button text={i18next.t("admin.users-editor.button.permissions")}
                        intent={Intent.PRIMARY}
                        loading={disabled}
                        onClick={() => this.setState({permissionsOpen: true})}
                />
            </FormGroup>
        )

        const lockButton = (
            <Tooltip
                content={showPassword ? i18next.t("admin.users-editor.text.hide-pass") : i18next.t("admin.users-editor.text.show-pass")}
            >
                <Button
                    icon={showPassword ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={Intent.WARNING}
                    minimal
                    onClick={this.handleLockClick}
                />
            </Tooltip>
        )

        return (
            <div className="admin-header" style={{minHeight: "100vh"}}>
                <Button icon={IconNames.ARROW_LEFT}
                        onClick={this.goBack}
                        text={i18next.t("admin.users-editor.button.back")}
                        intent={Intent.PRIMARY}
                        minimal
                        style={{marginTop: 10, marginLeft: 10}}
                />
                {isCreate ? (
                    <div style={{maxWidth: 300, marginTop: 10, padding: 10, border: "1px solid #aaa"}}>
                        <p>{i18next.t("admin.users-editor.text.create-user")}</p>
                        <form onSubmit={this.create}>
                            <FormGroup label={i18next.t("admin.users-editor.form.login.label")} labelFor="login">
                                <InputGroup
                                    id="login"
                                    leftIcon={IconNames.PERSON}
                                    value={login}
                                    onChange={(e) => this.setState({login: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup label={i18next.t("admin.users-editor.form.first-name.label")}
                                       labelFor="firstName"
                            >
                                <InputGroup
                                    id="firstName"
                                    leftIcon={IconNames.PERSON}
                                    value={firstName}
                                    onChange={(e) => this.setState({firstName: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup label={i18next.t("admin.users-editor.form.last-name.label")} labelFor="lastName">
                                <InputGroup
                                    id="lastName"
                                    leftIcon={IconNames.PERSON}
                                    value={lastName}
                                    onChange={(e) => this.setState({lastName: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup label={i18next.t("admin.users-editor.form.role.label")} labelFor="role">
                                <HTMLSelect
                                    id="role"
                                    fill
                                    options={AdminUsersTypes}
                                    value={role}
                                    onChange={(e) => this.setState({role: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup label={i18next.t("admin.users-editor.form.password.label")} labelFor="password">
                                <InputGroup
                                    id="password"
                                    leftIcon={IconNames.KEY}
                                    type={showPassword ? "text" : "password"}
                                    rightElement={lockButton}
                                    value={this.state.password}
                                    onChange={(e) => this.setState({password: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup label={i18next.t("admin.users-editor.form.password-rep.label")}
                                       labelFor="password-confirm"
                            >
                                <InputGroup
                                    id="password-confirm"
                                    leftIcon={IconNames.KEY}
                                    type={showPassword ? "text" : "password"}
                                    rightElement={lockButton}
                                    value={this.state.passwordConfirm}
                                    onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                                />
                            </FormGroup>

                            {dialogButton}

                            <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{justifyContent: "space-between"}}>
                                <Button
                                    style={{marginLeft: 0}}
                                    text={i18next.t("admin.drones-editor.button.generate-password")}
                                    intent={Intent.PRIMARY}
                                    type="button"
                                    loading={disabled}
                                    onClick={this.generateBtnClick}
                                />
                                <Button
                                    text={i18next.t("admin.users-editor.button.create")}
                                    intent={Intent.PRIMARY}
                                    type="submit"
                                    loading={disabled}
                                />
                            </div>
                        </form>
                    </div>
                ) : (
                    <>
                        <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                            <p>{i18next.t("admin.users-editor.text.edit-user")}</p>
                            <p>{i18next.t("admin.users-editor.text.login")} {login}</p>
                            <form onSubmit={this.edit}>
                                <FormGroup label={i18next.t("admin.users-editor.form.first-name.label")}
                                           labelFor="firstName"
                                >
                                    <InputGroup
                                        id="firstName"
                                        leftIcon={IconNames.USER}
                                        value={firstName}
                                        onChange={(e) => this.setState({firstName: e.target.value})}
                                    />
                                </FormGroup>
                                <FormGroup label={i18next.t("admin.users-editor.form.last-name.label")}
                                           labelFor="lastName"
                                >
                                    <InputGroup
                                        id="lastName"
                                        leftIcon={IconNames.USER}
                                        value={lastName}
                                        onChange={(e) => this.setState({lastName: e.target.value})}
                                    />
                                </FormGroup>
                                <FormGroup label={i18next.t("admin.users-editor.form.role.label")} labelFor="role">
                                    <HTMLSelect
                                        id="role"
                                        fill
                                        options={AdminUsersTypes}
                                        value={role}
                                        onChange={(e) => this.setState({role: e.target.value})}
                                    />
                                </FormGroup>

                                <FormGroup label={i18next.t("admin.users-editor.form.stations.label")}>
                                    <MultiSelect
                                            items={this.props.adminStations.adminStationsList}
                                            itemRenderer={
                                                (item, {handleClick, modifiers}) => (
                                                    <MenuItem
                                                        key={item.stationId}
                                                        active={modifiers.active}
                                                        text={item.name}
                                                        label={item.stationId}
                                                        icon={this.isStationSelected(item) ? "tick" : "blank"}
                                                        onClick={handleClick}
                                                        shouldDismissPopover={false}
                                                    />
                                                )
                                            }
                                            itemPredicate={(query, stationItem) => stationItem.name.toLowerCase().includes(query.toLowerCase())}
                                            onItemSelect={(item) => this.handleChangeItemToList(item)}
                                            selectedItems={stationsData}
                                            tagRenderer={(item) => item.name}
                                            onRemove={(item) => this.handleChangeItemToList(item)}
                                            noResults={(
                                                <MenuItem disabled
                                                          text={i18next.t("admin.users-editor.form.multi-select.result")}
                                                />
                                            )}
                                            placeholder={undefined}
                                            fill
                                    />
                                </FormGroup>
                                {dialogButton}
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button text={i18next.t("admin.users-editor.button.save")}
                                            intent={Intent.PRIMARY}
                                            type="submit"
                                            loading={disabled}
                                    />
                                </div>
                            </form>
                        </div>

                        <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
                            <p>{i18next.t("admin.users-editor.text.change-password")}</p>
                            <p>{i18next.t("admin.users-editor.text.login")} {login}</p>
                            <form onSubmit={this.changePassword}>
                                <FormGroup label={i18next.t("admin.users-editor.form.password.label")}
                                           labelFor="password"
                                >
                                    <InputGroup
                                        id="password"
                                        leftIcon={IconNames.KEY}
                                        type={showPassword ? "text" : "password"}
                                        rightElement={lockButton}
                                        value={password}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                    />
                                </FormGroup>
                                <FormGroup label={i18next.t("admin.users-editor.form.password-rep.label")}
                                           labelFor="password-confirm"
                                >
                                    <InputGroup
                                        id="password-confirm"
                                        leftIcon={IconNames.KEY}
                                        type={showPassword ? "text" : "password"}
                                        rightElement={lockButton}
                                        value={passwordConfirm}
                                        onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                                    />
                                </FormGroup>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button text={i18next.t("admin.users-editor.button.change")}
                                            intent={Intent.PRIMARY}
                                            type="submit"
                                            loading={disabled}
                                    />
                                </div>
                            </form>
                        </div>
                    </>
                )}
                <PermissionsDialog
                    permissions={permissions}
                    isOpen={permissionsOpen}
                    onClose={this.handleDialogClose}
                    onChange={this.handleTreeChange}
                />
            </div>
        )
    }

    isStationSelected = (item) => {
        const {stationsData} = this.state
        return stationsData.some((s) => s.stationId === item.stationId)
    }

    handleChangeItemToList = (item) => {
        const {stationsData} = this.state
        const isStation = stationsData.some((s) => s.stationId === item.stationId)

        let arrStations
        if (isStation)
            arrStations = stationsData.filter((s) => s.stationId !== item.stationId)
        else
            arrStations = [...stationsData, item]
        this.setState({stationsData: arrStations})
    }

    handleLockClick = () => this.setState({showPassword: !this.state.showPassword})

    changePassword = (event) => {
        event.preventDefault()
        const {id, password, passwordConfirm} = this.state

        if (!password || !passwordConfirm) return

        if (password !== passwordConfirm) {
            infoToast(i18next.t("admin.users-editor.toast.password-match"))
            return
        }
        this.setState({disabled: true})

        api.adminUsers
            .resetPassword(id, password)
            .then(() => {
                infoToast(i18next.t("admin.users-editor.toast.pass-change"))
                this.props.history.replace("/admin/users")
            })
            .catch(() => this.setState({disabled: false}))
    }

    create = (event) => {
        event.preventDefault()
        const {login, firstName, lastName, role, password, passwordConfirm, permissions} = this.state

        if (!login) {
            infoToast(i18next.t("admin.users-editor.toast.enter-login"))
            return
        }

        if (!password || !passwordConfirm) {
            infoToast(i18next.t("admin.users-editor.toast.enter-pass"))
            return
        }

        if (password !== passwordConfirm) {
            infoToast(i18next.t("admin.users-editor.toast.password-match"))
            return
        }

        this.setState({disabled: true})

        api.adminUsers
            .submit({login, firstName, lastName, role, password, permissions})
            .then((request) => {
                infoToast(i18next.t("admin.users-editor.toast.user-create"))
                this.props.adminUsers.setAdminUser(request)
                this.props.history.replace("/admin/users")
            })
            .catch(() => this.setState({disabled: false}))
    }

    edit = (event) => {
        event.preventDefault()
        const {id, login, firstName, lastName, role, stations, stationsData, permissions} = this.state

        let stationsRequest
        if (role === "consumer" || role === "station_operator")
            stationsRequest = stationsData.map((s) => s.stationId)
        else
            stationsRequest = stations

        this.setState({disabled: true})

        api.adminUsers
            .update(id, {firstName, lastName, role, stations: stationsRequest, enabled: true, permissions})
            .then(() => {
                infoToast(i18next.t("admin.users-editor.toast.user-edit"))
                this.props.adminUsers.updateAdminUser({
                    id,
                    login,
                    firstName,
                    lastName,
                    role,
                    stations: stationsRequest,
                    enabled: true,
                })
                this.props.history.replace("/admin/users")
            })
            .catch(() => this.setState({disabled: false}))
    }

    goBack = () => this.props.history.replace("/admin/users")
}

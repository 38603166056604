import {HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr"
import i18next from "i18next"
import {DroneListStore} from "@/store/DroneListStore"
import {dangerToast, warningToast} from "@/components/AppToaster"
import api from "@/services/ApiService"
import userInfo from "@/store/UserInfoStore"
import cameraAccess, {GrantedPermission} from "@/store/CameraAccessStore"
import type Username from "@/model/Username"
import playWarningSound from "@/components/Sounds"

export default class DroneConnection {
    connection: HubConnection = null
    drones: DroneListStore = null

    constructor(droneListStore: DroneListStore) {
        this.drones = droneListStore
        this.connect()
    }

    connect() {
        const socketConnection = new HubConnectionBuilder()
            .configureLogging(process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.Error)
            .withUrl("/api/hub/drone", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => api.auth.getToken(),
            })
            .withAutomaticReconnect()
            .build()

        socketConnection.onreconnected(this.drones.setIsConnected)
        socketConnection.onclose(this.drones.setIsDisconnected)
        socketConnection.on("stationState", this.onStationState)
        socketConnection.on("droneState", this.onDroneState)
        socketConnection.on("notifications", this.onNotificationReceived)
        socketConnection.on("permissionsUpdated", this.permissionsUpdated)
        socketConnection.on("permissionRevoked", (revoked: GrantedPermission, all: GrantedPermission[]) => {
            this.permissionsUpdated(all)
            if (userInfo.isConsumer)
                warningToast(i18next.t("control.drone-control.camera.access.toast.text.your-access-revoked"))
            else if (revoked != null) {
                warningToast(i18next.t("control.drone-control.camera.access.toast.text.consumer-revoked", {
                    user: `${revoked.user?.firstName} ${revoked.user?.lastName}`,
                }))
            }
        })

        if (userInfo.isConsumer)
            this.subscribeToConsumerEvents(socketConnection)
        else
            this.subscribeToOperatorEvents(socketConnection)

        socketConnection.start().then(() => {
            this.connection = socketConnection
            this.drones.setIsConnected()
        })
    }

    permissionsUpdated = (permissions: GrantedPermission[]) => cameraAccess.updatePermissions(permissions)

    subscribeToConsumerEvents(socket) {
        socket.on("accessRejected", () => {
            dangerToast(i18next.t("control.drone-control.camera.access.toast.text.control-forbidden"))
        })
    }

    subscribeToOperatorEvents(socket) {
        socket.on("permissionsUpdated", this.permissionsUpdated)
        socket.on("accessRequested", (user: Username, droneId: string) => {
            let requested = false
            warningToast(i18next.t("control.drone-control.camera.access.toast.text.control-requested", {
                user: `${user?.firstName} ${user?.lastName}`,
            }), {
                action: {
                    text: i18next.t("control.drone-control.camera.access.toast.text.grant"),
                    onClick: () => {
                        requested = true
                        api.cameraAccess.grantAccess(droneId, user.id).then()
                        return false
                    },
                },

                onDismiss: () => {
                    if (!requested)
                        api.cameraAccess.rejectAccess(droneId, user.id).then()
                },

                timeout: 10000,
            })
        })
    }

    onStationState = (stationId, data) => {
        this.drones.setStationProps(stationId, data)
    }

    onDroneState = (serial, data) => {
        this.drones.setDroneProps(serial, data)
    }

    onNotificationReceived = (message: string) => {
        dangerToast(message)
        playWarningSound()
    }

    async invoke(droneId: string, methodName: string, args?: any) {
        if (!this.connection) return

        try {
            if (args)
                await this.connection.invoke(methodName, droneId, args)
            else
                await this.connection.invoke(methodName, droneId)
        } catch (e) {
            dangerToast("Connection lost")
        }
    }

    async command(droneId: string, cmd: string, args: any) {
        await this.invoke(droneId, "Command", {cmd, args})
    }

    async getBatteryInfoCommand(droneId: string) {
        await this.invoke(droneId, "GetBattery")
    }

    async gimbalShift(droneId: string, gimbalYaw: number, gimbalPitch: number, time: number) {
        await this.invoke(droneId, "GimbalShift", {y: gimbalYaw, p: gimbalPitch, t: time})
    }

    async setFocusPoint(droneId: string, x: number, y: number, camId: number) {
        await this.invoke(droneId, "SetFocusPoint", {x, y, cam_idx: camId})
    }

    async setZoomFactor(droneId: string, camIdx: number, factor: number) {
        await this.invoke(droneId, "SetZoomFactor", {cam_idx: camIdx, factor})
    }

    async cameraSource(droneId: string, camIdx: number, camSrc: number) {
        await this.invoke(droneId, "CameraSource", {cam_idx: camIdx, cam_src: camSrc})
    }

    async zoomCtrl(droneId: string, camIdx: number, start: boolean, direction: number, speed: number) {
        await this.invoke(droneId, "ZoomCtrl", {cam_idx: camIdx, start, direction, speed})
    }

    async shootPhoto(droneId: string, camIdx: number) {
        await this.invoke(droneId, "ShootPhoto", {cam_idx: camIdx})
    }

    async resetGimbal(droneId: string, camIdx: number) {
        await this.invoke(droneId, "ResetGimbal", {cam_idx: camIdx})
    }
}

// TODO: MOVE THIS FILE FROM SERVICES TO THE PLAN LOGIC
import {Cartesian3} from "cesium"
import type {ActionData} from "@/model/Actions"
import Waypoint, {BaseWaypoint} from "@/model/Waypoint"
import type DegreesPosition from "@/models/DegreesPosition"
import {pointToCartesian3} from "@/components/map/cesium/utils"

type TimeInSeconds = number;

type BaseTask<Type, Time = TimeInSeconds> = {
    type: Type;
    timeFromStart: Time;
    taskTime: TimeInSeconds;
}

type WithPosition<Pos> = {
    from: Pos;
    to: Pos;
}

export type SimulatorActionTask<Time, Pos> = BaseTask<'Action', Time> & {
    action: ActionData;
    waypoint: BaseWaypoint<Pos> | Pos;
}

export type SimulatorMoveToTask<Time, Pos> = BaseTask<'MoveTo', Time> & WithPosition<BaseWaypoint<Pos>>
export type SimulatorTakeOffTask<Time, Pos> = BaseTask<'Takeoff', Time> & WithPosition<Pos>
export type SimulatorLandingTask<Time, Pos> = BaseTask<'Landing', Time> & WithPosition<Pos>

export type BaseSimulatorTasks<Time, Pos> =
    | SimulatorActionTask<Time, Pos>
    | SimulatorMoveToTask<Time, Pos>
    | SimulatorTakeOffTask<Time, Pos>
    | SimulatorLandingTask<Time, Pos>

export type SimulatorTasks = BaseSimulatorTasks<TimeInSeconds, DegreesPosition>

const posScratch = new Cartesian3()

export const getTimeByAction = (action: ActionData): TimeInSeconds => {
    switch (action.type.name) {
        case "gimbal-pitch-angle":
        case "gimbal-yaw-angle":
        case "aircraft-yaw":
        case "distance-interval-shot":
        case "timed-interval-shot":
        case "hover":
        case "panorama":
        case "take-photo":
        case "camera-zoom": return 5

        case "start-rec":
        case "stop-rec":
        default:
            return 0
    }
}

// TODO: ADD DELAY LATER
export const speedToSeconds = (speed: number, distance: number): number => distance / speed

// TODO: ONLY CESIUM FOR NOW
const distanceBetweenPoints = (left: DegreesPosition, right: DegreesPosition) => Math.abs(Cartesian3.magnitude(
    Cartesian3.subtract(
        pointToCartesian3(left),
        pointToCartesian3(right),
        posScratch,
    ),
))

export const buildSimulatorTasks = (startPoint: DegreesPosition, aboveStartPoint: DegreesPosition, waypoints: Waypoint[]): SimulatorTasks[] => {
    if (!waypoints || waypoints?.length === 0)
        return []

    const takeoffTime = speedToSeconds(5, distanceBetweenPoints(startPoint, aboveStartPoint))
    const takeoffTask: SimulatorTakeOffTask = {
        timeFromStart: takeoffTime,
        taskTime: takeoffTime,
        type: "Takeoff",
        from: startPoint,
        to: aboveStartPoint,
    }

    const [first] = waypoints

    const moveToFirstWaypointTime = speedToSeconds(10, distanceBetweenPoints(aboveStartPoint, first))
    const moveToFirstWaypointTask: SimulatorMoveToTask = {
        timeFromStart: moveToFirstWaypointTime + takeoffTime,
        from: aboveStartPoint,
        to: first,
        taskTime: moveToFirstWaypointTime,
        type: "MoveTo",
    }

    const result: SimulatorTasks[] = [takeoffTask, moveToFirstWaypointTask]

    let lastTime = takeoffTime + moveToFirstWaypointTime
    waypoints.forEach((curr, i, arr) => {
        const next = i + 1 <= arr.length - 1 ? arr[i + 1] : null

        curr.actions?.items?.forEach((x: ActionData) => {
            const actionTime = getTimeByAction(x)
            const newTime = actionTime + lastTime
            const task: SimulatorActionTask = {
                timeFromStart: newTime,
                taskTime: actionTime,
                type: "Action",
                action: x,
                waypoint: curr,
            }

            result.push(task)
            lastTime = newTime
        })

        if (next) {
            const distance = distanceBetweenPoints(curr, next)
            const taskTime = speedToSeconds(curr.speed ?? 1, distance)
            const newTime = taskTime + lastTime
            const task: SimulatorMoveToTask = {
                timeFromStart: newTime,
                taskTime,
                from: curr,
                to: next,
                type: "MoveTo",
            }
            result.push(task)
            lastTime = newTime
        }
    })

    const lastPoint = result[result.length - 1]
    const returnTask: SimulatorMoveToTask = {
        timeFromStart: lastTime + moveToFirstWaypointTime,
        from: lastPoint.to,
        to: aboveStartPoint,
        taskTime: moveToFirstWaypointTime,
        type: "MoveTo",
    }

    lastTime += moveToFirstWaypointTime
    result.push(returnTask)

    const landingTask: SimulatorLandingTask = {
        timeFromStart: takeoffTime + lastTime,
        taskTime: takeoffTime,
        type: "Landing",
        from: aboveStartPoint,
        to: startPoint,
    }

    result.push(landingTask)
    return result
}

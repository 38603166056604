import React, {Component, useCallback, useState} from "react"
import {withRouter, Route, Switch, Link, matchPath, NavLink} from "react-router-dom"
import {saveAs} from "file-saver"
import {H5, Icon, Spinner, Intent, Button} from "@blueprintjs/core"
import {inject, observer} from "mobx-react"
import {Tooltip2} from "@blueprintjs/popover2"
import {IconName, IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import axios, {CancelTokenSource} from "axios"
import {bytesHuman, formatDateTime, secondsToTime} from "@/Tools"
import api from "@/services/ApiService"
import type {RouteProps} from "@/types/RouteProps"
import {FileStages, FileTypes} from "@/model"
import type MissionFile from "@/model/MissionFile"
import type Mission from "@/model/Mission"
import {SelectionStore} from "@/store/SelectionStore"
import PlanStore from "@/store/PlanStore"
import {DroneListStore, UnitStores} from "@/store/DroneListStore"
import Navigator from "@/components/left/Navigator"
import "./MissionPanel.scss"
import userInfo from "@/store/UserInfoStore"
import LoaderComponent from "@/components/LoaderComponent"
import {stageDescription} from "@/model/Enums"
import {dangerToast, infoToast} from "@/components/AppToaster"
import gpsTrackLog from "@/store/GpsTrackStore"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import permissionsStore from "@/store/PermissionsStore"

function fileTooltip(file: MissionFile) {
    return (
        <>
            <div>{file.fileName}</div>
            {file.size && <div>{bytesHuman(file.size)}</div>}
            {file.dateTime && <div>{formatDateTime(file.dateTime)}</div>}
            {file.files && <div>Files: {file.files.length}</div>}
            {!!file.duration && <div>Duration: {secondsToTime(file.duration)}</div>}
        </>
    )
}

function fileTypeBadge(type: number): IconName {
    switch (type) {
        case FileTypes.IMAGE:
            return IconNames.MEDIA
        case FileTypes.VIDEO:
            return IconNames.VIDEO
        case FileTypes.ARCHIVE:
            return IconNames.ARCHIVE
        default:
            return IconNames.UNKNOWN_VEHICLE
    }
}

// TODO src /api/files/${file.id}/preview
function FilePreview(props: {file: MissionFile}) {
    const {file} = props

    switch (file.stage) {
        case FileStages.NEW:
            return <Icon icon={IconNames.CLOUD_UPLOAD} iconSize={40} className="file-icon" />
        case FileStages.UPLOADED:
            return <Icon icon={IconNames.COG} iconSize={40} className="file-icon" />
    }

    switch (file.type) {
        case FileTypes.VIDEO:
            return (
                <>
                    <img src={file.previewUrl} alt="" />
                    <NavLink to={file.url} target="_blank" rel="noreferrer"><Icon icon={fileTypeBadge(file.type)} className="media-badge" /></NavLink>
                    <NavLink to={file.url} target="_blank" rel="noreferrer" download><Icon icon={IconNames.DOWNLOAD} className="media-badge second" /></NavLink>
                </>
            )
        case FileTypes.IMAGE:
        case FileTypes.ARCHIVE:
            return (
                <>
                    <img src={file.previewUrl} alt="" />
                    <Icon icon={fileTypeBadge(file.type)} className="media-badge" />
                </>
            )

        default:
            return <Icon icon={IconNames.DOCUMENT} iconSize={40} className="file-icon" />
    }
}

// TODO href /api/files/${file.id}/content
@withRouter
@inject("selection")
class FileItem extends Component<{selection?: SelectionStore, file: MissionFile} & RouteProps> {
    render() {
        const {file, match} = this.props

        if (file.stage === FileStages.NEW || file.type === FileTypes.VIDEO) {
            return (
                <Tooltip2 content={fileTooltip(file)}>
                    <div className="file-item">
                        <div className="preview">
                            <FilePreview file={file} />
                        </div>
                        <div className="label">{file.fileName}</div>
                    </div>
                </Tooltip2>
            )
        }

        return file.type === FileTypes.ARCHIVE ? (
            <Tooltip2 content={fileTooltip(file)}>
                <Link
                    style={{width: 89}}
                    to={`${match.url}/file/${file.id}`}
                    onMouseOver={this.mouseOver}
                    onMouseOut={this.mouseOut}
                    className="file-item"
                >
                    <div className="preview">
                        <FilePreview file={file} />
                    </div>
                    <div className="label">{file.fileName}</div>
                </Link>
            </Tooltip2>
        ) : (
            <Tooltip2 content={fileTooltip(file)}>
                <a
                    href={file.url}
                    target="_blank"
                    rel="noreferrer"
                    onMouseOver={this.mouseOver}
                    onFocus={this.mouseOver}
                    onMouseOut={this.mouseOut}
                    onBlur={this.mouseOut}
                    className="file-item"
                >
                    <div className="preview">
                        <FilePreview file={file} />
                    </div>
                    <div className="label">{file.fileName}</div>
                </a>
            </Tooltip2>
        )
    }

    timeout = null

    mouseOver = () => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.props.selection.setFile(this.props.file), 100)
    }

    mouseOut = () => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.props.selection.setFile(null), 100)
    }
}

const FileItemDetail = withRouter((props) => {
    const {grouped, match} = props
    const {parentId} = props.match.params
    const childFiles = grouped.filter((files) => files.id === parentId)[0]
    const [loading, setLoading] = useState(false)
    const [downloadProgress, setProgress] = useState(0)
    const [downloadError, setError] = useState(false)

    const handleClick = useCallback(() => {
        if (loading)
            return

        setError(false)
        setLoading(true)
        api.downloadFile(childFiles.url, {
            onDownloadProgress: (e) => {
                setProgress(e.loaded / e.total)
            },
        }).then((x) => {
            if (x.status !== 200 && x.data == null) {
                setError(true)
                return
            }

            setError(false)

            saveAs(
                new Blob([x.data], {type: "text/plain;charset=utf-8"}),
                childFiles.fileName,
            )
        }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error("Unable to download file", err)
            setError(true)
        }).finally(() => {
            setLoading(false)
            setProgress(0)
        })
    }, [childFiles, loading])

    return (
        <>
            <Link to={`/unit/${match.params.unitId}/missions/${match.params.missionId}`}>{i18next.t("operator.mission-panel.file-item-detail.text.hide")}</Link>
            <p style={{marginTop: 10}}>{i18next.t("operator.mission-panel.file-item-detail.text.file-name")}</p>
            <p>{childFiles.fileName}</p>
            <p>
                <Button
                    onClick={handleClick}
                    style={{marginBottom: 5, width: "100%"}}
                    intent={downloadError ? Intent.DANGER : Intent.PRIMARY}
                    icon={!loading ? <Icon icon={IconNames.DOWNLOAD} /> : null}
                >
                    {!downloadError && loading && (
                        <Spinner className="white" value={downloadProgress} intent={Intent.PRIMARY} />
                    )}
                    {!downloadError && !loading && (
                        <>
                            {i18next.t("operator.mission-panel.file-item-detail.text.download")}
                            {" "}
                            {childFiles.files?.length}
                            {" "}
                            {i18next.t("operator.mission-panel.file-item-detail.text.files")}
                        </>
                    )}
                    {downloadError && !loading && i18next.t("operator.mission-panel.file-item-detail.text.download-error")}
                </Button>
            </p>
            <div className="file-list">
                {childFiles.files?.map((f) => <FileItem file={f} key={f.id} />)}
            </div>
        </>
    )
})

class FilesList extends Component<{files: MissionFile[]}> {
    state = {
        grouped: null,
    }

    componentDidMount() {
        const {files} = this.props
        const parents = {}
        const grouped = []

        files.forEach((f: MissionFile) => {
            if (f.parentId) {
                if (f.parentId in parents)
                    parents[f.parentId].files.push(f)
                else {
                    grouped.push(parents[f.parentId] = {
                        id: f.id,
                        url: `/api/files/${f.id}/zip`,
                        previewUrl: f.previewUrl,
                        fileName: `${f.fileName}.zip`,
                        type: FileTypes.ARCHIVE,
                        dateTime: f.dateTime,
                        files: [f],
                        lat: f.lat,
                        lon: f.lon,
                    })
                }
            } else
                grouped.push(f)
        })

        this.setState({grouped})
    }

    render() {
        const {grouped} = this.state
        if (!grouped)
            return <></>

        return (
            <>
                <H5>{i18next.t("operator.mission-panel.files-list.text.title")} {this.props.files.length}</H5>
                <div className="file-list">
                    {grouped.map((f) => <FileItem key={f.id} file={f} />)}
                </div>
                <Switch>
                    <Route path="/unit/:unitId/missions/:missionId/file/:parentId" exact>
                        <FileItemDetail grouped={grouped} />
                    </Route>
                </Switch>
            </>
        )
    }
}

type State = {
    mission: Mission
}

@withRouter
@inject("selection", "drones")
@observer
export default class MissionPanel extends LoaderComponent<{unit: UnitStores, drones?: DroneListStore, selection?: SelectionStore} & RouteProps<{missionId: string, unitId: string}>, State> {
    ctx: CancelTokenSource;

    state: State = {
        mission: null,
    }

    async prepare(): Promise {
        const droneId = this.props.selection?.drone?.droneId
        const stationId = this.props.selection?.station?.stationId
        this.ctx = axios.CancelToken.source()
        const mission = await api.mission.get(this.props.match.params.missionId)
        this.setState({mission})
        this.props.selection.setMission(mission)
        this.props.drones.showOnlySelected(droneId || stationId)
        this.props.selection.setPlan(new PlanStore(mission.plan, mission.homeLocation || this.stationPosition))

        if (!mission?.startTime)
            return

        if (permissionsStore.hasPermission(permissions.missionViewTrackLog)) {
            gpsTrackLog.setLoading(true)
            api.gpsTrackLog.get(mission.droneId, mission.startTime, mission.completed, this.ctx.token)
                .then((res) => {
                    if (!this.ctx.token.reason)
                        gpsTrackLog.init(res)
                }).finally(() => {
                    gpsTrackLog.setLoading(false)
                })
        }
    }

    get stationPosition() {
        const {drone, station} = this.props.unit
        if (station)
            return station.position
        return drone.stationPosition
    }

    get unitId() {
        const {drone, station} = this.props.unit
        if (station)
            return station.stationId
        return drone.droneId
    }

    componentWillUnmount() {
        if (this.ctx)
            this.ctx.cancel("Unmount")
        this.props.selection.setPlan(null)
        this.props.selection.setMission(null)
        this.props.drones.clearShowSelection()
        gpsTrackLog.reset()
    }

    startMission = () => {
        const {mission} = this.state
        api.mission.start(mission.id)
            .then((response) => {
                if (response.error) {
                    dangerToast(response.error)
                    return
                }

                infoToast(i18next.t("operator.plan-editor.toast.mission-started"))
                this.props.unit.drone.setMissionId(mission.id)
                this.props.history.push(`/unit/${mission.droneId}/info`)
            })
            .catch((error) => {
                dangerToast(i18next.t("operator.mission-panel.start-mission.error", error.response.data))
            })
    }

    successRender() {
        const {location} = this.props
        const match = matchPath(location.pathname, {
            path: "/unit/:unitId/missions/:missionId/stats",
            exact: true,
        })

        const showingStats = match != null

        const {mission} = this.state
        if (!mission)
            return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

		const id = this.props.match.params.unitId

        return (
            <Navigator backTitle={this.props.unit.displayName} title={mission.id} backUrl={`/unit/${id}/missions`}>
                <div className="bp3-text-small" style={{padding: 8, overflow: "auto"}}>
                    {mission.missionStage === 9 && <p><Button icon={IconNames.PLAY} minimal intent={Intent.SUCCESS} onClick={this.startMission} /></p>}
                    {userInfo.isAdmin && <p>{i18next.t("operator.mission-panel.text.id")}: {mission.id}</p>}
                    <p>{i18next.t("operator.mission-list.card.text.stage")}: {stageDescription[mission.missionStage]}</p>
                    <p>{i18next.t("operator.mission-panel.text.plan")}: <Link to={`/unit/${id}/plans/edit/${mission.plan.id}`}>{mission.plan.name}</Link></p>
                    <p>{i18next.t("operator.mission-panel.text.created")}: {formatDateTime(mission.created)}</p>
                    {mission.scheduledTime && <p>{i18next.t("operator.mission-panel.text.scheduled")}: {formatDateTime(mission.scheduledTime)}</p>}
                    {mission.startTime && <p>{i18next.t("operator.mission-panel.text.start-time")}: {formatDateTime(mission.startTime)}</p>}
                    {mission.completed && <p>{i18next.t("operator.mission-panel.text.end")}: {formatDateTime(mission.completed)}</p>}
                    <p>{mission.withStation ? i18next.t("operator.mission-panel.text.with-station") : i18next.t("operator.mission-panel.text.without-station")}</p>
                    {mission.failReason && <p>Fail reason: {mission.failReason}</p>}

                    {/* TODO: CHANGE TO THE missionStatisticsView */}

                    <PrivateComponent permission={permissions.chartsView}>
                        <Link to={`/unit/${mission.droneId}/missions/${mission.id}${showingStats ? "" : "/stats"}`}>
                            {showingStats && (<Button icon={<Icon icon={IconNames.MAP} />} style={{marginBottom: 10}}>
								{i18next.t("operator.mission-panel.text.show-map")}
							</Button>)}
                            {!showingStats && (<Button icon={<Icon icon={IconNames.TIMELINE_LINE_CHART} />} style={{marginBottom: 10}}>
								{i18next.t("operator.mission-panel.text.show-stats")}
							</Button>)}
                        </Link>
                    </PrivateComponent>
                    <PrivateComponent permission={permissions.missionFilesViewAndDownload}>
                        {mission.files && <FilesList unitId={this.unitId} files={mission.files} />}
                    </PrivateComponent>
                </div>
            </Navigator>
        )
    }
}

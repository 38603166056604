import {Button, Icon, Slider, Spinner} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import React from "react"
import {observer} from "mobx-react"
import gpsTrackLog, {GpsPositionDtoWithoutTime} from "@/store/GpsTrackStore"
import {reaction} from "mobx"
import "./TrackPlayer.scss"

const displayTime = (date: Date) => {
    if (date == null)
        return "0:00"

    const seconds = date.getSeconds()
    const minutes = date.getMinutes()
    const hours = date.getHours()

    return `${hours > 0 ? `${hours}:` : ""}${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`
}

type NextPayload = {back?: boolean; reset?: boolean} | null
function* indexMaker(length: number) {
    let index = 1
    while (true) {
        const payload: NextPayload = yield index
        if (payload?.back && index > 0)
            index -= 1

        if (index < length - 1 && !payload?.back)
            index += 1

        if (payload?.reset)
            index = 0
    }
}

const interpolateNumber = (a: number, b: number) => {
    const na = +a
    const nb = +b
    return (t: number) => na * (1 - t) + nb * t
}

function interpolateObject<T>(a: T = {}, b: T = {}): T {
    const aKeys = a ? Object.keys(a) : []
    const bKeys = b ? Object.keys(b) : []

    const keys = [...new Set([...aKeys, ...bKeys])]
    return (t: number) => {
        const result = {}
        keys.forEach((key) => {
            const ak = a[key]
            const bk = b[key]
            result[key] = ak && bk ? interpolateNumber(ak, bk)(t) : ak ?? bk
        })
        return result
    }
}

type State = {
    playing: boolean;
    speed: number;
    currentTime?: Date;
}

const speedValues = [1, 2, 4, 16, 32, 100]

@observer
export default class TrackPlayer extends React.Component<{missionStarted: Date, missionCompleted: Date }, State> {
    indexIterator = null
    nextIndex: IteratorResult<number>;
    prevFrameTimeMs: Date = null
    requestId: ?number;

    state = {
        playing: false,
        speed: 1,
    }

    componentDidMount() {
        reaction(() => gpsTrackLog.combined.slice(), (next) => {
            this.indexIterator = indexMaker(next.length)
            this.nextIndex = this.indexIterator.next()
            this.setState({
                playing: false,
                currentTime: new Date(gpsTrackLog.timeLine[0]),
            })
            if (this.requestId)
                window.cancelAnimationFrame(this.requestId)
        })
    }

    componentWillUnmount() {
        gpsTrackLog.reset()
        this.setState({
            playing: false,
        })
        if (this.requestId)
            window.cancelAnimationFrame(this.requestId)
    }

    mainLoop = () => {
        const {speed, playing, currentTime} = this.state

        if (!playing || gpsTrackLog.combined == null) {
            this.prevFrameTimeMs = null
            return
        }

        const [first] = gpsTrackLog.timeLine

        if (this.prevFrameTimeMs == null)
            this.prevFrameTimeMs = new Date()

        const diffBetweenFrames = new Date().getTime() - this.prevFrameTimeMs

        const msTime = currentTime ? diffBetweenFrames * speed + currentTime.getTime() : first

        if (this.nextIndex.value >= gpsTrackLog.combined.length - 1) {
            this.setState({
                currentTime: gpsTrackLog.totalTime,
                playing: false,
            })
            this.prevFrameTimeMs = null
            this.resetIterator()
            return
        }

        this.setClosestPosition(msTime)
        this.setState({
            currentTime: new Date(msTime),
        })
        this.prevFrameTimeMs = new Date()

        this.requestId = window.requestAnimationFrame(this.mainLoop)
    }

    setClosestPosition = (time: number, back = false) => {
        let currentPos: GpsPositionDtoWithoutTime = null

        const canGo = (firstTime, nextTime) => ((back ? firstTime < nextTime : firstTime >= nextTime))
        let pointsSkipped = 0
        let nextTime = gpsTrackLog.timeLine[this.nextIndex.value]
        if (!this.nextIndex.done) {
            while (canGo(time, nextTime)) {
                const next = this.indexIterator.next({back})
                if (next.value === this.nextIndex.value)
                    break

                this.nextIndex = next

                currentPos = gpsTrackLog.combined[next.value]
                nextTime = gpsTrackLog.timeLine[this.nextIndex.value]
                pointsSkipped += 1
            }
        }

        if (pointsSkipped > 2)
            gpsTrackLog.setCurrentPos(currentPos)
        else {
            const prevTime = gpsTrackLog.timeLine[this.nextIndex.value - 1]
            const ratio = (nextTime - time) / (nextTime - prevTime)
            const next = gpsTrackLog.combined[this.nextIndex.value]
            const prevEl = gpsTrackLog.combined[this.nextIndex.value - 1]
            const result = interpolateObject(prevEl, next)(1 - ratio)
            gpsTrackLog.setCurrentPos(result)
        }
    }

    handlePlayPause = () => {
        const playing = !this.state.playing
        this.setState(({currentTime}) => ({
            playing,
            currentTime: gpsTrackLog.totalTime === currentTime ? null : currentTime,
        }))

        if (playing)
            this.requestId = window.requestAnimationFrame(this.mainLoop)
    }

    resetIterator = () => {
        this.indexIterator.next({reset: true})
        this.nextIndex = this.indexIterator.next()
    }

    handleReset = () => {
        this.setState({
            playing: false,
            currentTime: new Date(gpsTrackLog.timeLine[0]),
        })
        gpsTrackLog.setCurrentPos(gpsTrackLog.combined[0])
        this.resetIterator()
    }

    handleSpeedClick = () => {
        const {speed} = this.state
        const index = speedValues.indexOf(speed)
        let nextIndex = 0
        if (index < speedValues.length - 1)
            nextIndex = index + 1

        this.setState({speed: speedValues[nextIndex]})
    }

    handleSliderChange = (x) => {
        const {playing} = this.state
        this.setClosestPosition(x, x < this.state.currentTime)
        this.setState({
            currentTime: new Date(x),
            playing: playing ? x !== 0 : playing,
        })
    }

    render() {
        const {playing, currentTime, speed} = this.state
        const {loading} = gpsTrackLog

        if (!loading && (gpsTrackLog.combined.length === 0 || !gpsTrackLog.combined))
            return <></>

        return (
            <div className={`player ${loading ? "loading" : ""}`}>
                <div className="loader">
                    {loading && <Spinner size={30} />}
                </div>
                <div className="controls">
                    <Button minimal onClick={this.handleReset} icon={<Icon icon={IconNames.STOP} color="#000" />} />
                    <Button minimal
                            onClick={this.handlePlayPause}
                            icon={<Icon icon={playing ? IconNames.PAUSE : IconNames.PLAY} color="#000" />}
                            className={`play ${playing ? "active" : ""}`}
                    />
                    <Button minimal onClick={this.handleSpeedClick} color="#000">{speed}x</Button>
                </div>
                <div className="bar">
                    <div className="text" style={{marginRight: "15px"}}>
                        {displayTime(new Date(this.props.missionStarted.valueOf() + (currentTime?.valueOf() || 0)))}
                    </div>
                    <div className="slider">
                        <Slider min={new Date(gpsTrackLog.timeLine[0] ?? 0).getTime()} max={gpsTrackLog.totalTime?.getTime()} onChange={this.handleSliderChange} value={currentTime?.getTime()} labelRenderer={false} />
                    </div>
                    <div className="text">
                        {displayTime(this.props.missionCompleted)}
                    </div>
                </div>
            </div>
        )
    }
}

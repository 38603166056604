import JoystickControls from "./JoystickControls.json"
import DroneControl from "./DroneControl.json"
import JoystickSettings from "./JoystickSettings.json"
import CameraControl from "./CameraControl.json"

const Control = {
    ...JoystickControls,
    ...DroneControl,
    ...JoystickSettings,
    ...CameraControl,
}
export default Control

import {action, makeObservable, observable, IObservableArray} from "mobx"
import type {ChartSettingsDto} from "@/model/ChartDto"

export class ChartsStore {
    constructor() {
        makeObservable(this)
    }

    @observable loading: boolean
    @observable chartSettings: IObservableArray<ChartSettingsDto> = []

    @observable floatingCharts: Map<string, boolean> = new Map();

    isChartVisible(chartName: string) {
        return this.floatingCharts.get(chartName) ?? false
    }

    // MOVE TO IDS LATER
    @action showChart(name: string) {
        this.floatingCharts.set(name, true)
    }

    @action hideChart(name: string) {
        this.floatingCharts.set(name, false)
    }

    @action setLoading(val: boolean) {
        this.loading = val
    }

    @action setSettings(arr: ChartSettingsDto[]) {
        this.chartSettings.replace(arr)
        this.loading = false
    }

    get getChartSettings() {
        return this.chartSettings.slice()
    }
}

const charts = new ChartsStore()
export default charts

export default {
    missionRead: "missions:read",
    missionCanStart: "missions:start",
    missionCanStop: "missions:stop",
    missionFilesViewAndDownload: "missions:files:view",
    missionStatisticsView: "missions:stats:view",
    missionViewTrackLog: "missions:tracks:view",
    missionCanRequest: "missions:request:create",
    missionRequestCanApprove: "missions:request:approve",
    planRead: "plans:read",
    planWrite: "plans:write",
    cameraView: "cameras:view",
    cameraControl: "cameras:control",
    cameraCanRequestControl: "cameras:request:create",
    cameraCanGrantControl: "cameras:request:grant",
    chartsView: "charts:view",
    droneFlyControl: "drones:control",
    droneWrite: "drones:write",
    droneCanExecute: "drones:execute",
    droneReattach: "drones:reattach_files",
    droneRtk: "drones:rtk",
    userConsumerWrite: "users:consumers:write",
    userFullWrite: "users:all:write",
    stationsWrite: "stations:write",
    stationsReadAll: "stations:view",
    stationCanExecute: "stations:execute",
    profilesReadWrite: "users:profiles:read_write",
    accessAll: "common:all",
}

import React, {Component} from "react"
import {Button, Menu, MenuItem, Popover, PopoverPosition} from "@blueprintjs/core"
import i18next from "i18next"
import api from "@/services/ApiService"
import {infoToast} from "@/components/AppToaster"

const SnowMeltingCommands = [
    "ManualOn",
    "ManualOff",
    "Auto",
    "Disabled",
]

export default class SnowMeltingControl extends Component<{stationId: string}> {
    render() {
        const menu = (
            <Menu>
                {SnowMeltingCommands.map((c) => (
                    <MenuItem key={c}
                              text={c}
                              onClick={() => this.command(c)}
                    />
                ))}
            </Menu>
        )

        return (
            <>
                <Popover content={menu} position={PopoverPosition.LEFT_BOTTOM} modifiers={{arrow: {enabled: false}}}>
                    <Button text={i18next.t("operator.snow-melting-control.button.command")} />
                </Popover>
            </>
        )
    }

    command(cmd: string) {
        api.station.snowMeltingCommand(this.props.stationId, cmd)
            .then(() => infoToast("Command is send"))
    }
}

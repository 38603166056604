import React from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Intent, Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import api from "@/services/ApiService"
import {AdminStationsStore} from "@/store/AdminStationsStore"
import AppToaster, {infoToast} from "@/components/AppToaster"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"

import "./Admin.scss"

@withRouter
@inject("adminStations")
@observer
export default class StationsList extends LoaderComponent<{adminStations?: AdminStationsStore} & RouteProps, {disabled: boolean }> {
    state = {
        disabled: false,
    }

    prepare(): Promise {
        return api.adminStations.list()
            .then((list) => { this.props.adminStations.setAdminStationsList(list) })
    }

    render() {
        const {disabled} = this.state
        return (
            <>
                <p><Button icon={IconNames.ADD} text={i18next.t("admin.stations-list.button.add-station")} minimal onClick={this.create} intent={Intent.PRIMARY} /></p>
                <div className="admin-panel">
                    <div className="admin-table-wrapper">
                        <table
                            className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped"
                            style={{border: "1px solid #ccc"}}
                        >
                            <thead>
                                <tr>
                                    <th>{i18next.t("admin.stations-list.text.name")}</th>
                                    <th>{i18next.t("admin.stations-list.text.station")}</th>
                                    <th>{i18next.t("admin.stations-list.text.edit")}</th>
                                    <th>{i18next.t("admin.stations-list.text.delete")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.adminStations.adminStationsList.map((s) => (
                                    <tr key={s.id}>
                                        <td>{s.name}</td>
                                        <td>{s.stationId}</td>
                                        <td><Button icon={IconNames.EDIT} minimal onClick={() => this.edit(s.id)} loading={disabled} intent={Intent.PRIMARY} /></td>
                                        <td><Button icon={IconNames.TRASH} minimal onClick={() => this.delete(s.id)} loading={disabled} intent={Intent.DANGER} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    delete = (id: string) => {
        const station = this.props.adminStations.getAdminStationById(id)
        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                this.setState({disabled: true})
                api.adminStations.delete(station.stationId)
                    .then(() => {
                        infoToast(i18next.t("admin.stations-list.toast.station-deleted"))
                        this.props.adminStations.removeAdminStation(station.id)
                    })
                    .finally(() => this.setState({disabled: false}))
            },
        }
        AppToaster.show({
            message: i18next.t("admin.stations-list.toast.show.station-delete", {
                name: station.name,
            }),
            action,
            timeout: 10000,
            intent: Intent.DANGER,
        })
    }

    edit = (stationId: string) => this.props.history.push(`${this.props.match.url}/${stationId}`)
    create = () => this.props.history.push(`${this.props.match.url}/create`)
}

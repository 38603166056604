import {makeObservable, observable, action, set, IObservableArray} from "mobx"
import api from "@/services/ApiService"
import type DroneState from "@/model/DroneState"
import type Plan from "@/model/Plan"
import type {DroneInfo} from "@/model/DroneInfo"
import type Mission from "@/model/Mission"
import type {StationPosition} from "@/model/Station"
import PlanStore from "@/store/PlanStore"
import StationStore from "@/store/StationStore"

export const PARAM_TIMEOUT_MS = 5 * 1000

export default class DroneStore {
    droneId: string
    @observable name: string
    @observable withStation: boolean
    @observable missionApprovement: boolean
    @observable rtk: boolean
    @observable params: ?DroneState = {}
    @observable paramsTimeout = {}
    paramsUpdTime = {}
    sshPort: string
    cameras: string[]

    @observable currentMissionId: string
    @observable currentMission: Mission
    @observable currentPlan: PlanStore

    @observable plans: IObservableArray<PlanStore> = []
    station: StationStore

    constructor(info: DroneInfo) {
        this.droneId = info.droneId
        this.name = info.name
        this.currentMissionId = info.currentMissionId
        this.withStation = info.withStation
        this.rtk = info.rtk
        this.sshPort = info.sshPort
        this.cameras = info.cameras ?? []
        this.missionApprovement = info.missionApprovement
        makeObservable(this)
    }

    get displayName() {
        return this.name ? this.name : this.droneId
    }

    get stationPosition(): StationPosition {
        if (this.station)
            return this.station.position
        return null
    }

    @action setParams(params: DroneState) {
        set(this.params, params)
        Object.keys(params).forEach((k) => {
            this.paramsUpdTime[k] = Date.now()
            this.paramsTimeout[k] = false
        })
    }

    @action checkTimeout() {
        const now = Date.now()
        Object.entries(this.paramsUpdTime).forEach(([k, v]) => {
            if (now - v > PARAM_TIMEOUT_MS)
                this.paramsTimeout[k] = true
        })
    }

    prepareMission() {
        if (this.currentMissionId && !this.currentMission)
            api.mission.get(this.currentMissionId).then((mission) => this.setCurrentMission(mission))
        else
            this.setCurrentMission(null)
    }

    @action setMissionId(id: string) {
        this.currentMissionId = id
        if (id) {
            return api.mission.get(id).then((mission) => {
                this.setCurrentMission(mission)
                return this
            })
        }
        this.setCurrentMission(null)
        this.currentPlan = null
        return Promise.resolve(null)
    }

    @action setCurrentMission(mission: Mission) {
        this.currentMission = mission
        if (mission)
            this.currentPlan = new PlanStore(mission.plan, this.stationPosition)
        else
            this.currentPlan = null
    }

    @action setWithStation(value: boolean) {
        this.withStation = value
    }

    @action setMissionApprovement(value: boolean) {
        this.missionApprovement = value
    }

    @action setRTK(value: boolean) {
        this.rtk = value
    }

    @action setName(name: string) {
        this.name = name
    }

    @action setPlans(plans: Plan[]) {
        this.plans.replace(plans.map((p) => new PlanStore(p, this.stationPosition)))
    }

    @action addPlan(planStore: PlanStore): PlanStore {
        const ps = new PlanStore()
        ps.copyFrom(planStore)
        this.plans.push(ps)
        return ps
    }

    @action deletePlan(planStore: PlanStore) {
        this.plans.remove(planStore)
    }
}

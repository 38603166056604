import type Mission from "@/model/Mission"
import HttpService from "@/services/HttpService"
import type Route from "@/model/Route"
import type Waypoint from "@/model/Waypoint"

const baseUrl = "/mission"

export default class MissionService {
    constructor(api: HttpService) {
        this.api = api
    }

    get(missionId: string): Promise<Mission> {
        return this.api.get(`${baseUrl}/${missionId}`)
    }

    start(missionId: string): Promise {
        return this.api.post(`${baseUrl}/${missionId}/start`)
    }

    startAnonymous(droneId: string, stationId: string, name: string, route: Route, points: Waypoint[]) {
        return this.api.post(`${baseUrl}/start-anonymous`, {
            droneId, stationId, name, route, points,
        })
    }
}

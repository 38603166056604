import {coerceNumber} from "@visx/scale"

const MEASUREMENT_ELEMENT_ID = "__react_svg_text_measurement_id"
export function getStringWidth(str: string, style?: any) {
    try {
        // Calculate length of each word to be used to determine number of words per line
        let textEl = document.getElementById(MEASUREMENT_ELEMENT_ID)
        if (!textEl) {
            const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
            svg.style.width = "0"
            svg.style.height = "0"
            svg.style.position = "absolute"
            svg.style.top = "-100%"
            svg.style.left = "-100%"
            textEl = document.createElementNS("http://www.w3.org/2000/svg", "text")
            textEl.setAttribute("id", MEASUREMENT_ELEMENT_ID)
            svg.appendChild(textEl)
            document.body.appendChild(svg)
        }

        Object.assign(textEl.style, style)
        textEl.textContent = str
        return textEl.getComputedTextLength()
    } catch (e) {
        return null
    }
}

export const getTicks = (x: number) => Math.round(x / 100)

const timeFormats = {
    second: "HH:mm:ss",
    minute: "HH:mm",
    hour: "MM/DD HH:mm",
    day: "MM/DD",
    month: "YYYY-MM",
    year: "YYYY",
}

export const graphTimeFormat = (ticks: number | null, range: number | null): string => {
    if (range && ticks) {
        const secPerTick = range / ticks / 1000
        // Need have 10 millisecond margin on the day range
        // As sometimes last 24 hour dashboard evaluates to more than 86400000
        const oneDay = 86400010
        const oneYear = 31536000000

        if (secPerTick <= 45)
            return timeFormats.second

        if (range <= oneDay)
            return timeFormats.minute

        if (secPerTick <= 80000)
            return timeFormats.hour

        if (range <= oneYear)
            return timeFormats.day

        if (secPerTick <= 31536000)
            return timeFormats.month

        return timeFormats.year
    }

    return timeFormats.minute
}

export type TimeRange = {
    from: Date;
    to: Date;
}

export function roundInterval(interval: number) {
    switch (true) {
        // 0.015s
        case interval < 15:
            return 10 // 0.01s
        // 0.035s
        case interval < 35:
            return 20 // 0.02s
        // 0.075s
        case interval < 75:
            return 50 // 0.05s
        // 0.15s
        case interval < 150:
            return 100 // 0.1s
        // 0.35s
        case interval < 350:
            return 200 // 0.2s
        // 0.75s
        case interval < 750:
            return 500 // 0.5s
        // 1.5s
        case interval < 1500:
            return 1000 // 1s
        // 3.5s
        case interval < 3500:
            return 2000 // 2s
        // 7.5s
        case interval < 7500:
            return 5000 // 5s
        // 12.5s
        case interval < 12500:
            return 10000 // 10s
        // 17.5s
        case interval < 17500:
            return 15000 // 15s
        // 25s
        case interval < 25000:
            return 20000 // 20s
        // 45s
        case interval < 45000:
            return 30000 // 30s
        // 1.5m
        case interval < 90000:
            return 60000 // 1m
        // 3.5m
        case interval < 210000:
            return 120000 // 2m
        // 7.5m
        case interval < 450000:
            return 300000 // 5m
        // 12.5m
        case interval < 750000:
            return 600000 // 10m
        // 12.5m
        case interval < 1050000:
            return 900000 // 15m
        // 25m
        case interval < 1500000:
            return 1200000 // 20m
        // 45m
        case interval < 2700000:
            return 1800000 // 30m
        // 1.5h
        case interval < 5400000:
            return 3600000 // 1h
        // 2.5h
        case interval < 9000000:
            return 7200000 // 2h
        // 4.5h
        case interval < 16200000:
            return 10800000 // 3h
        // 9h
        case interval < 32400000:
            return 21600000 // 6h
        // 1d
        case interval < 86400000:
            return 43200000 // 12h
        // 1w
        case interval < 604800000:
            return 86400000 // 1d
        // 3w
        case interval < 1814400000:
            return 604800000 // 1w
        // 6w
        case interval < 3628800000:
            return 2592000000 // 30d
        default:
            return 31536000000 // 1y
    }
}

export const calcMsRange = (start: Date, end: Date) => end.valueOf() - start.valueOf()

export function calculateInterval(msRange: number, resolution: number): number {
    const lowLimitMs = 10 // 10 milliseconds default low limit

    let intervalMs = roundInterval(msRange / resolution)
    if (lowLimitMs > intervalMs)
        intervalMs = lowLimitMs

    return intervalMs
}

export const getMinMax = (values: (number | { valueOf(): number })[]) => {
    const numericValues = values.map(coerceNumber)
    return [Math.min(...numericValues), Math.max(...numericValues)]
}

const calcDelta = (min: number, max: number, ticksLength: number) => (((max - min) / ticksLength) === Infinity ? (max / ticksLength - min / ticksLength) : (max - min) / ticksLength)

const floorInBase = (n: number, base: number) => base * Math.floor(n / base)

export const fixupNumberOfTicks = (axisSize: number) => 0.3 * Math.sqrt(axisSize)

const saturate = (a: number) => {
    if (a === Infinity)
        return Number.MAX_VALUE

    if (a === -Infinity)
        return -Number.MAX_VALUE

    return a
}

const multiplyAdd = (a: number, bInt: number, c: number) => {
    if (Number.isFinite(a * bInt))
        return saturate(a * bInt + c)

    let result = c

    for (let i = 0; i < bInt; i++)
        result += a

    return saturate(result)
}

const autoScale = (min: number, max: number) => {
    const delta = saturate(max - min)
    const margin = 0.02

    let scaledMin = saturate(min - delta * margin)
    const scaledMax = saturate(max + delta * margin)

    // make sure we don't go below zero if all values are positive
    if (scaledMin < 0 && min >= 0)
        scaledMin = 0

    return [scaledMin, scaledMax]
}

const generateTicks = (min: number, max: number, tickSize: number) => {
    const ticks = []
    let start = saturate(floorInBase(min, tickSize))
    let i = 0
    let v = Number.NaN
    let prev

    if (start === -Number.MAX_VALUE) {
        ticks.push(start)
        start = floorInBase(min + tickSize, tickSize)
    }

    do {
        prev = v
        // v = start + i * axis.tickSize;
        v = multiplyAdd(tickSize, i, start)
        ticks.push(v)
        i += 1
    } while (v < max && v !== prev)

    return ticks
}

const calcTickSize = (min: number, max: number, ticksLength: number) => {
    const delta = calcDelta(min, max, ticksLength)
    const dec = -Math.floor(Math.log(delta) / Math.LN10)

    const magn = parseFloat(`1e${-dec}`)
    const norm = delta / magn // norm is between 1.0 and 10.0
    let size

    if (norm < 1.5)
        size = 1
    else if (norm < 3) {
        size = 2
        if (norm > 2.25)
            size = 2.5
    } else if (norm < 7.5)
        size = 5
    else
        size = 10

    size *= magn
    return size
}

export const calcLinearDomain = (data: number[]) => {
	if (!data || data.length === 0 || data.length === null) return [-1, 1]

	const [dataMin, dataMax] = getMinMax(data)
	const [scaleMin, scaleMax] = autoScale(dataMin, dataMax)

	if (scaleMax === scaleMin) {
		const avg = 1
		return [scaleMax - avg, scaleMin + avg]
	}

	return [scaleMin, scaleMax]
}
import {Button,
    Card,
    Classes,
    EditableText,
    H2,
    H3,
    Icon,
    Intent,
    Menu,
    MenuItem,
    Overlay,
    Popover,
    PopoverPosition,
    Position,
    ProgressBar,
    Switch,
    Tooltip} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {Tooltip2} from "@blueprintjs/popover2"
import i18next from "i18next"
import {reaction} from "mobx"
import {inject, observer} from "mobx-react"
import React, {Component, useCallback, VFC} from "react"
import CameraButton from "@/components/left/CameraButton"
import UploadProgress from "@/components/left/UploadProgress"
import DroneConnection from "@/connection/DroneConnection"
import {DisplayModes, WaypointStates} from "@/model"
import {DroneCommands} from "@/model/DroneCommands"
import type DroneState, {DownloadParam} from "@/model/DroneState"
import {batteryStateSelfCheckError, flightStatusDescription} from "@/model/Enums"
import api from "@/services/ApiService"
import {CameraStore} from "@/store/CameraStore"
import charts from "@/store/ChartsStore"
import DroneStore from "@/store/DroneStore"
import {JoystickStore} from "@/store/JoystickStore"
import userInfo from "@/store/UserInfoStore"
import {bytesHuman} from "@/Tools"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import roles from "@/authorization/roles"
import permissionsStore from "@/store/PermissionsStore"

function batteryTooltip(drone: DroneStore) {
    if (!drone.params.battery_info)
        return null

    const {battery1, battery2} = drone.params.battery_info

    return (
        <>
            {drone.params.battery_info && (
                <>
                    <p>{i18next.t("operator.drone-params.battery-info.text.general")}</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.discharge-rate")} {(drone.params.battery_info.capacityConsumeSpeed * 3600).toFixed(2)} {i18next.t("operator.drone-params.battery-info.text.mah")}</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.battery-1")}</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.current-charge")} {battery1.batteryCapacityPercent} %</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.temperature")} {battery1.batteryTemperature / 10} ℃</p>
                    <p>{batteryStateSelfCheckError(battery1.batteryState.selfCheckError)}</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.battery-2")}</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.current-charge")} {battery2.batteryCapacityPercent} %</p>
                    <p>{i18next.t("operator.drone-params.battery-info.text.temperature")} {battery2.batteryTemperature / 10} ℃</p>
                    <p>{batteryStateSelfCheckError(battery2.batteryState.selfCheckError)}</p>
                </>
            )}
        </>
    )
}

const Warn = ({text}: {text: string}) => <Tooltip content={text} position={Position.BOTTOM_RIGHT}><Icon icon={IconNames.WARNING_SIGN} intent={Intent.DANGER} /></Tooltip>

/* eslint-disable no-bitwise */
function FlightAnomaly({flight_anomaly}: {flight_anomaly: number}) {
    return (
        <>
            {((flight_anomaly & 1) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-1")} />}
            {((flight_anomaly & 2) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-2")} />}
            {((flight_anomaly & 4) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-4")} />}
            {((flight_anomaly & 8) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-8")} />}
            {((flight_anomaly & 0x10) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x10")} />}
            {/* ((flight_anomaly & 0x20) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x20")} /> */}
            {((flight_anomaly & 0x40) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x40")} />}
            {((flight_anomaly & 0x80) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x80")} />}
            {((flight_anomaly & 0x100) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x100")} />}
            {((flight_anomaly & 0x200) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x200")} />}
            {((flight_anomaly & 0x400) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x400")} />}
            {((flight_anomaly & 0x800) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x800")} />}
            {((flight_anomaly & 0x1000) > 0) && <Warn text={i18next.t("operator.drone-params.warn.text-0x1000")} />}
        </>
    )
}
/* eslint-enable no-bitwise */

function MissionState({p}: {p: DroneState}) {
    if (p.display_mode === DisplayModes.ON_MISSION && p.wp_state !== undefined)
        return <> - {WaypointStates.description(p.wp_state)}</>
    return <></>
}

function DownloadProgress(props: {value: DownloadParam}) {
    const {value} = props
    if (!value || value.df === value.tf)
        return React.Fragment

    const db = value.db + value.cb
    const total = db / value.tb
    const file = value.cb / value.cz

    return (
        <>
            <p>{i18next.t("operator.drone-params.text.downloaded")} {value.df} ({bytesHuman(db)}) {i18next.t("operator.drone-params.text.of")} {value.tf} ({bytesHuman(value.tb)})</p>
            {!!value.ff && <p>{i18next.t("operator.drone-params.text.failed")} {value.ff}</p>}
            <ProgressBar value={total} intent={Intent.PRIMARY} />

            <p>{value.file}</p>
            <p>{bytesHuman(value.cb)} {i18next.t("operator.drone-params.text.of")} {bytesHuman(value.cz)} {Math.round(value.speed)}kB/s</p>
            <ProgressBar value={file} intent={Intent.PRIMARY} />
        </>
    )
}

const DroneContext = React.createContext()

@observer
class DroneParameter extends Component<{param: string, children: React.ReactNode, renderRight?: React.ReactNode}> {
    static contextType = DroneContext
    render() {
        const drone = this.context
        if (drone.params[this.props.param] === undefined)
            return <></>

        const cl = drone.paramsTimeout[this.props.param] ? "param-timeout" : ""
        return (
            <div className={`${this.props.renderRight ? "flex vertical " : ""}param ${cl}`}>
                {this.props.children}
                {this.props.renderRight && this.props.renderRight}
            </div>
        )
    }
}

@inject("dronesConn")
@observer
class DroneParameterBattery extends Component<{dronesConn?: DroneConnection, children?: React.ReactNode, renderRight?: React.ReactNode}> {
    static contextType = DroneContext

    render() {
        const drone = this.context
        if (drone.params.batt === undefined)
            return <></>

        const cl = drone.paramsTimeout.batt ? "param-timeout" : ""
        return (
            <div className={`flex vertical param ${cl}`}>
                <Tooltip2 content={batteryTooltip(drone)}>
                    <span
                         onMouseOver={this.getBatteryInfo}
                         onFocus={this.getBatteryInfo}
                    >
                        {this.props.children}
                    </span>
                </Tooltip2>
                {this.props.renderRight && this.props.renderRight}
            </div>
        )
    }

    getBatteryInfo = () => {
        const drone = this.context
        this.props.dronesConn.getBatteryInfoCommand(drone.droneId).then()
    }
}

const ShowChartButton: React.VFC<{chartName: string}> = ({chartName}) => {
    const handleClick = useCallback(() => charts.showChart(chartName), [chartName])
    return (
        <Button
                minimal
                onClick={handleClick}
                rightIcon={<Icon icon={IconNames.TIMELINE_LINE_CHART} />}
                small
        >
            stats
        </Button>
    )
}

@observer
export class DroneParamsValues extends Component<{drone: DroneStore}> {
    render() {
        const {drone} = this.props
        const p = drone.params

        return (
            <DroneContext.Provider value={drone}>
                <p>{i18next.t("operator.drone-params.text.serial")} {drone.droneId}</p>
                <PrivateComponent role={roles.admin}>
                    <p>SSH: {drone.sshPort}</p>
                </PrivateComponent>

                <DroneParameter param="display_mode">{DisplayModes.description(p.display_mode)}<MissionState p={p} /></DroneParameter>
                <DroneParameter param="flight_anomaly"><FlightAnomaly flight_anomaly={p.flight_anomaly} /></DroneParameter>
                {p.gps && <DroneParameter param="gps">{i18next.t("operator.drone-params.text.gps")} {Math.round(p.gps.alt * 10) / 10}</DroneParameter>}
                <DroneParameter param="height_above_takeoff">{i18next.t("operator.drone-params.text.height-above-takeoff")} {Math.round(p.height_above_takeoff * 10) / 10}</DroneParameter>
                <DroneParameter param="gps_health">{i18next.t("operator.drone-params.text.gps-health")} {p.gps_health}</DroneParameter>

                <DroneParameterBattery
                    renderRight={(
                        <PrivateComponent permission={permissions.chartsView}>
                            <ShowChartButton chartName="battery" />
                        </PrivateComponent>
                      )}
                >
                    {i18next.t("operator.drone-params.text.battery")} {p.batt}%
                </DroneParameterBattery>

                <DroneParameter param="flight_status">{i18next.t("operator.drone-params.text.flight-status")} {i18next.t(flightStatusDescription[p.flight_status])}</DroneParameter>
                <DroneParameter
                    param="lander_markers"
                    renderRight={(
                        <PrivateComponent permission={permissions.chartsView}>
                            <ShowChartButton chartName="markers" />
                        </PrivateComponent>
                  )}
                >
                    {i18next.t("operator.drone-params.text.lander-markers")} {p.lander_markers}
                </DroneParameter>

                <DroneParameter param="mission_step">{i18next.t("operator.drone-params.text.mission-step")} {p.mission_step}</DroneParameter>
                <DroneParameter param="mission_action">{p.mission_action}</DroneParameter>
                <DroneParameter param="mission_stage">{i18next.t("operator.drone-params.text.mission-stage")}{p.mission_stage}</DroneParameter>
                {p.display_mode === DisplayModes.ON_MISSION && <DroneParameter param="wp_vel">{i18next.t("operator.drone-params.text.wp-vel")} {p.wp_vel / 100}</DroneParameter>}
                <DroneParameter param="download"><DownloadProgress value={p.download} /></DroneParameter>
                <DroneParameter param="upload_percent"><UploadProgress params={p} /></DroneParameter>
            </DroneContext.Provider>
        )
    }
}

const DroneControlButtons: VFC<{drone: DroneStore, dronesConn: DroneConnection, joystick?: JoystickStore}> = ({drone, dronesConn, joystick}) => {
    const handleClick = useCallback(() => {
        joystick?.activate(drone)
    }, [joystick, drone])

    return (
        <p className="buttons-margin">{i18next.t("operator.drone-params.text.control")}
            <Button text={i18next.t("operator.drone-params.button.joystick")} onClick={handleClick} />
            <Popover content={(
                <Menu>
                    {DroneCommands(drone.droneId).map((t) => (
                        <MenuItem key={t.name}
                                  text={t.name}
                                  onClick={() => t.execute(dronesConn, drone.droneId).then()}
                        />
                    ))}
                </Menu>
            )}
                     position={PopoverPosition.LEFT_BOTTOM}
                     modifiers={{arrow: {enabled: false}}}
            >
                <Button text={i18next.t("operator.drone-params.popover.command")} />
            </Popover>
        </p>
    )
}

type States = {
    isOpen: boolean,
    calibrationResult: string
}

type Props = {
    drone: DroneStore,
    cameras?: CameraStore,
    joystick?: JoystickStore,
    dronesConn?: DroneConnection,
}

@inject("cameras", "joystick", "dronesConn")
@observer
export class DroneParams extends Component<Props, States> {
    state = {
        isOpen: false,
        calibrationResult: null,
    }

    componentDidMount() {
        reaction(() => this.props.drone.params.calibration_result,
            (calibration_result) => {
                this.setState({calibrationResult: calibration_result.result})
            })
    }

    render() {
        const {drone, cameras} = this.props

        return (
            <div className={Classes.TEXT_SMALL}>
                <H2>
                    <PrivateComponent
                        permission={permissions.droneWrite}
                        fallback={drone.name}
                    >
                        <EditableText placeholder={i18next.t("operator.drone-params.drone.placeholder")} defaultValue={drone.name} onConfirm={this.rename} />
                    </PrivateComponent>
                </H2>

                <PrivateComponent permission={permissions.droneWrite}>
                    <p>
						{i18next.t("operator.drone-params.text.station")}
						<Switch checked={drone.withStation} onChange={this.switchStationUsage} inline />
					</p>
                </PrivateComponent>

                <PrivateComponent permission={permissions.missionCanStart}>
                    <p>
                        {i18next.t("operator.drone-params.text.mission-approvement")}
                        <Switch checked={drone.missionApprovement} onChange={this.switchMissionApprovement} inline />
                    </p>
                </PrivateComponent>

                <PrivateComponent permission={permissions.droneRtk}>
                    <p className="flex">
						RTK:
						<Switch checked={drone.rtk} onChange={this.switchRTK} inline />
                        <span>{drone.params.rtk_connection_status ? <Icon className="ws-icon" icon={IconNames.RECORD} iconSize={10} color="#008000" /> : <Icon className="ws-icon" icon={IconNames.RECORD} iconSize={10} color="#808080" />}</span>
                    </p>
                </PrivateComponent>

                <DroneParamsValues drone={drone} />

                <p className="buttons-margin">
                    <PrivateComponent permission={permissions.chartsView}>
                        <Button icon={IconNames.SHARE} text="Grafana" onClick={(e) => this.clickOnLinkToGrafana(e)} minimal />
                    </PrivateComponent>
                </p>

                <PrivateComponent permission={permissions.cameraView}>
                    <p className="buttons-margin">{i18next.t("operator.drone-params.text.cameras")}
                        <Button text="FPV" icon={drone.params.fpv_camera_activity ? IconNames.RECORD : undefined} onClick={this.showFPV} />
                        {drone.cameras.map((c) => <CameraButton key={c} name={c} drone={drone} cameras={cameras} />)}
                        {userInfo.isAdmin && <Button text={i18next.t("operator.drone-params.button.landing")} icon={drone.params.landing_camera_activity ? IconNames.RECORD : undefined} onClick={this.showLanding} />}
                    </p>
                </PrivateComponent>

                <PrivateComponent permission={permissions.droneFlyControl}>
                    <DroneControlButtons drone={drone} dronesConn={this.props.dronesConn} joystick={this.props.joystick} />
                </PrivateComponent>

                {flightStatusDescription[drone.params.flight_status] === "Stopped" && (
                    <p>{i18next.t("operator.drone-params.text.camera")} <Button text={i18next.t("operator.drone-params.button.calibration")} onClick={this.startCalibration} /></p>
                )}
                <Overlay isOpen={this.state.isOpen} onClose={this.closeWindow} usePortal hasBackdrop enforceFocus>
                    <Card className="centered">
                        <H3>{i18next.t("operator.drone-params.calibration.title")}</H3>
                        <p>{this.state.calibrationResult ? this.state.calibrationResult : i18next.t("operator.drone-params.calibration.loading")}</p>
                        <br />
                        <div>
                            <Button onClick={this.closeWindow}>{i18next.t("operator.drone-params.calibration.close")}</Button>
                        </div>
                    </Card>
                </Overlay>

                {drone.currentMission && (
                    <p>
                        <Button
                            intent={Intent.WARNING}
                            text={i18next.t("operator.drone-params.button.cancel-mission")}
                            onClick={this.cancelMission}
                            disabled={!permissionsStore.hasPermission(permissions.missionCanStop)}
                        />
                        {drone.params.wp_curr !== undefined && <span>{drone.params.wp_curr} / {drone.currentMission.pointsCount}</span>}
                    </p>
                )}
                {!drone.currentMission && drone.params.mission_step !== undefined && drone.params.mission_step !== "DispatcherControlStep" && (
                    <p>
                        <Button
                            intent={Intent.WARNING}
                            text={i18next.t("operator.drone-params.button.cancel-step")}
                            // TODO CHANGE TO THE missionCanStop
                            disabled={!permissionsStore.hasPermission(permissions.droneCanExecute)}
                            onClick={this.stopMission}
                        />
                    </p>
                )}
                <hr />
            </div>
        )
    }

    clickOnLinkToGrafana = () => {
        window.open(process.env.NODE_ENV === "development" ? `https://grafana.drone-test.aniklab.com/d/r1sYiaJMk/drone?orgId=1&var-drone=${this.props.drone.droneId}` : `https://grafana.${window.location.host}/d/r1sYiaJMk/drone?orgId=1&var-drone=${this.props.drone.droneId}`, "_blank").focus()
    }

    startCalibration = () => {
        this.props.dronesConn.command(this.props.drone.droneId, "calibrate_camera").then()
        this.setState({isOpen: true})
    }

    closeWindow = () => this.setState({isOpen: false})

    showFPV = () => {
        const {drone, cameras} = this.props
        cameras.addCamera(drone.droneId, "fpv", "FPV")
    }

    showLanding = () => {
        const {drone, cameras} = this.props
        cameras.addCamera(drone.droneId, "landing", "Landing")
    }

    openJoystick = () => {
        const {drone, joystick} = this.props
        joystick.activate(drone)
    }

    switchStationUsage = () => {
        const {drone} = this.props
        const newValue = !drone.withStation
        api.drone.withStation(drone.droneId, newValue)
            .then(() => drone.setWithStation(newValue))
    }

    switchMissionApprovement = () => {
        const {drone} = this.props
        const newValue = !drone.missionApprovement
        api.drone.missionApprovement(drone.droneId, newValue)
            .then(() => drone.setMissionApprovement(newValue))
    }

    switchRTK = () => {
        const {drone} = this.props
        const newValue = !drone.rtk
        api.drone.enableRTK(drone.droneId, newValue)
            .then(() => drone.setRTK(newValue))
    }

    rename = (val) => {
        const {drone} = this.props
        if (val !== drone.name) {
            api.drone.rename(drone.droneId, val)
                .then(() => {
                    drone.setName(val)
                })
        }
    }

    cancelMission = () => {
        const {drone} = this.props

        api.drone.cancelMission(drone.droneId)
            .then(() => {
                drone.setMissionId(null)
            })
    }

    stopMission = () => {
        this.props.dronesConn.command(this.props.drone.droneId, "stop_mission")
            .then()
    }
}

import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"
import {RequestTypesLabel} from "@/model/RequestTypes"
import type {IRequest} from "@/model/IRequest"
import {RequestStates} from "@/model/index"
import {GrayIcon, GreenIcon, RedIcon} from "@/components/map/markers/MapIcons"

export const stageDescription = {
    0: "Queued",
    1: "New",
    2: "Activating drone",
    3: "Executing mission",
    4: "Disabling drone",
    5: "Uploaded",
    6: "Completed",
    7: "Failed",
    8: "Approvement",
    9: "Approved",
}

export const flightStatusDescription = {
    0: "model.enums.flight-status-description.stopped",
    1: "model.enums.flight-status-description.on-ground",
    2: "model.enums.flight-status-description.in-air",
}

export function batteryStateSelfCheckError(error: number) {
    switch (error) {
        case 0: return ""
        case 1: return "Closed"
        case 2: return "Discharge short circuit shutdown"
        case 3: return "Discharge overload shutdown"
        case 4: return "Discharge overcurrent shutdown"
        case 5: return "Discharge undervoltage shutdown"
        case 6: return "Discharge over temperature shutdown"
        case 16: return "Charging short circuit shutdown"
        case 17: return "Charge overcurrent shutdown"
        case 18: return "Charge overvoltage shutdown"
        case 19: return "Charger overvoltage shutdown"
        case 20: return "Charging low temperature shutdown"
        case 21: return "Charging high temperature shutdown"

        default: return "There's something wrong with the battery"
    }
}

const stationErrors = {
    0x00: ["Ok", "Ошибок нет"],
    0x01: ["InvalidRequestCode", "Некорректный код запроса"],
    0x02: ["InvalidRequestLength", "Некорректная длина запроса"],
    0x03: ["CmdRequestWhileBusy", "Получен запрос на выполнение команды в то время, пока контроллер занят выполнением другой команды"],
    0x04: ["CmdForbidenForState", "Получена команда недопустимая для текущего состояния"],
    0x0A: ["BS_LEFT_FULL", "Ошибка при установке батареи в левый слот зарядной станции - все левые слоты заполнены"],
    0x0B: ["BS_RIGHT_FULL", "Ошибка при установке батареи в правый слот зарядной станции - все правые слоты заполнены"],
    0x0C: ["ROOF_CHECK_LIFT", "Крыша не открывается (не закрывается) из-за неопущенного лифта"],
    0x0D: ["ROOF_OPEN_TIMEOUT", "Таймаут открытия крыши"],
    0x0E: ["ROOF_CLOSE_TIMEOUT", "Таймаут закрытия крыши"],
    0x0F: ["TABLE_CHECK_Y", "Ошибка сведения направляющих. Ось Y (малые направляющие) уже сведена"],
    0x10: ["TABLE_GRIP_X_TIMEOUT", "Таймаут сведения по оси X (большие направляющие)"],
    0x11: ["TABLE_GRIP_Y_TIMEOUT", "Таймаут сведения по оси Y (малые направляющие)"],
    0x13: ["TABLE_RELEASE_TIMEOUT", "Таймаут разведения направляющих"],
    0x14: ["LIFT_UP_CHECK", "Ошибка при подъеме лифта. Проверить расположение робота или состояние двигателя"],
    0x15: ["LIFT_UP_TIMEOUT", "Таймаут при подъеме лифта. Проверить концевики или состояние двигателя"],
    0x16: ["LIFT_DOWN_CHECK", "Ошибка при опускании лифта. Проверить расположение робота или состояние двигателя"],
    0x17: ["LIFT_DOWN_TIMEOUT", "Таймаут при опускании лифта. Проверить концевики или состояние двигателя"],
    0x18: ["ROBOT_MOVE_TIMEOUT", "Таймаут при перемещении робота"],
    0x19: ["GRIP_ERROR", "Ошибка фиксатора дрона на столе"],
    0x1A: ["DRONE_OUTSIDE_ERROR", "Ошибка. Дрон находится вне станции или авария дрона при активации команды closeroofclampdrone"],
    0x1B: ["ROBOT_HOME_ERROR", "Ошибка хоуминга робота при инициализации"],
    0x1C: ["BS_LEFT_DROP_ERROR", "Ошибка при выгрузке батареи в зарядную станцию"],
    0x1D: ["BS_RIGHT_DROP_ERROR", "Ошибка при выгрузке батареи в зарядную станцию"],
    0x1E: ["LIFT_MOTOR_ERROR", "Таймаут при опускании лифта. Проверить концевики или состояние подъемника"],
    0x1F: ["DRONE_GRIP_ERROR", "Ошибка захвата батареи из левого слота дрона"],
    0x20: ["BS60_NO_VALID_BAT", "На станции отсутствует заряженная пара батарей для установки в дрон"],
    0x21: ["BS60_CONN_FAILED", "Ошибка соединения с зарядной станцией"],
    0x22: ["ROBOT_GRIP_TIMEOUT", "Ошибка пневматики схвата батарей"],
    0x23: ["DRONE_PWRBTN_TIMEOUT", "Ошибка пневматики механизма нажатия на кнопку включения дрона"],
    0x24: ["ACTUATOR_ERROR", "Ошибка пневмоцилиндров захвата батарей или кнопки нажатия дрона при инициализации"],
    0x25: ["POWER_ERROR", "Прекращение электроснабжения"],
    0x26: ["PRESSION_ERROR", "Отсутствует давление в ресивере компрессора"],
    0x27: ["DRONE_RIGHT_GRIP_ERROR", "Ошибка захвата батареи из правого слота дрона"],
    0x28: ["LIFT_INIT_ERROR", "Ошибка инициализации подъемника"],
    0x29: ["DRONE_BUTTONPUSHER_ERROR", "Ошибка пневмоцилиндра нажатия на кнопку дрона"],
}

export function stationErrorName(val: number) {
    if (val in stationErrors)
        return stationErrors[val][0]
    return `Unknown ${val}`
}

export function stationErrorDescription(val: number) {
    if (val in stationErrors)
        return stationErrors[val][1]
    return `Unknown ${val}`
}

export function requestTypeName(val: string) {
    return RequestTypesLabel(val)
}

export function requestStateDescription(state: number) {
    switch (state) {
        case RequestStates.REVIEW: return i18next.t("model.enums.request-state-description.on-review")
        case RequestStates.CONFIRMED: return i18next.t("model.enums.request-state-description.confirmed")
        case RequestStates.REJECTED: return i18next.t("model.enums.request-state-description.rejected")
        case RequestStates.CANCELED: return i18next.t("model.enums.request-state-description.canceled")
        case RequestStates.COMPLETED: return i18next.t("model.enums.request-state-description.completed")

        default: return i18next.t("model.enums.request-state-description.unknown-state") + state
    }
}

export function requestStateIcon(state: number) {
    switch (state) {
        case RequestStates.REVIEW: return IconNames.TIME
        case RequestStates.CONFIRMED: return IconNames.CONFIRM
        case RequestStates.REJECTED: return IconNames.BAN_CIRCLE
        case RequestStates.CANCELED: return IconNames.DISABLE
        case RequestStates.COMPLETED: return IconNames.TICK_CIRCLE

        default: return IconNames.ERROR
    }
}

export function requestMapIcon(request: IRequest) {
    switch (request.state) {
        case RequestStates.REVIEW: return GrayIcon
        case RequestStates.CONFIRMED: return RedIcon
        case RequestStates.COMPLETED: return GreenIcon

        default: return GrayIcon
    }
}

export function isRequestEdit(state: number) {
    switch (state) {
        case RequestStates.REVIEW: return true
        case RequestStates.CONFIRMED: return true
        case RequestStates.REJECTED: return false
        case RequestStates.CANCELED: return false
        case RequestStates.COMPLETED: return false

        default: return true
    }
}

export function isRequestEditFlag(state: number) {
    switch (state) {
        case RequestStates.REVIEW: return false
        case RequestStates.CONFIRMED: return true
        case RequestStates.REJECTED: return false
        case RequestStates.CANCELED: return false
        case RequestStates.COMPLETED: return false

        default: return true
    }
}

import React from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Intent, Button} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import i18next from "i18next"

import api from "@/services/ApiService"
import {AdminDronesStore} from "@/store/AdminDronesStore"
import AppToaster, {infoToast} from "@/components/AppToaster"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"

import "./Admin.scss"

@withRouter
@inject("adminDrones")
@observer
export default class DronesList extends LoaderComponent<{adminDrones?: AdminDronesStore} & RouteProps, {disabled: boolean }> {
    state = {
        disabled: false,
    }

    prepare(): Promise {
        return api.adminDrones.list()
            .then((list) => { this.props.adminDrones.setAdminDronesList(list) })
    }

    successRender() {
        const {disabled} = this.state
        return (
            <>
                <p><Button icon={IconNames.ADD} text={i18next.t("admin.drones-list.button.add-drone")} minimal onClick={this.create} intent={Intent.PRIMARY} /></p>
                <div className="admin-panel">
                    <div className="admin-table-wrapper">
                        <table
                            className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped"
                            style={{border: "1px solid #ccc"}}
                        >
                            <thead>
                                <tr>
                                    <th>{i18next.t("admin.drones-list.text.name")}</th>
                                    <th>{i18next.t("admin.drones-list.text.drone")}</th>
                                    <th>{i18next.t("admin.drones-list.text.edit")}</th>
                                    <th>{i18next.t("admin.drones-list.text.delete")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.adminDrones.adminDronesList.map((d) => (
                                    <tr key={d.droneId}>
                                        <td>{d.name}</td>
                                        <td>{d.droneId}</td>
                                        <td><Button icon={IconNames.EDIT} minimal onClick={() => this.edit(d.droneId)} loading={disabled} intent={Intent.PRIMARY} /></td>
                                        <td><Button icon={IconNames.TRASH} minimal onClick={() => this.delete(d.droneId)} loading={disabled} intent={Intent.DANGER} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    delete = (id: string) => {
        const drone = this.props.adminDrones.getAdminDroneById(id)
        const action = {
            text: i18next.t("yes"),
            onClick: () => {
                this.setState({disabled: true})
                api.adminDrones.delete(drone.droneId)
                    .then(() => {
                        infoToast(i18next.t("admin.drones-list.toast.drone-deleted"))
                        this.props.adminDrones.removeAdminDrone(drone.droneId)
                    })
                    .finally(() => this.setState({disabled: false}))
            },
        }
        AppToaster.show({
            message: i18next.t("admin.drones-list.toast.show.user-deleted", {
                name: drone.name,
            }),
            action,
            timeout: 10000,
            intent: Intent.DANGER,
        })
    }

    edit = (droneId: string) => this.props.history.push(`${this.props.match.url}/${droneId}`)
    create = () => this.props.history.push(`${this.props.match.url}/create`)
}

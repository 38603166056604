import type {DroneInfo} from "@/model/DroneInfo"
import HttpService from "@/services/HttpService"

const baseUrl = "/admin/drones"

export default class AdminDronesService {
    constructor(api: HttpService) {
        this.api = api
    }

    list(): Promise<DroneInfo[]> {
        return this.api.get(baseUrl)
    }

    submit(params: { droneId: string, name: string, withStation: boolean, password: string }) {
        return this.api.post(baseUrl, params)
    }

    update(droneId: string, params: DroneInfo) {
        return this.api.post(`${baseUrl}/${droneId}`, params)
    }

    delete(droneId: string) {
        return this.api.delete(`${baseUrl}/${droneId}`)
    }

    resetPassword(droneId: string, password: string) {
        return this.api.post(`${baseUrl}/${droneId}/reset-password`, {password})
    }
}

import React, {useCallback, useEffect, useMemo, useState, VFC} from "react"
import {Checkbox, Intent, Spinner, Tree, TreeNodeInfo} from "@blueprintjs/core"
import {autorun} from "mobx"
import {observer} from "mobx-react"
import {Tooltip2} from "@blueprintjs/popover2"
import api from "@/services/ApiService"
import permissionsStore from "@/store/PermissionsStore"
import type {PermissionDto, PermissionGroupDto} from "@/services/PermissionsService"

type BoolMap = Record<number, boolean>
type ExpandMap = Record<string, boolean>

type BoolSetter = (prev: BoolMap) => void;

type LeafProps = {
    permission: PermissionDto;
    checked: boolean;
    set: BoolSetter;
}

const PermissionLeaf: VFC<LeafProps> = ({permission: {description, value, displayName},
    checked,
    set}) => {
    const handleChange = useCallback((e) => {
        set((prev) => {
            const next = {...prev}
            next[value] = e.target.checked
            return next
        })
    }, [set, value])

    return (
        <Tooltip2 content={description} placement="right" style={{width: "100%"}}>
            <div className="flex vertical">
                <Checkbox
                    onChange={handleChange}
                    checked={checked}
                    inline
                    style={{
                        marginBottom: 0,
                        marginTop: 0,
                    }}
                />
                {displayName}
            </div>
        </Tooltip2>
    )
}

const findPermissionsDeep = (group: PermissionGroupDto): number[] => [...group.permissions.map((x) => x.value),
    ...group.children.flatMap((x) => findPermissionsDeep(x)),
]

type GroupProps = {
    group: PermissionGroupDto;
    boolMap: BoolMap;
    set: BoolSetter;
}
const GroupComponent: VFC<GroupProps> = ({group, set, boolMap}) => {
    const deepPermissions = useMemo(() => findPermissionsDeep(group), [group])

    const handleChange = useCallback((e) => {
        const checked = e.currentTarget?.checked
        if (checked == null)
            return

        set((prev) => {
            const next = {...prev}
            deepPermissions.forEach((x) => {
                next[x] = checked
            })

            return next
        })
    }, [deepPermissions, set])

    const boolArr = deepPermissions.map((x) => boolMap[x])
    const all = boolArr.reduce((p, n) => p && n) ?? false
    const any = boolArr.includes(true) && !all

    return (
        <div className="flex vertical">
            <Checkbox
                onChange={handleChange}
                checked={all}
                indeterminate={any}
                inline
                style={{
                    marginBottom: 0,
                    marginTop: 0,
                }}
            />
            {group.name}
        </div>
    )
}

const permissionToTreeNodeInfo = (permission: PermissionDto, boolMap: BoolMap, setBool: BoolSetter): TreeNodeInfo => ({
    id: `leaf-${permission.value}`,
    icon: "tag",
    label: (
        <PermissionLeaf
            permission={permission}
            checked={boolMap[permission.value] ?? false}
            set={setBool}
        />)
    ,
})

const groupToTreeNodeInfo = (
    groupDto: PermissionGroupDto,
    index: number,
    expandDefault: boolean,
    boolMap: BoolMap,
    expandMap: ExpandMap,
    setBool: BoolSetter,
): TreeNodeInfo => {
    const id = `category-${index}`
    return {
        id,
        icon: "folder-close",
        isExpanded: expandMap[id] ?? expandDefault,
        isSelected: false,
        label: <GroupComponent group={groupDto} boolMap={boolMap} set={setBool} />,
        childNodes: [
            ...groupDto.children.map((x, i) => groupToTreeNodeInfo(x, id + i, expandDefault, boolMap, expandMap, setBool)),
            ...groupDto.permissions.map((x) => permissionToTreeNodeInfo(x, boolMap, setBool)),
        ],
    }
}

type Props = {
    preset?: number[];
    onChange?: (arr: number[]) => void;
    expand?: boolean;
}

const PermissionsTree: VFC<Props> = ({preset, onChange, expand}) => {
    const [loading, setLoading] = useState(false)
    const [boolMap, setBoolMap] = useState<BoolMap>(null)
    const [expandMap, setExpandMap] = useState<ExpandMap>({})
    const [tree, setTree] = useState([])

    useEffect(() => {
        if (permissionsStore.treeInitialized)
            return

        api.permissions.getTree().then((res) => {
            permissionsStore.setTree(res)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        const cancel = autorun(() => {
            const newMap = {}
            Object.entries(permissionsStore.permissionsMap).forEach(([, val]) => {
                newMap[val] = false
            })

            if (preset) {
                preset.forEach((x) => {
                    newMap[x] = true
                })
            }

            setBoolMap(newMap)
        })

        return () => cancel()
    }, [preset])

    useEffect(() => {
        if (!boolMap || !onChange)
            return

        onChange(Object.entries(boolMap).filter(([, val]) => Boolean(val)).map(([key]) => parseInt(key, 10)))
    }, [boolMap, onChange])

    useEffect(() => {
        const cancel = autorun(() => {
            if (!boolMap)
                return

            setTree(permissionsStore.tree.map((x, i) => groupToTreeNodeInfo(x, i, expand ?? false, boolMap, expandMap, setBoolMap)))
        })

        return () => cancel()
    }, [boolMap, expand, expandMap])

    const setExpand = useCallback((expanded, id: string) => {
        setExpandMap((prev) => {
            const newExpandMap = {...prev}
            newExpandMap[id] = expanded
            return newExpandMap
        })
    }, [])

    const handleNodeCollapse = useCallback(({id}: TreeNodeInfo) => setExpand(false, id), [setExpand])
    const handleNodeExpand = useCallback(({id}: TreeNodeInfo) => setExpand(true, id), [setExpand])

    if (loading)
        return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

    return (
        <Tree
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
            contents={tree}
        />
    )
}

export default observer(PermissionsTree)

import React, {useCallback, useEffect, useState, VFC} from "react"
import i18next from "i18next"
import {Button, FormGroup, InputGroup, Intent, Spinner} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import {useHistory} from "react-router-dom"
import PermissionsTree from "@/components/left/admin/PermissionsTree"
import api from "@/services/ApiService"
import {dangerToast, successToast} from "@/components/AppToaster"
import type {ProfileDto} from "@/services/ProfilesService"
import "./ProfileForm.scss"

type Props = {
    id?: string;
}
const ProfileForm: VFC<Props> = ({id}) => {
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState<ProfileDto>(null)

    const [name, setName] = useState("")
    const [permissions, setPermissions] = useState<number[]>([])
    const [disabled, setDisabled] = useState(false)

    const handleTreeChange = useCallback((arr) => setPermissions(arr), [])
    const handleNameChange = useCallback((e) => setName(e.target.value), [])

    const history = useHistory()

    useEffect(() => {
        if (!id)
            return

        setLoading(true)

        api.profiles.getById(id)
            .then((res) => setProfile(res))
            .catch(() => dangerToast("Unable to fetch profile. Try again"))
            .finally(() => {
                setLoading(false)
            })
    }, [id])

    useEffect(() => {
        if (profile)
            setName(profile.name)
    }, [profile])

    useEffect(() => {
        const anyPermissions = permissions != null && permissions.length > 0

        if (!name || !anyPermissions)
            setDisabled(true)

        if (name && anyPermissions)
            setDisabled(false)
    }, [name, permissions])

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        const handleError = () => dangerToast("An error occurred.")
        if (id) {
            api.profiles.update({
                id,
                name,
                permissions,
            }).then(() => {
                successToast("Successfully updated")
            }).catch(() => handleError())
        } else {
            api.profiles.create({
                name,
                permissions,
            }).then((res) => {
                successToast("Successfully created")
                history.push(`/admin/profiles/${res.id}`)
            }).catch(() => handleError())
        }
    }, [history, id, name, permissions])

    return (
        <div style={{maxWidth: 300, margin: 10, padding: 10, border: "1px solid #aaa"}}>
            {loading && (
                <Spinner intent={Intent.PRIMARY} className="spinner-center" />
            )}
            { !loading && (
                <form onSubmit={handleSubmit}>
                    <FormGroup label={i18next.t("admin.drones-editor.form.name.label")} labelFor="name">
                        <InputGroup
                            id="name"
                            leftIcon={IconNames.PERSON}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button
                            intent={Intent.PRIMARY}
                            type="submit"
                            disabled={disabled}
                        >
                            {id ? "Save" : "Create"}
                        </Button>
                    </FormGroup>
                    <FormGroup>
                        <PermissionsTree
                            expand
                            preset={profile?.permissions}
                            onChange={handleTreeChange}
                        />
                    </FormGroup>
                </form>
            )}
        </div>
    )
}

export default ProfileForm

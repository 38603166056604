import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {Card, Elevation, H5, Button, Spinner, Intent} from "@blueprintjs/core"
import {DateRangePicker} from "@blueprintjs/datetime"
import {Popover2} from "@blueprintjs/popover2"
import {IconNames} from "@blueprintjs/icons"
import moment from "moment"
import i18next from "i18next"
import MomentLocaleUtils from "react-day-picker/moment"
import {formatDate, formatDateTime} from "@/Tools"
import api from "@/services/ApiService"
import type Mission from "@/model/Mission"
import {stageDescription} from "@/model/Enums"
import type {UnitStores} from "@/store/DroneListStore"
import type {RouteProps} from "@/types/RouteProps"
import LoaderComponent from "@/components/LoaderComponent"

@withRouter
class MissionCard extends Component<{mission: Mission} & RouteProps> {
    render() {
        const {mission} = this.props
        return (
            <Card elevation={Elevation.TWO}
                  className="bp3-text-small"
                  interactive
                  onClick={this.onClick}
            >
                <H5>{mission.name}</H5>
                <div>{i18next.t("operator.mission-list.card.text.points")} {mission.pointsCount}</div>
                <div>{i18next.t("operator.mission-list.card.text.created")} {formatDateTime(mission.created)}</div>
				{mission.scheduledTime && <div>{i18next.t("operator.mission-list.card.text.scheduled")} {formatDateTime(mission.scheduledTime)}</div>}
                {mission.completed && <div>{i18next.t("operator.mission-list.card.text.end")} {formatDateTime(mission.completed)}</div>}
                <div>{i18next.t("operator.mission-list.card.text.stage")} {stageDescription[mission.missionStage]}</div>
                {mission.failReason && <div>{i18next.t("operator.mission-list.card.text.fail-reason")} {mission.failReason}</div>}
                {!!mission.filesCount && <div>{i18next.t("operator.mission-list.card.text.files")} {mission.filesCount}</div>}
            </Card>
        )
    }

    onClick = () => {
        this.props.history.push(`${this.props.match.url}/${this.props.mission.id}`)
    }
}

const LAST_MISSION_RANGE_KEY = "last_mission_range"
const LAST_MISSION_CHANGE_KEY = "last_mission_change"
const LAST_MISSION_LABEL_KEY = "last_mission_label"

export default class MissionList extends LoaderComponent<{unit: UnitStores}, {missions: Mission[], value: string, loading: boolean}> {
    state = {
        loading: true,
        missions: [],
        value: "",
        dateRange: [new Date(), new Date()],
        rangeLabel: i18next.t("operator.mission-list.date-picker.today"),
    }

    prepare(): Promise {
        return this.setupDateRange()
    }

    setupDateRange() {
        const dateRangeJson = localStorage.getItem(LAST_MISSION_RANGE_KEY)
        if (dateRangeJson) {
            const dateRange = JSON.parse(dateRangeJson)
            if (Array.isArray(dateRange)) {
                const lastChange = JSON.parse(localStorage.getItem(LAST_MISSION_CHANGE_KEY))
                if (moment(lastChange).isSame(new Date(), "day"))
                    return this.updateList(dateRange[0], dateRange[1])
            }
        }

        return this.updateList(new Date(), new Date())
    }

    updateList(fromDate, toDate) {
        const {drone, station} = this.props.unit
        this.setState({loading: true})

        localStorage.setItem(LAST_MISSION_RANGE_KEY, JSON.stringify([fromDate, toDate]))
        localStorage.setItem(LAST_MISSION_CHANGE_KEY, JSON.stringify(new Date()))

        const from = moment(fromDate).startOf("day").toDate()
        const to = moment(toDate).startOf("day").add(1, "day").toDate()
        const params = {from, to}

        if (station) {
            return api.station.missionsQuery(station.stationId, params)
                .then(this.missionsLoaded)
        }
        return api.drone.missionsQuery(drone.droneId, params)
            .then(this.missionsLoaded)
    }

    missionsLoaded = (missions) => this.setState({missions, loading: false})

    filtered(): Mission[] {
        const {missions, value} = this.state

        let missionsFiltered = missions

        if (value) {
            const valLower = value.toLowerCase()
            missionsFiltered = missionsFiltered.filter((m) => m.name && m.name.toLowerCase().includes(valLower))
        }

        return missionsFiltered
    }

    successRender() {
        const {loading, value} = this.state
        if (loading)
            return <Spinner intent={Intent.PRIMARY} className="spinner-center" />

        const rangeLabelMission = localStorage.getItem(LAST_MISSION_LABEL_KEY) ?? this.state.rangeLabel

        const shortcuts = [{
            label: i18next.t("operator.mission-list.date-picker.today"),
            dateRange: [new Date(), new Date()],
            includeTime: false,
        }, {
            label: i18next.t("operator.mission-list.date-picker.last-2-days"),
            dateRange: [moment().subtract(2, "days").toDate(), new Date()],
            includeTime: false,
        }, {
            label: i18next.t("operator.mission-list.date-picker.last-7-days"),
            dateRange: [moment().subtract(7, "days").toDate(), new Date()],
            includeTime: false,
        }, {
            label: i18next.t("operator.mission-list.date-picker.last-month"),
            dateRange: [moment().subtract(1, "month").toDate(), new Date()],
            includeTime: false,
        }, {
            label: i18next.t("operator.mission-list.date-picker.last-year"),
            dateRange: [moment().subtract(1, "year").toDate(), new Date()],
            includeTime: false,
        }]

        const picker = (
            <DateRangePicker allowSingleDayRange
                             locale={localStorage.getItem("i18nextLng")}
                             localeUtils={MomentLocaleUtils}
                             shortcuts={shortcuts}
                             value={this.state.dateRange}
                             onChange={(dateRange) => {
                                 this.setState({dateRange})

                                 if (dateRange[0] && dateRange[1]) {
                                     localStorage.setItem(LAST_MISSION_LABEL_KEY, `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`)
                                     this.updateList(dateRange[0], dateRange[1]).then()
                                     this.setState({
                                         rangeLabel: `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`,
                                     })
                                 }
                             }}
                             onShortcutChange={(shortcut) => {
                                 localStorage.setItem(LAST_MISSION_LABEL_KEY, shortcut.label)
                                 this.setState({rangeLabel: shortcut.label})
                             }}
            />
        )

        return (
            <>
                <Popover2
                    interactionKind="click"
                    placement="right-start"
                    content={picker}
                    renderTarget={({isOpen, ref, ...targetProps}) => (
                        <Button {...targetProps} elementRef={ref} icon={IconNames.TIME} minimal text={rangeLabelMission} />)}
                />
                <div style={{margin: 8}} className="bp3-input-group">
                    <span className="bp3-icon bp3-icon-search" />
                    <input
                        type="search"
                        className="bp3-input"
                        value={value}
                        onChange={(e) => this.setState({value: e.target.value})}
                        placeholder={i18next.t("operator.mission-list.search.placeholder")}
                        dir="auto"
                    />
                </div>
                {this.filtered().map((m) => (
                    <MissionCard
                        key={m.id}
                        mission={m}
                    />
                ))}
            </>
        )
    }
}

import Common from "./Common.json"
import Login from "./Login.json"
import UserInfo from "./UserInfo.json"
import DroneMap from "./DroneMap.json"
import Operator from "./operator"
import Consumer from "./consumer"
import Admin from "./admin"
import Profile from "./profile"
import Store from "./store"
import Model from "./model"
import Control from "./control"

const ru = {
    ...Common,
    ...Login,
    ...UserInfo,
    ...DroneMap,
    ...Operator,
    ...Consumer,
    ...Admin,
    ...Profile,
    ...Store,
    ...Model,
    ...Control,
}
export default ru

import HttpService from "@/services/HttpService"

const baseUrl = "/camera-access"

export default class CameraAccessService {
    constructor(api: HttpService) {
        this.api = api
    }

    getPermissions = () => this.api.get(`${baseUrl}/get-all`);

    grantAccess = (droneId: string, userId: string) => this.api.post(`${baseUrl}/grant`, {
        droneId,
        userId,
    });

    rejectAccess = (droneId: string, userId: string) => this.api.post(`${baseUrl}/reject`, {
        droneId,
        userId,
    });

    revokeAccess = (droneId: string, userId: string) => this.api.delete(`${baseUrl}/revoke/${droneId}/${userId}`);
    revokeMyAccess = (droneId: string) => this.api.delete(`${baseUrl}/revoke/${droneId}`);

    requestAccess = (droneId: string) => this.api.post(`${baseUrl}/request-access/${droneId}`);
}

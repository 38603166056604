import React, {VFC, ComponentProps} from "react"
import {observer} from "mobx-react"
import {Entity, PolylineGraphics} from "resium"
import {ArcType, Color, PolylineArrowMaterialProperty} from "cesium"
import type {Waypoint} from "@/model/Waypoint"
import {pointToCartesian3} from "@/components/map/cesium/utils"

type Props = {
    from: Waypoint;
    to: Waypoint;
    heightAboveEllipsoid: number;
    arrowNumber?: number;
    arrowProps?: ComponentProps<PolylineGraphics>
}

const WaypointsArrow: VFC<Props> = ({from, to, heightAboveEllipsoid, arrowNumber, arrowProps}) => (
    <Entity {...(arrowNumber && {description: `Arrow ${arrowNumber}`})}>
        <PolylineGraphics
            width={20}
            arcType={ArcType.NONE}
            material={new PolylineArrowMaterialProperty(Color.fromCssColorString("#2196F3"))}
            {...arrowProps}
            positions={[pointToCartesian3(from, heightAboveEllipsoid), pointToCartesian3(to, heightAboveEllipsoid)]}
        />
    </Entity>
)

export default observer(WaypointsArrow)

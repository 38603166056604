/* eslint-disable no-console */
import PlaySettings from "@/store/PlaySettings"
import type {Callbacks} from "@/webrtc/types"
import {mungeSDPPlay} from "./mungeSDP"


class Receiver {
	callbacks: Callbacks
	ws: WebSocket | null = null;
	pc: RTCPeerConnection | null = null;
	restartTimeout = null;
	restartPause = 5000;

    constructor(playSettings: PlaySettings, websocket: WebSocket, callbacks: Callbacks) {
        this.callbacks = callbacks
        this.ws = null;
        this.pc = null;
        this.restartTimeout = null;
        this.restartPause = 5000;
        this.start(playSettings, websocket);
    }

    start(playSettings, websocket) {
        try {
            this.ws = websocket ?? new WebSocket(playSettings.signalingURL);
			if (!this.ws) return
            this.ws.onerror = (error) => {
                if (this.callbacks.onError) this.callbacks.onError({message: `Websocket Error: ${error?.message}`})
                if (this.ws === null) {
                    return;
                }
                this.ws.close();
                this.ws = null;
            };

            this.ws.onmessage = (msg) => this.onIceServers(msg, playSettings);

            if (this.callbacks.onSetWebsocket) this.callbacks.onSetWebsocket({websocket: this.ws})
        } catch (e) {
            if (this.callbacks.onError) this.callbacks.onError(e)
        }
    }

    onIceServers(msg, playSettings) {
        if (this.ws === null) {
            return;
        }

        const iceServers = JSON.parse(msg.data);

        this.pc = new RTCPeerConnection({
            iceServers,
        });

        this.ws.onmessage = (msg) => this.onRemoteDescription(msg, playSettings);
        this.pc.onicecandidate = (evt) => this.onIceCandidate(evt);

        this.pc.ontrack = (evt: RTCTrackEvent) => {
            if (this.callbacks.onPeerConnectionOnTrack) this.callbacks.onPeerConnectionOnTrack(evt)
        };

        this.pc.oniceconnectionstatechange = (event) => {
            if (this.pc === null) {
                return;
            }

            const callback = this.callbacks.onConnectionStateChange
            if (!callback) return
            callback({connected: event.currentTarget?.iceConnectionState === "connected" })

            switch (this.pc.iceConnectionState) {
                case "disconnected":
                    this.scheduleRestart(playSettings, this.ws);
            }
        };

        const direction = "sendrecv";
        this.pc.addTransceiver("video", {direction});
        this.pc.addTransceiver("audio", {direction});

        this.pc.createOffer()
            .then((desc) => {
                if (this.pc === null || this.ws === null) {
                    return;
                }
                this.pc.setLocalDescription(desc);
                this.ws.send(JSON.stringify(desc));
            });

        if (this.callbacks.onSetPeerConnection) this.callbacks.onSetPeerConnection({peerConnection: this.pc})
    }

    onRemoteDescription(msg, playSettings) {
        if (this.pc === null || this.ws === null) {
            return;
        }
        const msgJSON = JSON.parse(msg.data)
        if (msgJSON.sdp != null) {
            const {aspect} = mungeSDPPlay(msgJSON.sdp)

            playSettings.setAspect(aspect)
            this.pc.setRemoteDescription(new RTCSessionDescription(msgJSON))
                .catch((err) => this.peerConnectionOnError(err));
            this.ws.onmessage = (msg) => this.onRemoteCandidate(msg);
        }
    }

    onIceCandidate(evt) {
        if (this.ws === null) {
            return;
        }

        if (evt.candidate !== null) {
            if (evt.candidate.candidate !== "") {
                this.ws.send(JSON.stringify(evt.candidate));
            }
        }
    }

    onRemoteCandidate(msg) {
        if (this.pc === null) {
            return;
        }

        this.pc.addIceCandidate(JSON.parse(msg.data));
    }

    scheduleRestart(playSettings, websocket) {
        if (this.ws !== null) {
            this.ws.close();
            this.ws = null;
        }

        if (this.pc !== null) {
            this.pc.close();
            this.pc = null;
        }

        this.restartTimeout = window.setTimeout(() => {
            this.restartTimeout = null;
            this.start(playSettings, websocket);
        }, this.restartPause);
    }

    peerConnectionOnError(error: Error) {
        if (this.callbacks.onError) this.callbacks.onError({message: `PeerConnection Error: ${error.message}`})
    }

    peerConnectionSetRemoteDescriptionSuccess(description: RTCSessionDescriptionInit) {
		if (!this.pc) return
        this.pc
            .setLocalDescription(description)
            .then(() => {
				if (!this.ws) return
                this.ws.send(`{"direction":"play", "command":"sendResponse", "sdp":${JSON.stringify(description)}}`)
            })
            .catch((error) => {
                const newError = {message: "Peer connection failed", ...error}
                this.peerConnectionOnError(newError)
            })
    }
}

export default Receiver
import {action, makeObservable, observable} from "mobx"
import PlanStore from "@/store/PlanStore"
import type {UnitStores} from "@/store/DroneListStore"
import StationStore from "@/store/StationStore"
import DroneStore from "@/store/DroneStore"
import type MissionFile from "@/model/MissionFile"
import type Mission from "@/model/Mission"
import userInfo from "@/store/UserInfoStore"

export class SelectionStore {
    @observable drone: DroneStore
    @observable station: StationStore
    @observable plan: PlanStore
    @observable file: MissionFile = null
    @observable mission: Mission = null
    @observable isFocused: boolean = false

    constructor() {
        makeObservable(this)
    }

    @action selectDrone(unit: UnitStores) {
        if (unit) {
            this.isFocused = true
            this.drone = unit.drone
            this.station = unit.station
            if (unit.drone && !userInfo.isConsumer)
                unit.drone.prepareMission()
        } else {
            this.isFocused = false
            this.drone = null
            this.station = null
        }
    }

    @action unFocus() {
        this.isFocused = false
    }

    @action setPlan(plan: PlanStore) {
        this.plan = plan
    }

    @action setFile(file: MissionFile) {
        this.file = file
    }

    @action setMission(m: Mission) {
        this.mission = m
    }

    get displayPlan(): PlanStore | null {
        if (this.plan)
            return this.plan
        if (this.drone)
            return this.drone.currentPlan
        return null
    }

    get selectUnitId() {
        if (this.drone) return this.drone.droneId
        if (this.station) return this.station.stationId
        return null
    }
}

const selection = new SelectionStore()

export default selection

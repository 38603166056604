import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {inject, observer} from "mobx-react"
import {Button, FormGroup, Icon, Intent, TextArea, FileInput, ProgressBar, Dialog, Classes} from "@blueprintjs/core"
import {IconNames} from "@blueprintjs/icons"
import moment from "moment"
import i18next from "i18next"

import api from "@/services/ApiService"
import type {IRequest, IRequestFile} from "@/model/IRequest"
import type {RouteProps} from "@/types/RouteProps"
import * as RequestStates from "@/model/RequestState"
import {requestTypeName} from "@/model/Enums"
import {SelectionStore} from "@/store/SelectionStore"
import RequestStore from "@/store/RequestStore"
import {UnitStores} from "@/store/DroneListStore"
import {infoToast} from "@/components/AppToaster"
import Navigator from "@/components/left/Navigator"
import LoaderComponent from "@/components/LoaderComponent"

type Props = {
    requestModel?: IRequest,
    selection?: SelectionStore,
    request?: RequestStore,
}

type States = {
    loading: boolean,
    isOpen: boolean,
    uploading: boolean,
    progress: number,
    file: IRequestFile
}

@withRouter
@inject("selection", "request")
@observer
class RequestEditorControl extends Component<Props & RouteProps<{ unitId: string }>, States> {
    state = {
        loading: false,
        isOpen: false,
        uploading: false,
        progress: 0,
        file: null,
    }

    componentDidMount() {
        const {request, selection, requestModel} = this.props
        request.setEditMode(true)
        request.stationId = selection.station.stationId

        if (requestModel)
            request.setRequest(requestModel)
        else
            request.reset()
    }

    componentWillUnmount() {
        const {request} = this.props
        request.reset()
        request.setEditMode(false)
    }

    render() {
        const {loading, isOpen, uploading, progress, file} = this.state
        const {request} = this.props

        return (
            <div style={{padding: 16}}>
                {(!request.latitude || !request.longitude) && (
                    <p>
                        <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} /> {i18next.t("operator.operator-request-editor.control.text.setup")}
                    </p>
                )}
                <p>{i18next.t("operator.operator-request-editor.control.text.author")}</p>
                <p>{request.author}</p>
                <p>{i18next.t("operator.operator-request-editor.control.text.type")}</p>
                <p>{requestTypeName(request.type)}</p>
                {request.comments && (
                    <>
                        <p>{i18next.t("operator.operator-request-editor.control.text.comments")}</p>
                        <p>{request.comments}</p>
                    </>
                )}
                {request.changeDate && (
                    <>
                        <p>{i18next.t("operator.operator-request-editor.control.text.change-date")}</p>
                        <p>{moment(request.changeDate).locale("ru").format("LL HH:mm:ss")}</p>
                    </>
                )}
                {request.createTime && (
                    <>
                        <p>{i18next.t("operator.operator-request-editor.control.text.create-time")}</p>
                        <p>{moment(request.createTime).locale("ru").format("LL HH:mm:ss")}</p>
                    </>
                )}
                <p>{i18next.t("operator.operator-request-editor.control.text.planned-date")}</p>
                <p>{moment(request.date).locale("ru").format("LL")}</p>
                <FormGroup label={i18next.t("operator.operator-request-editor.control.form.comment.label")} labelFor="operatorComment">
                    <TextArea
                        id="operatorComment"
                        fill
                        value={request?.operatorComment ?? ""}
                        onChange={(e) => request.setOperatorComment(e.target.value)}
                    />
                </FormGroup>
                <div style={{display: "flex"}}>
                    <p>
                        <Button
                            icon={IconNames.PLUS}
                            text={i18next.t("operator.operator-request-editor.control.form.add.text")}
                            onClick={this.handleOpen}
                            minimal
                            intent={Intent.NONE}
                        />
                    </p>
                    <p>
                        <Button
                            icon={IconNames.FLOPPY_DISK}
                            text={i18next.t("operator.operator-request-editor.control.form.save.text")}
                            onClick={this.save}
                            intent={Intent.NONE}
                            minimal
                            loading={loading}
                        />
                    </p>
                </div>
                {request.files && (
                    <>
                        <p>{i18next.t("operator.operator-request-editor.control.text.files")}</p>
                        {request.files.map((f) => (
                            <div key={f.id}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <a href={f.link}
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{marginBottom: 10}}
                                    >
                                        <div>{f.fileName}</div>
                                    </a>
                                    <p><Button icon={IconNames.TRASH} minimal onClick={() => request.removeFiles(f.id)} intent={Intent.DANGER} /></p>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {request.state === RequestStates.REVIEW && (
                    <div style={{maxWidth: 150, display: "flex", justifyContent: "space-between"}}>
                        <p><Button text={i18next.t("operator.operator-request-editor.control.button.confirm")} intent={Intent.PRIMARY} onClick={this.confirm} loading={loading} /></p>
                        <p><Button text={i18next.t("operator.operator-request-editor.control.button.reject")} intent={Intent.PRIMARY} onClick={this.reject} loading={loading} /></p>
                    </div>
                )}
                {request.state === RequestStates.CONFIRMED && (
                    <p><Button text={i18next.t("operator.operator-request-editor.control.button.complete")} intent={Intent.PRIMARY} onClick={this.complete} loading={loading} /></p>
                )}
                <Dialog
                    icon="info-sign"
                    isOpen={isOpen}
                    onClose={this.handleClose}
                    title={i18next.t("operator.operator-request-editor.control.dialog.add.text")}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup label={i18next.t("operator.operator-request-editor.control.form.file.label")} labelFor="file">
                            <FileInput
                                id="file"
                                fill
                                text={file?.name ? file.name : "Choose file"}
                                onInputChange={this.selectFile}
                            />
                            {uploading && <ProgressBar value={progress} intent={Intent.PRIMARY} />}
                        </FormGroup>
                        <p><Button text={i18next.t("operator.operator-request-editor.control.button.upload")} icon="upload" minimal onClick={this.upload} intent={Intent.NONE} /></p>
                    </div>
                </Dialog>
            </div>
        )
    }

    onProgress = (event) => {
        this.setState({
            progress: event.loaded / event.total,
        })
    }

    selectFile = (event) => {
        const file = event.target.files[0]
        this.setState({file})
    }

    upload = () => {
        const {request} = this.props

        if (!this.state.file) return

        this.setState({
            uploading: true,
        })

        api.requests.upload(request.id, this.state.file, this.onProgress).then((f) => {
            infoToast(i18next.t("operator.operator-request-editor.control.toast.success"))
            request.setFiles(f)
            api.requests.submit(request.getRequest())
                .finally(() => this.setState({file: null, isOpen: false}))
        }).finally(() => this.setState({uploading: false}))
    }

    handleOpen = () => this.setState({isOpen: true});
    handleClose = () => this.setState({isOpen: false});

    save = () => {
        const request = this.props.request.getRequest()
        const {station} = this.props.selection

        const params = {
            id: request.id,
            state: request.state,
            operatorComment: request.operatorComment,
            files: request.files,
        }

        this.setState({loading: true})
        api.requests.submit(params).then((r) => {
            infoToast(i18next.t("operator.operator-request-editor.control.toast.request-saved"))
            if (!this.props.request.id)
                station.appendRequest(r)
            else
                station.updateRequest(r)
        }).finally(() => this.setState({loading: false}))
    }

    complete = () => {
        const request = this.props.request.getRequest()
        const {station} = this.props.selection

        const params = {
            id: request.id,
            state: RequestStates.COMPLETED,
            operatorComment: request.operatorComment,
            files: request.files,
        }

        this.setState({loading: true})
        api.requests.submit(params).then((r) => {
            infoToast(i18next.t("operator.operator-request-editor.control.toast.request-completed"))
            if (!this.props.request.id)
                station.appendRequest(r)
            else
                station.updateRequest(r)
            this.props.history.push(`/unit/${this.props.match.params.unitId}/requests`)
        }).catch(() => this.setState({loading: false}))
    }

    confirm = () => {
        const request = this.props.request.getRequest()
        const {station} = this.props.selection

        const params = {
            id: request.id,
            state: RequestStates.CONFIRMED,
            operatorComment: request.operatorComment,
            files: request.files,
        }

        this.setState({loading: true})
        api.requests.submit(params).then((r) => {
            infoToast(i18next.t("operator.operator-request-editor.control.toast.request-confirmed"))
            if (!this.props.request.id)
                station.appendRequest(r)
            else
                station.updateRequest(r)
            this.props.history.push(`/unit/${this.props.match.params.unitId}/requests`)
        }).catch(() => this.setState({loading: false}))
    }

    reject = () => {
        const request = this.props.request.getRequest()
        const {station} = this.props.selection

        const params = {
            id: request.id,
            state: RequestStates.REJECTED,
            operatorComment: request.operatorComment,
            files: request.files,
        }

        this.setState({loading: true})
        api.requests.submit(params).then((r) => {
            infoToast(i18next.t("operator.operator-request-editor.control.toast.request-rejected"))
            if (!this.props.request.id)
                station.appendRequest(r)
            else
                station.updateRequest(r)
            this.props.history.push(`/unit/${this.props.match.params.unitId}/requests`)
        }).catch(() => this.setState({loading: false}))
    }
}

@withRouter
@observer
export default class OperatorRequestEditor extends LoaderComponent<
    {unit: UnitStores} & RouteProps<{ unitId: string, requestsId: string }>,
    { requestModel: IRequest }
> {
    state = {
        requestModel: null,
    }

    prepare(): Promise {
        if (this.props.match.params.requestsId) {
            return api.requests.get(this.props.match.params.requestsId).then((result) => {
                this.setState({requestModel: result})
            })
        }
        return Promise.resolve()
    }

    successRender() {
        const {requestModel} = this.state

        return (
            <Navigator
                backTitle={this.props.unit.displayName}
                title={i18next.t("operator.operator-request-editor.title")}
                backUrl={`/unit/${this.props.match.params.unitId}/requests`}
            >
                <RequestEditorControl requestModel={requestModel} />
            </Navigator>
        )
    }
}

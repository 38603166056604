import {action, IObservableArray, makeObservable, observable} from "mobx"
import arrayMove from "array-move"
import type {ActionData} from "@/model/Actions"
import {ActionById} from "@/model/Actions"

export default class ActionsStore {
    @observable items: IObservableArray<ActionData> = []

    constructor(items?: ActionData[]) {
        if (items) {
            items.forEach((a) => {
                a.type = ActionById[a.type.id]
            })
            this.items = items
        }
        makeObservable(this)
    }

    @action
    moveAction(oldIndex, newIndex) {
        const arr = arrayMove(this.items, oldIndex, newIndex)
        this.items.replace(arr)
    }

    @action
    addAction(act: ActionData) {
        this.items.push(act)
    }

    @action
    removeAction(act) {
        this.items.remove(act)
    }

    @action
    setActionParam(index, value) {
        const a = this.items[index]
        this.items[index] = {
            type: a.type,
            param: value,
        }
    }
}

import {IconNames} from "@blueprintjs/icons"

export type CameraActionNames =
    | 'hover'
    | 'take-photo'
    | 'start-rec'
    | 'stop-rec'
    | 'camera-zoom'
    | 'timed-interval-shot'
    | 'distance-interval-shot'
    | 'panorama';
export type GimbalActionNames = 'gimbal-pitch-angle' | 'gimbal-yaw-angle';
export type AircraftActionNames = 'aircraft-yaw';
export type ActionNames = CameraActionNames | GimbalActionNames | AircraftActionNames;
export const actionPrefix = "model.actions.action."

export type Action = {
    id: number,
    name?: ActionNames,
    icon: string,
    defaultValue?: number,
    minValue?: number,
    maxValue?: number,
    step?: number,
    time?: number,
    unit?: string
}

export const ActionHover: Action = {
    id: 0,
    name: "hover",
    icon: IconNames.TIME,
    defaultValue: 10,
    minValue: 1,
    maxValue: 60 * 60, // 1 hour
    step: 1,
    unit: "model.actions.action.seconds",
}

export const ActionTakePhoto: Action = {
    id: 1,
    name: "take-photo",
    icon: IconNames.CAMERA,
    time: 3,
}

export const ActionStartRec: Action = {
    id: 2,
    name: "start-rec",
    icon: IconNames.RECORD,
    time: 3,
}

export const ActionStopRec: Action = {
    id: 3,
    name: "stop-rec",
    icon: IconNames.STOP,
    time: 3,
}

export const ActionAircraftYaw: Action = {
    id: 4,
    name: "aircraft-yaw",
    icon: IconNames.REFRESH,
    defaultValue: 180,
    minValue: -180,
    maxValue: 180,
    step: 1,
    time: 5,
    unit: "model.actions.action.angle",
}

export const ActionGimbalPitchAngle: Action = {
    id: 5,
    name: "gimbal-pitch-angle",
    icon: IconNames.ARROWS_VERTICAL,
    defaultValue: 0,
    minValue: -90,
    maxValue: 0,
    step: 1,
    time: 3,
    unit: "model.actions.action.degree",
}

export const ActionGimbalYaw: Action = {
    id: 6,
    name: "gimbal-yaw-angle",
    icon: IconNames.ARROWS_HORIZONTAL,
    defaultValue: 0,
    minValue: -306,
    maxValue: 306,
    step: 1,
    time: 3,
    unit: "model.actions.action.degree",
}

export const ActionCameraZoom: Action = {
    id: 7,
    name: "camera-zoom",
    icon: IconNames.SEARCH,
    defaultValue: 2,
    minValue: 1,
    maxValue: 199,
    step: 1,
    time: 3,
}

export const ActionTimedIntervalShot: Action = {
    id: 8,
    name: "timed-interval-shot",
    icon: IconNames.STOPWATCH,
    defaultValue: 3,
    minValue: 1,
    maxValue: 30,
    step: 1,
    time: 10,
}

export const ActionDistanceIntervalShot: Action = {
    id: 9,
    name: "distance-interval-shot",
    icon: IconNames.FLOWS,
    defaultValue: 10,
    minValue: 1,
    maxValue: 100,
    step: 1,
    time: 15,
}

export const ActionPanorama: Action = {
    id: 10,
    name: "panorama",
    icon: IconNames.GLOBE,
    time: 120,
}

export const AllActions: Action[] = [
    ActionHover,
    ActionTakePhoto,
    ActionStartRec,
    ActionStopRec,
    ActionAircraftYaw,
    ActionGimbalPitchAngle,
    ActionGimbalYaw,
    ActionCameraZoom,
    ActionTimedIntervalShot,
    ActionDistanceIntervalShot,
    ActionPanorama,
]

export const ActionById: {[t: number]: Action} = AllActions.reduce((m, o) => {
    m[o.id] = o
    return m
}, {})

export type ActionData = {
    type: Action,
    param?: number,
}

export function ActionTypesIcon(state: number): string {
    if (!(state in ActionById))
        return IconNames.ERROR

    return ActionById[state].icon
}

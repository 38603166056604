import {action, IObservableArray, makeObservable, observable, set} from "mobx"
import type {GpsPosition, GpsPositionDto} from "@/model/GpsTrackLog"

export const isAttitude = (val: GpsPositionDto) => val.y && val.r && val.p
export const isPosition = (val: GpsPositionDto) => val.lo && val.la
export const toLeafletPos = (x: GpsPosition) => ({
    lat: x.la,
    lon: x.lo,
})

export type GpsPositionDtoWithoutTime = Omit<GpsPositionDto, 'time'>

export class GpsTrackStore {
    @observable showTrack = false;
    @observable currentPosition: Partial<GpsPositionDtoWithoutTime> = {};
    @observable combined: IObservableArray<GpsPositionDtoWithoutTime> = [];
    @observable totalTime: ?Date;
    @observable timeLine: IObservableArray<number> = [];
    @observable loading: boolean = false;

    constructor() {
        makeObservable(this)
    }

    @action setLoading = (loading: boolean) => {
        this.loading = loading
    }

    @action init = (res: GpsPositionDto[]) => {
        this.setLoading(false)
        if (res == null || res?.length <= 2) {
            this.reset()
            return
        }

        this.totalTime = new Date(res[res.length - 1].t)

        const withoutTime = res.map(({t, ...rest}) => ({...rest}))
        this.combined.replace(withoutTime)
        this.setCurrentPos(withoutTime[0])
        this.timeLine.replace(res.map((x) => x.t))
        this.showTrack = true
    }

    @action setCurrentPos = (value: Partial<GpsPositionDtoWithoutTime>) => {
        set(this.currentPosition, value)
    }

    @action reset = () => {
        this.loading = false
        this.currentPosition = {}
        this.combined.replace([])
        this.totalTime = null
        this.timeLine.replace([])
        this.showTrack = false
    }

    get getPolylinePos() {
        return this.combined.filter((x) => isPosition(x)).map((x) => toLeafletPos(x))
    }
}

const gpsTrackLog = new GpsTrackStore()
export default gpsTrackLog

import {useCallback, useEffect, useRef, useState} from "react"

const calcDiff = (expiresDate: string) => new Date(expiresDate) - Date.now()

const useTimeLeft = (expiresDate?: Date, onExpire?: () => void) => {
    const intervalRef = useRef()
    const [timeLeft, setTimeLeft] = useState(null)

    const cleanUp = useCallback(() => {
        if (intervalRef.current)
            clearInterval(intervalRef.current)

        setTimeLeft(null)
    }, [])

    const tick = useCallback(() => {
        const diff = calcDiff(expiresDate)
        if (diff < 0) {
            cleanUp()
            if (onExpire)
                onExpire()

            return
        }

        setTimeLeft(new Date(diff))
    }, [onExpire, expiresDate, cleanUp])

    useEffect(() => {
        if (expiresDate) {
            tick()
            intervalRef.current = setInterval(tick, 1000)
        } else
            cleanUp()

        return () => cleanUp()
    }, [tick, expiresDate, cleanUp])

    return timeLeft
}

export default useTimeLeft

import React, {Component} from "react"
import {inject, observer} from "mobx-react"
import {reaction} from "mobx"
import L, {Map} from "leaflet"
import {LayersControl, MapConsumer, MapContainer, TileLayer} from "react-leaflet"
import {
    faBan, faGlobe,
    faLocationArrow, faMap,
    faPause,
    faPlaneArrival,
    faPlaneDeparture,
    faPlay,
    faStop,
    faTrash,
    faUndo,
    faUpload
} from "@fortawesome/free-solid-svg-icons"
import i18next from "i18next"
import {DisplayModes, WaypointStates} from "@/model"
import DroneConnection from "@/connection/DroneConnection"
import {SelectionStore} from "@/store/SelectionStore"
import DroneStore from "@/store/DroneStore"
import RequestStore from "@/store/RequestStore"
import globalStore, {GlobalStore} from "@/store/GlobalStore"
import DroneLayer from "@/components/map/DroneLayer"
import PlanLayer from "@/components/map/PlanLayer"
import StationLayer from "@/components/map/StationLayer"
import {tileLayers} from "@/components/map/Layres"
import Control from "@/components/map/Control"
import FileLocationLayer from "@/components/map/FileLocationLayer"
import GpsTrackLayer from "@/components/map/GpsTrackLayer"
import TrackPlayer from "@/components/map/TrackPlayer"
import CircleButton from "@/components/CircleButton"
import gpsTrackLog from "@/store/GpsTrackStore"
import {PrivateComponent} from "@/authorization/components"
import permissions from "@/authorization/permissions"
import "./LeafletMap.scss"

function defaultLayer() {
    return tileLayers.find((l) => l.default)
}

type Props = {
    selection?: SelectionStore;
    dronesConn?: DroneConnection;
    globalStore?: GlobalStore;
    request?: RequestStore;
    children: React.ReactNode;
    controls?: React.ReactNode;
}

@inject("selection", "dronesConn", "globalStore", "request")
@observer
export default class LeafletMap extends Component<Props> {
    map: Map = null

    constructor(props) {
        super(props)
        this.initLayers()
    }

    componentDidMount() {
        reaction(() => this.props.selection.drone,
            (newDrone, oldDrone) => {
                if (newDrone != null && newDrone?.droneId !== oldDrone?.droneId)
                    this.focusDrone(newDrone, 18)
            })

        reaction(() => this.props.selection.isFocused,
            (isFocused) => {
                if (isFocused)
                    this.focusDrone(this.props.selection.drone, 18)
            })

        reaction(() => this.props.request.id,
            () => {
                const {latitude, longitude} = this.props.request
                if (latitude !== undefined && longitude !== undefined)
                    this.focusRequestMarker(latitude, longitude, 18)
            })

        reaction(() => this.props.selection.plan,
            (plan) => {
                if (plan && plan.points.length > 1) {
                    const bounds = L.latLngBounds(plan.points.map((p) => [p.waypoint?.lat, p.waypoint?.lon]))
                    this.map.fitBounds(bounds)
                }
            })

        reaction(() => this.props.globalStore.collapsed,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                this.map._onResize()
            })
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.off("moveend", this.handleMove)
            this.map.off("zoom", this.handleZoom)
            this.map.off("baselayerchange", this.baseLayerChanged)
        }
    }

    render() {
        const {drone, plan, mission} = this.props.selection
        const missionStartedDate = mission?.startTime ? new Date(mission.startTime) : new Date(0)
        const missionCompletedDate = mission?.completed ? new Date(mission.completed) : new Date(0)

        const showMarkers = gpsTrackLog.combined.length === 0 && !plan?.editMode

        return (
            <div className="leaflet-wrap">
                <MapContainer center={this.getLastPosition()}
                              zoom={this.getLastZoom()}
                              className="map"
                              zoomControl={false}
                              inertia={false}
                              attributionControl={false}
                              crs={defaultLayer().crs || L.CRS.EPSG3857}
                >
                    <MapConsumer>
                        {(map) => {
                            if (!this.map) {
                                this.map = map
                                map.on("moveend", this.handleMove)
                                map.on("zoom", this.handleZoom)
                                map.on("baselayerchange", this.baseLayerChanged)
                            }
                            return null
                        }}
                    </MapConsumer>
                    <LayersControl position="topright">
                        {tileLayers.map((l) => (
                            <LayersControl.BaseLayer checked={l.default} name={l.name} key={l.name}>
                                <TileLayer url={l.url} subdomains={l.subdomains || "abc"} crs={l.crs} h={l.h} />
                            </LayersControl.BaseLayer>
                        ))}
                    </LayersControl>
                    <StationLayer />
                    {(!gpsTrackLog.showTrack || showMarkers) && <DroneLayer />}
                    <FileLocationLayer />
                    <GpsTrackLayer />
                    <Control position="topleft">
                        {this.props.controls}
                        {plan && (
                            <>
                                {plan.selectedWP && plan.editMode
                                && (
                                    <PrivateComponent permission={permissions.planWrite}>
                                        <CircleButton tooltip={i18next.t("drone-map.tooltip.delete-point")}
                                                      icon={faTrash}
                                                      onClick={() => plan.deleteCurrent()}
                                        />
                                    </PrivateComponent>
                                )}
                            </>
                        )}
                        {drone && !plan && (
                            <>
                                {drone.params.gps && drone.params.gps_health >= 3 && (
                                    <CircleButton tooltip={i18next.t("drone-map.tooltip.center-map")}
                                                  icon={faLocationArrow}
                                                  onClick={() => this.focusDrone(this.props.selection.drone)}
                                    />
                                )}
                                <PrivateComponent permission={permissions.droneCanExecute}>
                                    <>
                                        {drone.params.display_mode === DisplayModes.ON_MISSION && drone.params.wp_state !== WaypointStates.STOPPED && (
                                            <CircleButton tooltip={i18next.t("drone-map.tooltip.stop-mission")}
                                                          icon={faStop}
                                                          onClick={() => this.droneCommand("pause_mission")}
                                            />
                                        )}
                                        {drone.params.display_mode !== DisplayModes.ON_MISSION && drone.params.mission_step === "ProcessingMissionStep" && (
                                            <CircleButton tooltip={i18next.t("drone-map.tooltip.resume-mission")}
                                                          icon={faPlay}
                                                          onClick={() => this.droneCommand("resume_mission")}
                                            />
                                        )}
                                        {drone.params.display_mode === DisplayModes.ON_MISSION && drone.params.wp_state === WaypointStates.IN_PROGRESS && (
                                            <CircleButton tooltip={i18next.t("drone-map.tooltip.pause-mission")}
                                                          icon={faPause}
                                                          onClick={() => this.droneCommand("ros_pause_mission")}
                                            />
                                        )}
                                        {drone.params.display_mode === DisplayModes.ON_MISSION && drone.params.wp_state === WaypointStates.PAUSED && (
                                            <CircleButton tooltip={i18next.t("drone-map.tooltip.resume-mission")}
                                                          icon={faPlay}
                                                          onClick={() => this.droneCommand("ros_resume_mission")}
                                            />
                                        )}
                                        {(drone.params.display_mode === DisplayModes.GO_HOME || drone.params.display_mode === DisplayModes.AUTOLANDING) && (
                                            <CircleButton tooltip={i18next.t("drone-map.tooltip.cancel-home")}
                                                          icon={faBan}
                                                          onClick={() => this.droneCommand("ros_exit_rth")}
                                            />
                                        )}
                                        {drone.params.mission_step === "DispatcherControlStep" && (
                                            <>
                                                <CircleButton tooltip={i18next.t("drone-map.tooltip.get-mission")}
                                                              icon={faPlaneDeparture}
                                                              onClick={() => this.droneCommand("dispatcher_control", {step: "get_mission"})}
                                                />
                                                <CircleButton tooltip={i18next.t("drone-map.tooltip.return-station")}
                                                              icon={faUndo}
                                                              onClick={() => this.droneCommand("dispatcher_control", {step: "prepare_landing"})}
                                                />
                                                <CircleButton tooltip={i18next.t("drone-map.tooltip.landing")}
                                                              icon={faPlaneArrival}
                                                              onClick={() => this.droneCommand("dispatcher_control", {step: "landing"})}
                                                />
                                                <CircleButton tooltip={i18next.t("drone-map.tooltip.upload-data")}
                                                              icon={faUpload}
                                                              onClick={() => this.droneCommand("dispatcher_control", {step: "upload"})}
                                                />
                                            </>
                                        )}
                                    </>
                                </PrivateComponent>
                            </>
                        )}
                    </Control>
                    <PlanLayer />
                    {this.props.children}
                </MapContainer>
                <PrivateComponent permission={permissions.missionViewTrackLog}>
                    <TrackPlayer missionStarted={missionStartedDate} missionCompleted={missionCompletedDate} />
                </PrivateComponent>
            </div>
        )
    }

    droneCommand(cmd: string, args?: any) {
        return this.props.dronesConn.command(this.props.selection.drone.droneId, cmd, args)
    }

    focusDrone(drone?: DroneStore, zoom?: number) {
        if (!this.map) return

        if (drone?.params?.gps && drone?.params?.gps_health >= 3)
            this.map.setView(drone?.params?.gps, zoom)
        else if (drone?.stationPosition)
            this.map.setView(drone?.stationPosition, zoom)
    }

    focusRequestMarker(lat?: number, lng: number, zoom?: number) {
        if (!this.map) return
        this.map.setView({lat, lng}, zoom)
    }

    baseLayerChanged = (e) => {
        const crs = e.layer.options.crs || L.CRS.EPSG3857
        const {map} = this

        if (map.options.crs !== crs) {
            const c = map.getCenter()
            map.options.crs = crs
            map.invalidateSize()
            map.setView(c)
        }
        localStorage.setItem("baselayerMap", e.name)
    }

    handleMove = () => {
        const {lat, lng} = this.map.getCenter()
        const position = [lat, lng]
        localStorage.setItem("positionMap", JSON.stringify(position))
        this.props.selection.unFocus()
    }

    handleZoom = () => {
        const zoom = this.map.getZoom()
        localStorage.setItem("zoomMap", zoom)
    }

    getLastPosition() {
        const defaultPosition = [59.946225, 30.35219]
        const lastPosition = localStorage.getItem("positionMap")
        return lastPosition ? JSON.parse(lastPosition) : defaultPosition
    }

    getLastZoom() {
        const defaultZoom = 3
        const lastZoom = localStorage.getItem("zoomMap")
        return lastZoom || defaultZoom
    }

    initLayers() {
        const lastBaselayer = localStorage.getItem("baselayerMap")
        if (lastBaselayer) {
            tileLayers.forEach((layer) => {
                layer.default = layer.name === lastBaselayer
            })
        }
    }
}

import React, { Component } from 'react';
import L from 'leaflet';
import { LayerGroup, Marker, Polygon, Circle, Pane, MapConsumer, Tooltip } from 'react-leaflet';
import { inject, observer } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import i18next from 'i18next';
import PlanStore, { maxLastPointDist } from '@/store/PlanStore';
import { SelectionStore } from '@/store/SelectionStore';
import DroneStore from '@/store/DroneStore';
import { DisplayModes, WaypointStates } from '@/model';
import { POIIcon } from '@/components/plans/WaypointIcons';
import PolylineExt from '@/components/map/PolylineExt';
import { ActionTypesIcon } from '@/model/Actions';
import './PlanLayer.scss';
import permissionsStore from '@/store/PermissionsStore';
import permissions from '@/authorization/permissions';

@observer
// eslint-disable-next-line no-unused-vars
class DroneTargetLine extends Component<{ drone: DroneStore, plan: PlanStore; }> {
	destPoint() {
		const { drone, plan } = this.props;
		if (!drone.params.gps || !drone.params.gps.lat)
			return null;

		const { points } = plan;

		if (drone.params.display_mode === DisplayModes.ON_MISSION) {
			switch (drone.params.wp_state) {
				case WaypointStates.MOVE_TO_FIRST: {
					const first = points[drone.params.wp_curr || 0].waypoint;
					return [first.lat, first.lon];
				}
				case WaypointStates.IN_PROGRESS: {
					if (drone.params.wp_curr === undefined)
						return null;
					const ind = drone.params.wp_curr + 1;
					if (ind >= points.length)
						return null;
					const pt = points[ind].waypoint;
					return [pt.lat, pt.lon];
				}
				default:
					return null;
			}
		}

		if (drone.params.display_mode === DisplayModes.GO_HOME) {
			if (drone.station && drone.station.position)
				return [drone.station.position.lat, drone.station.position.lon];
		}

		return null;
	}

	render() {
		const { drone } = this.props;
		const dest = this.destPoint();

		if (dest) {
			return (
				<PolylineExt
					positions={[[drone.params.gps.lat, drone.params.gps.lon], dest]}
					interactive={false}
				/>
			);
		}
		return <></>;
	}
}

@inject('selection')
@observer
export default class PlanLayer extends Component<{ selection?: SelectionStore; }> {
	map: Map = null;
	mapDown = false;

	componentWillUnmount(): void {
		if (!this.map) return;
		this.map.off('click', this.onMapClick);
		this.map.off('mousedown', this.onMapMouseDown);
		this.map.off('keydown', this.onMapKeyDown);
	}

	// Events
	onMapClick = (e) => {
		if (!this.mapDown) return; // Костыль от ложных срабатываний во время перетаскивания маркера
		if (!permissionsStore.hasPermission(permissions.planWrite))
			return;

		const buttonInPath = e.originalEvent.path && e.originalEvent.path.find((element: Element) => element.tagName === 'BUTTON' && element.classList.contains('circle-button'));
		if (buttonInPath) return; // Костыль от ложных срабатываний(добавления новой точки плана) при клике на кнопку

		const plan = this.props.selection.displayPlan;
		this.props.selection.unFocus();
		if (!plan?.editMode) return;

		if (plan.setupPOIMode)
			plan.setPOIPosition(e.latlng);
		else
			plan.addWaypoint(e.latlng);
	};

	onMapMouseDown = () => {
		const plan = this.props.selection.displayPlan;
		if (!plan || !plan.editMode) return;
		this.mapDown = true;
	};

	onMapKeyDown = (e) => {
		const plan = this.props.selection.displayPlan;
		this.props.selection.unFocus();
		if (!plan || !plan.editMode) return;

		const { key } = e.originalEvent;
		if (key === 'Delete') {
			plan.deleteCurrent();
			this.map.getContainer().focus();
		}
	};

	render() {
		const plan = this.props.selection.displayPlan;
		if (!plan) return <></>;

		const { editMode, points } = plan;

		const stationPosition = this.props.selection.drone?.withStation ? this.props.selection.drone.stationPosition : null;
		const firstPoint = points.length ? points[0].waypoint : null;
		const lastPoint = points.length ? points[points.length - 1].waypoint : null;

		return (
			<LayerGroup>
				<MapConsumer>
					{(map) => {
						this.map = map;
						map.on('click', this.onMapClick);
						map.on('mousedown', this.onMapMouseDown);
						map.on('keydown', this.onMapKeyDown);
						return null;
					}}
				</MapConsumer>

				<Pane name="return-area" style={{ zIndex: 600 }}>
					{stationPosition && editMode && (
						<Circle
							center={[stationPosition.lat, stationPosition.lon]}
							radius={maxLastPointDist}
							fillColor="#000000"
							weight={0}
							interactive={false}
						/>
					)}
				</Pane>

				<Pane name="poi-area" style={{ zIndex: 620 }}>
					{points.map((m, idx) => (
						m.waypoint.pointOfInterest && (idx < points.length - 1 || stationPosition) && (
							<Polygon key={`poi-area-${m.index}`}
								positions={[
									{ lat: m.waypoint.pointOfInterest.lat, lng: m.waypoint.pointOfInterest.lon },
									{ lat: m.waypoint.lat, lng: m.waypoint.lon },
									idx < points.length - 1 ? {
										lat: points[idx + 1].waypoint.lat,
										lng: points[idx + 1].waypoint.lon,
									} : { lat: stationPosition.lat, lng: stationPosition.lon },
								]}
								interactive={false}
								fillColor="#00ff00"
								weight={0}
								zIndexOffset={0}
							/>
						)))}
				</Pane>

				<Pane name="plan-lines" style={{ zIndex: 620 }}>
					{stationPosition && firstPoint && (
						<PolylineExt key="line-from-station"
							positions={[[stationPosition.lat.toFixed(6), stationPosition.lon.toFixed(6)], [firstPoint.lat.toFixed(6), firstPoint.lon.toFixed(6)]]}
							zIndexOffset={0}
							interactive={editMode}
							eventHandlers={{
								click: (e) => {
									if (!editMode) return;
									L.DomEvent.stopPropagation(e);
									L.DomEvent.preventDefault(e);
									plan.addWaypoint(e.latlng);
								},
							}}
						/>
					)}
					{points.map((m, idx) => (idx > 0
						? (
							<PolylineExt key={`line-${m.index}`}
								positions={[
									[points[idx - 1].waypoint.lat, points[idx - 1].waypoint.lon],
									[m.waypoint.lat, m.waypoint.lon],
								]}
								zIndexOffset={0}
								interactive={editMode}
								className={(points[idx - 1].waypoint.isCurve || m.waypoint.isCurve) ? ' curve' : ''}
								eventHandlers={{
									click: (e) => {
										if (!editMode) return;
										L.DomEvent.stopPropagation(e);
										L.DomEvent.preventDefault(e);
										plan.addWaypoint(e.latlng, idx);
									},
								}}
							/>
						) : null))}
					{stationPosition && lastPoint && (
						<PolylineExt positions={[[lastPoint.lat, lastPoint.lon], [stationPosition.lat, stationPosition.lon]]}
							interactive={false}
						/>
					)}
					{!stationPosition && lastPoint && firstPoint && (
						<PolylineExt positions={[[lastPoint.lat, lastPoint.lon], [firstPoint.lat, firstPoint.lon]]}
							interactive={false}
						/>
					)}
				</Pane>
				<Pane name="plan-markers" style={{ zIndex: 630 }}>
					{points.map((m, idx) => (
						<React.Fragment key={`marker-${m.index}-${editMode}`}>
							<Marker position={{ lat: m.waypoint.lat, lng: m.waypoint.lon }}
								draggable={editMode}
								icon={m.getIcon(m.waypoint.actions.items.length, idx)}
								zIndexOffset={1000}
								eventHandlers={{
									click: (e) => {
										if (!editMode) return;
										e.originalEvent.preventDefault();
										plan.selectWP(points[idx]);
									},
									drag: (e) => plan.movePoint(idx, e.latlng),
									mousedown: (e) => {
										if (!editMode) return;
										this.stopMouseDown(e);
									},
								}}
							>
								{m.waypoint.actions.items.length > 0 && (
									<Tooltip direction="top" offset={[0, -10]} opacity={1} className="tooltip-plan-markers">
										{m.waypoint.actions.items.map((i, aidx) => (
											// eslint-disable-next-line react/no-array-index-key
											<div key={aidx} className="tooltip-plan-markers-container">
												<p className="tooltip-plan-markers-block"><Icon icon={ActionTypesIcon(i.type?.id)} className="tooltip-plan-markers-icon" />{i18next.t(i.type?.name)} {i18next.t(i.type?.minValue ? `${i.param} ${i18next.t(i.type?.unit)}` : '')}</p>
											</div>
										))}
									</Tooltip>
								)}

							</Marker>
							{m.waypoint.pointOfInterest && (
								<Marker position={{ lat: m.waypoint.pointOfInterest.lat, lng: m.waypoint.pointOfInterest.lon }}
									interactive={editMode}
									draggable
									icon={POIIcon}
									zIndexOffset={1500}
									eventHandlers={{
										drag: (e) => {
											plan.setPOIPosition(e.latlng, idx);
										},
										mousedown: this.stopMouseDown,
									}}
								/>
							)}
						</React.Fragment>
					))}
				</Pane>
			</LayerGroup>
		);
	}

	stopMouseDown = (e) => {
		L.DomEvent.stopPropagation(e);
		L.DomEvent.preventDefault(e);
		this.mapDown = false;
	};
}

import React, {useCallback, useEffect, useState, VFC} from "react"
import {ParentSize} from "@visx/responsive"
import {observer} from "mobx-react"
import selection from "@/store/SelectionStore"
import api from "@/services/ApiService"
import type {Margin} from "@/components/charts/types"
import XYChart from "@/components/charts/XYChart"
import {ChartSize} from "@/components/charts/types"
import {calcMsRange, calculateInterval} from "@/components/charts/utils"
import type {ChartDataDto, ChartSettingsDto} from "@/model/ChartDto"

type ChartProps = {
	settings: ChartSettingsDto;
	margin?: Margin;
	startTime: Date | null;
	endTime: Date | null;
}

const defaultMsRange = 3600 * 1000

const Chart: VFC<ChartProps> = ({
		margin,
		startTime,
		endTime,
		settings: {
			chartType,
			name,
			deviceType,
			id,
			title,
			unit,
		}
	}) => {
	const drone = selection?.drone?.droneId
	const station = selection?.station?.stationId
	const deviceId = deviceType === "station" ? station : drone

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState()
	const [data, setData] = useState<ChartDataDto>()
	const [box, setBox] = useState<ChartSize>()

	const fetch = useCallback((width) => {
		const msInterval = calculateInterval(
			startTime && endTime ? calcMsRange(startTime, endTime) : defaultMsRange, width,
		)

		setLoading(true)

		api.charts.getStats(
			{
				deviceType,
				id: deviceId,
				name,
				msInterval,
				endTime: endTime?.toISOString(),
				startTime: startTime?.toISOString()
			},
		)
			.then((res) => setData(res))
			.catch((err) => setError(err))
			.finally(() => setLoading(false))
	}, [deviceId, deviceType, endTime, name, startTime])

	useEffect(() => {
		if (!box || box?.width < 0) return
		if (data != null || loading || error) return

		fetch(box.width)
	}, [box, data, error, loading, fetch])

	return (
		<div>
			{title && <h3>{title}</h3>}
			<ParentSize>
				{({width}) => (
					<XYChart
						id={id}
						setBox={setBox}
						margin={margin}
						series={data?.series}
						timeRangeSeconds={data?.timeRangeSeconds}
						startTime={data ? new Date(data?.startTime) : undefined}
						endTime={data ? new Date(data?.endTime) : undefined}
						chartName={name}
						chartType={chartType}
						unit={unit}
						title={title}
						loading={loading}
						width={width}
						height={width / (4 / 3)}
					/>
				)}
			</ParentSize>
		</div>
	)
}

export default observer(Chart)

import HttpService from "@/services/HttpService"
import type {ChartDataDto, ChartSettingsDto, GetStatsParams} from "@/model/ChartDto"

const baseUrl = "/charts"

export default class ChartsService {
	api: HttpService

	constructor(api: HttpService) {
		this.api = api
	}

	getAllSettings: () => Promise<ChartSettingsDto[]> = () => this.api.get<ChartSettingsDto[]>(`${baseUrl}/settings`)

	getStats: (x: GetStatsParams) => Promise<ChartDataDto> = ({
		  deviceType,
		  id,
		  name,
		  msInterval,
		  startTime,
		  endTime
	  }: GetStatsParams) => this.api.get<ChartDataDto>(`${baseUrl}/${deviceType.toLowerCase()}/${id}/${name}`, {
		params: {
			msInterval,
			startTime,
			endTime,
		}
	});
}

import type {StationPosition} from "@/model/Station"
import type Mission from "@/model/Mission"
import type MissionQueryParams from "@/model/MissionQueryParams"
import HttpService from "@/services/HttpService"

const baseUrl = "/station"

export default class StationService {
    constructor(api: HttpService) {
        this.api = api
    }

    setLocation(stationId: string, pos: StationPosition) {
        return this.api.post(`${baseUrl}/${stationId}/location`, pos)
    }

    command(stationId: string, cmdCode: string) {
        return this.api.post(`${baseUrl}/${stationId}/command`, {cmdCode})
    }

    status(stationId: string, cmdId: string) {
        return this.api.get(`${baseUrl}/${stationId}/command/${cmdId}`)
    }

    snowMeltingCommand(stationId: string, mode: string) {
        return this.api.post(`${baseUrl}/${stationId}/snowMeltingCommand`, {mode})
    }

    rename(stationId: string, name: string) {
        return this.api.post(`${baseUrl}/${stationId}/rename`, {name})
    }

    plans(stationId: string) {
        return this.api.get(`${baseUrl}/${stationId}/plans`)
    }

    missionsQuery(stationId: string, params: MissionQueryParams): Promise<Mission[]> {
        return this.api.post(`${baseUrl}/${stationId}/missions`, params)
    }
}

import ThemeWrapper from "./theme-wrapper";
import { PrivateRoute } from "@/authorization/components";
import permissions from "@/authorization/permissions";
import Profile from "@/components/left/profile/Profile";
import Login from "@/components/Login";
import CameraPage from "@/pages/CameraPage";
import DashboardMain from "@/pages/operator/DashboardMain";
import api from "@/services/ApiService";
import globalStore from "@/store/GlobalStore";
import permissionsStore from "@/store/PermissionsStore";
import userInfo from "@/store/UserInfoStore";
import { Intent, Spinner } from "@blueprintjs/core";
import { observer, Provider } from "mobx-react";
import React, { useEffect, VFC } from "react";
import { Route, Switch } from "react-router-dom";

const PageContainer: VFC = () => {
	useEffect(() => {
		api.permissions.getMap()
			.then((x) => permissionsStore.setPermissionsMap(x))
			// eslint-disable-next-line no-console
			.catch((err) => console.error("Unable to continue. No permissions sent", err));

		if (api.auth.getToken()) {
			api.user.profile()
				.then((res) => {
					globalStore.setLoggedIn(true);
					userInfo.setFromProfile(res);
					permissionsStore.setPermissions(res.permissions);
				})
				.catch(() => {
					globalStore.setLoggedIn(false);
				});
		} else
			globalStore.setLoggedIn(false);
	}, []);

	if (globalStore.authLoading) {
		return (
			<Spinner
				className="centered"
				intent={Intent.PRIMARY}
				size={100}
			/>
		);
	}

	return (
		<Provider globalStore={globalStore}>
			<ThemeWrapper>
				<Switch>
					<Route
						path="/login"
						component={Login}
					/>
					<PrivateRoute
						permission={permissions.cameraView}
						path="/camera/:droneId/:camera"
						exact
						component={CameraPage}
					/>
					<PrivateRoute
						path="/profile"
						component={Profile}
					/>
					<PrivateRoute
						component={DashboardMain}
					/>
				</Switch>
			</ThemeWrapper>
		</Provider>
	);
};

export default observer(PageContainer);

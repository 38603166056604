// eslint-disable-next-line import/extensions
import globalStore from "@/store/GlobalStore"
import {observer} from "mobx-react"
import React, {Component} from "react"
import Theme from "@/application/layout/theme"

@observer
export default class ThemeWrapper extends Component<{ children: React.ReactNode; }> {
    getClassName() {
        switch (globalStore.layoutTheme) {
            case Theme.Dark:
                return "bp3-dark"
            default:
                return ""
        }
    }

    render() {
        return (
            <div className={this.getClassName()}>
                {this.props.children}
            </div>
        )
    }
}

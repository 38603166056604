const commonCommands = [
    "EmergencyStop",
    "EnableDroneController",
    "DisableDroneController",
    "RestartComputer",
    "TurnOffComputer",
]

export const autoCommands = [
    "ActivateManualMode",
    "ActivateDrone",
    "ReplaceBatteries",
    "PrepareTakeoff",
    "CloseRoofAndWaitDrone",
    "PrepareLanding",
    "ClampDroneAndCloseRoof",
    "RemoveBatteries",
    "ToggleDroneButton",
    ...commonCommands,
]

export const manualCommands = [
    "ActivateAutoMode",
    "LiftDown",
    "HomeRobot",
    "ClampDroneCentralizers",
    "ReleaseDroneCentralizers",
    "LiftUp",
    "OpenRoof",
    "CloseRoof",
    ...commonCommands,
]

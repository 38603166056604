import React, {Component} from "react"
import {observer} from "mobx-react"
import i18next from "i18next"

import PlanStore from "@/store/PlanStore"
import ParamEditor from "@/components/plans/ParamEditor"

@observer
export default class RouteParamEditor extends Component<{planStore: PlanStore, disabled?: boolean}> {
    render() {
        const {planStore} = this.props
        return (
            <>
                <ParamEditor label={i18next.t("operator.route-param-editor.text.height")}
                             min={50}
                             max={500}
                             step={10}
                             value={planStore.route.height}
                             disabled={this.props.disabled}
                             onChange={(val) => planStore.setRouteParam({height: val})}
                />
                <ParamEditor label={i18next.t("operator.route-param-editor.text.speed")}
                             min={1}
                             max={15}
                             step={1}
                             value={planStore.route.speed}
                             disabled={this.props.disabled}
                             onChange={(val) => planStore.setRouteParam({speed: val})}
                />
            </>
        )
    }
}
